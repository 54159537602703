import React from 'react';
import { Form, Field } from 'react-final-form';

import { RecommendationResponseStyle } from './RecommendationResponseStyle';

import Input from 'components/form/input/Input';
import MultiColorButton from 'components/buttons/multiColorButton/MultiColorButton';
import { View } from 'react-native';

function RecommendationResponseForm({ onSubmit, initialValues, responseType, isLoading }) {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ form }) => (
        <View
          style={RecommendationResponseStyle.form}
        >
          <Field
            style={{ marginLeft: 0, paddingLeft: 0 }}
            name="message"
            label="Votre message"
            width="100%"
            component={Input}
            height={200}
            multiline={true}
            required={true}
          />
          <MultiColorButton
            loading={isLoading}
            text={responseType === 'ACCEPTED' ? 'Accepter la recommandation' : 'Refuser la recommandation'}
            onPress={form.submit}
            style={RecommendationResponseStyle.submit}
          />
        </View>
      )}
    />
  );
}

export default RecommendationResponseForm;
