import { connect } from 'react-redux';
import React, { useState, useEffect, useMemo } from 'react';

import { getEventDetails } from 'store/actions/event';
import { toastError, toastSuccess } from 'services/toasts';

import EventDetails from './EventDetails';

import { isBrowser } from 'components/device';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import HeaderSection from 'components/layouts/headerSection';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';

function Event({
  event,
  userId,
  getEventDetails,
  publicPage = false,
  route: { params: { eventId, status } },
  navigation,
  user,
}) {
  const { push, goBack, reset, canGoBack } = navigation;
  const [statusShown, setStatusShown] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const loadEvent = async () => {
    setRefreshing(true);
    try {
      await getEventDetails(eventId.split('-').slice(-1)[0], publicPage);
    } catch (err) {
      console.error('Error loading event', err);
    } finally {
      setRefreshing(false);
      console.log('EVENT HERE');
    }
  };

  useEffect(() => {
    if (!event) {
      return;
    }
    if (event && !statusShown) {
      if (status === 'success') {
        setStatusShown(true);
        toastSuccess('Votre achat a bien été effectué!');
      }
      if (status === 'error') {
        setStatusShown(true);
        toastError('Votre achat de billet n\'a pas pu être finalisé.');
      }
    }
  }, [event]);

  useEffect(() => {
    return navigation.addListener('focus', loadEvent);
  }, [navigation]);

  const date = event ? new Date(event.start_date) : undefined;
  let organizer = event ? (event.network || event.user) : undefined;
  const takenPlaces = event ? event.user_tickets?.reduce((acc, cur) => (acc + 1), 0) : undefined;
  const availablePlaces = event ? event.total_places - (takenPlaces || 0) : undefined;
  const isInternal = useMemo(() => (
    !publicPage && (
      (event?.cityGroupAuthor?.id || (!event?.user && !event?.network && !event?.cityGroupAuthor)) ||
      (event?.network && [ ...(user?.networks || []), ...(user?.administratedNetworks || []) ]?.some(n => n.id === event.network.id))
    )
  ), [ publicPage, user, event ]);

  return (
    <LoadResourceLayout
      push={push}
      disableMenu={publicPage}
      mobilePadding={false}
      publicPage={publicPage}
      loadResources={loadEvent}
      disableHeader={publicPage}
      headerSection={!publicPage ? <HeaderSection push={push} menuSelect="Events" redirect="Event"/> : null}
      breadCrumb={isBrowser && !publicPage && <Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Events" />}
    >
      <EventDetails
        date={date}
        push={push}
        reset={reset}
        event={event}
        loadEvent={loadEvent}
        organizer={organizer}
        publicPage={publicPage}
        refreshing={refreshing}
        isInternal={isInternal}
        takenPlaces={takenPlaces}
        availablePlaces={availablePlaces}
      />
    </LoadResourceLayout>
  );
}

const mapStateToProps = (state, props) => ({
  event: state.Event.events?.find((e) => e?.id?.toString() === props?.route?.params?.eventId?.toString().split('-').slice(-1)[0]),
  userId: state.Account.userId,
  user: state.Account.currentUser,
});

const actionProps = {
  getEventDetails,
};

export default connect(mapStateToProps, actionProps)(Event);
