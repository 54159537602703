import { StyleSheet } from 'react-native';

import { colors, fonts } from 'styles';

export const SquareButtonStyle = StyleSheet.create({
  container: {
    elevation: 5,
    shadowColor: colors.greyDark,
    shadowOffset: {
    	width: 0,
    	height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  button: {
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  content: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  image: {

  },
  text: {
    fontSize: 14,
    fontFamily: fonts.lato.black,
    textTransform: 'uppercase',
    paddingHorizontal: 10,
    color: colors.greyDark,
  },
});
