import React from 'react';
import { View } from 'react-native';
import { Field, reduxForm } from 'redux-form';

import Input from 'components/form/input/Input';
import styles from './EditPasswordStyle';

const validate = (values) => {
  const errors = {};

  if (!values.newPassword) {
    errors.newPassword = 'Obligatoire';
  } else if (values.newPassword.length < 6) {
    errors.newPassword = 'Minimum 6 caractères';
  }

  if (!values.newPasswordConfirm) {
    errors.newPasswordConfirm = 'Obligatoire';
  } else if (values.newPasswordConfirm !== values.newPassword) {
    errors.newPasswordConfirm = 'Les mots de passe ne sont pas identiques';
  }

  return errors;
};

function EditPasswordForm() {
  return (
    <View style={styles.form}>
      <Field
        width="100%"
        required={true}
        secureTextEntry
        component={Input}
        name="newPassword"
        autoCorrect={false}
        style={styles.input}
        autoCapitalize="none"
        textContentType="password"
        autoCompleteType="password"
        label="Nouveau mot de passe"
      />
      <Field
        width="100%"
        required={true}
        secureTextEntry
        component={Input}
        autoCorrect={false}
        style={styles.input}
        autoCapitalize="none"
        name="newPasswordConfirm"
        textContentType="password"
        autoCompleteType="password"
        label="Confirmez le nouveau mot de passe"
      />
    </View>
  );
}

export default reduxForm({ form: 'editPassword', validate, enableReinitialize: true })(EditPasswordForm);
