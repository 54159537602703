import IBAN from 'iban';

export default (values) => {
  const errors = {
    contact: {},
  };

  if (!values.picture_url) {
    errors.picture_url = 'Obligatoire';
  }
  if (!values.logo_url) {
    errors.logo_url = 'Obligatoire';
  }
  if (!values.contact_email) {
    errors.contact_email = 'Obligatoire';
  }
  if (!values.contact_phone) {
    errors.contact_phone = 'Obligatoire';
  }
  if (!values.name) {
    errors.name = 'Obligatoire';
  }
  if (!values.description) {
    errors.description = 'Obligatoire';
  }
  if (!values.parent_network) {
    errors.parent_network = 'Obligatoire';
  }
  if (!values.address) {
    errors.address = 'Obligatoire';
  }
  // if (!values.iban) {
  //   errors.iban = 'Obligatoire';
  // }
  // if (values.iban && !IBAN.isValid(values.iban)) {
  //   errors.iban = 'Iban Invalide';
  // }
  // if (!values.contact?.firstname) {
  //   errors.contact.firstname = 'Obligatoire';
  // }
  if (!values.contact?.lastname) {
    errors.contact.lastname = 'Obligatoire';
  }
  if (!values.contact?.phoneNumber) {
    errors.contact.phoneNumber = 'Obligatoire';
  }
  if (!values.contact?.email) {
    errors.contact.email = 'Obligatoire';
  }
  return errors;
};
