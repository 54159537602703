import { colors } from 'react-native-elements';

import Api from 'services/api';
import Http from 'services/Http';
import { APP_URL } from '../../config';
import { getCurrentUser } from 'services/reducers/AccountReducerService';

export function getEvents() {
  return async (dispatch) => {
    const response = await Api.events.getAll();

    if (response?.status === 200) {
      const events = response.data;
      dispatch({
        type: 'HOME_EVENTS',
        events,
      });
      dispatch({
        type: 'LOAD_EVENTS',
        events,
      });
      return Promise.resolve(events);
    }

    return Promise.reject();
  };
}

export function getMyEvents() {
  return async (dispatch) => {
    const response = await Api.events.getMine();

    if (response?.status === 200) {
      const events = response.data;
      dispatch({
        type: 'MY_EVENTS',
        events,
      });
      return Promise.resolve(events);
    }

    return Promise.reject();
  };
}

const computeEventDates = (event) => {
  event.start_date = new Date(event.start_date);
  event.end_date = new Date(event.start_date);
  event.start_date.setHours(new Date(event.start_hour).getHours());
  event.start_date.setMinutes(new Date(event.start_hour).getMinutes());
  event.end_date.setHours(new Date(event.end_hour).getHours());
  event.end_date.setMinutes(new Date(event.end_hour).getMinutes());
};

export function createEvent(values, createAs) {
  return async () => {
    try {
      computeEventDates(values);
      console.log('values', values);
      const response = await Api.events.create({ ...values, createAs });
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in create event', err);
      return Promise.reject(err);
    }
  };
}

export function editEvent(values) {
  return async () => {
    try {
      computeEventDates(values);
      const response = await Api.events.edit(values.id, values);
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in create event', err);
      return Promise.reject(err);
    }
  };
}

export function closeInscription(values) {
  return async () => {
    try {
      const response = await Api.events.closeInscription(values.id);
      console.log('Event close inscription');
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in event close inscription', err);
      return Promise.reject(err);
    }
  };
}

export function deleteEvent(values) {
  return async () => {
    try {
      const response = await Api.events.delete(values.id);
      console.log('Event deleted');
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in delete event', err);
      return Promise.reject(err);
    }
  };
}

export function getEventDetails(id, publicPage = false) {
  return async (dispatch) => {
    const response = await Api.events.get(id, publicPage);

    if (response?.status === 200) {
      const event = response.data;
      dispatch({
        type: 'LOAD_EVENT',
        event,
      });
      return event;
    }
    return null;
  };
}

export function networkMembers() {
  return async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    const response = await Http.get({
      url: `users/${currentUser.id}`,
      data: {},
    });
    if (response?.status === 200) {
      const userNetworks = response.data.networkMembers;
      return userNetworks.map((network) => network.network);
    }
    return [];
  };
}

export function Tickets() {
  return async (dispatch) => {
    const response = await Api.profile.getTickets();
    if (response?.status === 200) {
      const tickets = response.data;
      console.log('tickets response', tickets);
      dispatch({
        type: 'LOAD_TICKETS',
        tickets,
      });
      return Promise.resolve(tickets);
    }
    return [];
  };
}

export function BuyTicket(data, paymentInfos) {
  return async (dispatch) => {
    const response = await Http.post({
      url: 'user_events',
      data,
    });
    if (response?.status === 201) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject();
    }
  };
};

export function BuyTicketWeb(data) {
  return async (dispatch) => {
    try {
      const response = await Api.payments.buyTicket(
        data.quantity,
        data.owners,
        data.ticketId,
        data.eventId,
        `${APP_URL}/tickets`,
        `${APP_URL}/tickets`,
      );
      return response;
    } catch (err) {
      console.log('Error in login', err);
      return Promise.reject(err);
    }
  };
}

export function setTicket(ticket) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'EVENT_PLACE',
      eventPlace: ticket,
    });
  };
}

export function LoadHomeEvents(homeEvents) {
  return [];
  return homeEvents
    ? homeEvents.map((event) => ({
      id: event.id,
      date: event.startDate,
      title: event.title,
      subTitle: event.address.city,
      color: event.network ? event.network.type.color : colors.primary,
      image: event.image.url,
      logo: event.network ? event.network.logo.url : '',
    }))
    : [];
}

export function LoadHighLightEvents(events) {
  return events
    ? events.map((event) => ({
      id: event.id,
      date: event.startDate,
      text: event.title,
      network: event.network ? event.network.name : '',
      address: event.address.city,
      image: event.image.url,
    }))
    : [];
}

export function setTicketSelected(ticketSelected) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'TICKET_SELECTED',
      ticketSelected,
    });
  };
}
