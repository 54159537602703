import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Alert, SafeAreaView, Platform } from 'react-native';

import { toastError, toastSuccess } from 'services/toasts';
import { ForgotPassword as forgotPassword } from 'store/actions/account';

import styles from '../register/stepOne/StepOneStyle';
import ForgotPasswordForm from './ForgotPasswordForm';

import Logo from 'components/logo';
import AuthGoBack from 'components/authGoBack';
import TextSection from 'components/textSection';
import MultiColorButton from 'components/buttons/multiColorButton';
import { isMobile, isBrowser, BrowserView } from 'components/device';
import LinearBackground from 'components/backgrounds/linearBackground';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';
import GoBack from '../../../components/buttons/goBackButton';

function ForgotPassword({ submit, forgotPassword, navigation: { navigate, pop, push, goBack } }) {
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');

  const actionForgotPassword = async (values) => {
    try {
      setLoading(true);
      await forgotPassword(values);
      if (isMobile) {
        Alert.alert(
          'Succès',
          'Un e-mail contenant un lien de réinitialisation de votre mot de passe vous a été envoyé.\n\nPensez à vérifier vos indésirables !',
          [
            {
              onPress: pop,
            }
          ],
        );
      }
      if (isBrowser) {
        push('Login');
        toastSuccess('Un lien de réinitialisation vous a été envoyé !');
      }
    } catch (err) {
      console.log('err', err);
      setError('Email incorrect');
      setTimeout(() => {
        setError('');
      }, 3000);
      if (isBrowser) {
        toastError('Email incorrect');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <LinearBackground />
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        {Platform.OS === 'web' ? <View style={{ marginTop: '2%', marginLeft: '2%', width: '100%' }}>
          <AuthGoBack goBack={goBack}/>
        </View> : <GoBack/>
        }
        <SafeAreaView style={styles.container}>
          <Logo style={styles.logo} />
          <View style={{
            width: '100%',
            alignItems: 'center',
            ...isBrowser && {
              width: 450,
              paddingTop: 50,
              borderRadius: 30,
              paddingBottom: 50,
              backgroundColor: 'white',
            }
          }}>
            {/* BROWSER TITLE */}
            <BrowserView style={{ height: 'auto' }}>
              <View style={{ flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 40 }}>
                <TextSection textAlign="center" titleSection="Réinitialisation du mot de passe" noBar />
                <View style={{ width: 30, height: 1, marginTop: 20, backgroundColor: 'black' }} />
              </View>
            </BrowserView>
            <ForgotPasswordForm onSubmit={actionForgotPassword} />
            <Text selectable style={styles.forgotTxt}>Entrez l'email de votre compte afin de recevoir un lien de réinitialisation de mot de passe.</Text>
            <MultiColorButton
              text="Envoyer"
              loading={loading}
              onPress={() => submit('forgotPassword')}
              style={{ marginTop: isMobile ? 40 : '10%', marginBottom: 10 }}
            />
            <TouchableOpacity style={{ marginTop: 0 }} onPress={() => { navigate('Login'); }}>
              <Text selectable style={styles.forgotTxt}>Se connecter</Text>
            </TouchableOpacity>
          </View>
        </SafeAreaView>
      </KeyboardAwareScrollView>
    </View>
  );
}

const mapStateToProps = () => ({});

const actionProps = { forgotPassword, submit };

export default connect(mapStateToProps, actionProps)(ForgotPassword);
