import { Text } from 'native-base';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { View, Alert } from 'react-native';

import { TextStyles } from 'styles';
import { createEvent } from 'store/actions/event';
import { pushToEvent } from 'services/navigationPush';
import { pushToNetwork } from 'services/navigationPush';
import { toastSuccess, toastError } from 'services/toasts';

import CreateEventForm from './CreateEventForm';
import { CreateEventStyle } from './CreateEventStyle';

import CreateAs from 'components/createAs';
import NoResources from 'components/noResources';
import GrayButton from 'components/buttons/grayButton';
import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import BreadCrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import { isMobile, isBrowser, BrowserView } from 'components/device';

function CreateEvent({ navigation: { reset, push, goBack, canGoBack }, createEvent, user }) {
  const [createAs, setCreateAs] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const addEvent = async (values) => {
    setLoading(true);
    try {
      const { event } = await createEvent(values, createAs);
      if (isMobile) {
        push('MyEvents');
      }
      if (isBrowser) {
        reset({
          index: 1,
          routes: [
            { name: 'Home' },
            { name: 'MyEvents' },
          ]
        });
        toastSuccess(`Votre évènement a bien été créé. Code administrateur: ${event.code}`);
      }
    } catch (err) {
      console.log('Error', err);
      if (isMobile) {
        Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
      }
      if (isBrowser) {
        toastError('Une erreur est survenue.');
      }
    } finally {
      setLoading(false);
    }
  };

  const CreateEventContainer = () => {
    if (!createAs) {
      return (
        <CreateAs
          user={user}
          type="Event"
          rightType="EVENTS"
          setCreateAs={setCreateAs}
        />
      );
    }
    return (
      <View style={{ flex: 1, width: '100%' }}>
        <BrowserView>
          <Text selectable style={TextStyles({ marginBottom: 40, width: '100%', textAlign: 'center' }).bold30}>
            Créez votre évènement
          </Text>
        </BrowserView>
        <View style={isMobile && CreateEventStyle.flexGrowOne}>
          <CreateEventForm
            onSubmit={addEvent}
            initialValues={{
              zone: user.zone?.id,
              contact_name: `${user.firstname} ${user.lastname}`,
              contact_email: user.email_contact || user.email,
              contact_number: user.phone,
              tickets: [{}],
            }}
            Button={({ onClick }) => (
              <MultiColorButton
                onPress={onClick}
                text={isMobile ? 'Publier' : 'Publier mon évènement'}
                style={isMobile ? {
                  width: '80%', height: 70, marginBottom: '20%', marginTop: '20%'
                } : {
                  width: 300
                }}
                loading={loading}
              />
            )}
          />
        </View>
      </View>
    );
  };

  return (
    <BasicLayout
      push={push}
      disableMenu={true}
      mobilePadding={false}
      title={'Créer un évènement'}
      headerSection={<HeaderSection push={push} menuSelect="Events" redirect="Event"/>}
      breadCrumb={<BreadCrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Events" />}
    >
      <CreateEventContainer />
    </BasicLayout>
  );
}

const mapStateToProps = (state) => ({
  user: state.Account.essentialUser,
});

const actionProps = {
  createEvent,
};

export default connect(mapStateToProps, actionProps)(CreateEvent);
