import { StyleSheet } from 'react-native';

import { colors, fonts } from 'styles';

export const HollowButtonStyle = StyleSheet.create({
  containerNewButton: {
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: colors.whiteShadow,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    width: '100%',
  },
  textStyleButton: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: '5%',
    color: colors.greyDark,
    fontFamily: fonts.lato.regular,
  },
});
