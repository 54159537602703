import React, { useState } from 'react';
import { EvilIcons } from '@expo/vector-icons';
import { ScrollView, Text, View } from 'native-base';
import { Dimensions, Image, TouchableOpacity } from 'react-native';

import { fonts, TextStyles } from 'styles';
import { pushToEvent, pushToMedia, pushToNetwork, pushToParentNetwork, pushToUser } from 'services/navigationPush';

import HollowBackground from 'components/backgrounds/hollowBackground';

const { width: screenWidth } = Dimensions.get('screen');

const Search = ({
  data,
  push,
  fields,
  type,
  placeholder = 'Recherche',
  value = null,
  width = null,
  onKeyDelete = null,
  borderRadius = 25,
  onPress = null,
  redirect = false
}) => {
  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState(undefined);

  const Item = ({ d }) => {
    switch (type) {
    case 'event':
      return (
        <TouchableOpacity
          onPress={() => pushToEvent(push, d)}
          style={{ height: '100%', width: '100%', flexDirection: 'row', alignItems: 'center' }}
        >
          <Image
            alt="Change me"
            source={{ uri: d.picture_url }}
            style={{
              margin: '5%',
              borderRadius: 10,
              width: screenWidth * 0.025,
              height: screenWidth * 0.025,
            }}
          />
          <View>
            <Text style={TextStyles().bold14}>
              {d.title}
            </Text>
            <Text style={TextStyles().regular14}>
              {d.network ? d.network.name : `${d.user.firstname} ${d.user.lastname}`}
            </Text>
          </View>
        </TouchableOpacity>
      );
    case 'user':
      return (
        <TouchableOpacity
          onPress={onPress ? () => onPress(d) : () => pushToUser(push, d)}
          style={{ height: '100%', width: '100%', flexDirection: 'row', alignItems: 'center' }}
        >
          <Image
            alt="Change me"
            source={{ uri: d.picture_url }}
            style={{
              margin: '5%',
              borderRadius: 10,
              width: screenWidth * 0.025,
              height: screenWidth * 0.025,
            }}
          />
          <View>
            <Text style={TextStyles().bold14}>
              {d.firstname} {d.lastname}
            </Text>
            <Text style={TextStyles().regular14}>
              {d.job}
            </Text>
          </View>
        </TouchableOpacity>
      );
    case 'network':
      return (
        <TouchableOpacity
          onPress={() => !d.name ? pushToParentNetwork(push, d) : pushToNetwork(push, d)}
          style={{ height: '100%', width: '100%', flexDirection: 'row', alignItems: 'center' }}
        >
          <Image
            alt="Change me"
            source={{ uri: d.picture_url }}
            style={{
              margin: '5%',
              borderRadius: 10,
              width: screenWidth * 0.025,
              height: screenWidth * 0.025,
            }}
          />
          <Text style={TextStyles().regular14}>
            {d.name ? d.name : d.description}
          </Text>
        </TouchableOpacity>
      );
    case 'media':
      const title = d.items?.find(i => i.type === 'title')?.content;
      return (
        <TouchableOpacity
          onPress={() => redirect ? push(redirect) : pushToMedia(push, d.type, d)}
          style={{ height: '100%', width: '100%', flexDirection: 'row', alignItems: 'center' }}
        >
          <Image
            alt="Change me"
            source={{ uri: d.picture_url }}
            style={{
              margin: '5%',
              borderRadius: 10,
              width: screenWidth * 0.025,
              height: screenWidth * 0.025,
            }}
          />
          <View>
            <Text style={TextStyles().bold14}>
              {title}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }
  };

  let findNumber = 0;

  return (
    <View style={{ zIndex: 100 }}>
      <View style={{
        overflow: 'hidden',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        borderRadius: borderRadius,
        height: screenWidth * 0.027,
        width: width || screenWidth * 0.144,
      }}>
        <HollowBackground />
        <input
          type="text"
          onKeyDown={onKeyDelete}
          value={value || search}
          placeholder={placeholder}
          onFocus={() => setFocused(true)}
          onChange={e => setSearch(e.target.value)}
          onBlur={() => setTimeout(() => setFocused(false), 100)}
          style={{ backgroundColor: 'transparent', fontFamily: fonts.lato.regular, borderWidth: 0, outline: 'none', width: width ? '82.5%' : '75%' }}
        />
        <EvilIcons name="search" size={30} color="black" style={{ width: '10%' }}/>
      </View>
      {
        focused && search ? (
          <ScrollView
            style={{
              borderRadius: 10, marginTop: screenWidth * 0.028, width: width || screenWidth * 0.144, maxHeight: screenWidth * 0.144, backgroundColor: 'white', position: 'absolute'
            }}
          >
            {console.log('search', search, data)}
            {
              data.map((d, index) => fields.map((field) => {
                let targetString;
                let targetFields = field.split('.');
                if (targetFields.length === 1) {
                  targetString = d[targetFields[0]];
                } else {
                  if (d[targetFields[0]]) {
                    targetString = d[targetFields[0]][targetFields[1]];
                  }
                }
                console.log('targetString', targetString);
                if (targetString?.toLowerCase().includes(search.toLowerCase())) {
                  findNumber += 1;
                  return (
                    <View key={d.id}>
                      <Item d={d} />
                    </View>
                  );
                } else if (d.items?.find(i => i.type === field)?.content?.toLowerCase().includes(search.toLowerCase())) {
                  findNumber += 1;
                  return (
                    <View key={d.id}>
                      <Item d={d} />
                    </View>
                  );
                }
                return null;
              }))
            }
            {
              findNumber === 0 && (
                <View style={{ height: 50, alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                  <Text style={TextStyles().regular14}>Aucun résultat</Text>
                </View>
              )
            }
          </ScrollView>
        ) : null
      }
    </View>
  );
};

export default Search;
