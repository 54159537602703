'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.ParentNetworks = void 0;
var index_1 = require('./index');
var ParentNetworks = /** @class */ (function (_super) {
  __extends(ParentNetworks, _super);
  function ParentNetworks() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  ParentNetworks.prototype.getAll = function (ignoreZone) {
    if (ignoreZone === void 0) { ignoreZone = false; }
    return this.requester.get('/parent-networks?ignoreZone=' + ignoreZone);
  };
  ParentNetworks.prototype.get = function (id) {
    return this.requester.get('/parent-networks/' + id);
  };
  ParentNetworks.prototype.getHome = function () {
    return this.requester.get('/parent-networks/home');
  };
  ParentNetworks.prototype.create = function (data) {
    return this.requester.post('/parent-networks', data);
  };
  return ParentNetworks;
}(index_1.Component));
exports.ParentNetworks = ParentNetworks;
