'use strict';
exports.__esModule = true;
exports.Component = void 0;
var Component = /** @class */ (function () {
  function Component(requester) {
    this.requester = requester;
  }
  return Component;
}());
exports.Component = Component;
