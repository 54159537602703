'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.Profile = void 0;
var index_1 = require('./index');
var Profile = /** @class */ (function (_super) {
  __extends(Profile, _super);
  function Profile() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Profile.prototype.update = function (params) {
    return this.requester.patch('/profile', params);
  };
  Profile.prototype.get = function () {
    return this.requester.get('/profile');
  };
  Profile.prototype.getEssential = function () {
    return this.requester.get('/profile/essential');
  };
  Profile.prototype.getTickets = function () {
    return this.requester.get('/profile/tickets');
  };
  Profile.prototype['delete'] = function (id) {
    return this.requester['delete']('/profile/' + id);
  };
  Profile.prototype.contactUs = function (object, request) {
    return this.requester.post('/profile/contact-us', {
      object: object,
      request: request
    });
  };
  Profile.prototype.setToken = function (token) {
    return this.requester.post('/profile/token', {
      token: token
    });
  };
  Profile.prototype.setCard = function (uuid) {
    return this.requester.post('/profile/nfc-card', {
      uuid: uuid
    });
  };
  Profile.prototype.editEmail = function (newEmail) {
    return this.requester.patch('/profile/email', {
      newEmail: newEmail
    });
  };
  Profile.prototype.editPassword = function (newPassword) {
    return this.requester.patch('/profile/password', {
      newPassword: newPassword
    });
  };
  Profile.prototype.getNotifications = function (clear) {
    return this.requester.get('/profile/notifications?clear=' + clear);
  };
  Profile.prototype.getEditableGroups = function (type) {
    return this.requester.get('/profile/editable-groups?type=' + type);
  };
  return Profile;
}(index_1.Component));
exports.Profile = Profile;
