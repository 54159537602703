import React, { useEffect, useState } from 'react';
import { View } from 'native-base';
import { formValueSelector, submit } from 'redux-form';
import { connect } from 'react-redux';
import { Text, Dimensions, Platform } from 'react-native';

import styles from './StepOneStyle';
import StepOneForm from './StepOneForm';

import Logo from 'components/logo';
import { isBrowser } from 'components/device';
import AuthGoBack  from 'components/authGoBack';
import TextSection from 'components/textSection';
import GoBack from 'components/buttons/goBackButton';
import BombButton from 'components/buttons/bombButton';
import LinearBackground from 'components/backgrounds/linearBackground';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';
import { toastError, toastSuccess } from '../../../../services/toasts';
import { AppleRegister, GoogleRegister, Register } from 'store/actions/account';
import Api from '../../../../services/api';
import Loader from '../../../../components/loader/Loader';

const StepOne = ({
  navigation: { navigate, goBack },
  submit,
  route,
  registerTwo,
  Register,
  AppleRegister,
  GoogleRegister,
}) => {
  const { registerType, firstname, lastname, credential } = { ...route.params }; // Safe destructuring
  const [loading, setLoading] = useState(false);
  const [spheres, setSpheres] = useState(undefined);

  const loadSpheres = async () => {
    try {
      const { data } = await Api.misc.getSpheres();
      setSpheres(data);
    } catch (err) {
      console.error('Error getting spheres', err);
    }
  };

  useEffect(() => {
    loadSpheres();
  }, []);

  if (!spheres) return <Loader />;

  const handleSubmit = async (values) => {
    setLoading(true);
    let userId;
    let token;
    let zoneId;
    try {
      if (registerType === 'form') {
        const result = await Register({
          ...registerTwo,
          ...values,
        });
        userId = result.id;
        token = result.token;
        zoneId = result.zone_id;
      } else if (registerType === 'google') {
        const result = await GoogleRegister({
          ...registerTwo,
          ...values,
          credential: isBrowser ? credential : credential.serverAuthCode,
        });
        userId = result.id;
        token = result.token;
        zoneId = result.zone_id;
      } else if (registerType === 'apple') {
        const result = await AppleRegister({ ...registerTwo, ...values, appleToken: credential.authorizationCode });
        userId = result.id;
        token = result.token;
        zoneId = result.zone_id;
      }
      if (isBrowser) {
        toastSuccess('Votre compte a bien été créé');
      }
      navigate('StepThree', { userId, token, zoneId });
    } catch (err) {
      if (err?.response.data?.err?.id === 'Auth01') {
        toastError('Votre email est déjà utilisé, veuillez vous connecter');
      } else {
        toastError('Une erreur est survenue');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <LinearBackground />
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        {isBrowser ? <View style={{ marginTop: '2%', marginLeft: '2%', width: '100%' }}>
          <AuthGoBack goBack={goBack} />
        </View> : <GoBack />}
        <View style={styles.container}>
          <Logo style={styles.logo} />
          <View style={{
            width: '100%',
            alignItems: 'center',
            ...isBrowser && {
              width: 450,
              paddingTop: 50,
              borderRadius: 30,
              paddingBottom: 50,
              backgroundColor: 'white',
            }
          }}>
            <View style={{ flexDirection: 'column', alignItems: 'center', marginBottom: 20 }}>
              <TextSection titleSection="Inscription" noBar />
              <View style={{ width: 30, height: 1, backgroundColor: 'black', marginTop: isBrowser ? 20 : 0 }} />
            </View>
            <View style={styles.titleContent}>
              <Text style={styles.title}>Tu n'es pas comme les autres.</Text>
              <Text style={styles.title}>Tu es ...</Text>
            </View>
            <StepOneForm
              spheres={spheres}
              initialValues={{ firstname, lastname }}
              onSubmit={handleSubmit}
            />
            <BombButton
              width={255}
              height={52}
              titre="Passer au paiement"
              titrePosition="middle"
              style={{ marginTop: '7%', zIndex: -1 }}
              onPress={() => submit('StepOne')}
              loading={loading}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

const mapStateToProps = (state) => ({
  registerTwo: formValueSelector('StepTwo')(state, 'email', 'password', 'confirmPassword'),
});

const actionProps = {
  submit,
  Register,
  GoogleRegister,
  AppleRegister,
};

export default connect(mapStateToProps, actionProps)(StepOne);
