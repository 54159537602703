import React from 'react';
import moment from 'moment';
import { Pressable, View } from 'native-base';
import { EvilIcons } from '@expo/vector-icons';
import { Image, Text, TouchableOpacity } from 'react-native';

import { colors, fonts } from 'styles';
import { pushToEvent } from 'services/navigationPush';

import styles from './EventStyles';

import { isBrowser } from 'components/device';
import RoundedLogo from 'components/roundedLogo';
import DarkBackground, { DisableCardBackground } from 'components/backgrounds/darkBackground';
import Svg from '../../svg/Svg';
import { OthrBg } from '../../../styles/svg';

export default (props) => {
  const onPress = () => {
    if (props.onPress) {
      props.onPress();
    } else {
      pushToEvent(props.push, props);
    }
  };

  let shortMonth = moment(props.start_date).format('MMM');
  if (shortMonth.length > 3) {
    shortMonth = shortMonth.slice(0, -1);
  }

  return (
    <TouchableOpacity
      activeOpacity={1}
      delayPressIn={50}
      onPress={onPress}
      disabled={props.disabled}
      style={[styles.container, { borderRadius: props.noRadius ? 0 : 15 }]}
    >
      <Image alt="Change me" source={{ uri: props.picture_url }} style={styles.image} />
      {
        !props.disabled && (
          <DarkBackground />
        )
      }
      <View style={styles.dateContainer}>
        <View style={styles.dayContainer}>
          <Text style={styles.day}>{moment(props.start_date).format('ddd').slice(0, -1).toUpperCase()}</Text>
          <View style={styles.separator} />
          <Text style={styles.number}>{moment(props.start_date).format('DD')}</Text>
          <Text adjustsFontSizeToFit style={styles.month}>{shortMonth}</Text>
        </View>
        <Text style={styles.hour}>{moment(props.start_date).format('HH[h]mm')}</Text>
      </View>
      <View style={styles.infosContainer}>
        {props.user_author_id || props.network_author_id ? (
          <RoundedLogo uri={props.user?.logo_url || props.network?.logo_url} style={{ marginBottom: 10, marginRight: isBrowser ? 'auto' : '0%' }} />
        ) : (
          <View style={{ height: 80, width: 80, marginBottom: 10, borderRadius: 10, overflow: 'hidden', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Svg src={OthrBg} height={80} width={80} style={{ borderRadius: 10, overflow: 'hidden', position: 'absolute', top: 0, left: 0, }}/>
            <Text style={{ color: colors.white, opacity: 0.5, fontSize: 25, fontFamily: fonts.lato.bold, marginBottom: 10 }}>{props.zone?.code}</Text>
          </View>
        )}
        <Text adjustsFontSizeToFit numberOfLines={1} style={[styles.title, isBrowser && { fontSize: 20 }]}>{props.title}</Text>
        <View style={styles.locationContainer}>
          <EvilIcons name="location" size={24} color="white" />
          <Text style={styles.location}>{props.address?.city}</Text>
        </View>
      </View>
      {
        props.total_places > props.numberOfTakenTicket && props.total_places - props.numberOfTakenTicket < props.total_places * 0.15 && (
          <View style={{ height: '10%', backgroundColor: colors.orange, justifyContent: 'center' }}>
            <Text style={{ marginLeft: '8%', fontFamily: fonts.lato.bold, fontSize: 18, color: colors.white, textTransform: 'uppercase' }}>
              Dernières places
            </Text>
          </View>
        )
      }
      {
        props.total_places <= props.numberOfTakenTicket && (
          <View style={{ height: '10%', backgroundColor: colors.burgundy, justifyContent: 'center' }}>
            <Text style={{ marginLeft: '8%', fontFamily: fonts.lato.bold, fontSize: 18, color: colors.white, textTransform: 'uppercase' }}>
              COMPLET
            </Text>
          </View>
        )
      }
      {
        props.disabled && (
          <DisableCardBackground />
        )
      }
    </TouchableOpacity>
  );
};
