'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.Media = void 0;
var index_1 = require('./index');
var Media = /** @class */ (function (_super) {
  __extends(Media, _super);
  function Media() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Media.prototype.getMedia = function (type) {
    return this.requester.get('/media?type=' + type);
  };
  Media.prototype.getMedium = function (id, publicPage) {
    if (publicPage) {
      return this.requester.get('/media/' + id + '/public');
    }
    return this.requester.get('/media/' + id);
  };
  Media.prototype.createMedium = function (data) {
    return this.requester.post('/media', data);
  };
  Media.prototype.updateMedium = function (id, data) {
    return this.requester.patch('/media/' + id, data);
  };
  Media.prototype.deleteMedium = function (id) {
    return this.requester['delete']('/media/' + id);
  };
  Media.prototype.likeMedium = function (id) {
    return this.requester.post('/media/' + id + '/like');
  };
  Media.prototype.getMyArticles = function () {
    return this.requester.get('/media?me=true&type=article');
  };
  Media.prototype.getMyPodcasts = function () {
    return this.requester.get('/media?me=true&type=podcast');
  };
  Media.prototype.getMyVideos = function () {
    return this.requester.get('/media?me=true&type=video');
  };
  Media.prototype.startUpload = function (contentType) {
    return this.requester.post('/media/start-upload', {
      contentType: contentType
    });
  };
  Media.prototype.partUpload = function (fileName, partNumber, uploadId) {
    return this.requester.post('/media/part-upload', {
      fileName: fileName,
      partNumber: partNumber,
      uploadId: uploadId
    });
  };
  Media.prototype.completeUpload = function (fileName, parts, uploadId) {
    return this.requester.post('/media/complete-upload', {
      fileName: fileName,
      parts: parts,
      uploadId: uploadId
    });
  };
  return Media;
}(index_1.Component));
exports.Media = Media;
