const articleData = [
  {
    id: '1',
    type: 'media',
    mediaType: 'article',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  },
  {
    id: '1',
    type: 'media',
    mediaType: 'article',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  },
  {
    id: '1',
    type: 'media',
    mediaType: 'article',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  },
  {
    id: '1',
    type: 'media',
    mediaType: 'article',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  }
];

const podcastData = [
  {
    id: '1',
    type: 'media',
    mediaType: 'podcast',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  },
  {
    id: '1',
    type: 'media',
    mediaType: 'podcast',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  },
  {
    id: '1',
    type: 'media',
    mediaType: 'podcast',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  },
  {
    id: '1',
    type: 'media',
    mediaType: 'podcast',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  }
];

const videoData = [
  {
    id: '1',
    type: 'media',
    mediaType: 'video',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  },
  {
    id: '1',
    type: 'media',
    mediaType: 'video',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  },
  {
    id: '1',
    type: 'media',
    mediaType: 'video',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  },
  {
    id: '1',
    type: 'media',
    mediaType: 'video',
    title: 'L\'art dans l\'entreprise',
    sphere: 'Communication',
    image: 'https://img-0.journaldunet.com/gcFxQdXNrx_SEqEx53NYdxidcGM=/1500x/smart/df12c3d6f77445008518acae53aa34a4/ccmcms-jdn/11174895.jpg',
    description: 'Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, it amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.  Sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  }
];

export {
  articleData,
  podcastData,
  videoData,
};
