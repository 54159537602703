import { StyleSheet, Dimensions } from 'react-native';
import { colors, fonts } from 'styles';

const widthScreen = Dimensions.get('screen').width;

export const ConnexionUsersStyle = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentView: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleUser: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: '20%',
  },
  contentBtn: {
    alignSelf: 'center',
    marginTop: '0%',
    marginBottom: '20%',
  },
  contentDescription: {
    width: '60%',
  },
  descriptionUser: {
    marginBottom: 15,
    fontSize: 16,
    color: colors.greyDark,
    fontFamily: fonts.lato.bold,
    lineHeight: 20,
    textAlign: 'center',
  },
  descriptionFooterUser: {
    fontSize: 15,
    color: colors.grayTitle,
    textAlign: 'center',
    width: '85%',
    marginBottom: '10%',
  },
  containerButton: {
    position: 'absolute',
    bottom: 35,
    left: (widthScreen * 0.5) - (169 / 2),
    width: 169,
    backgroundColor: colors.white,
    alignItems: 'center',
    padding: 20,
    borderRadius: (widthScreen * 0.25) / 2,
  },
});
