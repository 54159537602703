import { Platform, StyleSheet } from 'react-native';

export default StyleSheet.create({
  containerEventValidation: {
    width: '75%',
    flexDirection: 'row',
    justifyContent: Platform.OS !== 'web' ? 'space-between' : 'flex-start',
  },
  imgStyle: {
    width: 45,
    height: 45,
    marginHorizontal: 5,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    shadowRadius: 5,
    shadowOpacity: 0.2,
    shadowOffset: { width: 2, height: 2 },
  },
  svgStyle: {
    alignItems: 'center',
    alignSelf: 'center',
  },
  containerButton: {
    margin: 50,
    width: '65%',
  },
  shareButtonContainer: {
    width: '70%',
  },
  hollowButton: {
    width: '100%',
    height: 45,
    marginTop: 20,
    borderRadius: 30,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontSize: 13,
    fontWeight: 'bold',
    padding: 5,
  },
});
