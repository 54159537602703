import { Text, View } from 'native-base';
import React, { useContext } from 'react';

import { colors, fonts } from 'styles';

import { AudioPlayerContext } from 'components/audioPlayer/AudioPlayerContext';
import GlobalPlayer from 'components/audioPlayer/GlobalAudioPlayer';

const PlayerModal = ({ setIsPlayerOpen }) => {
  const { title } = useContext(AudioPlayerContext);

  return (
    <div onMouseLeave={() => setIsPlayerOpen(false)} style={{ zIndex: 3 }}>
      <View
        style={{
          width: 375,
          height: 50,
          marginTop: -50,
          paddingTop: 55,
          marginLeft: 'auto',
          alignItems: 'center',
          marginRight: 5,
        }}
      >
        <View
          style={{
            minHeight: 100,
            width: '100%',
            borderRadius: 10,
            paddingBottom: 10,
            alignItems: 'center',
            backgroundColor: colors.white,
          }}
        >
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 14, paddingTop: 10, }}>{title}</Text>
          <GlobalPlayer />
        </View>
      </View>
    </div>
  );
};

export default PlayerModal;
