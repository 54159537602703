import React from 'react';
import { View } from 'react-native';

import { reduxForm, Field } from 'redux-form';

import styles from './StepOneStyle';
import Input from 'components/form/input';
import PlaceAutocomplete from 'components/form/placeAutocomplete';

const validate = (values) => {
  const errors = {};

  if (!values.firstname || values.firstname.length === 0) {
    errors.firstname = 'Prénom obligatoire';
  }

  if (!values.lastname || values.lastname.length === 0) {
    errors.lastname = 'Nom obligatoire';
  }

  if (!values.phone || values.phone.length === 0) {
    errors.phone = 'Téléphone obligatoire';
  } else if (!values.phone.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/)) {
    errors.phone = 'Numéro invalide';
  }

  if (!values.address || values.address.length === 0) {
    errors.address = 'Siège social obligatoire';
  }
  return errors;
};

function StepOneForm({ spheres }) {
  return (
    <View style={styles.form}>
      <Field
        name="firstname"
        label="Prénom"
        component={Input}
        autoCapitalize={'words'}
        textContentType="givenName"
        width="100%"
        required
      />
      <Field
        name="lastname"
        label="Nom"
        autoCorrect={false}
        returnKeyType="next"
        component={Input}
        autoCapitalize={'words'}
        textContentType="familyName"
        width="100%"
        required
      />
      <Field
        name="phone"
        width="100%"
        required={false}
        component={Input}
        keyboardType="phone-pad"
        label="Téléphone portable"
        textContentType="telephoneNumber"
        required={true}
      />
      <View style={{ zIndex: 100 }}>
        <Field
          width="100%"
          name="address"
          label="Address"
          component={PlaceAutocomplete}
          required={true}
        />
      </View>
    </View>
  );
}

export default reduxForm({ form: 'StepOne', validate, enableReinitialize: true })(StepOneForm);
