import { Platform, StyleSheet } from 'react-native';

import { colors, fonts } from 'styles';

export default StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    borderRadius: 15,
    overflow: 'hidden',
  },
  infosContainer: {
    width: '100%',
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  typeContainer: {
    backgroundColor: colors.burgundy,
    borderRadius: 25,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
    maxWidth: '80%',
    paddingHorizontal: 10,
  },
  type: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    textTransform: 'uppercase',
    fontSize: 16,
  },
  name: {
    marginBottom: 5,
    color: colors.white,
    fontSize: Platform.OS !== 'web' ? 24 : 25,
    lineHeight: Platform.OS === 'web' ? 24 : undefined,
    fontFamily: fonts.lato.bold,
  },
  job: {
    color: colors.white,
    fontSize: 18,
    width: '80%',
    fontFamily: fonts.lato.light,
  },
  linkContainer: {
    flexDirection: 'row',
    borderRadius: 20,
    borderColor: 'white',
    borderWidth: 1,
    padding: 5,
    paddingHorizontal: 10,
    marginLeft: -10,
    marginTop: 10,
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '80%',
  },
  link: {
    color: colors.white,
    fontFamily: fonts.lato.light,
    fontSize: 14,
    marginLeft: 10,
  },
  swapContainer: {
    backgroundColor: '#ffffff55',
    height: 65,
    width: 65,
    borderRadius: 50,
    position: 'absolute',
    top: 20,
    left: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '3%',
    marginLeft: -3,
  },
  location: {
    color: colors.white,
    fontFamily: fonts.lato.light,
    fontSize: 14,
  },
  surname: {
    color: colors.white,
    fontFamily: fonts.alexanderLettering,
    fontSize: Platform.OS === 'web' ? 50 : 100,
    maxWidth: '100%',
  }
});
