import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  linearBackground: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 1,
  },
});
