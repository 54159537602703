import Api from 'services/api';

export function ParentNetworks() {
  return async (dispatch) => {
    const response = await Api.parent_networks.getAll();
    if (response?.status === 200) {
      const parentNetworks = response.data;
      dispatch({
        type: 'LOAD_PARENT_NETWORKS',
        parentNetworks,
      });
    }
  };
}

export function ParentNetwork(id) {
  return async (dispatch) => {
    const response = await Api.parent_networks.get(id);
    if (response?.status === 200) {
      const parentNetwork = response.data;
      dispatch({
        type: 'LOAD_PARENT_NETWORK',
        parentNetwork,
      });
    }
  };
}
