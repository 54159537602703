import React from 'react';
import { View, Image } from 'react-native';

import { images } from 'styles';
import AdvertStyle from './AdvertStyle';

export default function Advert(props) {
  const pubArray = [images.advertBH, images.advertWelcome, images.advertCoulisses];
  const advertBnp = pubArray[Math.floor(Math.random() * pubArray.length)];

  return (
    <View style={[AdvertStyle(props.enableMarginTop).containerPublicite, props.style]}>
      <Image alt="Change me" source={advertBnp} style={AdvertStyle(props.enableMarginTop).imagePub} />
    </View>
  );
}
