import { Text } from 'native-base';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import { View, Alert } from 'react-native';
import React, { useMemo, useState } from 'react';

import { TextStyles } from 'styles';
import { toastError, toastSuccess } from 'services/toasts';
import { editEvent, getEventDetails } from 'store/actions/event';

import EditEventForm from './CreateEventForm';
import { CreateEventStyle } from './CreateEventStyle';

import HeaderSection from 'components/layouts/headerSection';
import BreadCrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import { isMobile, BrowserView, isBrowser } from 'components/device';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';

function EditEvent({
  event,
  editEvent,
  route: { params: { eventId } },
  navigation: { pop, push, goBack, canGoBack },
}) {
  const [loading, setLoading] = useState(false);

  const fetchEvent = async () => {
    await getEventDetails(eventId);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await editEvent(values);
      if (isMobile) {
        Alert.alert(
          'Succès',
          'Vos changements ont bien été sauvegardés',
          [{ text: 'Ok', onPress: pop }],
        );
      }
      if (isBrowser) {
        push('MyEvents');
        toastSuccess('Votre évènement a bien été modifié.');
      }
    } catch (err) {
      console.log('Error', err);
      if (isMobile) {
        Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
      }
      if (isBrowser) {
        toastError('Une erreur est survenue.');
      }
    } finally {
      setLoading(false);
    }
  };

  const initialValues = useMemo(() => {
    if (!event) return null;
    const start_date = new Date(event.start_date);
    const end_date = new Date(event.end_date);
    const start_hour = new Date('2000-01-01');
    const end_hour = new Date('2000-01-01');
    start_hour.setHours(start_date.getHours());
    start_hour.setMinutes(start_date.getMinutes());
    end_hour.setHours(end_date.getHours());
    end_hour.setMinutes(end_date.getMinutes());
    return ({
      ...event,
      start_hour: start_hour.toString(),
      end_hour: end_hour.toString(),
      tickets: event?.tickets.map(t => ({ ...t, price: t.price?.toString(), internal_price: t.internal_price?.toString() })),
      total_places: event?.total_places.toString(),
      zone: event?.zone?.id,
      addressPrecisions: event?.address?.precisions,
    });
  }, [ event ]);

  return (
    <LoadResourceLayout
      push={push}
      disableMenu={true}
      mobilePadding={false}
      title={'Modifier mon event'}
      loadResources={fetchEvent}
      breadCrumb={<BreadCrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Events" />}
      headerSection={<HeaderSection push={push} menuSelect="Events" redirect="Event"/>}
    >
      <View style={{ flex: 1 }}>
        <BrowserView>
          <Text selectable style={TextStyles({ marginBottom: 40, width: '100%', textAlign: 'center' }).bold30}>
            Modifiez votre évènement
          </Text>
        </BrowserView>
        <KeyboardAwareScrollView
          contentContainerStyle={isMobile && CreateEventStyle.flexGrowOne}
          showsVerticalScrollIndicator={false}
        >
          <EditEventForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            Button={({ onClick }) => (
              <MultiColorButton
                onPress={onClick}
                text="Modifier"
                style={isMobile ? CreateEventStyle.mobileEditButton : CreateEventStyle.webEditButton}
                loading={loading}
              />
            )}
          />
        </KeyboardAwareScrollView>
      </View>
    </LoadResourceLayout>
  );
}

const mapStateToPros = (state, props) => ({
  event: state.Event.events?.find((e) => (e?.id?.toString() === props?.route?.params?.eventId?.toString().split('-').slice(-1)[0])),
});

const mapDispatchToProps = {
  getEventDetails,
  submit,
  editEvent,
};

export default connect(mapStateToPros, mapDispatchToProps)(EditEvent);
