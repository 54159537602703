import { colors } from 'styles';
import { StyleSheet } from 'react-native';

const NotificationStyles = StyleSheet.create({
  mobileContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  listContainer: {
    width: '100%',
    paddingBottom: 40,
  },
  notifContainer: {
    height: 80,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 25,
  },
  imageContainer: {
    width: 60,
    height: 60,
    marginLeft: 5,
    borderRadius: 40,
    shadowRadius: 10,
    shadowOpacity: 0.2,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: colors.black,
    backgroundColor: colors.white,
    shadowOffset: { height: 2, width: 0 },
  },
  littleImageContainer: {
    position: 'absolute',
    bottom: -5, right: -5,
    width: 30,
    height: 30,
    borderRadius: 40,
    shadowRadius: 10,
    shadowOpacity: 0.2,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: colors.black,
    backgroundColor: colors.white,
    shadowOffset: { height: 2, width: 0 },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 40,
  },
  textContainer: {
    marginLeft: 10,
    paddingRight: 5,
  },
  seen: {
    width: 10,
    right: 25,
    height: 10,
    borderRadius: 5,
    position: 'absolute',
    backgroundColor: colors.burgundy,
  },
  webContainer: {
    flex: 1,
    width: '50%',
    marginLeft: '25%',
  },
  webTitleContainer: {
    marginBottom: 30,
    alignItems: 'center',
  },
  webTitleTextContainer: {
    width: '93%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  webTitleRow: {
    marginLeft: 'auto',
    flexDirection: 'row',
  }
});

export default NotificationStyles;
