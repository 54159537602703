import Api from 'services/api';
import { View } from 'react-native';
import { useEffect, useState } from 'react';
import EventCard from 'components/cards/event';

export default ({
  route: { params: { networkId } },
}) => {
  const [events, setEvents] = useState(null);

  const loadEvents = async () => {
    try {
      const { data: events } = await Api.events.getNetworkEvents(networkId);
      setEvents(events);
    } catch (err) {
      console.error('Othr - Error loading events', err);
    }
  };

  const handleClick = (eventId) => {
    window.open(`https://app.othr.pro/events/${eventId}`);
  };

  useEffect(() => {
    loadEvents();
  }, []);

  return (
    <View style={{ flex: 1, width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center', paddingTop: 20 }}>
      {events?.map((e) => (
        <View key={e.id} style={{ width: 300, height: 400, marginBottom: 20 }}>
          <EventCard {...e} onPress={() => handleClick(e.id)} />
        </View>
      ))}
    </View>
  );
};
