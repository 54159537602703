'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.Networks = void 0;
var index_1 = require('./index');
var Networks = /** @class */ (function (_super) {
  __extends(Networks, _super);
  function Networks() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Networks.prototype.getAll = function () {
    return this.requester.get('/networks');
  };
  Networks.prototype.get = function (id) {
    return this.requester.get('/networks/' + id);
  };
  Networks.prototype.getPublic = function (id) {
    return this.requester.get('/networks/' + id + '/public');
  };
  Networks.prototype.getHome = function () {
    return this.requester.get('/networks/home');
  };
  Networks.prototype.search = function (q) {
    return this.requester.get('/networks/search/' + q);
  };
  Networks.prototype.create = function (data) {
    return this.requester.post('/networks', data);
  };
  Networks.prototype.join = function (id) {
    return this.requester.post('/networks/' + id + '/join_request');
  };
  Networks.prototype.edit = function (id, data) {
    return this.requester.patch('/networks/' + id, data);
  };
  Networks.prototype.contact = function (networkId, title, message) {
    return this.requester.post('/networks/' + networkId + '/contact', {
      title: title,
      message: message
    });
  };
  Networks.prototype.invite = function (name, email, message) {
    return this.requester.post('/networks/invite', {
      name: name,
      email: email,
      message: message
    });
  };
  return Networks;
}(index_1.Component));
exports.Networks = Networks;
