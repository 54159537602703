'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.Recommendations = void 0;
var index_1 = require('./index');
var Recommendations = /** @class */ (function (_super) {
  __extends(Recommendations, _super);
  function Recommendations() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Recommendations.prototype.getAll = function () {
    return this.requester.get('/recommendations');
  };
  Recommendations.prototype.getGiven = function () {
    return this.requester.get('/recommendations/given');
  };
  Recommendations.prototype.getReceived = function () {
    return this.requester.get('/recommendations/received');
  };
  Recommendations.prototype.get = function (id) {
    return this.requester.get('/recommendations/' + id);
  };
  Recommendations.prototype.create = function (data) {
    return this.requester.post('/recommendations', data);
  };
  Recommendations.prototype['delete'] = function (id) {
    return this.requester['delete']('/recommendations/' + id);
  };
  Recommendations.prototype.accept = function (id, message) {
    return this.requester.put('/recommendations/' + id + '/accept', { message: message });
  };
  Recommendations.prototype.refuse = function (id, message) {
    return this.requester.put('/recommendations/' + id + '/refuse', { message: message });
  };
  Recommendations.prototype.contractSigned = function (id, amount) {
    return this.requester.post('/recommendations/' + id + '/contract-signed', { amount: amount });
  };
  return Recommendations;
}(index_1.Component));
exports.Recommendations = Recommendations;
