import React from 'react';
import { Alert, View } from 'react-native';

import { MenuStyle } from './MenuStyle';
import HollowButton from '../buttons/hollowButton';

const SubMenu = ({ item, index, onPress, logout }) => {
  const handleClick = () => {
    if (item.logout) {
      Alert.alert(
        'Déconnexion',
        'Êtes-vous sûr de vouloir vous déconnecter ?', [
          {
            text: 'Annuler',
            style: 'cancel',
          },
          {
            text: 'Oui',
            onPress: logout,
          },
        ],
      );
      return;
    }
    if (item.disabled) {
      Alert.alert('Bientôt disponible !', 'Cette fonctionnalité est encore en cours de développement');
      return;
    }
    onPress(item.target);
  };

  return (
    <View key={index} style={[MenuStyle.subMenu, { opacity: item.disabled ? 0.3 : 1 }]}>
      <HollowButton
        onPress={handleClick}
        textStyle={{ fontSize: 10 }}
        images={null}
        width={65}
        height={65}
        svg={item.image}
        titre={item.text}
        shadow
        corner={0.1}
      />
    </View>
  );
};

export default SubMenu;
