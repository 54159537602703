import React, { useEffect, useRef, useState } from 'react';

export const AudioPlayerContext = React.createContext(null);

const AudioPlayerContextProvider = ({ children }) => {
  const [title, setTitle] = useState(null);
  const [audio, setAudio] = useState(null);
  const [status, setStatus] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [current, setCurrent] = useState(0);
  const [duration, setDuration] = useState(0);
  const [ loading, setLoading ] = useState(true);
  const [ mediumId, setMediumId ] = useState(null);

  const intervalRef = useRef(null);

  const increment = async () => {
    const status = await audio?.getStatusAsync();
    console.log('increment status', status);
    setStatus(status);
    if (!status.isPlaying) {
      clearInterval(intervalRef.current);
    }
    if (status.positionMillis === status.durationMillis) {
      setCurrent(0);
    } else {
      setCurrent(status.positionMillis / 1000);
    }
  };

  const togglePlayAudio = async () => {
    setLoading(true);
    if (!audio) {
      return;
    }
    const status = await audio.getStatusAsync();
    if (status.isPlaying) {
      clearInterval(intervalRef.current);
      await audio.pauseAsync();
    } else {
      if (status.positionMillis === status.durationMillis) {
        await audio.setPositionAsync(0);
      }
      await audio.playAsync();
      intervalRef.current = setInterval(increment, 1000);
    }
    // Wait a bit so that the newStatus will have to real playing status
    await new Promise((resolve) => setTimeout(resolve, 100));
    const newStatus = await audio.getStatusAsync();
    setStatus(newStatus);
    setLoading(false);
  };

  const killAudio = async () => {
    await audio.unloadAsync();
  };

  const loadAudio = async (newAudio, title, duration, audioUrl, mediumId) => {
    setLoading(true);
    if (audio) {
      clearInterval(intervalRef.current);
      await killAudio();
    }
    setCurrent(0);
    setAudio(newAudio);
    setTitle(title);
    setDuration(duration);
    setAudioUrl(audioUrl);
    setMediumId(mediumId);
    setLoading(false);
  };

  const setPosition = async (newValue) => {
    setLoading(true);
    try {
      await audio.playFromPositionAsync(newValue);
      await new Promise((resolve) => setTimeout(resolve, 100));
      await increment();
    } catch (err) {
      console.error('Error setting position', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    togglePlayAudio();
  }, [ audio ]);

  return (
    <AudioPlayerContext.Provider
      value={{
        title,
        audio,
        status,
        current,
        duration,
        audioUrl,
        setAudio,
        setTitle,
        setStatus,
        setCurrent,
        setDuration,
        setAudioUrl,
        intervalRef,
        togglePlayAudio,
        loadAudio,
        setPosition,
        mediumId,
      }}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerContextProvider;
