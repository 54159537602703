import { StyleSheet } from 'react-native';
import { colors, fonts } from 'styles';

const BombButtonStyle = (props) => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: props && props.titre ? '50%' : '100%',
    width: (props && props.titre && props.titrePosition === 'middle') ? '60%' : 50,
    flexDirection: props && props.titrePosition === 'middle' ? 'row' : 'column',
  },
  content: {
    backgroundColor: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#4B4B4B29',
    shadowOpacity: 1,
    shadowRadius: 20,
    shadowOffset: { width: 10, height: 10 },
    elevation: 12,
    borderWidth: 0.1,
    borderColor: 'white',
  },
  containerNewButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textStyleButton: {
    fontSize: 15,
    textAlign: 'center',
    fontWeight: 'bold',
    color: colors.grey,
    fontFamily: fonts.lato.bold,
    marginBottom: 0,
  },
  imgLogoButton: {
    width: 53,
    height: 53,
    resizeMode: 'center',
    backgroundColor: 'green',
  },
});

export default BombButtonStyle;
