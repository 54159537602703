import IBAN from 'iban';
import Api from '../../../services/api';
import { ARRAY_ERROR } from 'final-form';

export const validate = async (values) => {
  const errors = {};

  if (!values.picture_url) {
    errors.picture_url = 'Obligatoire';
  }
  if (!values.title || values.title.length === 0) {
    errors.title = 'Obligatoire';
  }
  if (!values.start_date) {
    errors.start_date = 'Obligatoire';
  }
  /*
  if (!values.end_date) {
    errors.end_date = 'Obligatoire';
  }
   */
  if (!values.start_hour) {
    errors.start_hour = 'Obligatoire';
  }
  if (!values.end_hour) {
    errors.end_hour = 'Obligatoire';
  }
  /*
  if (values.start_date && values.end_date) {
    const start = moment(values.start_date);
    const end = moment(values.end_date);
    if (start > end) {
      errors.end_date = 'La date de fin doit être postérieure à la date de début';
    } else if (start.isSame(end) && new Date(values.start_hour) >= new Date(values.end_hour)) {
      errors.end_hour = 'L\'heure de fin doit être postérieure à l\'heure de début';
    }
  }
   */
  if (!values.total_places) {
    errors.total_places = 'Obligatoire';
  } else if (!Number.isInteger(Number(values.total_places)) || Number(values.total_places) < 1) {
    errors.total_places = 'Nombre invalide';
  }
  if (values.address) {
    if (values.address.placeId) {
      try {
        await Api.address.verify(values.address.placeId);
      } catch (err) {
        errors.address = 'L\'Address doit être comme "123 rue du Moulin, Paris France"';
      }
    }
    if (!values.address.number) {
      errors.address = 'Vous devez renseigner un numéro';
    }
    if (!values.address.city) {
      errors.address = 'Vous devez renseigner un numéro';
    }
  }
  if (!values.address) {
    errors.address = 'Obligatoire';
  }
  if (!values.description) {
    errors.description = 'Obligatoire';
  }
  if (!values.tickets) {
    errors.tickets = 'Obligatoire';
  }
  if (!values.contact_name) {
    errors.contact_name = 'Obligatoire';
  }
  if (!values.contact_number) {
    errors.contact_number = 'Obligatoire';
  }
  if (!values.contact_email) {
    errors.contact_email = 'Obligatoire';
  }
  if (!values.tickets || values.tickets.length < 1) {
    console.log('error tickets');
    errors.tickets = {};
    errors.tickets[ARRAY_ERROR] = 'Vous devez renseigner au moins un billet' ;
  } else {
    errors.tickets = [];
    values.tickets.map((ticket, i) => {
      errors.tickets[i] = {};
      console.log('ticket', ticket);
      if (!ticket.title) errors.tickets[i].title = 'Obligatoire';
      if (!ticket.description) errors.tickets[i].description = 'Obligatoire';
      if (!parseInt(ticket.price, 10)) {
        console.log('error', ticket.price);
        errors.tickets[i].price = 'Le prix public doit être supérieur à 0€';
      }
    });
  }

  return errors;
};
