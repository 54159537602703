import React from 'react';
import { View } from 'react-native';
import { Field, reduxForm } from 'redux-form';

import Input from 'components/form/input/Input';

import styles from './ContactNetworkStyle';

const validate = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Obligatoire';
  }
  if (!values.message) {
    errors.message = 'Obligatoire';
  }

  return errors;
};

function ContactNetworkForm() {
  return (
    <View style={styles.form}>
      <Field
        name="title"
        width="100%"
        required={true}
        component={Input}
        label="Objet du message"
      />
      <Field
        width="100%"
        name="message"
        required={true}
        multiline={true}
        component={Input}
        label="Votre message"
      />
    </View>
  );
}

export default reduxForm({ form: 'contactNetwork', validate, enableReinitialize: true })(ContactNetworkForm);
