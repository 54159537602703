import { View } from 'native-base';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { ScrollView, Text, TouchableOpacity } from 'react-native';

import Api from 'services/api';
import { colors, fonts, TextStyles } from 'styles';
import { SwapWhite, ArrowDownWhite } from 'styles/svg';
import { acceptSwapRequest, rejectSwapRequest, removeRelation, getSwapRequests } from 'store/actions/user';

import UserDetails from './UserDetails';
import UserDetailsStyle from './UserDetailsStyle';

import Svg from 'components/svg';
import GrayButton from 'components/buttons/grayButton';
import { isMobile, isBrowser } from 'components/device';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import HollowBackground from 'components/backgrounds/hollowBackground';

const UserDetailsContainer = ({
  navigation: { goBack, addListener, push, canGoBack }, route,
  currentUser,
  removeRelation,
  acceptSwapRequest,
  rejectSwapRequest,
  getSwapRequests,
}) => {
  const [user, setUser] = useState(null);
  const [showSwapRequest, setShowSwapRequest] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);

  const userId = route.params?.userId ? route.params?.userId?.toString()?.split('-')?.slice(-1)[0] : null;

  const loadUser = async () => {
    try {
      const { data } = await Api.users.get(userId);
      setUser(data);
      if (currentUser.swapRequests) {
        getSwapRequests();
      } else {
        await getSwapRequests();
      }
    } catch (err) {
      console.error('Error loading user', err);
    }
  };

  const handleAccept = async () => {
    try {
      setLoadingAccept(true);
      await acceptSwapRequest(user?.swapRequest?.id);
      setLoadingAccept(false);
      push('ValidationSwapRequest', { type: 'accepted' });
    } catch (err) {
      console.error('Error accepting swap request', err);
      setLoadingAccept(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoadingReject(true);
      await rejectSwapRequest(user?.swapRequest?.id);
      setLoadingReject(false);
      push('ValidationSwapRequest', { type: 'rejected' });
    } catch (err) {
      console.error('Error refusing swap request', err);
      setLoadingReject(false);
    }
  };

  const SwapRequestLabel = (
    <View style={UserDetailsStyle.srLabelContainer}>
      <TouchableOpacity style={UserDetailsStyle.srTouchableContainer} onPress={() => setShowSwapRequest(!showSwapRequest)}>
        <View style={UserDetailsStyle.srTextContainer}>
          <Svg src={SwapWhite} width={21} height={12}/>
          <Text selectable style={UserDetailsStyle.srText}>
            {user?.firstname} souhaite vous rencontrer
          </Text>
        </View>
        <Svg src={ArrowDownWhite} width={15} height={8.5}/>
      </TouchableOpacity>
      {showSwapRequest && <ScrollView style={{ width: '100%', height: 430 }} contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20, alignItems: 'center' }}>
        <View style={{ marginTop: 20, minHeight: 240, width: '100%', borderRadius: 15, overflow: 'hidden' }}>
          <HollowBackground/>
          <Text style={{ color: colors.grey, fontFamily: fonts.lato.regular, fontSize: 14, margin: 20, lineHeight: 20 }}>{user.swapRequest.description}</Text>
        </View>
        <Text style={TextStyles({ width: '90%', color: colors.greyDayColor, textAlign: 'center', marginTop: 10 }).regular12}>
          Si vous acceptez, vos cordonnées seront transmises à {user.firstname} lors de cette demande afin de prendre contact avec vous
        </Text>
        <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: 45 }}>
          <GrayButton titre={'Accepter'} width={'45%'} height={40} onPress={handleAccept} loading={loadingAccept}/>
          <GrayButton titre={'Refuser'} width={'45%'} height={40} onPress={handleReject} loading={loadingReject}/>
        </View>
      </ScrollView>}
    </View>
  );

  return (
    <LoadResourceLayout
      push={push}
      disableMenu={true}
      mobilePadding={false}
      loadResources={loadUser}
      addListener={addListener}
      BottomComponent={isMobile && currentUser.id?.toString() !== userId && user?.swapRequest && SwapRequestLabel}
      headerSection={
        currentUser.id?.toString() !== userId &&
        <HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>
      }
      breadCrumb={
        isBrowser &&
        currentUser.id?.toString() !== userId &&
        <Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Othrs" />
      }
    >
      <UserDetails
        user={user}
        push={push}
        route={route}
        goBack={goBack}
        currentUser={currentUser}
        userId={currentUser.userId}
        removeRelation={removeRelation}
        acceptSwapRequest={acceptSwapRequest}
        rejectSwapRequest={rejectSwapRequest}
      />
    </LoadResourceLayout>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.Account.currentUser,
});

const ActionsProps = {
  removeRelation,
  acceptSwapRequest,
  rejectSwapRequest,
  getSwapRequests,
};

export default connect(mapStateToProps, ActionsProps)(UserDetailsContainer);
