import { StyleSheet, Platform } from 'react-native';

export const RecommendationResponseStyle = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    marginTop: Platform.OS !== 'web' ? 15 : '5%',
    width: '90%',
    alignItems: 'center',
  },
  submit: {
    marginBottom: 50,
    marginTop: 40,
    width: Platform.OS === 'web' ? undefined : '100%',
  }
});
