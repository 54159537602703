import { StyleSheet, Platform, Dimensions } from 'react-native';
import { colors, fonts } from 'styles';
import { isIphoneX, getStatusBarHeight } from 'react-native-iphone-x-helper';

const { height } = Dimensions.get('screen');

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: Platform.OS !== 'web' ? 'flex-start' : 'center',
    width: '100%',
    minHeight: height * 0.9,
    paddingTop: Platform.OS !== 'web' ? getStatusBarHeight(true) : 0,
    paddingBottom: 40,
  },
  logo: {
    marginTop: Platform.OS !== 'web' ? isIphoneX() ? '5%' : '10%' : '-5%',
    marginBottom: Platform.OS !== 'web' ? isIphoneX() ? '10%' : '5%' : '2%',
  },
  titleContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 0,
  },
  title: {
    color: colors.greyDayColor,
    fontSize: 15,
  },
  form: {
    width: '70%',
    marginTop: Platform.OS !== 'web' ? '10%' : '0%',
  },
  forgotTxt: {
    color: colors.grey,
    fontSize: 14,
    opacity: 0.5,
    textAlign: 'center',
    marginTop: 10,
    width: '80%',
  },
  separatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 40,
    marginBottom: 40,
    width: 250,
  },
  separator: {
    flex: 1,
    height: 1,
    backgroundColor: colors.greyDayColor,
  },
  separatorText: {
    fontFamily: fonts.lato.regular,
    color: colors.greyMedium,
    fontSize: 14,
    marginHorizontal: 15,
    lineHeight: 14,
    marginBottom: 0,
  }
});
