import { StyleSheet } from 'react-native';
import { colors, fonts } from 'styles';

const PickerStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: 52,
    borderRadius: 30,
    alignItems: 'center',
    overflow: 'hidden',
  },
  inputIOS: {
    left: '7%',
    color: colors.grey,
    fontSize: 14,
    fontFamily: fonts.lato.regular,
    width: '100%',
    height: '100%',
  },
  inputAndroid: {
    left: '7%',
    color: colors.grey,
    fontSize: 14,
    fontFamily: fonts.lato.regular,
    width: '100%',
    height: '100%',
  },
  placeholder: {
    color: colors.secondary,
  },
});

export default PickerStyles;
