import React from 'react';
import Slider from 'react-slider';
import { Text, View } from 'react-native';
import AudioPlayerStyle from '../audioPlayer/AudioPlayerStyle';
import { colors, TextStyles } from '../../styles';
import moment from 'moment/moment';

import styles from './AudioSliderStyle';

const CustomSlider = ({ realCurrent, realDuration, onChange, disabled }) => {
  const renderTrack = (props, state) => {
    return (
      <div {...props} style={{
        ...props.style,
        ...styles().playerBar,
        backgroundColor: state.index === 0 ? colors.burgundy : colors.black
      }}/>
    );
  };

  const renderThumb = (props, state) => {
    return (
      <div {...props} style={{ ...props.style, ...styles().playerDuration, marginTop: -4 }}/>
    );
  };

  return (
    <div style={{ width: '100%', paddingRight: 30 }}>
      <View style={styles().spaceholder} />
      <Slider
        disabled={disabled}
        style={{ alignItems: 'center' }}
        // className="slider"
        value={Math.round(realCurrent / realDuration * 1000)}
        min={0}
        max={1000}
        defaultValue={0}
        renderTrack={renderTrack}
        renderThumb={renderThumb}
        onChange={onChange}
      />
      <View style={styles().playerTimerContainer}>
        <Text style={TextStyles().bold10}>{realCurrent >= 0 ? moment.utc(realCurrent * 1000).format('mm:ss') : '__:__'}</Text>
        <Text style={TextStyles().bold10}>{realDuration ? moment.utc(realDuration * 1000).format('mm:ss') : '__:__'}</Text>
      </View>
    </div>
  );
  // return (
  //   <View style={AudioPlayerStyle().playerContainer}>
  //     <View style={AudioPlayerStyle().playerBar}/>
  //     <View style={AudioPlayerStyle({ current: realCurrent, duration: realDuration }).playerDuration}/>
  //     <View style={AudioPlayerStyle().playerTimerContainer}>
  //       <Text style={TextStyles().bold10}>{realCurrent >= 0 ? moment.utc(realCurrent * 1000).format('mm:ss') : '__:__'}</Text>
  //       <Text style={TextStyles({ color: colors.greyDayColor }).bold10}>{realDuration ? moment.utc(realDuration * 1000).format('mm:ss') : '__:__'}</Text>
  //     </View>
  //   </View>
  // );
};

export default CustomSlider;
