import { Platform, StyleSheet } from 'react-native';

import { colors, fonts } from 'styles';

export const HomeStyle = StyleSheet.create({
  container: {
    flex: 1,
    overflow: Platform.OS === 'web' ? 'visible' : 'scroll',
    backgroundColor: 'transparent',
  },
  containerCount: {
    width: '90%',
    height: 85,
    borderRadius: 50,
    marginTop: '10%',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  textCountContainer: {
    alignItems: 'center',
    width: '30%',
  },
  textCount: {
    fontSize: 30,
    fontFamily: fonts.lato.bold,
  },
  titleCount: {
    fontSize: 14,
    fontFamily: fonts.lato.regular,
    color: colors.grey,
    marginTop: '2%',
  },
});
