import React from 'react';
import { connect } from 'react-redux';
import { View, Text, Platform, Dimensions, useWindowDimensions, TouchableOpacity } from 'react-native';

import RecommendationDetailsForm from './RecommendationDetailsForm';
import { RecommendationDetailsStyle } from './RecommendationDetailsStyle';

import { colors, fonts } from 'styles';
import {
  acceptRecommendation,
  refuseRecommendation,
  getRecommendationDetails,
} from 'store/actions/recommendation';

import GrayButton from 'components/buttons/grayButton';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton/MultiColorButton';
import BackgroundLinearGradient from 'components/backgrounds/backgroundLinearGradient';
import LoadResourceLayout from '../../../components/layouts/loadResourceLayout';
import { pushToUser } from '../../../services/navigationPush';
import WhiteButton from '../../../components/buttons/whiteButton';

const recommendationStatus = (status, amount) => {
  if (status === 'PENDING') {
    return 'Statut: En attente';
  } else if (status === 'ACCEPTED') {
    return 'Statut: Accepté';
  } else if (status === 'REFUSED') {
    return 'Statut: Refusé';
  }
  if (!amount) {
    return 'Statut: Erreur';
  }
  return `Devis: ${amount}€`;
};

function HeaderUser({ userId, push, recommendation, height }) {
  const user = recommendation.user.id === userId ? recommendation.worker : recommendation.user;
  const id = recommendation.user.id === userId ? recommendation.recommended_user_id : recommendation.user_author_id;

  return (
    <TouchableOpacity onPress={() => pushToUser(push, { ...user, id })} style={RecommendationDetailsStyle.contentOtherSlideNew}>
      <BackgroundLinearGradient
        image={{ uri: user?.picture_url }}
        width="100%"
        height={height}
        styles={RecommendationDetailsStyle.contentSlideText}
      >
        <View style={RecommendationDetailsStyle.contentText}>
          <Text style={RecommendationDetailsStyle.nameHeader}>{user?.firstname} {user?.lastname}</Text>
          <View style={RecommendationDetailsStyle.statusContainer}>
            <Text style={RecommendationDetailsStyle.status}>{recommendationStatus(recommendation.status, recommendation.contract_amount)}</Text>
          </View>
        </View>
      </BackgroundLinearGradient>
    </TouchableOpacity>
  );
}

function RecommendationDetails({
  route,
  userId,
  recommendation,
  getRecommendationDetails,
  navigation: { goBack, push, canGoBack },
}) {
  const { height } = useWindowDimensions();

  const loadRecommendation = async () => {
    try {
      await getRecommendationDetails(route.params.recommendationId);
    } catch (err) {
      console.log('Error while getting user', err);
    }
  };

  return (
    <LoadResourceLayout
      push={push}
      disableFooter={true}
      mobilePadding={false}
      loadResources={loadRecommendation}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Othrs" />}
    >
      {Platform.OS !== 'web' ? (
        recommendation && (
          <View style={{ flex: 1, width: '100%' }}>
            <HeaderUser push={push} recommendation={recommendation} height={500} userId={userId} />
            <View style={RecommendationDetailsStyle.content}>
              <RecommendationDetailsForm userId={userId} recommendation={recommendation} push={push} />
              {
                userId === recommendation.worker.id && recommendation.status === 'PENDING' ? (
                  <View
                    style={{
                      width: '90%',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <MultiColorButton
                      onPress={() => push('RecommendationResponse', { recommendationId: recommendation.id, responseType: 'ACCEPTED', userFirstname: recommendation.user.firstname })}
                      text="Accepter"
                      style={{ width: '45%' }}
                    />
                    <GrayButton
                      titre="Refuser"
                      width="45%"
                      noBar
                      onPress={() => push('RecommendationResponse', { recommendationId: recommendation.id, responseType: 'REFUSED', userFirstname: recommendation.user.firstname })}
                    />
                  </View>

                ) : null
              }
              {
                userId === recommendation.worker.id && recommendation.status === 'ACCEPTED' ? (
                  <MultiColorButton
                    onPress={() => push('ContractSigned', { recommendationId: route.params.recommendationId })}
                    text={'Devis Signé'}
                    style={{
                      width: '80%', height: 70, marginBottom: '16%',
                    }}
                  />
                ) : <View style={{ marginBottom: 50 }} />
              }
            </View>
          </View>
        )
      ) : (recommendation && (
        <View style={{ width: '100%', alignItems: 'center' }}>
          <RecommendationDetailsForm userId={userId} recommendation={recommendation} push={push} />
          {
            userId === recommendation.worker.id && (
              <View
                style={{
                  width: '100%',
                  position: 'fixed',
                  alignItems: 'center',
                  height: height * 0.12,
                  marginTop: height * 0.88 - 120,
                  justifyContent: 'center',
                  backgroundColor: recommendation?.status === 'REFUSED' ? colors.red : recommendation?.status === 'CONTRACT_SIGNED' ? colors.green : colors.white,
                }}
              >
                {
                  recommendation.status === 'PENDING' ? (
                    <View
                      style={{
                        width: '100%', height: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                      }}
                    >
                      <MultiColorButton
                        noHollow={true}
                        onPress={() => push('RecommendationResponse', { recommendationId: recommendation.id, responseType: 'ACCEPTED', userFirstname: recommendation.user.firstname })}
                        text="Accepter"
                        style={{ width: '15%', marginRight: '2.5%' }}
                      />
                      <GrayButton
                        titre="Refuser"
                        width="15%"
                        noBar
                        onPress={() => push('RecommendationResponse', { recommendationId: recommendation.id, responseType: 'REFUSED', userFirstname: recommendation.user.firstname })}
                      />
                    </View>
                  ) : null
                }
                {
                  recommendation.status === 'ACCEPTED' ? (
                    <View style={{ width: '25%', flexDirection: 'row', alignItems: 'center', justifyItems: 'center' }}>
                      <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20 }}>Status:</Text>
                      <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, marginLeft: 10, color: colors.blue }}>Accepté</Text>
                      <MultiColorButton
                        onPress={() => push('ContractSigned', { recommendationId: route.params.recommendationId })}
                        text="Devis Signé"
                        style={{ width: '55%', marginLeft: 20 }}
                      />
                    </View>
                  ) : null
                }
                {
                  recommendation.status === 'REFUSED' ? (
                    <View style={{ width: '100%', alignItems: 'center', justifyItems: 'center' }}>
                      <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, color: colors.white }}>Recommandation refusée</Text>
                      {!!recommendation.response_message && (
                        <Text style={{ fontFamily: fonts.lato.regular, fontSize: 14, color: colors.white, marginTop: 10 }}>{recommendation.response_message}</Text>
                      )}
                    </View>
                  ) : null
                }
                {
                  recommendation.status === 'CONTRACT_SIGNED' ? (
                    <View style={{ width: '100%', alignItems: 'center', justifyItems: 'center' }}>
                      <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, color: colors.white }}>Devis signé pour un montant de: {recommendation.contract_amount}€ HT</Text>
                      {!!recommendation.response_message && (
                        <Text style={{ fontFamily: fonts.lato.regular, fontSize: 14, color: colors.white, marginTop: 10 }}>{recommendation.response_message}</Text>
                      )}
                    </View>
                  ) : null
                }
              </View>
            )
          }
        </View>
      ))}
    </LoadResourceLayout>
  );
}

const mapStateToProps = (state, props) => ({
  recommendation: state.Recommendation.recommendations?.find((e) => e.id?.toString() === props.route.params?.recommendationId?.toString()),
  userId: state.Account.essentialUser.id,
});

const mapDispatchToProps = {
  acceptRecommendation,
  refuseRecommendation,
  getRecommendationDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationDetails);
