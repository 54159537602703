'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.Payments = void 0;
var index_1 = require('./index');
var Payments = /** @class */ (function (_super) {
  __extends(Payments, _super);
  function Payments() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Payments.prototype.subscribe = function (token, successUrl, cancelUrl, coupon, zoneId) {
    return this.requester.post('/payments/subscribe', {
      successUrl: successUrl,
      cancelUrl: cancelUrl,
      coupon: coupon,
      zoneId: zoneId
    });
  };
  Payments.prototype.manageBilling = function (returnUrl) {
    return this.requester.post('/payments/manage-billing', {
      returnUrl: returnUrl
    });
  };
  Payments.prototype.paymentIntentEvent = function (amount, eventId, ticketId, places, owners) {
    return this.requester.post('/payments/payment-sheet-event', {
      amount: amount,
      eventId: eventId,
      ticketId: ticketId,
      places: places,
      owners: owners
    });
  };
  Payments.prototype.paymentIntentNetwork = function (networkId) {
    return this.requester.post('/payments/payment-sheet-network', {
      networkId: networkId
    });
  };
  Payments.prototype.buyTicket = function (owners, quantity, eventId, ticketId, successUrl, cancelUrl) {
    return this.requester.post('/payments/buy-tickets', {
      quantity: quantity,
      owners: owners,
      ticketId: ticketId,
      eventId: eventId,
      successUrl: successUrl,
      cancelUrl: cancelUrl
    });
  };
  Payments.prototype.guestBuyTicket = function (owners, quantity, eventId, ticketId, successUrl, cancelUrl) {
    return this.requester.post('/payments/guest-buy-tickets', {
      quantity: quantity,
      owners: owners,
      ticketId: ticketId,
      eventId: eventId,
      successUrl: successUrl,
      cancelUrl: cancelUrl
    });
  };
  Payments.prototype.checkCoupon = function (coupon) {
    return this.requester.get('/payments/check-coupon/' + coupon);
  };
  return Payments;
}(index_1.Component));
exports.Payments = Payments;
