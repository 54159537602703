import { fonts } from './fonts';
import { colors } from './colors';
import { Platform, StyleSheet } from 'react-native';

export const TextStyles = (props) => {
  const flattenProps = StyleSheet.flatten(props);
  return StyleSheet.create({
    // REGULAR
    regular12: {
      ...flattenProps,
      fontSize: 13,
      fontFamily: fonts.lato.regular,
    },
    regular13: {
      ...flattenProps,
      fontSize: 13,
      fontFamily: fonts.lato.regular,
    },
    regular14: {
      ...flattenProps,
      fontSize: 14,
      fontFamily: fonts.lato.regular,
    },
    regular15: {
      ...flattenProps,
      fontSize: 15,
      fontFamily: fonts.lato.regular,
    },
    regular16: {
      ...flattenProps,
      fontSize: 16,
      fontFamily: fonts.lato.regular,
    },
    regular17: {
      ...flattenProps,
      fontSize: 16,
      fontFamily: fonts.lato.regular,
    },
    regular23: {
      ...flattenProps,
      fontSize: 16,
      fontFamily: fonts.lato.regular,
    },
    // BOLD
    bold10: {
      ...flattenProps,
      fontSize: 10,
      fontFamily: fonts.lato.bold,
    },
    bold14: {
      ...flattenProps,
      fontSize: 14,
      fontFamily: fonts.lato.bold,
    },
    bold15: {
      ...flattenProps,
      fontSize: 15,
      fontFamily: fonts.lato.bold,
    },
    bold16: {
      ...flattenProps,
      fontSize: 16,
      fontFamily: fonts.lato.bold,
    },
    bold17: {
      ...flattenProps,
      fontSize: 16,
      fontFamily: fonts.lato.bold,
    },
    bold18: {
      ...flattenProps,
      fontSize: 18,
      fontFamily: fonts.lato.bold,
    },
    bold20: {
      ...flattenProps,
      fontSize: 20,
      fontFamily: fonts.lato.bold,
    },
    bold22: {
      ...flattenProps,
      fontSize: 22,
      fontFamily: fonts.lato.bold,
    },
    bold23: {
      ...flattenProps,
      fontSize: 23,
      fontFamily: fonts.lato.bold,
    },
    bold24: {
      ...flattenProps,
      fontSize: 24,
      fontFamily: fonts.lato.bold,
    },
    bold25: {
      ...flattenProps,
      fontSize: 25,
      fontFamily: fonts.lato.bold,
    },
    bold30: {
      ...flattenProps,
      fontSize: 30,
      fontFamily: fonts.lato.bold,
    },
    bold40: {
      ...flattenProps,
      fontSize: 40,
      fontFamily: fonts.lato.bold,
    },
    // BOLD ITALIC
    boldItalic15: {
      ...flattenProps,
      fontSize: 15,
      fontFamily: fonts.lato.boldItalic,
    },
    // BLACK
    black13: {
      ...flattenProps,
      fontSize: 13,
      fontFamily: fonts.lato.black,
    },
    black17: {
      ...flattenProps,
      fontSize: 17,
      fontFamily: fonts.lato.black,
    },
    black23: {
      ...flattenProps,
      fontSize: 23,
      fontFamily: fonts.lato.black,
    },
    black25: {
      ...flattenProps,
      fontSize: 25,
      fontFamily: fonts.lato.black,
    },
    black27: {
      ...flattenProps,
      fontSize: 27,
      fontFamily: fonts.lato.black,
    },
    black30: {
      ...flattenProps,
      fontSize: 30,
      fontFamily: fonts.lato.black,
    },
    // ITALIC
    italic14: {
      ...flattenProps,
      fontSize: 14,
      fontFamily: fonts.lato.italic,
    },
  });
};

export const ContainerStyles = StyleSheet.create({
  web: {
    marginTop: 0,
  },
  webHeader: {
    width: '90%',
    marginLeft: '5%',
    marginBottom: '1%',
  },
  webTitle: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  webTitleImage: {
    width: 30,
    height: 30,
  },
  /* REQUEST FORM (EX: INVITE NETWORK) */
  requestFormMobileSentContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  requestFormMobileContainer : {
    flex: 1,
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  requestFormWebContainer: {
    width: '50%',
    marginTop: '5%',
    marginLeft: '25%',
    alignItems: 'center',
  },
  requestFormWebSubmitButton: {
    width: '60%',
    marginTop: '2%',
  },
  descriptionText: {
    width: '85%',
    textAlign: 'center',
    color: colors.grey,
    fontFamily: fonts.lato.italic,
    marginBottom: Platform.OS === 'web' ? 0 : 20,
    marginTop: Platform.OS === 'web' ? 20 : 0,
  },
});
