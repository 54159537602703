import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    width: '75%',
    height: 65,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 50,
    backgroundColor: 'transparent',
  },
  buttonContainer: {
    width: '100%',
    height: '100%',
    padding: 10,
  },
  button: {
    flex: 1,
    borderRadius: 50,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  linearGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  svg: {
    height: '80%',
    aspectRatio: 1,
    marginRight: 15,
  },
});
