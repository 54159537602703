import React, { useState } from 'react';
import arrayMutators from 'final-form-arrays';
import { EvilIcons } from '@expo/vector-icons';
import { Form, Field } from 'react-final-form';
import { Alert, Text, View, TouchableOpacity } from 'react-native';
import { FieldArray } from 'react-final-form-arrays';

import { colors } from 'styles';
import styles from './EventBookingStyle';
import { toastError } from 'services/toasts';
import propsAreEqual from 'services/propsAreEqual';

import Input from 'components/form/input';
import { isBrowser, isMobile } from 'components/device';
import HollowBackground from 'components/backgrounds/hollowBackground/HollowBackground';
import EncartTitle from '../../../components/form/encartTitle';

export const validate = async (values) => {
  const errors = {};

  if (!values.owners || values.owners.length === 0) {
    errors.owners = 'Obligatoire';
  }
  values.owners.forEach((o, i) => {
    if (!errors.owners) errors.owners = [];
    if (!o.name) errors.owners[i] = { name: 'Obligatoire' };
  });
  return errors;
};

const BookingForm = ({ Button, onSubmit, initialValues, nbRemainingPlaces, isFree }) => {
  const [nbPlaces, setNbPlaces] = useState(1);

  const addPlace = (push) => {
    if (nbPlaces >= nbRemainingPlaces) {
      if (isMobile) {
        Alert.alert('Aucune place disponible', 'Toutes les places ont déjà été réservées');
      }
      if (isBrowser) {
        toastError('Il n\'y a plus assez de places disponibles pour cet Event');
      }
    } else {
      push('owners', { name: '' });
      setNbPlaces(nbPlaces + 1);
    }
  };
  const rmPlace = (pop) => {
    if (nbPlaces > 1) {
      pop('owners');
      setNbPlaces(nbPlaces - 1);
    }
  };

  const PlacesFieldArray = React.memo(({ push, pop }) => (
    <FieldArray name="owners">
      {({ fields }) => (
        <>
          {!isFree && <View style={[styles.placesMainContainer, { marginBottom: 20 }]}>
            <Text>Nombre de places</Text>
            <View style={styles.placesContainer}>
              <TouchableOpacity style={{ cursor: 'pointer' }} onPress={() => rmPlace(pop)}>
                <EvilIcons name="minus" size={35} color={colors.greyDayColor} />
              </TouchableOpacity>
              <View style={styles.placesNumber}>
                <HollowBackground />
                <Text style={styles.placesText}>{nbPlaces}</Text>
              </View>
              <TouchableOpacity style={{ cursor: 'pointer' }} onPress={() => addPlace(push)}>
                <EvilIcons name="plus" size={35} color={colors.greyDayColor} />
              </TouchableOpacity>
            </View>
          </View>}
          {fields.map((name, index) => (
            <View key={name} style={{ width: '80%' }}>
              <EncartTitle index={index} name={'Billet'}/>
              <Field
                width="100%"
                component={Input}
                name={`${name}.name`}
                label={'Nom et prénom'}
                required
              />
              <Field
                width="100%"
                component={Input}
                name={`${name}.job`}
                label={'Métier'}
              />
            </View>
          ))}
        </>
      )}
    </FieldArray>
  ), propsAreEqual);

  return (
    <Form
      onSubmit={(values) => onSubmit({ ...values, nbPlaces })}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmitFail={(error) => console.log(error)}
      validate={validate}
      render={({
        form,
        values,
        pristine,
        submitting,
        form: {
          mutators: { push, pop }
        },
      }) => (
        <>
          <PlacesFieldArray push={push} pop={pop} />
          <Button
            isFree={isFree}
            nbPlaces={nbPlaces}
            onClick={() => form.submit()}
          />
        </>
      )}
    />
  );
};

export default BookingForm;
