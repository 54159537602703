import React from 'react';
import { View, ActivityIndicator } from 'react-native';

import { colors } from 'styles/colors';

export default () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <ActivityIndicator size='large' color={colors.greyDayColor} />
  </View>
);
