import * as Linking from 'expo-linking';
import React, { useState } from 'react';
import { Dimensions, FlatList, Image, Text, TouchableOpacity, View } from 'react-native';
import * as ExpoNotifications from 'expo-notifications';

import Api from 'services/api';
import { colors, TextStyles } from 'styles';
import { Swap, Event, Reco } from 'styles/svg';

import NotificationsStyles from './NotificationsStyles';

import Svg from 'components/svg';
import { MobileView, BrowserView } from 'components/device';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';

const { height } = Dimensions.get('screen');

function Notifications({ navigation: { push, goBack } }) {
  const [ notifications, setNotifications ] = useState(null);

  const loadNotifications = async () => {
    try {
      const { data } = await Api.profile.getNotifications(true);
      setNotifications(data.map(enrichNotification).filter(d => !!d));
      await ExpoNotifications.setBadgeCountAsync(0);
    } catch (err) {
      console.error('Error loading notifications', err);
    }
  };

  const enrichNotification = (notification) => {
    let data = {};
    if (notification.recommendation_id) {
      data.source = `${notification.othr?.firstname} ${notification.othr?.lastname}`;
      data.image = notification.othr.picture_url;
      data.icon = Reco;
    } else if (notification.othr_id) {
      data.source = `${notification.othr?.firstname} ${notification.othr?.lastname}`;
      data.image = notification.othr.picture_url;
      data.icon = Swap;
    } else if (notification.event_id) {
      data.source = notification.event?.title;
      data.image = notification.event.network?.logo_url || notification.event.picture_url;
      data.icon = Event;
    } else {
      return null;
    }
    data.link = Linking.createURL(notification.link?.split('/application/')?.[1]);
    return {
      ...notification,
      ...data,
    };
  };

  const renderNotification = ({ item }) => (
    <TouchableOpacity style={{ ...NotificationsStyles.notifContainer, backgroundColor: item.seen ? colors.liteGrey : colors.whiteShadow }} onPress={() => {
      Linking.openURL(item.link);
    }}>
      <View style={NotificationsStyles.imageContainer}>
        <Image alt="Change me" source={{ uri: item.image }} style={{ width: '100%', height: '100%', borderRadius: 40 }}/>
        <View style={NotificationsStyles.littleImageContainer}>
          <Svg src={item.icon} width={18} height={18} />
        </View>
      </View>
      <View style={NotificationsStyles.textContainer}>
        <Text numberOfLines={1} style={TextStyles({ color: colors.black }).bold16}>{item.title}</Text>
        <Text numberOfLines={1} style={TextStyles({ color: colors.black }).regular13}>{item.source}</Text>
      </View>
      {!item.seen && <View style={NotificationsStyles.seen}/>}
    </TouchableOpacity>
  );

  const MobileContent = () => (
    <View style={NotificationsStyles.mobileContainer}>
      <FlatList
        data={notifications}
        renderItem={renderNotification}
        keyExtractor={(item) => (item.id)}
        contentContainerStyle={NotificationsStyles.listContainer}
        ItemSeparatorComponent={() => <View style={{ width: '100%', backgroundColor: colors.greyLight, height: 1 }}/>}
      />
    </View>
  );

  const WebContent = () => {
    const nbToSeenNotification = notifications.filter((not) => !not.seen).length;

    return (
      <View style={{ ...NotificationsStyles.webContainer, minHeight: height * 0.4 }}>
        <View style={NotificationsStyles.webTitleContainer}>
          <View style={NotificationsStyles.webTitleTextContainer}>
            <Text style={TextStyles().bold25}>
              Notifications
            </Text>
            <View style={NotificationsStyles.webTitleRow}>
              <Text style={TextStyles({ color: '#707070' }).regular16}>
                {notifications.length} Notifications
              </Text>
              <Text style={TextStyles({ marginLeft: 5, marginRight: 5, color: '#707070' }).regular16}>
                /
              </Text>
              <Text style={TextStyles({ color: colors.burgundy }).regular16}>
                {nbToSeenNotification} Nouveautés
              </Text>
            </View>
          </View>
        </View>
        <FlatList
          data={notifications}
          renderItem={renderNotification}
          keyExtractor={(item) => (item.id)}
          contentContainerStyle={NotificationsStyles.listContainer}
        />
      </View>
    );
  };

  return (
    <LoadResourceLayout
      push={push}
      goBack={goBack}
      disableGoBack={true}
      title="Notifications"
      mobilePadding={false}
      loadResources={loadNotifications}
    >
      {/* MOBILE */}
      <MobileView>
        <MobileContent />
      </MobileView>
      {/* BROWSER */}
      <BrowserView>
        <WebContent />
      </BrowserView>
    </LoadResourceLayout>
  );
}

export default Notifications;
