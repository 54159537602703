import React from 'react';
import { Text } from 'native-base';
import { useWindowDimensions } from 'react-native';
import { colors, TextStyles } from '../../styles';
import { isBrowser } from '../../components/device';

const SubscriptionCancel = ({ }) => {
  const { height } = useWindowDimensions();

  return (
    <div style={{ width: '100%', height: height * 0.6, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Text style={TextStyles({ lineHeight: 25, width: !isBrowser ? '80%' : '30%', textAlign: 'center' }).bold20}>
        Une erreur est survenue
      </Text>
      <Text style={TextStyles({ color: colors.greyPodcast, maxWidth: !isBrowser ? '80%' : '30%', textAlign: 'center', marginTop: 20 }).italic14}>
        Veuillez réessayer.
      </Text>
    </div>
  );
};

export default SubscriptionCancel;
