export const images = {
  swappeBackground: require('../../assets/images/background.png'),

  // Other//
  logo: require('../../assets/images/logo.png'),
  adress: require('../../assets/images/adress.png'),
  qrCode: require('../../assets/images/qrCode.png'),
  visas: require('../../assets/images/visaLarge.png'),
  activite: require('../../assets/images/activite.png'),
  why: require('../../assets/images/why.png'),
  closeButton: require('../../assets/images/closeButton.png'),

  // Network///
  backgroundNetwork: require('../../assets/images/backgroundNetwork.png'),
  backgroundNetworkDetail: require('../../assets/images/backgroundNetworkDetail.png'),
  network: require('../../assets/images/network.png'),

  // ---Company----//
  logoWebSite: require('../../assets/images/logoWebsite.png'),
  networkLogo: require('../../assets/images/bniLogo.png'),
  touchini: require('../../assets/images/touchinni.png'),
  dynabuy: require('../../assets/images/dynabuy.png'),
  dynabuyWhite: require('../../assets/images/dynabuyWhite.png'),
  optimRezo: require('../../assets/images/optimeRezo.png'),
  birdAndHuman: require('../../assets/images/birdAndHuman.png'),
  bni: require('../../assets/images/bni.png'),

  // -------Profil---------//
  woman: require('../../assets/images/profilWoman.png'),
  girl: require('../../assets/images/profilGirl.jpg'),
  man: require('../../assets/images/profilMan.jpg'),

  // customers
  lamasa: require('../../assets/images/lamasa.png'),
  miller: require('../../assets/images/millerOn.png'),
  orpi: require('../../assets/images/orpiOn.png'),
  rci: require('../../assets/images/rciBanque.png'),

  // Pub
  advertBH: require('../../assets/images/BH_OTHR.jpg'),
  advertWelcome: require('../../assets/images/page les coulisses.jpg'),
  advertCoulisses: require('../../assets/images/weWelcome.jpg'),

  // border
  borderTopRight: require('../../assets/images/borderTopRight.svg'),
  borderTopLeft: require('../../assets/images/borderTopLeft.svg'),
  borderBottomLeft: require('../../assets/images/borderBottomLeft.svg'),
  borderLeft: require('../../assets/images/borderLeft.svg'),
};

export const svgtopng = {
  Event: {
    event: require('../../assets/svgtopng/event.png'),
    consultEvent: require('../../assets/svgtopng/consultEvent.png'),
    myEvents: require('../../assets/svgtopng/myEvents.png'),
    view: require('../../assets/svgtopng/test2.png'),
    creer: require('../../assets/svgtopng/eventCreate.png'),
    billet: require('../../assets/svgtopng/eventBillet.png'),
    agenda: require('../../assets/svgtopng/eventFavoris.png'),
    inviter: require('../../assets/svgtopng/eventInviter.png'),

  },
  other: {
    other: require('../../assets/svgtopng/user.png'),
    contact: require('../../assets/svgtopng/userContact.png'),
    parrainer: require('../../assets/svgtopng/userParrainer.png'),
    recos: require('../../assets/svgtopng/userRecommand.png'),
    swap: require('../../assets/svgtopng/userSwap.png'),
    consultOthrs: require('../../assets/svgtopng/consultOthrs.png'),
  },
  reseaux: {
    reseaux: require('../../assets/svgtopng/network.png'),
    rejoindre: require('../../assets/svgtopng/networkJoin.png'),
    creer: require('../../assets/svgtopng/networkCreate.png'),
    inviter: require('../../assets/svgtopng/networkInviter.png'),
    networkFavoris: require('../../assets/svgtopng/networkFavoris.png'),
    consultNetwork: require('../../assets/svgtopng/consultNetwork.png'),
  },
  magazine: {
    magazine: require('../../assets/svgtopng/magazines.png'),
    monMag: require('../../assets/svgtopng/magazineMonMag.png'),
    publier: require('../../assets/svgtopng/magazinePublication.png'),
    creer: require('../../assets/svgtopng/magazineCreate.png'),
    consultMagazine: require('../../assets/svgtopng/consultMagazine.png'),
  },
  podcast: {
    podcast: require('../../assets/svgtopng/podcast.png'),
    mesPodcasts: require('../../assets/svgtopng/podcastMyPodcast.png'),
    playlist: require('../../assets/svgtopng/podcastPlaylist.png'),
    creer: require('../../assets/svgtopng/podcastCreate.png'),
  },
  otherTV: {
    otherTV: require('../../assets/svgtopng/userTv.png'),
    maTV: require('../../assets/svgtopng/userTvMyTv.png'),
    playlist: require('../../assets/svgtopng/userTvPlaylist.png'),
    creer: require('../../assets/svgtopng/userTvCreate.png'),
    consultTv: require('../../assets/svgtopng/consultTv.png'),
  },
  profil: {
    profil: require('../../assets/svgtopng/profile.png'),
    contactUs: require('../../assets/svgtopng/mail.png'),
    notif: require('../../assets/svgtopng/profilNotification.png'),
    dashboard: require('../../assets/svgtopng/profilDashboard.png'),
    parametre: require('../../assets/svgtopng/profilParameter.png'),
    souscription: require('../../assets/svgtopng/profilSubscribe.png'),
  },
};
