import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { View, Text, TouchableOpacity, Platform } from 'react-native';

import { colors } from 'styles/index';
import styles from './TicketPriceStyle';
import HollowBackground from '../backgrounds/hollowBackground';

const MobileContent = ({ ticketSelected, price, onPress, name }) => (
  <TouchableOpacity style={[styles.price, { backgroundColor: ticketSelected ? colors.burgundy : colors.white }]} disabled={!onPress} onPress={() => onPress(name)}>
    <Text style={[styles.priceText, { color: ticketSelected ? colors.white : colors.grey }]}>{price === 0 ? 'Gratuit' : `${price} €`}</Text>
  </TouchableOpacity>
);

const WebContent = ({ ticketSelected, price, onPress, name }) => (
  <TouchableOpacity style={[styles.price, { backgroundColor: ticketSelected ? colors.burgundy : colors.white }]} disabled={!onPress} onPress={() => onPress(name)}>
    <Text style={[styles.priceText, { color: ticketSelected ? colors.white : colors.grey }]}>{price === 0 ? 'Gratuit' : `${price} €`}</Text>
  </TouchableOpacity>
);

export default function TicketPrice({
  name, description, price, onPress, ticketSelected
}) {
  return (
    <View style={styles.hollowContainer}>
      <HollowBackground />
      <View style={styles.container}>
        <View style={[styles.infosContainer, Platform.OS !== 'web' ? {} : { marginLeft: '8%' }]}>
          <Text numberOfLines={1} style={[styles.title, { fontSize: 16, color: colors.greyMedium2 }]}>{name}</Text>
          <Text numberOfLines={2} style={styles.description}>{description}</Text>
        </View>
        {Platform.OS !== 'web' ? (
          <MobileContent price={price} ticketSelected={ticketSelected} onPress={onPress} name={name} />
        ) : (
          <WebContent price={price} ticketSelected={ticketSelected} onPress={onPress} name={name} />
        )}
      </View>
    </View>
  );
}
