import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { EvilIcons } from '@expo/vector-icons';
import { View, Text, Alert, Platform, useWindowDimensions } from 'react-native';

import Api from 'services/api';
import { toastError, toastSuccess } from 'services/toasts';
import { createRecommendation } from 'store/actions/recommendation';

import AddRecommendationForm from './AddRecommendationForm';
import { AddRecommendationStyle } from './AddRecommendationStyle';

import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import MultiColorButton from 'components/buttons/multiColorButton/MultiColorButton';
import BackgroundLinearGradient from 'components/backgrounds/backgroundLinearGradient';

function HeaderUser({ user, height }) {
  return (
    <View style={AddRecommendationStyle.contentOtherSlideNew}>
      <BackgroundLinearGradient
        image={{ uri: user?.picture_url }}
        width="100%"
        height={height}
        styles={AddRecommendationStyle.contentSlideText}
      >
        <View style={AddRecommendationStyle.contentText}>
          <Text style={AddRecommendationStyle.nameHeader}>{user?.firstname} {user?.lastname}</Text>
          <Text style={AddRecommendationStyle.jobHeader}>{user?.job}</Text>
          <Text style={AddRecommendationStyle.addressHeader}>
            <EvilIcons name="location" size={12} color="white" />
            {user?.address?.city}
          </Text>
        </View>
      </BackgroundLinearGradient>
    </View>
  );
}

function AddRecommendation({
  navigation: { goBack, addListener, push },
  route: { params: { userId } },
  submit,
  createRecommendation,
}) {
  const { width } = useWindowDimensions();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadUser = async () => {
    try {
      const response = await Api.users.get(userId);
      setUser(response.data);
    } catch (err) {
      console.error('Error loading user', err);
    }
  };

  const addRecommendation = async (values) => {
    try {
      setLoading(true);
      await createRecommendation({
        recommended_user_id: userId,
        ...values,
      });
      if (Platform.OS !== 'web') {
        push('ValidationAddRecommendation');
      } else {
        toastSuccess('Recommandation Envoyée!');
        push('Recommendations');
      }
    } catch (err) {
      console.log('Error', err);
      if (Platform.OS !== 'web') {
        Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
      } else {
        toastError('Une erreur est survenue, veuillez réessayer plus tard');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadResourceLayout
      push={push}
      mobilePadding={false}
      loadResources={loadUser}
      addListener={addListener}
      breadCrumb={<Breadcrumb push={push} goBack={goBack} menuSelect="Othrs" />}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
      disableMenu={true}
    >
      {
        Platform.OS !== 'web' ? (
          <View
            style={{ flex: 1, width: '100%' }}
          >
            <HeaderUser user={user} height={500} />
            <View style={AddRecommendationStyle.content}>
              <AddRecommendationForm onSubmit={addRecommendation} />
              <MultiColorButton
                loading={loading}
                onPress={() => submit('createAddRecommendationForm')}
                text="Recommander"
                style={{
                  width: '80%', height: 70, marginBottom: '16%', marginTop: '2%',
                }}
              />
            </View>
          </View>
        ) : (
          <View style={{ width: '100%' }}>
            <View style={{ alignItems: 'center' }}>
              <AddRecommendationForm user={user} onSubmit={addRecommendation} />
            </View>
            <MultiColorButton
              loading={loading}
              onPress={() => submit('createAddRecommendationForm')}
              text="Recommander"
              style={{
                width: 250, height: 70, marginLeft: width * 0.4,
              }}
            />
          </View>
        )
      }
    </LoadResourceLayout>
  );
}

const actionProps = {
  submit,
  createRecommendation,
};

export default connect(null, actionProps)(AddRecommendation);
