import React from 'react';
import { View, Text } from 'react-native';

import { TextSectionStyle } from './TextSectionStyle';
import GoBack from '../buttons/goBackButton';

export default function TextSection({ titleSection, textAlign, svg, noBar }) {
  return (
    <View style={TextSectionStyle.titleContainer}>
      {!!svg &&
        <View style={{ width: 40, height: 40 }}>
          {svg}
        </View>
      }
      <Text selectable style={[TextSectionStyle.textTitle, { textAlign }]}>{titleSection}</Text>
      {titleSection !== '' && !noBar && <View style={TextSectionStyle.vertLine} />}
    </View>
  );
}
