import { StyleSheet } from 'react-native';
import { colors } from '../../styles';

export default (props) => StyleSheet.create({
  playerContainer: {
    width: '65%',
    height: '65%',
    marginRight: '10%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playerBar: {
    height: 2,
    // width: '100%',
    backgroundColor: colors.burgundy,
  },
  playerDuration: {
    width: 10,
    height: 10,
    borderRadius: 5,
    // position: 'absolute',
    backgroundColor: colors.burgundy,
    // left: props && `${(props.current / props.duration) * 100}%`,
  },
  spaceholder: {
    height: 25,
  },
  playerTimerContainer: {
    height: 20,
    width: '100%',
    alignItems: 'center',
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingRight: 10
  },
});
