import React from 'react';
import { Image, Text } from 'native-base';
import { Platform, SafeAreaView, View } from 'react-native';

import { fonts } from 'styles';
import { Swap, Rejected } from 'styles/svg';
import Svg from 'components/svg';
import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';

const ValidationSwapRequest = ({ navigation: { reset, goBack, push, canGoBack }, route: { params: { type } } }) => {
  setTimeout(() => {
    reset({
      index: 1,
      routes: [
        { name: 'Home' },
        { name: 'SwapRequests' },
      ],
    });
  }, 2000);
  return (<BasicLayout
    push={push}
    disableGoBack={true}
    headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
    breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Othrs"/>}
  >
    <SafeAreaView
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <View style={{ alignItems: 'center', justifyContent: 'center', width: 86, height: 49 }}>
        <Svg src={Swap} width={86} height={49} style={{ position: 'absolute', top: 0, left: 0 }}/>
        {type !== 'accepted' && <Svg src={Rejected} width={13} height={13}/>}
      </View>
      <Text style={{
        fontSize: 16,
        marginTop: 20,
        fontFamily: fonts.lato.regular,
      }}>
        Demande {type === 'accepted' ? 'acceptée' : 'refusée'}
      </Text>
    </SafeAreaView>
  </BasicLayout>);
};

export default ValidationSwapRequest;
