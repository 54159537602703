import React from 'react';
import { View } from 'react-native';

import { reduxForm, Field } from 'redux-form';

import Input from 'components/form/input';
import styles from '../register/stepOne/StepOneStyle';

const validate = (values) => {
  const errors = {};

  if (!values.email || values.email.length === 0) {
    errors.email = 'Obligatoire';
  } else if (!/^[a-aA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email invalide';
  }

  return errors;
};

function ForgotPasswordForm() {
  return (
    <View style={styles.form}>
      <Field
        name="email"
        label="Email"
        required={true}
        centered={true}
        component={Input}
        autoCapitalize="none"
        autoCompleteType="email"
        keyboardType="email-address"
        textContentType="emailAddress"
      />
    </View>
  );
}

export default reduxForm({ form: 'forgotPassword', validate, enableReinitialize: true })(ForgotPasswordForm);
