import React from 'react';
import { View, Text } from 'react-native';

import { FieldWrapperStyle } from './FieldWrapperStyle';

export default function FieldWrapper({
  style,
  children,
  width = '100%',
  meta: { touched, error, warning },
}) {
  return (
    <View
      style={{
        ...FieldWrapperStyle.inputContainer,
        ...(touched && error ? FieldWrapperStyle.inputContainerInvalid : null),
        width,
        ...style
      }}
    >
      {children}
      {touched && error && <Text style={FieldWrapperStyle.error}>{error}</Text>}
      {warning && <Text style={FieldWrapperStyle.warning}>{warning}</Text>}
    </View>
  );
}
