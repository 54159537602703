export default function User(
  state = {
    users: null,
    user: {},
    homeUsers: null,
    relationships: null,
    swapRequests: null,
  },
  action,
) {
  switch (action.type) {
  case 'LOAD_USERS':
    return {
      ...state,
      users: action.users,
    };
  case 'HOME_USERS':
    return {
      ...state,
      homeUsers: action.homeUsers,
    };
  case 'LOAD_RELATIONSHIPS':
    return {
      ...state,
      relationships: action.data,
    };
  case 'LOAD_SWAP_REQUESTS':
    return {
      ...state,
      swapRequests: action.payload,
    };
  default:
    return state;
  }
}
