import { connect } from 'react-redux';
import React, { useMemo, useState } from 'react';
import { Dimensions, Image, Platform, Text, View, FlatList } from 'react-native';

import Api from 'services/api';
import { fonts } from 'styles';
import { NetworkStyle } from './NetworkStyle';

import Slider from 'components/slider';
import NetworkCard from 'components/cards/network';
import GrayButton from 'components/buttons/grayButton';
import PromoCarousel from 'components/webCarousel/Promo';
import HeaderSection from 'components/layouts/headerSection';
import CustomFlatList from 'components/webLists/CustomFlatList';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';

import promoData from './promoData';

function Network({ navigation: { push, goBack, canGoBack } }) {
  const [networkTypes, setNetworkTypes] = useState([]);
  const [parentNetworks, setParentsNetworks] = useState([]);
  const [networks, setNetworks] = useState([]);

  const loadNetworks = async () => {
    try {
      const { data: { parentNetworks } } = await Api.parent_networks.getHome();
      const { data: networks } = await Api.networks.getAll();
      // Exclude Othr fake network
      setNetworks(networks.filter((r) => (r.id !== 20)));
      setParentsNetworks(parentNetworks.filter((r) => (r.id !== 17)));
      const { data: networkTypes } = await Api.misc.getNetworkTypes();
      setNetworkTypes(networkTypes);
    } catch (err) {
      console.error('Error loading networks', err);
    }
  };

  const categories = networkTypes.map((nt) => (
    parentNetworks.filter((n) => n.type?.id === nt.id).map((n) => {
      return { ...n, type: 'network' };
    })
  ));

  const { height } = Dimensions.get('window');

  const data = useMemo(() => ([
    ...networkTypes?.map(nt => ({
      title: nt.name,
      subtitle: 'Avantages exclusifs pour votre entreprise, vos salariés, vos clients',
      networks: parentNetworks.filter(n => (
        networks.some((network) => network.type?.id === nt.id && network.parent_network_id === n.id)
      )),
    }))?.filter(o => o?.networks?.length > 0),
  ]), [ networkTypes, networks]);

  const renderMobileItem = ({ item, index }) => (
    <Slider key={index} height={450} title={item.title} subtitle={item.subtitle} noBar>
      {item.networks.map((network, i) => (
        <NetworkCard key={network.id} {...network} push={push}/>
      ))}
    </Slider>
  );
  const RenderMobileContent = () => (
    // <View style={NetworkStyle.slider}>
    <FlatList
      style={{ width: '100%', height: height * 0.93 }}
      data={data}
      renderItem={renderMobileItem}
      snapToOffsets={[450]}
      snapToEnd={false}
      decelerationRate={'normal'}
      ListHeaderComponent={() => (
        <Slider height={450} enableMarginTop={false} contentWidth="100%">
          {parentNetworks.map((e, i) => (
            <NetworkCard key={i} {...e} push={push} noRadius />
          ))}
        </Slider>
      )}
      ListFooterComponent={() => (
        <View style={{ alignItems: 'center' }}>
          <GrayButton
            style={{ marginTop: 50, marginBottom: 125 }}
            onPress={() => {
              push('AllNetworks');
            }}
            width="65%"
            titre="Les groupements de A à Z"
          />
        </View>
      )}
    />
    // </View>
  );

  const RenderWebContent = () => (
    <View>
      <PromoCarousel data={promoData} push={push} />
      <CustomFlatList
        push={push}
        type="network"
        resources={parentNetworks.map((n) => {
          return { ...n, type: 'network' };
        })}
        settings={{
          filters: ['asc', 'types'],
          sortField: 'description',
          searchFields: ['description'],
          categories,
          categoryLabels: networkTypes,
        }}
      />
    </View>
  );

  return (
    <LoadResourceLayout
      push={push}
      title="Groupements"
      mobilePadding={false}
      pagingEnabled={true}
      disableGoBack={false}
      disableScroll={true}
      disableMarginTop={true}
      loadResources={loadNetworks}
      headerSection={<HeaderSection push={push} menuSelect="Groupements" redirect="Network"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Groupements" />}
    >
      {Platform.OS !== 'web' ? <RenderMobileContent /> : <RenderWebContent />}
    </LoadResourceLayout>
  );
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(Network);
