import React from 'react';
import { Image, View } from 'native-base';
import { useWindowDimensions } from 'react-native';

import { colors } from 'styles';
import TestPromo from '../../../../assets/home.png';

import WebMenu from 'components/menu/WebMenu';
import { getResponsiveBreakpoint, ResponsiveView } from 'components/device';

const WebMenuContainer = ({ push }) => {
  const { width } = useWindowDimensions();
  let bp = getResponsiveBreakpoint(width);

  if (bp === 1290) {
    bp = 1140;
  }

  return (
    <ResponsiveView style={{
      zIndex: 3,
      marginTop: 100,
      position: 'fixed',
      // height: 530,
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}>
      <WebMenu push={push} />
    </ResponsiveView>
  );
};

export default WebMenuContainer;
