import React from 'react';
import { View } from 'react-native';
import { Field, reduxForm } from 'redux-form';

import styles from './EditEmailStyle';
import Input from 'components/form/input/Input';

const validate = (values) => {
  const errors = {};

  if (!values.newEmail) {
    errors.newEmail = 'Obligatoire';
  } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(values.newEmail)) {
    errors.newEmail = 'Adresse mail invalide';
  }

  if (!values.newEmailConfirm) {
    errors.newEmailConfirm = 'Obligatoire';
  } else if (values.newEmailConfirm !== values.newEmail) {
    errors.newEmailConfirm = 'Les emails ne sont pas identiques';
  }

  return errors;
};

function EditEmailForm() {
  return (
    <View style={styles.form}>
      <Field
        width="100%"
        name="newEmail"
        required={true}
        component={Input}
        autoCorrect={false}
        style={styles.input}
        label="Nouvel email"
        autoCapitalize="none"
        autoCompleteType="email"
      />
      <Field
        width="100%"
        required={true}
        component={Input}
        autoCorrect={false}
        style={styles.input}
        autoCapitalize="none"
        name="newEmailConfirm"
        autoCompleteType="email"
        label="Confirmez le nouvel email"
      />
    </View>
  );
}

export default reduxForm({ form: 'editEmail', validate, enableReinitialize: true })(EditEmailForm);
