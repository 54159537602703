import React from 'react';
import { Icon } from 'react-native-elements';
import arrayMutators from 'final-form-arrays';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Text, useWindowDimensions, View } from 'react-native';

import { colors } from 'styles';
import { validate } from './formValidation';
import propsAreEqual from 'services/propsAreEqual';
import { CreateEventStyle } from './CreateEventStyle';

import Input from 'components/form/input';
import PickerField from 'components/form/picker';
import DateInput from 'components/form/dateInput';
import ImagePicker from 'components/form/imagePicker';
import EncartTitle from 'components/form/encartTitle';
import GrayButton from 'components/buttons/grayButton';
import PlaceAutocomplete from 'components/form/placeAutocomplete';
import { FieldWrapperStyle } from 'components/form/fieldWrapper/FieldWrapperStyle';

const TicketsFieldArray = ({ push }) => (
  <FieldArray name="tickets">
    {({ fields , meta: { error } }) => (
      <>
        {fields.map((ticket, i) => (
          <View key={ticket}>
            <EncartTitle index={i} onRemove={() => fields.remove(i)} name={'Billet'} />
            <View style={CreateEventStyle.widthSixty}>
              <Field
                name={`${ticket}.title`}
                label={'Nom du billet'}
                component={Input}
                required
                width="100%"
                style={{ paddingHorizontal: 0 }}
              />
            </View>
            <View
              style={[CreateEventStyle.contentBillet, { width: '100%' }]}
            >
              <View
                style={CreateEventStyle.billetValue}
              >
                <Field
                  name={`${ticket}.internal_price`}
                  header={'Tarif membre (TTC)'}
                  label="(facultatif)"
                  autoCapitalize="none"
                  keyboardType="numeric"
                  component={Input}
                  width="100%"
                  style={{ paddingHorizontal: 0 }}
                />
              </View>
              <View
                style={CreateEventStyle.billetValue}
              >
                <Field
                  name={`${ticket}.price`}
                  header={'Tarif public (TTC)'}
                  label="0€"
                  autoCapitalize="none"
                  keyboardType="numeric"
                  component={Input}
                  required
                  width="100%"
                  style={{ paddingHorizontal: 0 }}
                />
              </View>
            </View>
            <View style={{ width: '100%' }}>
              <Field
                name={`${ticket}.description`}
                label="Description"
                height={170}
                component={Input}
                required
                width="100%"
                style={{ paddingHorizontal: 0 }}
                multiline
              />
            </View>
          </View>
        ))}
        {error && typeof(error) === 'string' && <Text style={FieldWrapperStyle.error}>{error}</Text>}
        <GrayButton
          height={50}
          onPress={() => push('tickets', { name: '', price: '', description: '' })}
          style={{ marginBottom: 40, marginTop: 20 }}
          width={200}
          titre="+ Ajouter un billet"
        />
      </>
    )}
  </FieldArray>
);

function CreateEventFormWeb({ onSubmit, initialValues, Button }) {
  const { height } = useWindowDimensions();

  let possibleHours = [];
  for (let i = 0; i < 24; ++i) {
    const now = new Date('2000-01-01');
    for (let j = 0; j < 4; ++j) {
      now.setHours(i);
      now.setMinutes(j * 15);
      possibleHours.push({
        label: `${i}h${j * 15}${j === 0 ? '0' : ''}`,
        value: now.toString(),
      });
    }
  }

  const FormContent = React.memo(({ form, push }) => (
    <View style={[CreateEventStyle.container]}>
      <View style={{ width: '60%' }}>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View style={{ width: 400 }}>
            <Field
              name='picture_url'
              style={{ marginBottom: 20 }}
              height={550}
              width={400}
              images={null}
              required={true}
              svg={(
                <Icon
                  name="plus"
                  type="simple-line-icon"
                  size={70}
                  color={colors.greyDark}
                />
              )}
              titre={'Photo de l\'Event'}
              component={ImagePicker}
            />
          </View>
          <View style={[CreateEventStyle.placesContainer, { width: '55%', marginLeft: '2.5%' }]}>
            {/* DATE PICKER */}
            <View style={CreateEventStyle.containerEvent}>
              <View style={CreateEventStyle.widthFifty}>
                <Field
                  name="start_date"
                  width="100%"
                  label="Date de début"
                  mode={'date'}
                  component={DateInput}
                  style={{ marginBottom: 10 }}
                  required={true}
                />
              </View>
              <View style={CreateEventStyle.widthFifty}>
                <Field
                  name="start_hour"
                  label="Heure de début"
                  component={PickerField}
                  items={possibleHours}
                  width="100%"
                  labelKey="label"
                  valueKey="value"
                  required={true}
                />
              </View>
            </View>
            {/* HOUR PICKER */}
            <View style={CreateEventStyle.containerEvent}>
              <View style={CreateEventStyle.widthFifty}>
              </View>
              <View style={CreateEventStyle.widthFifty}>
                <Field
                  name="end_hour"
                  label="Heure de fin"
                  component={PickerField}
                  items={possibleHours}
                  labelKey="label"
                  valueKey="value"
                  width="100%"
                  required={true}
                />
              </View>
            </View>
            <Field
              name="title"
              label="Titre de l'Event"
              style={{ marginBottom: 10 }}
              component={Input}
              required={true}
              width="100%"
            />
            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
              <View style={[CreateEventStyle.placesTextContainer, { marginLeft: '2.5%' }]}>
                <Text selectable style={CreateEventStyle.placesTitle}>Places disponibles</Text>
                <Text selectable numberOfLines={1} adjustsFontSizeToFit style={CreateEventStyle.placesText}>
                    Sans limite de place, vous ne pourrez
                </Text>
                <Text selectable numberOfLines={1} adjustsFontSizeToFit style={CreateEventStyle.placesText}>
                    refuser aucune inscription
                </Text>
              </View>
              <View style={CreateEventStyle.containtFieldPlace}>
                <Field
                  name="total_places"
                  label="Places disponibles"
                  component={Input}
                  keyboardType="numeric"
                  returnKeyType='done'
                  required={true}
                  width="100%"
                />
              </View>
            </View>
            {/* EVENT LOCATION */}
            <View style={[CreateEventStyle.containtLieu, { alignItems: undefined }]}>
              <Text selectable style={[CreateEventStyle.sectionTitle, { marginLeft: '2.5%' }]}>
                  Adresse précise de l'Event
              </Text>
              <View style={{ zIndex: 100, marginBottom: 10, width: '100%' }}>
                <Field
                  width="100%"
                  name="address"
                  label="Address"
                  required={true}
                  component={PlaceAutocomplete}
                />
              </View>
              <View style={CreateEventStyle.containerEvent}>
                <Field
                  name="addressPrecisions"
                  label="Précisions"
                  autoCapitalize="none"
                  component={Input}
                  width="100%"
                />
              </View>
            </View>
          </View>
        </View>
        <Text selectable style={[CreateEventStyle.sectionTitle, { marginLeft: '2.5%' }]}>
            Descriptif de votre évènement
        </Text>
        <View style={CreateEventStyle.containerDescriptif}>
          <Field
            name="description"
            label="Renseignez ici le descriptif de votre évènement."
            width="100%"
            component={Input}
            height={height * 0.37}
            multiline
            required={true}
          />
        </View>

        <Text selectable style={[CreateEventStyle.sectionTitle, { marginLeft: '2.5%' }]}>
            Organisateur
        </Text>
        <View style={{ width: '40%' }}>
          <Field
            name="contact_name"
            label="Nom de l'organisateur"
            width="100%"
            component={Input}
            required={true}
          />
          <Field
            name="contact_number"
            label="Téléphone de l'organisateur"
            width="100%"
            component={Input}
            required={true}
          />
          <Field
            name="contact_email"
            label="Email de l'organisateur"
            width="100%"
            component={Input}
            required={true}
          />
        </View>

        <View style={[CreateEventStyle.containerBillet, { alignItems: 'flex-start' }]}>
          <Text selectable style={[CreateEventStyle.sectionTitle, { marginLeft: '2.5%' }]}>Billets</Text>
          <TicketsFieldArray push={push} />
        </View>
        <Button onClick={form.submit} />
      </View>
    </View>
  ), propsAreEqual);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      render={({
        form,
        form: {
          mutators: { push }
        },
      }) => (
        <FormContent form={form} push={push} />
      )}
    />
  );
}

export default CreateEventFormWeb;
