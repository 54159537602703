import React from 'react';
import { colors, fonts } from 'styles';
import { ResponsiveView } from '../device';
import { Pressable, Text } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, useWindowDimensions } from 'react-native';

const ContactCard = ({ push, contact }) => {
  const { height } = useWindowDimensions();

  return (
    <ImageBackground
      source={{ uri: contact.image }}
      style={{ width: '100%', height: height * 0.8 > 600 ? height * 0.8 : 600, paddingTop: 80 }}
    >
      <LinearGradient colors={['rgba(0,0,0,0.5)', 'rgba(0,0,0,0)']} start={[0.0, 0.5]} end={[1.0, 0.5]} locations={[0.0, 1.0]} style={{ width: '100%', height: '100%' }}>
        <ResponsiveView style={{ justifyContent: 'center', height: '100%' }}>
          <Text style={{ fontFamily: fonts.lato.black, fontSize: 15, color: colors.white, textUnderlineOffset: 6, textDecorationLine: 'underline' }}>Sphere {contact.sphere}</Text>
          <Text style={{ marginTop: '0.5%', fontFamily: fonts.lato.black, fontSize: 60, color: colors.white, lineHeight: 61 }}>{contact.firstname} {contact.lastname}</Text>
          <Text style={{ marginTop: '2%', width: '80%', fontFamily: fonts.lato.regular, fontSize: 20, color: colors.white, lineHeight: 24 }}>{contact.description}</Text>
          <Pressable
            onPress={() => {}}
            // onPress={() => push('UserDetails', { userId: contact.userId })}
            style={{ marginTop: '3%', backgroundColor: colors.white, borderRadius: 30, width: '12%', height: 35, alignItems: 'center', justifyContent: 'center' }}
          >
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 18 }}>
              Rencontrer
            </Text>
          </Pressable>
        </ResponsiveView>
      </LinearGradient>
    </ImageBackground>
  );
};

export default ContactCard;
