import React from 'react';
import { View } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';

import DarkBackgroundStyle from './DarkBackgroundStyle';

export default () => (
  <LinearGradient
    end={{ x: 0, y: 0 }}
    start={{ x: 0, y: 1 }}
    style={DarkBackgroundStyle.gradient}
    colors={['#000000B3', '#00000059', '#00000033', '#0000000D', '#00000000']}
  />
);

export const DisableCardBackground = () => (
  <View style={[DarkBackgroundStyle.gradient, { backgroundColor: 'rgba(255,255,255,0.5)' }]} />
);
