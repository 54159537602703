import { submit } from 'redux-form';
import { Alert } from 'react-native';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Text, View } from 'native-base';

import { IconCheck } from 'styles/svg';
import { editEmail } from 'store/actions/account';
import { TextStyles, ContainerStyles } from 'styles';
import { toastError, toastSuccess } from 'services/toasts';

import EditEmailForm from './EditEmailForm';

import BasicLayout from 'components/layouts/basicLayout';
import MultiColorButton from 'components/buttons/multiColorButton';
import { MobileView, BrowserView, isMobile, isBrowser } from 'components/device';

const EditEmail = ({ navigation: { push, goBack }, submit, editEmail }) => {
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = async (values) => {
    try {
      setIsLoading(true);
      await editEmail(values);
      setIsSend(true);
      if (isBrowser) {
        toastSuccess('Votre mot de passe a bien été effectué!');
      }
    } catch (err) {
      if (isBrowser) {
        toastError('Une erreur est survenue, veuillez réessayer plus tard');
      }
      if (isMobile) {
        Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
      }
    } finally {
      setIsLoading(false);
      setTimeout(goBack, 3000);
    }
  };

  return (
    <BasicLayout push={push} title="Modifier mon email" disableMenu={true}>
      {
        /* MOBILE CONFIRMATION */
        isSend && isMobile ? (
          <View style={[ContainerStyles.requestFormMobileContainer, { justifyContent: 'center' }]}>
            <IconCheck width="20%" height="20%"/>
            <Text style={TextStyles({ marginTop: 20 }).regular16}>
              Votre email de connexion a bien été modifié
            </Text>
          </View>
        ) : (
          <View style={{ width: '100%' }}>
            {/* MOBILE */}
            <MobileView>
              <View style={ContainerStyles.requestFormMobileContainer}>
                <EditEmailForm onSubmit={sendRequest} />
                <MultiColorButton style={{ marginBottom: 40 }} loading={isLoading} text="Modifier" onPress={() => submit('editEmail')} />
              </View>
            </MobileView>
            {/* BROWSER */}
            <BrowserView>
              <View style={ContainerStyles.requestFormWebContainer}>
                <Text style={TextStyles().black23}>Modifier mon email</Text>
                <EditEmailForm onSubmit={sendRequest} />
                <MultiColorButton
                  text="Envoyer"
                  loading={isLoading}
                  style={ContainerStyles.requestFormWebSubmitButton}
                  onPress={() => submit('editEmail')}
                />
              </View>
            </BrowserView>
          </View>
        )
      }
    </BasicLayout>
  );
};

const actionProps = { editEmail, submit };

export default connect(null, actionProps)(EditEmail);
