'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.Users = void 0;
var index_1 = require('./index');
var Users = /** @class */ (function (_super) {
  __extends(Users, _super);
  function Users() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Users.prototype.getAll = function () {
    return this.requester.get('/users');
  };
  Users.prototype.getContacts = function () {
    return this.requester.get('/users/contacts');
  };
  Users.prototype.get = function (id) {
    return this.requester.get('/users/' + id);
  };
  Users.prototype.getPublic = function (id) {
    return this.requester.get('/users/' + id + '/public');
  };
  Users.prototype.getHome = function () {
    return this.requester.get('/users/home');
  };
  Users.prototype.connect = function (id, token) {
    return this.requester.post('/users/' + id + '/connect', {
      token: token
    });
  };
  Users.prototype.getSwapRequests = function () {
    return this.requester.get('/users/swap-requests');
  };
  Users.prototype.swapRequest = function (description, requestedUserId) {
    return this.requester.post('/users/swap-requests', {
      description: description,
      requestedUserId: requestedUserId
    });
  };
  Users.prototype.acceptSwapRequest = function (swapRequestId) {
    return this.requester.put('/users/swap-requests/' + swapRequestId + '/accept');
  };
  Users.prototype.rejectSwapRequest = function (swapRequestId) {
    return this.requester.put('/users/swap-requests/' + swapRequestId + '/reject');
  };
  Users.prototype.removeRelation = function (userId) {
    return this.requester['delete']('/users/relations/' + userId);
  };
  Users.prototype.networkAdhesion = function (message, networkId, type) {
    return this.requester.post('/users/network-adhesion', {
      type: type,
      message: message,
      networkId: networkId
    });
  };
  Users.prototype.block = function (userId) {
    return this.requester.post('/users/block/' + userId);
  };
  return Users;
}(index_1.Component));
exports.Users = Users;
