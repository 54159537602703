import { Icon } from 'react-native-elements';
import arrayMutators from 'final-form-arrays';
import { Form, Field } from 'react-final-form';
import React, { useEffect, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { View, Text, useWindowDimensions } from 'react-native';

import Api from 'services/api';
import { colors, TextStyles } from 'styles';
import propsAreEqual from 'services/propsAreEqual';

import { validate } from './formValidation';
import { AccountStyles } from './AccountStyles';

import Loader from 'components/loader';
import Input from 'components/form/input';
import EncartTitle from 'components/form/encartTitle';
import ImagePicker from 'components/form/imagePicker';
import GrayButton from 'components/buttons/grayButton';
import PickerField from '../../components/form/picker';
import PlaceAutocomplete from 'components/form/placeAutocomplete';

const ActivitiesFieldArray = ({ push }) => (
  <FieldArray name="activities">
    {({ fields }) => (
      <>
        {fields.map((name, i) => (
          <View key={fields.value[i].id} style={AccountStyles.webSubFieldContainer}>
            <EncartTitle index={i} onRemove={() => fields.remove(i)} name={'Activité'} />
            <Field
              width="100%"
              component={Input}
              name={`${name}.name`}
              label={'Activité #' + (i + 1)}
            />
            <Field
              multiline
              height={140}
              width="100%"
              component={Input}
              label="Description"
              name={`${name}.description`}
              style={AccountStyles.webSubFieldDescription}
            />
          </View>
        ))}
        <GrayButton
          noBar
          width='50%'
          height={50}
          titre="+ Ajouter une activité"
          style={AccountStyles.webSubFieldAddButton}
          onPress={() => push('activities', { id: Math.round(Math.random() * 100), name: '', description: '' })}
        />
      </>
    )}
  </FieldArray>
);

const ServicesFieldArray = ({ push }) => (
  <FieldArray name="services">
    {({ fields }) => (
      <>
        {fields.map((argument, i) => (
          <View key={fields.value[i].id} style={AccountStyles.webSubFieldContainer}>
            <EncartTitle index={i} onRemove={() => fields.remove(i)} name={'Service'} />
            <Field
              width="100%"
              component={Input}
              name={`${argument}.name`}
              label={'Service #' + (i + 1)}
            />
            <Field
              multiline
              width="100%"
              height={140}
              component={Input}
              label="Description"
              name={`${argument}.description`}
              style={AccountStyles.webSubFieldDescription}
            />
          </View>
        ))}
        <GrayButton
          noBar
          width='50%'
          height={50}
          titre="+ Ajouter un service"
          style={AccountStyles.webSubFieldAddButton}
          onPress={() => push('services', { id: Math.round(Math.random() * 100), name: '', description: '' })}
        />
      </>
    )}
  </FieldArray>
);

const ArgumentsFieldArray = ({ push }) => (
  <FieldArray name="arguments">
    {({ fields }) => (
      <>
        {fields.map((argument, i) => (
          <View key={fields.value[i].id} style={AccountStyles.webSubFieldContainer}>
            <EncartTitle index={i} onRemove={() => fields.remove(i)} name={'Argument'} />
            <Field
              width="100%"
              name={`${argument}.name`}
              label={'Argument #' + (i + 1)}
              component={Input}
            />
            <Field
              multiline
              width="100%"
              height={140}
              component={Input}
              label="Description"
              name={`${argument}.description`}
              style={AccountStyles.webSubFieldDescription}
            />
          </View>
        ))}
        <GrayButton
          noBar
          width='50%'
          height={50}
          titre="+ Ajouter un argument"
          style={AccountStyles.webSubFieldAddButton}
          onPress={() => push('arguments', { id: Math.round(Math.random() * 100), name: '', description: '' })}
        />
      </>
    )}
  </FieldArray>
);

const AnecdotesFieldArray = ({ push }) => (
  <FieldArray name="anecdotes">
    {({ fields }) => (
      <>
        {fields.map((argument, i) => (
          <View key={fields.value[i].id} style={AccountStyles.webSubFieldContainer}>
            <EncartTitle index={i} onRemove={() => fields.remove(i)} name={'Anecdote'} />
            <Field
              width="100%"
              component={Input}
              name={`${argument}.name`}
              label={'Anecdote #' + (i + 1)}
            />
            <Field
              multiline
              width="100%"
              height={140}
              label="Description"
              component={Input}
              name={`${argument}.description`}
              style={AccountStyles.webSubFieldDescription}
            />
          </View>
        ))}
        <GrayButton
          noBar
          width='50%'
          height={50}
          titre="+ Ajouter une anecdote"
          style={AccountStyles.webSubFieldAddButton}
          onPress={() => push('anecdotes', { id: Math.round(Math.random() * 100), name: '', description: '' })}
        />
      </>
    )}
  </FieldArray>
);

function AccountFormWeb({ onSubmit, initialValues, Button, isCompleted }) {
  const [loaded, setLoaded] = useState(false);
  const [spheres, setSpheres] = useState([]);
  const { width: widthScreen, height } = useWindowDimensions();

  const loadSpheres = async () => {
    try {
      const { data } = await Api.misc.getSpheres();
      setSpheres(data);
    } catch (err) {
      console.error('Error getting spheres', err);
    }
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      loadSpheres();
    }
  }, []);

  if (!spheres) return <Loader />;

  const FormContent = React.memo(({ form, push }) => (
    <View style={AccountStyles.webFormContainer}>
      {
        !isCompleted ? (
          <Text style={TextStyles({ margin: 20, color: colors.burgundy }).boldItalic15}>
            Vous devez compléter les mentions obligatoires de votre profil avant de pouvoir accéder à votre outil Othr          </Text>
        ) : null
      }
      <Text style={TextStyles({ margin: 20 }).bold30}>Editez votre profil</Text>
      <View style={AccountStyles.webFormFieldsContainer}>
        <View style={AccountStyles.webFormFirstFields}>
          <Field
            required={true}
            name='picture_url'
            images={null}
            svg={<Icon
              size={50}
              name="plus"
              type="simple-line-icon"
              color={colors.greyDark}
            />}
            width="100%"
            titre="Photo de Profil"
            height={widthScreen * 0.3}
            component={ImagePicker}
            style={AccountStyles.borderStyleComponent}
          />
        </View>
        <View style={AccountStyles.webFormSecondFields}>
          <Text style={TextStyles({ margin: 20, marginTop: 0 }).bold20}>Infos</Text>
          <View style={AccountStyles.webFormInformation}>
            <View style={AccountStyles.webFormInformationFirstInput}>
              <Field
                width="100%"
                label="Prénom"
                required={true}
                name="firstname"
                component={Input}
              />
            </View>
            <View style={AccountStyles.webFormInformationSecondInput}>
              <Field
                label="Nom"
                width="100%"
                name="lastname"
                component={Input}
                required={true}
              />
            </View>
          </View>
          <View style={AccountStyles.webFormInformation}>
            <View style={AccountStyles.webFormInformationFirstInput}>
              <Field
                width="100%"
                label="Métier"
                required={true}
                component={Input}
                name="job"
              />
              <Field
                label="Surnom"
                width="100%"
                name="surname"
                required={false}
                component={Input}
              />
            </View>
            <View style={AccountStyles.webFormInformationSecondInput}>
              <Field
                width="100%"
                name="sphere"
                valueKey="id"
                label="Sphère"
                labelKey="name"
                required={true}
                component={PickerField}
                items={spheres.map((s) => {
                  return {
                    ...s,
                    id: s.id.toString(),
                  };
                })}
              />
            </View>
          </View>
          <Text style={TextStyles({ margin: 20 }).bold20}>Contact</Text>
          <View style={AccountStyles.webFormInformation}>
            <View style={AccountStyles.webFormInformationFirstInput}>
              <Field
                name="phone"
                width="100%"
                required={false}
                component={Input}
                keyboardType="phone-pad"
                label="Téléphone portable"
                textContentType="telephoneNumber"
              />
            </View>
            <View style={AccountStyles.webFormInformationSecondInput}>
              <Field
                width="100%"
                required={true}
                component={Input}
                name="email_contact"
                autoCapitalize="none"
                keyboardType="email-address"
                textContentType="emailAddress"
                label="Adresse email de contact"
              />
            </View>
          </View>

          <Text style={TextStyles({ margin: 20 }).bold20}>Vidéo de présentation</Text>
          {<Field
            width="100%"
            name="video_url"
            component={Input}
            autoCorrect={false}
            autoCapitalize="none"
            label="Ajouter un lien vidéo de présentation (youtube)"
          />}
        </View>
      </View>
      <Text style={TextStyles({ marginTop: 30, margin: 20 }).bold20}>Descriptif de votre profil</Text>
      <Field
        multiline
        width="100%"
        required={false}
        component={Input}
        name="description"
        height={height * 0.37}
        label="Renseignez ici le descriptif de votre profil. Qui êtes vous ? Qu’est-ce qui vous porte ?"
      />
      <Text style={TextStyles({ marginTop: 30, margin: 20 }).bold30}>Votre société</Text>
      <View style={AccountStyles.webFormInformation}>
        <View style={AccountStyles.webFormFirstFields}>
          <Field
            width="100%"
            images={null}
            name='logo_url'
            titre="Votre logo"
            component={ImagePicker}
            height={widthScreen * 0.6 * 0.42}
            svg={<Icon
              name="plus"
              size={70}
              type="simple-line-icon"
              color={colors.greyDark}
            />}
          />
        </View>
        <View style={AccountStyles.webFormSecondFields}>
          <Text style={TextStyles({ marginBottom: 20, marginLeft: 20 }).bold20}>Infos</Text>
          <View style={AccountStyles.webFormInformation}>
            <View style={AccountStyles.webFormInformationFirstInput}>
              <Field
                width="100%"
                name="company"
                required={false}
                component={Input}
                autoCorrect={false}
                autoCapitalize="none"
                label="Nom de votre entreprise"
              />
            </View>
            <View style={AccountStyles.webFormInformationSecondInput}>
              <Field
                width="100%"
                name="website"
                required={false}
                label="Site Web"
                component={Input}
                autoCorrect={false}
                autoCapitalize="none"
              />
            </View>
          </View>
          <Text style={TextStyles({ margin: 20 }).bold20}>Siège social</Text>
          <View style={{ zIndex: 100, marginBottom: 10 }}>
            <Field
              width="100%"
              name="address"
              label="Address"
              component={PlaceAutocomplete}
              required={true}
            />
          </View>
          <Text style={TextStyles({ margin: 20 }).bold20}>Vidéo de présentation</Text>
          <Field
            width="100%"
            name="video_url"
            component={Input}
            autoCorrect={false}
            autoCapitalize="none"
            label="Ajouter un lien vidéo de présentation"
          />
        </View>
      </View>
      <View style={AccountStyles.webFormInformation}>
        <View style={AccountStyles.webFormInformationFirstInput}>
          <Text style={TextStyles({ margin: 20 }).bold20}>Activités</Text>
          <ActivitiesFieldArray push={push} />
          <Text style={TextStyles({ margin: 20 }).bold20}>Services</Text>
          <ServicesFieldArray push={push} />
        </View>
        <View style={AccountStyles.webFormInformationSecondInput}>
          <Text style={TextStyles({ margin: 20 }).bold20}>Arguments</Text>
          <ArgumentsFieldArray push={push} />
          <Text style={TextStyles({ margin: 20 }).bold20}>Anecdotes</Text>
          <AnecdotesFieldArray push={push} />
        </View>
      </View>
      <Button onClick={form.submit} />
    </View>
  ), propsAreEqual);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      render={({
        form,
        form: {
          mutators: { push }
        },
      }) => (
        <FormContent form={form} push={push} />
      )}
    />
  );
}

export default AccountFormWeb;
