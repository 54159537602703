import { StyleSheet, Dimensions, Platform } from 'react-native';

import { colors, fonts } from 'styles';

const widthScreen = Dimensions.get('screen').width;

export const CreateEventStyle = StyleSheet.create({
  flexGrowOne: {
    width: '100%',
    alignItems: 'center',
  },
  centerButton: {
    alignItems: 'center',
    marginBottom: 15,
  },
  container: {
    alignItems: 'center',
    paddingHorizontal: 25,
    width: '100%',
  },
  containerEvent: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  formPlacesContainer: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  widthFifty: {
    width: Platform.OS !== 'web' ? '45%' : '48%',
    marginBottom: Platform.OS !== 'web' ? 20 : 0,
  },
  placesContainer: {
    width: '100%',
    alignItems: 'center',
  },
  placesTextContainer: {
    width: '55%',
  },
  placesTitle: {
    fontSize: 15,
    marginBottom: 5,
    color: colors.greyDark,
  },
  placesText: {
    fontSize: Platform.OS !== 'web' ? 9 : 13,
    color: colors.greyDayColor,
  },
  containtFieldPlace: {
    width: Platform.OS !== 'web' ? '40%' : '45%',
    marginLeft: Platform.OS !== 'web' ? 0 : '-2.5%',
  },
  sectionTitle: {
    color: colors.grey,
    fontFamily: fonts.lato.bold,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  sectionSubtitle: {
    fontFamily: fonts.lato.regular,
    color: colors.secondary,
    fontSize: 12,
    marginBottom: 20,
    marginTop: -10,
    textAlign: 'center',
    maxWidth: '80%',
  },
  address: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    // backgroundColor: colors.white,
    borderRadius: 10,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 5,
  },
  adressImage: {
    width: '20%',
    alignItems: 'flex-start',
    marginLeft: 10,
  },
  adreeInput: {
    width: '100%',
  },
  tarifContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 65,
    borderColor: colors.liteGrey,
    borderBottomWidth: 0.4,
  },
  tarif: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tariftext: {
    color: colors.black,
    fontSize: 16,
    marginRight: 5,
    marginLeft: 10,
  },
  note: {
    width: '30%',
    alignItems: 'flex-start',
    fontSize: 10,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.whiteShadow,
    borderRadius: 25,
    height: '100%',
    width: '100%',
  },
  title: {
    padding: 10,
    margin: 5,
    color: colors.grey,
    fontSize: 17,
    fontWeight: 'bold',
  },
  desciption: {
    paddingLeft: 15,
    paddingRight: 15,
    color: colors.black,
    margin: 15,
    borderRadius: 10,
    height: 150,
    textAlignVertical: 'top',
    paddingTop: 15,
  },
  gallery: {
    height: '100%',
  },
  gallerySlider: {},
  button: {
    width: '100%',
    height: 80,
    backgroundColor: colors.blue,
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
  },
  buttonText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  tickets: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 15,
    borderRadius: 10,
    // backgroundColor: colors.white,
  },
  viewContaint: {
    marginBottom: 30,
    alignItems: 'center',
    borderWidth: 10,
    borderColor: '#e0dede',
    alignSelf: 'center',
    borderRadius: 50,
    width: widthScreen * 0.7,
  },
  textBombButton: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    letterSpacing: 1.2,
    fontSize: 16,
  },
  marginFive: {
    margin: 5,
  },
  containtLieu: {
    width: '100%',
    marginVertical: 20,
    alignItems: 'center',
  },
  containerDescriptif: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 20,
  },
  containerBillet: {
    marginVertical: 10,
    alignItems: 'center',
    width: '100%'
  },
  contentBillet: {
    marginBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  widthSixty: {
    width: '65%',
    marginRight: '5%',
  },
  billetValue: {
    width: '48%',
    alignItems: 'flex-end',
  },
  marginTopThirty: {
    marginTop: 30,
  },
  placeAvailable: {
    fontWeight: '100',
    fontSize: 15,
  },
  webEditButton: {
    width: '14%',
    marginLeft: '20%',
  },
  mobileEditButton: {
    height: 70,
    width: '80%',
    marginTop: '5%',
    marginBottom: '20%',
  }
});
