import { StyleSheet, Dimensions } from 'react-native';
import { colors } from 'styles';

const widthScreen = Dimensions.get('screen').width;
const heightScreen = Dimensions.get('screen').height;

export const ValidationEventStyle = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.whiteShadow,
    height: '100%',
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
  },
  image: {
    width: 85,
    height: 50,
    marginBottom: 50,
  },
  textOne: {
    textAlign: 'center',
    width: 200,
    color: colors.grey,
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  textTwo: {
    textAlign: 'center',
    color: colors.grey,
    fontSize: 18,
    width: 265,
  },
});
