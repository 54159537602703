import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';

import { colors } from 'styles';
import LinearBackgroundStyle from './LinearBackgroundStyle';

export default () => (
  <LinearGradient
    style={LinearBackgroundStyle.linearBackground}
    start={{ x: 0, y: 0 }}
    end={{ x: 0, y: 1 }}
    colors={[colors.white, colors.liteGrey]}
  />
);
