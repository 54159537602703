import React from 'react';
import { ScrollView, View, Dimensions } from 'react-native';

import SliderTip from '../sliderTip';

const { width } = Dimensions.get('screen');

export default ({
  height = '100%',
  children,
  style,
  contentWidth = '90%',
  tipSpace,
  tipHeight = 50,
}) => (
  <ScrollView pagingEnabled horizontal style={{ height, ...style }} showsHorizontalScrollIndicator={false}>
    {children.map ? children.map((c, i) => (
      <View key={i} style={{ width, alignItems: 'center' }}>
        <View style={{ width: contentWidth }}>
          {c}
        </View>
        {i > 0 && <SliderTip tipHeight={tipHeight} tipSpace={tipSpace} position="left" style={{ top: (height - tipHeight || 25) / 2 }} />}
        {(i < children.length - 1) && <SliderTip tipHeight={tipHeight} tipSpace={tipSpace} position="right" style={{ top: (height - tipHeight || 25) / 2 }} />}
      </View>
    )) : (
      <View style={{ width, alignItems: 'center' }}>
        <View style={{ width: contentWidth }}>
          {children}
        </View>
      </View>
    )}
  </ScrollView>
);
