import React from 'react';
import { Text, View } from 'native-base';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { colors, fonts } from 'styles';
import { WebUserImageStyle } from './WebUserImageStyle';
import { pushToUser } from '../../../services/navigationPush';
import BackgroundLinearGradient from '../../backgrounds/backgroundLinearGradient';

const UserImage = ({ user, height = 612 }) => {
  const { push } = useNavigation();

  return (
    <TouchableOpacity onPress={() => pushToUser(push, user)} style={WebUserImageStyle.contentOtherSlideNew}>
      <BackgroundLinearGradient
        image={{ uri: user?.picture_url }}
        width={'100%'}
        height={height}
        styles={WebUserImageStyle.contentSlideText}
      >
        <View style={{ height: height * 0.9, justifyContent: 'flex-end', width: '80%', marginLeft: '10%' }}>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 60, color: colors.white, lineHeight: 65 }}>{user?.firstname} {user?.lastname}</Text>
          <Text style={{ fontFamily: fonts.lato.black, fontSize: 20, textUnderlineOffset: 6, textDecorationLine: 'underline', color: colors.white, marginTop: 30 }}>{user?.job}</Text>
        </View>
      </BackgroundLinearGradient>
    </TouchableOpacity>
  );
};

export default UserImage;
