import { Image } from 'native-base';
import { connect } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { View, Platform, Text, FlatList } from 'react-native';

import { EventBillet } from 'styles/svg';
import { colors, TextStyles } from 'styles';
import { Tickets } from 'store/actions/event';
import { toastSuccess, toastError } from 'services/toasts';

import styles from './TicketsStyle';

import Event from 'components/cards/event';
import NoResources from 'components/noResources';
import GrayButton from 'components/buttons/grayButton';
import WebSoloList from 'components/webLists/SoloList';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import { BrowserView, MobileView, ResponsiveView } from 'components/device';
import HollowBackground from 'components/backgrounds/hollowBackground/HollowBackground';

const TicketsPage = ({ Tickets, tickets, navigation: { push, goBack, canGoBack }, route }) => {
  useEffect(() => {
    if (route.params?.status === 'success') {
      toastSuccess('Votre réservation a bien été effectuée !');
    }
    if (route.params?.status === 'error') {
      toastError('Votre réservation n\'a pas pu être finalisée.');
    }
  }, []);

  const data = useMemo(() => {
    if (!tickets) {
      return;
    }
    const knownEvents = [];
    return tickets.map((t) => {
      const item = !knownEvents.includes(t.event.id) ? ({
        ...t,
        date: new Date(t.event.start_date),
      }) : null;
      knownEvents.push(t.event.id);
      return item;
    }).filter(t => t !== null);
  }, [ tickets ]);

  const renderTicket = ({ item }) => {
    return (
      <View style={styles.ticketContainer} key={item.id}>
        <Event {...item.event} onPress={() => push('TicketDetails', { ticketId: item.id })}/>
      </View>
    );
  };

  const RenderWebContent = () => {
    const ticketsGroup = data?.filter((d) => d.type !== 'month').reduce((ticketsGroup, item) => {
      const group = (ticketsGroup[item.event.id] || []);
      group.push(item);
      ticketsGroup[item.event.id] = group;
      return ticketsGroup;
    }, {});

    const eventsGroup = Object.values(ticketsGroup).map((item) => {
      return {
        ...item[0].event,
        type: 'ticket',
      };
    });

    const current = new Date();
    const nextEvents = eventsGroup.filter((d) => (new Date(d.start_date)) >= current);
    const pastEvents = eventsGroup.filter((d) => (new Date(d.start_date)) < current);

    return (
      <View>
        <ResponsiveView style={styles.webHeader}>
          <Image alt="Change me" resizeMode="contain" style={styles.webHeaderImage} source={EventBillet} />
          <Text selectable={true} style={TextStyles({ color: colors.black }).bold25}>Tous mes billets</Text>
        </ResponsiveView>
        {nextEvents.length > 0 && <WebSoloList showSide onPress={(item) => push('TicketDetails', { eventId: item.id })} push={push} resources={nextEvents} />}
        {
          pastEvents.length > 0 && (
            <ResponsiveView style={styles.webPastTicketsContainer}>
              <View style={styles.webPastTicketsSubContainer}>
                <HollowBackground />
                <Image alt="Change me" resizeMode="contain" style={styles.webPastTicketImage} source={EventBillet} />
                <Text selectable={true} style={TextStyles({ color: colors.grey }).bold18}>J'y ai participé</Text>
              </View>
            </ResponsiveView>
          )
        }
        {pastEvents.length > 0 && <WebSoloList showSide onPress={(item) => push('TicketDetails', { eventId: item.id })} push={push} resources={pastEvents} />}
      </View>
    );
  };

  return (
    <LoadResourceLayout
      push={push}
      title={'Mes billets'}
      mobilePadding={false}
      loadResources={Tickets}
      headerSection={<HeaderSection push={push} menuSelect="Events" redirect="Event"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Events" />}
    >
      {
        data?.length > 0 ? (
          <View>
            {/* MOBILE */}
            <MobileView>
              <FlatList contentContainerStyle={{ paddingBottom: 40 }} data={data} renderItem={renderTicket}/>
            </MobileView>
            {/* BROWSER */}
            <BrowserView>
              <RenderWebContent />
            </BrowserView>
          </View>
        ) : (
          /* NO RESOURCES COMPONENT */
          <NoResources
            noResourceText="Vous n'avez pas encore de billet"
            findResourceText="Une fois que vous aurez acheté votre premier billet, vous pourrez le retrouver ici"
            buttons={[
              () => <GrayButton width="100%" onPress={() => push('Event')} titre={'Participer à un Event'} />,
            ]}
          />
        )
      }
    </LoadResourceLayout>
  );
};

const mapStateToProps = (state) => ({
  tickets: Platform.OS !== 'web' ? state.Event.tickets?.filter(t => new Date(t.event?.start_date) > new Date()) : state.Event.tickets,
});
const mapDispatchToProps = {
  Tickets,
};
export default connect(mapStateToProps, mapDispatchToProps)(TicketsPage);
