import Api from 'services/api';
import JwtDecode from 'jwt-decode';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { isBrowser } from 'components/device';
import { hideMenu } from 'store/actions/menu';

export function Login(data) {
  return async (dispatch) => {
    try {
      const response = await Api.auth.signin(data.email, data.password);
      const { token, id, isSub } = response.data;
      if (isSub) {
        dispatch(hideMenu());
        dispatch(loadToken(token));
        dispatch({
          type: 'LOGGED',
          logged: true,
        });
        await AsyncStorage.setItem('USER_ID', id.toString());
        await AsyncStorage.setItem('AUTH_TOKEN', token);
      }
      return Promise.resolve({ id, isSub, token });
    } catch (err) {
      return Promise.reject(err);
    }
  };
}

export function GoogleLogin(data) {
  return async (dispatch) => {
    try {
      const response = await Api.auth.googleSignin({ credential: data.credential, accessToken: data.accessToken });
      // If response is an error
      if (response.status >= 400 || !response.data) {
        throw response;
      }
      const { isSub, token, accessToken, id } = response.data;
      if (isSub) {
        dispatch(loadToken(token));
        dispatch({
          type: 'LOGGED',
          logged: true,
        });
        await AsyncStorage.setItem('USER_ID', id.toString());
        await AsyncStorage.setItem('AUTH_TOKEN', token);
      }
      return Promise.resolve({ isSub, token, id });
    } catch (err) {
      console.log('Error in GoogleLogin', err);
      return Promise.reject(err);
    }
  };
}

export function GoogleRegister(data) {
  return async () => {
    try {
      const response = await Api.auth.googleSignup(data);
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in GoogleRegister', err);
      return Promise.reject(err);
    }
  };
}

export function AppleLogin(data) {
  return async (dispatch) => {
    try {
      const response = await Api.auth.appleSignin({
        authorizationCode: data.authorizationCode,
        origin: isBrowser ? 'BROWSER' : 'APP',
      });
      const { isSub, token, status, id } = response.data;
      console.log('isSub', isSub, token, id);
      if (isSub) {
        dispatch(loadToken(token));
        dispatch({
          type: 'LOGGED',
          logged: true,
        });
        await AsyncStorage.setItem('USER_ID', id.toString());
        await AsyncStorage.setItem('AUTH_TOKEN', token);
      }
      return Promise.resolve({ isSub, status, id, token });
    } catch (err) {
      console.log('Error in login', err);
      return Promise.reject(err);
    }
  };
}

export function AppleRegister(data) {
  return async () => {
    try {
      const response = await Api.auth.appleSignup({
        ...data,
        origin: isBrowser ? 'BROWSER' : 'APP',
      });
      if (!response.data) return Promise.reject(response);
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in AppleRegister', err);
      return Promise.reject(err);
    }
  };
}

export function Register(data) {
  return async () => {
    try {
      const response = await Api.auth.signup(data);
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in register', err);
      return Promise.reject(err);
    }
  };
}

export function ForgotPassword(data) {
  return async (dispatch) => {
    try {
      const response = await Api.auth.forgotPassword(data.email);
      return Promise.resolve(response?.data);
    } catch (err) {
      console.log('Error in forgotPassword', err);
      return Promise.reject(err);
    }
  };
}

export function ResetPassword(data) {
  return async (dispatch) => {
    try {
      const response = await Api.auth.resetPasswordToken(data.token, data.password);
      return Promise.resolve(response?.data);
    } catch (err) {
      console.log('Error in resetPassword', err);
      return Promise.reject(err);
    }
  };
}

export function GetUser() {
  return async (dispatch) => {
    try {
      const response = await Api.profile.get();
      dispatch({
        type: 'CURRENT_USER',
        currentUser: response.data,
      });
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in get account', err);
      return Promise.reject(err);
    }
  };
}

export function GetEssentialUser() {
  return async (dispatch) => {
    try {
      const response = await Api.profile.getEssential();
      dispatch({
        type: 'ESSENTIAL_USER',
        essentialUser: response.data,
      });
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in get account', err);
      return Promise.reject(err);
    }
  };
}

export function Logout(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'LOAD_TOKEN',
        token: null,
      });
      dispatch({
        type: 'LOGGED',
        logged: false,
      });
      dispatch({
        type: 'SET_USER_ID',
        payload: null,
      });
      await AsyncStorage.removeItem('USER_ID');
      await AsyncStorage.removeItem('AUTH_TOKEN');
    } catch (err) {
      console.log('Error in login', err);
    }
  };
}

export function loadToken(token) {
  return async (dispatch) => {
    try {
      const { id } = JwtDecode(token);
      Api.auth.setToken(token);
      dispatch({
        type: 'LOAD_TOKEN',
        token,
      });
      dispatch({
        type: 'SET_USER_ID',
        payload: id,
      });
    } catch (err) {
      console.log('load token failed:', err);
    }
  };
}

export function refreshToken() {
  return async (dispatch) => {
    try {
      const response = await Api.auth.refreshToken();
      const token = response.data.token;
      Api.auth.setToken(token);
      dispatch({
        type: 'LOAD_TOKEN',
        token,
      });
    } catch (err) {
      console.log('load token failed:', err);
    }
  };
}

export function contactUs(data) {
  return async () => {
    try {
      const response = await Api.profile.contactUs(data.object, data.request);
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in contact us', err);
      return Promise.reject(err);
    }
  };
}

export function editEmail(data) {
  return async () => {
    try {
      const response = await Api.profile.editEmail(data.newEmail);
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in contact us', err);
      return Promise.reject(err);
    }
  };
}

export function editPassword(data) {
  return async () => {
    try {
      const response = await Api.profile.editPassword(data.newPassword);
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in contact us', err);
      return Promise.reject(err);
    }
  };
}

export function manageBilling(returnUrl) {
  return async () => {
    try {
      const response = await Api.payments.manageBilling(returnUrl);
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in manage billing', err);
      return Promise.reject(err);
    }
  };
}
