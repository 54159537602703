import { StyleSheet, Dimensions } from 'react-native';

import { colors, fonts } from 'styles';

const widthScreen = Dimensions.get('screen').width;
const heightScreen = Dimensions.get('screen').height;

export default StyleSheet.create({
  itemStyle: {
    flexDirection: 'row',
    borderColor: 'transparent',
    width: '100%',
    alignItems: 'center',
  },
  containerInput: {
    width: '100%',
    height: 52,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 25,
  },
  container: {
    paddingTop: heightScreen * 0.05,
    alignItems: 'center',
  },
  imgClose: {
    resizeMode: 'contain',
    width: 150,
    height: 150,
  },
  containerImgClose: {
    position: 'absolute',
    padding: 10,
    left: 60,
    top: 50,
    borderRadius: 50,
    width: 50,
    backgroundColor: '#FEFEFE',
    zIndex: 2,
  },
  errorStyle: {
    position: 'absolute',
    top: 'auto',
    right: 10,
    width: 3,
    height: 13,
    borderRadius: 5,
    backgroundColor: colors.burgundy,
  },
  inputStyle: {
    textAlign: 'center',
    overflow: 'hidden',
    color: colors.grey,
    fontSize: 14,
    fontFamily: fonts.lato.regular,
    width: '100%',
    paddingHorizontal: 21,
  },
  containerForm: {
    alignItems: 'center',
    width: widthScreen * 0.85,
    backgroundColor: 'transparent',
  },
  textClose: {
    fontSize: 25,
    textAlign: 'center',
    color: colors.grey,
  },
  titre: {
    fontSize: 22,
    color: colors.black,
    fontWeight: 'bold',
    margin: 0,
  },
  imgInput: {
    position: 'absolute',
    top: 0,
    width: 50,
    height: 50,
    resizeMode: 'contain',
    zIndex: 10,
    left: 15,
  },
  datePicker: {
    backgroundColor: colors.white,
    fontFamily: fonts.lato.regular,
    borderRadius: 15,
  },
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff66'
  }
});
