import React, { useEffect, useState } from 'react';
import { Alert } from 'react-native';
import { View } from 'native-base';
import { GoogleApiWrapper } from 'google-maps-react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { colors, fonts } from 'styles';
import { GOOGLE_API_KEY } from 'config';

import FieldWrapper from 'components/form/fieldWrapper';
import InputStyles from 'components/form/input/InputStyles';
import HollowBackground from 'components/backgrounds/hollowBackground/HollowBackground';
import { toastError } from '../../../services/toasts';

const AutocompleteContainer = ({ input }) => {
  const [ valueCurrent, setValueCurrent ] = useState('');

  useEffect(() => {}, []);

  return (
    <GooglePlacesAutocomplete
      minLengthAutocomplete={3}
      apiKey={GOOGLE_API_KEY}
      placeholder='Addresse'
      returnKeyType="search"
      apiOptions={{ language: 'fr', region: 'fr' }}
      textInputProps={{
        selectionColor: 'red',
        placeholderTextColor: colors.secondary,
      }}
      selectProps={{
        value: valueCurrent,
        onChange: (value) => {
          const number = value.value.terms[0]?.value;
          const street = value.value.terms[1]?.value;
          const city = value.value.terms[2]?.value;
          const placeId = value.value.place_id;
          if (!number || !street || !city || !placeId) {
            toastError(
              'Adresse invalide. Merci de sélectionner une adresse précise. Ex: 1, rue des Champs, 77000 Chelles',
            );
          } else {
            setValueCurrent(value);
            input.onChange({
              number,
              street,
              city,
              placeId,
            });
          }
        },
        placeholder: input.value ? `${input.value.number} ${input.value.street}, ${input.value.city}` : 'Addresse précise',
        components: {
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        },
        styles: {
          menu: (provided) => ({
            ...provided,
            padding: 5,
            borderRadius: 10,
            zIndex: 200,
            maxHeight: 140,
            overflow: 'scroll',
          }),
          container: (provided) => ({
            ...provided,
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 120,
            fontSize: 14,
            fontFamily: fonts.lato.regular,
          }),
          valueContainer: (provided) => ({
            ...provided,
            zIndex: 100,
          }),
          control: (provided) => ({
            ...provided,
            borderWidth: 0,
            color: 'red',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            height: '100%',
            width: '100%',
            paddingLeft: 13,
            paddingRight: 21,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: colors.secondary,
            fontFamily: fonts.lato.regular,
          }),
          input: (provided) => ({
            ...provided,
            color: colors.grey,
            fontFamily: fonts.lato.regular,
            fontSize: 14,
          }),
        }
      }}
    />
  );
};

const Wrapper = GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
  libraries: ['places']
})(AutocompleteContainer);

const PlaceAutocomplete = (props) => {
  const {
    input,
    label,
    style,
    img = null,
    height = 52,
    customOnChange,
    centered = false,
    multiline = false,
    maxLength = undefined,
    ...inputProps
  } = props;

  return (
    <FieldWrapper {...props}>
      <View style={[InputStyles.containerInput, {}]}>
        <HollowBackground />
        {props.required ? (
          <View
            style={{
              width: 3,
              right: 5,
              margin: 10,
              height: 13,
              borderRadius: 20,
              position: 'absolute',
              borderColor: colors.whiteShadow,
              backgroundColor: colors.firebrick,
            }}
          />
        ) : null}      </View>
      <Wrapper input={input} />
    </FieldWrapper>
  );
};

export default PlaceAutocomplete;
