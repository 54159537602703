import React, { useRef, useEffect, useState } from 'react';
import { Text } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';
import { ActivityIndicator, TouchableOpacity, View, Animated, Easing } from 'react-native';

import styles from './MultiColorButtonStyle';

import { fonts } from 'styles';
import Svg from '../../svg/Svg';

import { MobileView, BrowserView } from 'components/device';
import HollowBackground from 'components/backgrounds/hollowBackground';

const MobileContent = (props) => {
  const progress = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    if (props.progress === undefined) return;
    const toValue = 1 - props.progress;
    Animated.timing(progress, {
      toValue,
      duration: 200,
      useNativeDriver: false,
      easing: Easing.linear,
    }).start();
  }, [props.progress]);

  return (
    <TouchableOpacity style={styles.button} onPress={props.onPress} disabled={props.loading || props.disabled || false}>
      <LinearGradient
        style={styles.linearGradient}
        colors={['#D20255', '#D20255', '#F37D1D', '#FBBF02', '#FBBF02']}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
      />
      {props.loading && props.progress !== undefined && (
        <Animated.View style={{
          position: 'absolute',
          top: 0,
          right: '-100%',
          height: '100%',
          width: '200%',
          transform: [{ scaleX: progress }],
          backgroundColor: '#ffffff77',
        }} />
      )}
      <View style={{ alignItems: 'center', flexDirection: 'row', }}>
        {!!props.svg && <Svg src={props.svg} style={styles.svg}/>}
        <Text style={{ color: 'white', fontFamily: fonts.lato.bold, fontSize: 16 }}>
          {!props.loading ? props.text : 'Chargement en cours...'}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const WebContent = (props) => (
  <TouchableOpacity style={styles.button} onPress={props.onPress} disabled={props.loading || props.disabled || false}>
    <LinearGradient
      style={[styles.linearGradient, { justifyContent: 'center', alignItems: 'center', paddingHorizontal: 15 }]}
      colors={props.color ? [props.color] : ['#D20255', '#D20255', '#F37D1D', '#FBBF02', '#FBBF02']}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
    >
      {props.loading && props.progress !== undefined && (
        <View style={{
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          width: `${((1 - (props.progress || 0.1)).toFixed(2) * 100).toString()}%`,
          backgroundColor: '#ffffff77'
        }} />
      )}
      <Text numberOfLines={1} style={{ color: props.textColor || 'white', fontWeight: 'bold', fontSize: 16 }}>
        {!props.loading ? props.text : 'Chargement en cours...'}
      </Text>
    </LinearGradient>
  </TouchableOpacity>
);

export default (props) => (
  <View style={[styles.container, props.style]}>
    {!props.noHollow && <HollowBackground />}
    <View style={[styles.buttonContainer, { paddingHorizontal: props.noHollow ? 0 : 10 }]}>
      <MobileView>
        <MobileContent {...props} />
      </MobileView>
      <BrowserView style={{ height: '100%' }}>
        <WebContent {...props} />
      </BrowserView>
    </View>
  </View>
);
