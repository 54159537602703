import { connect } from 'react-redux';
import { LinearGradient } from 'expo-linear-gradient';
import { AntDesign, Feather } from '@expo/vector-icons';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { View, ScrollView, Text, TouchableOpacity, Platform, Image, TextInput, Animated } from 'react-native';

import { colors, fonts } from 'styles';
import { svgtopng } from 'styles/images';
import { Networks } from 'store/actions/network';
import { pushToNetwork, pushToParentNetwork } from 'services/navigationPush';
import { ParentNetworks } from 'store/actions/parent_network';

import styles from './AllNetworksStyle';

import Loader from 'components/loader';
import Search from 'components/search/Search';
import TextSection from 'components/textSection';
import { ResponsiveView } from 'components/device';
import WebSoloList from 'components/webLists/SoloList';
import MenuButton from 'components/buttons/menuButton';
import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import HollowBackground from 'components/backgrounds/hollowBackground';

const alphabetArray = ['AC', 'DF', 'GI', 'JL', 'MO', 'PR', 'SU', 'VX', 'YZ'];

function AllNetworks({ route, navigation: { pop, push, goBack, canGoBack }, Networks, ParentNetworks, parentNetworks: parentNetworksRaw, networks: networksRaw }) {
  let scrollView = useRef();
  const [ search, setSearch ] = useState(false);
  const [ searchText, setSearchText ] = useState('');

  useEffect(() => {
    Networks();
    ParentNetworks();
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(0);

  function setIndexScroll(itemIndex) {
    setSelectedIndex(itemIndex);
    if (scrollView.current) {
      const index = networks.reduce((current, network, i) => {
        return (network.name[0] >= alphabetArray[itemIndex][0] && i < current) ? i : current;
      }, networks.length);
      scrollView.current.scrollTo({
        x: 0, y: 68 * index,
      });
    }
  }

  const networks = useMemo(() => {
    let data = networksRaw;
    if (search) {
      data = data?.filter(u => (
        `${u.name}`.toLowerCase()
          .includes(searchText.toLowerCase())
      ));
    }
    data = data?.sort((a, b) => (a.name?.localeCompare(b.name))).sort((a, b) => (b.nbUsers - a.nbUsers));
    return data;
  }, [ networksRaw, searchText, search ]);

  const parentNetworks = useMemo(() => {
    let data = parentNetworksRaw;
    if (search) {
      data = data?.filter(u => (
        `${u.description}`.toLowerCase()
          .includes(searchText.toLowerCase())
      ));
    }
    return data;
  }, [ parentNetworksRaw, searchText, search ]);

  const NetworksList = ({ firstLetter, secondLetter, disableMarginTop = false }) => {
    const filteredNetwork = parentNetworks?.filter((r) => (r.id !== 17)).filter(
      (n) => n.name ? (
        n.name.toLowerCase().charAt(0) >= firstLetter && n.name.toLowerCase().charAt(0) <= secondLetter
      ) : (
        n.description.toLowerCase().charAt(0) >= firstLetter && n.description.toLowerCase().charAt(0) <= secondLetter
      ))
      .sort((a, b) => {
        let sourceA = a.name || a.description;
        let sourceB = b.name || b.description;
        if (sourceA.toLowerCase() < sourceB.toLowerCase()) {
          return -1;
        } else if (sourceA.toLowerCase() > sourceB.toLowerCase()) {
          return 1;
        }
        return 0;
      })
      .map(
        (n) => {
          return { ...n, type: 'network' };
        });
    if (filteredNetwork?.length === 0) {
      return null;
    }
    return (
      <View style={{ marginTop: disableMarginTop ? 0 : '2%' }}>
        <ResponsiveView>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 23, marginBottom: '1%', color: colors.grey }}>
            {firstLetter.toUpperCase()} - {secondLetter.toUpperCase()}
          </Text>
        </ResponsiveView>
        <WebSoloList showSide push={push} type="network" resources={filteredNetwork ? filteredNetwork : []} />
      </View>
    );
  };

  const WebAllNetworks = () => (
    <BasicLayout
      push={push}
      headerSection={<HeaderSection push={push} menuSelect="Groupements" redirect="Network"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Groupements" />}
    >
      {
        !parentNetworks || !networks ? (
          <Loader />
        ) : (
          <View style={{ width: '100%' }}>
            <ResponsiveView style={{ marginBottom: '1%', flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Image alt="Change me" resizeMode="contain" style={{ width: 30, height: 30 }} source={svgtopng.reseaux.reseaux} />
                <Text style={{ fontFamily: fonts.lato.bold, fontSize: 25, marginLeft: 15 }}>Tous les groupements</Text>
              </View>
              <View style={{ marginLeft: 'auto', zIndex: 1, marginBottom: '1%' }}>
                <Search data={parentNetworks} fields={['name', 'description']} type="network" push={push} />
              </View>
            </ResponsiveView>
            <View style={{ zIndex: -1 }}>
              <NetworksList disableMarginTop firstLetter="a" secondLetter="c" />
              <NetworksList firstLetter="d" secondLetter="f" />
              <NetworksList firstLetter="g" secondLetter="i" />
              <NetworksList firstLetter="j" secondLetter="l" />
              <NetworksList firstLetter="m" secondLetter="o" />
              <NetworksList firstLetter="p" secondLetter="r" />
              <NetworksList firstLetter="s" secondLetter="u" />
              <NetworksList firstLetter="v" secondLetter="x" />
              <NetworksList firstLetter="y" secondLetter="z" />
            </View>
          </View>
        )
      }
    </BasicLayout>
  );

  const OPACITY = 0.95;

  const opacity = new Animated.Value(OPACITY);

  const showButton = () => {
    Animated.timing(opacity, { toValue: OPACITY, duration: 150, useNativeDriver: true, delay: 300 }).start();
  };

  const hideButton = () => {
    Animated.timing(opacity, { toValue: 0, duration: 50, useNativeDriver: true }).start();
  };

  if (Platform.OS === 'web') {
    return <WebAllNetworks />;
  }
  if (!parentNetworks || !networks) {
    return <Loader />;
  }
  return (
    <View style={styles.container}>
      <View style={{ marginTop: 10, width: '100%', alignItems: 'center' }}>
        <TextSection noBar={true} titleSection='Tous les groupements' />
      </View>
      <TouchableOpacity
        style={{ position: 'absolute', top: 10, right: 25 }}
        onPress={() => {
          if (search) {
            setSearchText('');
          }
          setSearch(!search);
        }}
      >
        {search ? <AntDesign name="close" size={24} color="black" /> : <Feather name="search" size={22} color="black" />}
      </TouchableOpacity>

      {search && (
        <View style={styles.searchContainer}>
          <HollowBackground />
          <TextInput
            onChangeText={setSearchText}
            value={searchText}
            defaultValue={''}
            placeholder={'Nom du groupement'}
            placeholderTextColor={colors.secondary}
            style={styles.searchInput}
          />
        </View>
      )}
      <View style={styles.containerRelations}>
        {!search && <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.scrollContainerAlphabet}
          contentContainerStyle={styles.containerAlphabet}
        >
          {alphabetArray.map((alpha, index) => {
            const tab = alpha.split('');
            return (
              <TouchableOpacity
                onPress={() => setIndexScroll(index)}
                key={index}
                style={styles.alphabet}
              >
                {index === selectedIndex && (
                  <LinearGradient
                    start={{ x: 0, y: 0 }}
                    end={{ x: 0, y: 1 }}
                    colors={['#FCB808', '#F3711B', '#CD004B', '#008AD1']}
                    style={{
                      position: 'absolute', top: 0, left: 0, height: '100%', width: '100%',
                    }}
                  />
                )}
                <View style={{ paddingVertical: 7, alignItems: 'center', justifyContent: 'center' }}>
                  <Text style={[styles.texteAlphabet, { color: index === selectedIndex ? colors.white : colors.greyDark }]}>
                    {tab[0]}
                  </Text>
                  <Text style={[styles.texteAlphabet, { color: index === selectedIndex ? colors.white : colors.greyDark }]}>
                  -
                  </Text>
                  <Text style={[styles.texteAlphabet, { color: index === selectedIndex ? colors.white : colors.greyDark }]}>
                    {tab[1]}
                  </Text>
                </View>

              </TouchableOpacity>
            );
          })}
        </ScrollView>}

        {parentNetworks?.length > 0 && <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.usersScroll}
          contentContainerStyle={styles.usersScrollContainer}
          ref={scrollView}
          onScrollBeginDrag={hideButton}
          onScrollEndDrag={showButton}
        >
          {parentNetworks?.filter((r) => (r.id !== 17)).map((network, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => {
                if (route.params?.onSelect) {
                  route.params.onSelect(network);
                  pop();
                } else {
                  network.name ? (
                    pushToNetwork(push, network)
                  ) : (
                    pushToParentNetwork(push, network)
                  );
                }
              }}
              style={styles.containerInfo}
            >
              <HollowBackground />
              <View style={styles.itemUser}>
                <View style={styles.imageContainer}>
                  {
                    network.logo_url && (
                      <Image
                        alt="Change me"
                        source={{ uri: `${network.logo_url}` }}
                        style={styles.imageProfile}
                      />
                    )
                  }
                </View>
                <View style={styles.infosContainer}>
                  <Text numberOfLines={1} style={styles.nameNetwork}>{network.name || network.description}</Text>
                  <Text style={styles.typeNetwork}>{network.type?.name}</Text>
                </View>

                <View>
                  <Text style={[styles.textStat, { width: 120, color: network.members > 1000 ? colors.burgundy : colors.orange }]}>
                    {`${network.nbUsers ? `${network.nbUsers} Othrs`: ''}`}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          ))}
        </ScrollView>}
        <MenuButton push={push} opacity={opacity} />
      </View>
    </View>
  );
}

const mapStateToProps = (state) => ({
  networks: state.Network.networks,
  parentNetworks: state.ParentNetwork.parentNetworks,
});

const ActionProps = {
  Networks,
  ParentNetworks,
};

export default connect(mapStateToProps, ActionProps)(AllNetworks);
