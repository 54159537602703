import moment from 'moment';
import { connect } from 'react-redux';
import { Pressable } from 'native-base';
import { AntDesign } from '@expo/vector-icons';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { ActivityIndicator, Alert, Image, ScrollView, Share, Text, TouchableOpacity, View } from 'react-native';

import Api from 'services/api';
import { Pencil } from 'styles/svg';
import { colors, fonts } from 'styles';
import { getMedium } from 'store/actions/media';
import { pushToMedia } from 'services/navigationPush';

import styles from './MediumDetailsStyle';
import AudioPlayerNative from 'components/audioPlayer';
import VideoPlayer from 'components/videoPlayer';
import WebSoloList from 'components/webLists/SoloList';
import HeaderSection from 'components/layouts/headerSection';
import { AuthorHeader } from 'components/cards/medium/Medium';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import BasicLayout from 'components/layouts/basicLayout/BasicLayout';
import HollowBackground from 'components/backgrounds/hollowBackground';
import { isBrowser } from 'components/device';
import { isMobile, isTablet } from 'react-device-detect';

const mobile = isMobile && !isTablet;

const selector = {
  'article': {
    title: 'Magazine',
  },
  'podcast': {
    title: 'Podcasts',
  },
  'video': {
    title: 'Othr TV',
  },
};

const MediumDetailsWeb = ({ medium, otherMedium, getMedium, route, navigation, type, publicPage = false }) => {
  const [likeLoading, setLikeLoading] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    getMedium(route.params.mediumId.split('-').slice(-1)[0], publicPage);
  }, [ route.params.mediumId ]);

  const data = useMemo(() => {
    if (!medium) return null;
    return {
      title: medium.items?.find(i => i.type === 'title')?.content,
      introduction: medium.items?.find(i => i.type === 'introduction')?.content,
      picture: medium.picture_url,
      items: medium.items.filter(i => i.type !== 'title' && i.type !== 'introduction'),
    };
  }, [ medium ]);

  const author = useMemo(() => {
    if (!medium) return null;
    return {
      lastname: medium.user?.lastname,
      firstname: medium.user?.firstname,
      id: medium.user?.id || medium.network?.id,
      name: (medium.user && `${medium.user?.firstname} ${medium.user?.lastname}`) || medium.network?.name,
      activity: medium.user?.job || medium.network?.type?.name,
      picture: medium.user?.picture_url || medium.network?.logo_url,
    };
  }, [ medium ]);

  const like = async () => {
    try {
      setLikeLoading(true);
      await Api.media.likeMedium(medium.id);
      await getMedium(medium.id);
    } catch (err) {
      Alert.error('Erreur', 'Veuillez réessayer plus tard');
      console.error('Error', err);
    } finally {
      setLikeLoading(false);
    }
  };

  const share = () => {
    Share.share({ message: `https://api.othr.pro/${type}s/${medium.id}` });
  };

  if (!data) return null;

  const ItemImageContainer = ({ item }) => {
    if (!item.content) {
      return null;
    }
    return <Image alt="Article illustration" style={[styles.image, { aspectRatio: 1, height: 'auto', borderRadius: 15 }]} source={{ uri: item.content }} />;
  };

  return (
    <BasicLayout
      disableMenu={publicPage}
      publicPage={publicPage}
      disableHeader={publicPage}
      mobilePadding={false}
      push={navigation.push}
      title={selector[type].title}
      headerSection={!publicPage ? <HeaderSection push={navigation.push} menuSelect={selector[type].title} redirect={selector[type].redirect} /> : null}
      breadCrumb={isBrowser && !publicPage && <Breadcrumb canGoBack={navigation.canGoBack} push={navigation.push} goBack={navigation.goBack} menuSelect={selector[type].title} />}
    >
      <ScrollView
        style={[styles.scrollView, { height: 'auto', marginTop: (publicPage && !mobile) ? 70 : 0 }]}
        contentContainerStyle={styles.scrollContainer}
      >
        <View style={{ width: mobile ? '90%' : '60%', flexDirection: mobile ? 'column' : 'row', marginTop: mobile ? 20 : 0 }}>
          <View style={{ width: mobile ? '100%' : '70%' }}>
            {medium?.canEdit &&
              <Pressable
                width="25%"
                height={47}
                onPress={() => pushToMedia(navigation.push, type, { title: data.title, id: medium.id }, 'edit')}
                style={{ marginBottom: 20, justifyContent: 'center', flexDirection: 'row', alignItems: 'center', borderRadius: 30, overflow: 'hidden' }}
              >
                <HollowBackground />
                <Image alt="modify icon" source={Pencil} style={{ width: 13, height: 13 }} />
                <Text selectable={true} style={{ fontFamily: fonts.lato.bold, fontSize: 17, marginLeft: 10 }}>Modifier</Text>
              </Pressable>
            }
            <Text style={[styles.editedAt, { fontSize: mobile ? 12 : 15, color: colors.black }]}>
              Écrit par {author.name} le {moment(medium.createdAt).format('DD/MM/YYYY')}. Mis à jour le {moment(medium.updatedAt).format('DD/MM/YYYY')}
            </Text>
            <Text selectable={true} style={{ fontFamily: mobile ? fonts.lato.bold : fonts.lato.regular, fontSize: mobile ? 22 : 50, lineHeight: mobile ? 25 : 60, color: colors.black, marginBottom: 20, marginTop: mobile ? 20 : 0 }}>{data.title}</Text>
            {type === 'podcast' && <AudioPlayerNative title={data.title} ref={audioRef} navigation={navigation} audio_url={medium.audio_url} width="60%" mediumId={medium.id.toString()} />}
            <Text selectable={true} style={[styles.introduction, { paddingHorizontal: 0, fontFamily: fonts.lato.regular, fontSize: mobile ? 16 : 20, lineHeight: mobile ? 16 : 30, color: colors.greyMedium2, marginTop: 0 }]}>{data.introduction}</Text>
            {type === 'video' && <VideoPlayer navigation={navigation} video_url={medium.video_url} width="100%" image={data.picture}/>}
            {type !== 'video' && <Image alt="Article illustration" style={[styles.image, { aspectRatio: 1, height: 'auto', borderRadius: 15 }]} source={{ uri: data.picture }} />}
            {data.items.sort((a, b) => (a.index - b.index)).map((item) => (
              <View key={item.id}>
                {
                  item.type === 'picture' ? (
                    <ItemImageContainer item={item} />
                  ) : (
                    <Text
                      selectable={true}
                      style={{
                        marginTop: '5%',
                        fontFamily: item.type === 'subtitle' ? fonts.lato.black : fonts.lato.regular,
                        fontSize: item.type === 'subtitle' ? 30 : (mobile ? 16 : 20),
                        lineHeight: mobile ? 20 : 30,
                        color: colors.greyMedium2
                      }}>
                      {item.content}
                    </Text>
                  )
                }
              </View>
            ))}
            {!publicPage && <View style={[styles.likesContainer, { width: 360, justifyContent: 'center' }]}>
              <HollowBackground/>
              <TouchableOpacity onPress={like} style={[styles.likeButton, { marginLeft: 0 }]}>
                {likeLoading ? <ActivityIndicator size="small"/> : <AntDesign name={medium.liked ? 'like1' : 'like2'} size={30} color="black" />}
              </TouchableOpacity>
              <Text style={[styles.likesText, { width: undefined }]}>
                <Text style={styles.likesCount}>
                  {medium.likes} membre{medium.likes > 1 ? 's' : ''} Othr
                </Text>
                estime{medium.likes > 1 ? 'nt' : ''} ce contenu pertinent
              </Text>
            </View>}
          </View>
          <View style={{ marginLeft: mobile ? 0 : '5%', marginTop: mobile ? 40 : 0 }}>
            <AuthorHeader inCard={false} push={navigation.push} user={medium.user} network={medium.network} likes={medium.likes} views={medium.views} statsHeight={21}/>
          </View>
        </View>
      </ScrollView>
      {!publicPage &&
        otherMedium.filter((om) => om.user?.id === author?.id).length > 0 && (
        <View style={{ backgroundColor: colors.white, paddingTop: '3%', paddingBottom: '3%' }}>
          <Text style={{ marginLeft: '5%', fontFamily: fonts.lato.bold, fontSize: 20, marginBottom: '2%' }}>Les {type}s qui pourraient vous intéresser</Text>
          <WebSoloList showSide={true} resources={otherMedium.filter((om) => om.user?.id === author?.id)} push={navigation.push} />
        </View>
      )
      }
    </BasicLayout>
  );
};

const mapStateToProps = (state, props) => ({
  medium: state.Media.media?.find((medium) => (medium.id.toString() === props.route.params.mediumId.toString().split('-').slice(-1)[0])),
  otherMedium: state.Media.media?.filter((medium) => (medium.type === props.type && medium.id.toString() !== props.route.params.mediumId.toString().split('-').slice(-1)[0])),
  userId: state.Account.userId,
});

const mapDispatchToProps = {
  getMedium,
};

export default connect(mapStateToProps, mapDispatchToProps)(MediumDetailsWeb);
