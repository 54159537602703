import React, { useState, useEffect } from 'react';
import { View, Text, Platform, SafeAreaView, Alert } from 'react-native';
import { connect } from 'react-redux';

import Api from 'services/api';
import styles from './UserSwapStyles';

import { Recommander } from 'styles/svg';
import User from 'components/cards/user';
import Slider from 'components/slider/Slider';
import GrayButton from 'components/buttons/grayButton';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import HollowBackground from 'components/backgrounds/hollowBackground';
import { isBrowser, isMobile } from '../../../components/device';
import { toastError } from '../../../services/toasts';

const UserSwap = ({ route, navigation: { goBack, push, addListener, reset, canGoBack }, currentUserId }) => {
  const [ loading, setLoading ] = useState(false);
  const [ connected, setConnected ] = useState(false);

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = addListener('focus', () => {
      setConnected(false);
    });

    return unsubscribe;
  }, [addListener]);

  useEffect(() => {
    if (route.params.userId.toString() === currentUserId.toString()) {
      reset({
        index: 1,
        routes: [
          { name: 'Home' },
          { name: 'UserDetails', params: { userId: currentUserId } },
        ],
      });
    }
    loadUser();
  }, [ route.params.userId ]);

  const loadUser = async () => {
    try {
      const response = await Api.users.get(route.params.userId);
      const userRelationships = [
        ...(response.data?.bRelationships || []),
        ...(response.data?.aRelationships || []),
      ];
      if (userRelationships.some(r => r.id.toString() === currentUserId.toString())) {
        reset({
          index: 1,
          routes: [
            { name: 'Home' },
            { name: 'UserDetails', params: { userId: route.params.userId } },
          ],
        });
      }
      setUser(response.data);
      setConnected(false);
    } catch (err) {
      console.error('Error loading user', err);
    }
  };

  const connectUser = async () => {
    try {
      setLoading(true);
      const { data } = await Api.users.connect(user.id, route.params.token);
      setUser(data);
      setConnected(true);
    } catch (err) {
      console.error('Error meeting user', err);
      if (err.response?.status === 409) {
        if (isMobile) {
          Alert.alert('Ce membre fait déjà partie de vos relatations', 'Déjà dans les contacts');
        }
        if (isBrowser) {
          toastError('Ce membre fait déjà partie de vos relatations');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadResourceLayout
      push={push}
      disableGoBack={true}
      mobilePadding={false}
      addListener={addListener}
      loadResources={loadUser}
      contentInsetAdjustmentBehavior={'automatic'}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Othrs" />}
    >
      <SafeAreaView style={styles.container}>
        <View style={styles.cardContainer}>
          <User {...user} push={push} />
        </View>
        {!connected ? (
          <View style={styles.container}>
            <Text style={styles.text}>Voulez-vous vous connecter avec {user?.firstname} ?</Text>
            <MultiColorButton style={{ marginTop: 30 }} text={'Se connecter'} onPress={connectUser} loading={loading} />
          </View>
        ) : (
          <View style={styles.container}>
            <Text style={styles.text}>Félicitations !</Text>
            <Text style={styles.description}>Vous avez rejoint le réseau de {user?.firstname} !</Text>
            <GrayButton titre={'Recommander'} noBar={true} icon={Recommander} onPress={() => push('AddRecommendation', { userId: user.id })} />
            {user?.contacts?.length > 0 && <View style={styles.hollowContainer}>
              <HollowBackground />
              <Text style={styles.text}>Ses contacts</Text>
              <Text style={{ ...styles.description, width: '55%', marginBottom: 20 }}>Vous pouvez désormais rencontrer les <Text style={styles.redText}>{user?.contacts?.length} personne{user?.contacts?.length > 1 ? 's' : ''}</Text> du réseau de {user?.firstname}</Text>
              <Slider height={400} push={push} enableMarginTop={false}>
                {user.contacts.map((u, i) => (
                  <View key={u.id}>
                    <User {...u} push={push}/>
                  </View>
                ))}
              </Slider>
              <View style={styles.bottomSpacer}/>
            </View>}
          </View>
        )}
      </SafeAreaView>
    </LoadResourceLayout>
  );
};

export default connect((state) => ({ currentUserId: state.Account.currentUser?.id }))(UserSwap);
