const promoData = [
  {
    id: '16',
    type: 'network',
    name: 'BNI - Business Network International',
    // title: 'Le 1er réseau social\nPhysique et Humain',
    sphere: 'Réseau d\'affaire',
    image: require('../../../assets/images/OTHRS.jpg'),
    // image: 'https://othr.pro/wp-content/uploads/2022/08/Groupe-de-masques-162-1.jpg',
    logoImage: 'https://media.othr.pro/868b9f8f-4b19-406f-8069-f73cb7651e76',
    // description: 'Fini les contacts fictifs sur les réseaux sociaus, découvrez-vous digitalement\net rencontrez-vous physiquement. Othr crée de véritables relations\navec les acteurs économiques de ton secteur, recommande-les et fais-toi recommander !',
  },
];

export default promoData;
