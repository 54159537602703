import 'moment/locale/fr';

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { View, Image, Text } from 'react-native';
import { getMyEvents } from 'store/actions/event';

import { ConsultEvents } from 'styles/svg';
import { colors, TextStyles } from 'styles';
import MyEventsStyles from './MyEventsStyles';

import EventCard from 'components/cards/event';
import NoResources from 'components/noResources';
import GrayButton from 'components/buttons/grayButton';
import WebSoloList from 'components/webLists/SoloList';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import { BrowserView, MobileView, ResponsiveView } from 'components/device';
import HollowBackground from 'components/backgrounds/hollowBackground/HollowBackground';

function Events({
  myEvents,
  getMyEvents,
  navigation: { push, goBack, canGoBack },
}) {
  moment.locale('fr');

  const loadEvents = async () => {
    try {
      await getMyEvents();
    } catch (err) {
      console.error('Error fetching home events', err);
    }
  };

  const MobileItem = ({ item }) => (
    <View style={MyEventsStyles.mobileItemSubContainer}>
      <EventCard {...item} push={push} />
    </View>
  );

  const RenderWebContent = () => {
    const formattedData = myEvents.filter((d) => d.type !== 'month').map((d) => {
      return {
        ...d,
        type: 'event',
        start_date: new Date(d.start_date),
      };
    });

    const current = new Date();
    const nextEvents = formattedData.filter((d) => d.start_date >= current);
    const pastEvents = formattedData.filter((d) => d.start_date < current);

    if (nextEvents.length === 0 && pastEvents.length === 0) {
      return (
        <NoResources
          noResourceText="Vous n'avez pas encore d'Event"
          findResourceText="Une fois que vous aurez créé votre premier Event, vous pourrez le retrouver ici"
          buttons={[
            () => <GrayButton width="100%" onPress={() => push('CreateEvent')} titre="Créer un Event" />,
          ]}
        />
      );
    }
    return (
      <View>
        {
          nextEvents.length > 0 && (
            <div>
              <ResponsiveView style={MyEventsStyles.webContainer}>
                <Image alt="Change me" resizeMode="contain" style={MyEventsStyles.webImage} source={ConsultEvents} />
                <Text selectable style={TextStyles({ color: colors.black }).bold25}>Mes prochains events</Text>
              </ResponsiveView>
              <WebSoloList showSide push={push} resources={nextEvents} />
            </div>
          )
        }
        {
          pastEvents.length > 0 && (
            <View>
              <ResponsiveView style={MyEventsStyles.webPastEventContainer}>
                <View style={MyEventsStyles.webPastEventSubContainer}>
                  <HollowBackground />
                  <Image alt="Change me" resizeMode="contain" style={MyEventsStyles.webPastEventImage} source={ConsultEvents} />
                  <Text selectable style={TextStyles({ color: colors.grey }).bold18}>Mes évènements passés</Text>
                </View>
              </ResponsiveView>
              <WebSoloList showSide push={push} resources={pastEvents}/>
            </View>
          )
        }
      </View>
    );
  };

  const RenderMobileContent = () => {
    if (myEvents.length === 0) {
      return (
        <NoResources
          noResourceText="Vous n'avez pas encore d'Event"
          findResourceText="Une fois que vous aurez créé votre premier Event, vous pourrez le retrouver ici"
          buttons={[
            () => <GrayButton width="100%" onPress={() => push('CreateEvent')} titre="Créer un Event" />,
          ]}
        />
      );
    }
    return (
      <View style={MyEventsStyles.mobileContainer}>
        {myEvents.map((e, i) => (
          <View key={e.id} style={MyEventsStyles.mobileItemContainer}>
            <MobileItem item={e} />
          </View>
        ))}
      </View>
    );
  };

  return (
    <LoadResourceLayout
      push={push}
      title={'Mes Events'}
      mobilePadding={false}
      loadResources={loadEvents}
      headerSection={<HeaderSection push={push} menuSelect="Events" redirect="Event"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Events" />}
      disableGoBack={true}
    >
      {/* MOBILE */}
      <MobileView>
        <RenderMobileContent />
      </MobileView>
      {/* BROWSER */}
      <BrowserView>
        <RenderWebContent />
      </BrowserView>
    </LoadResourceLayout>
  );
}

const mapStateToProps = (state) => ({
  myEvents: state.Event.myEvents,
});

const ActionProps = {
  getMyEvents,
};

export default connect(mapStateToProps, ActionProps)(Events);
