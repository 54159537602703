import React from 'react';
import { Text, View } from 'native-base';
import { Form, Field } from 'react-final-form';

import { fonts } from 'styles';
import Input from 'components/form/input';
import MultiColorButton from '../../../components/buttons/multiColorButton/MultiColorButton';
import { Platform } from 'react-native';

const validate = (values) => {
  const errors = {
    contractPrice: '',
  };
  // --- check introResume validation
  if (!values.contractPrice || values.contractPrice.length < 1) {
    errors.contractPrice = 'Resumé non valide';
  }

  return errors;
};

const CreateContractSigned = ({ onSubmit, loading }) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({ form }) => (
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 14, marginTop: 40 }}>Montant du devis signé (en euros hors taxes)</Text>
          <View style={{ marginLeft: '3%', marginTop: '5%' }}>
            <Field
              centered
              width="100%"
              name="contractPrice"
              label="Montant (HT)"
              keyboardType="numeric"
              autoCapitalize="none"
              component={Input}
            />
          </View>
          <MultiColorButton
            loading={loading}
            onPress={form.submit}
            text="Remercier"
            style={{
              width: Platform.OS !== 'web' ? '100%' : '100%', height: 70, marginBottom: '10%', marginTop: '4%',
            }}
          />
        </View>
      )}
    />
  );
};

export default CreateContractSigned;
