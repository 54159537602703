import { StyleSheet, Dimensions, Platform } from 'react-native';
import { colors, fonts } from 'styles';

const { width, height } = Dimensions.get('screen');

export default StyleSheet.create({
  ticketsHeader: {
    position: 'absolute',
    top: 57,
    left: 85,
    fontSize: 17,
    fontFamily: fonts.lato.bold,
    color: colors.grey,
  },
  ticketContainer: {
    width: '100%',
    // height: height * 0.7,
    backgroundColor: colors.white,
    borderRadius: 15,
    alignItems: 'center',
    shadowRadius: 10,
    shadowOpacity: 0.2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 5 },
    marginTop: 20,
  },
  ticketHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: 20,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  organizerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
  },
  subtitle: {
    color: colors.burgundy,
    fontSize: 12,
    fontFamily: fonts.lato.black,
  },
  content: {
    color: colors.grey,
    fontFamily: fonts.lato.regular,
    fontSize: 15,
    maxWidth: '90%',
  },
  eventInfosContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    marginTop: '5%',
  },
  section: {
    width: '100%',
    paddingHorizontal: 20,
    marginTop: '5%',
  },
  footer: {
    backgroundColor: '#ff243f',
    width: '100%',
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  ticketOwner: {
    color: colors.white,
    fontSize: 16,
    fontFamily: fonts.lato.bold,
  },
  ticketCompany: {
    color: colors.white,
    fontSize: 14,
    fontFamily: fonts.lato.regular,
  },
  buttonsContainer: {
    width: '85%',
    marginTop: 10,
    // paddingHorizontal: Platform.OS !== 'web' ? '7.5%' : 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '15%',
  },
  qrCodeContainer: {
    marginVertical: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  webContainer: {
    width: '100%',
    alignItems: 'center',
  },
  webItemContainer: {
    width: width * 0.235
  },
  webItemSubContainer: {
    width: '100%',
    borderRadius: 15,
    height: height * 0.634,
    backgroundColor: colors.white,
  },
  webItemHeaderContainer: {
    width: '80%',
    marginTop: 20,
    marginLeft: '10%',
  },
  webItemLogo: {
    width: 80,
    height: 80,
    marginRight: 12,
  },
  webItemDescriptionContainer: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'row',
  },
  webItemFooterContainer: {
    width:' 100%',
    marginTop: 'auto',
    alignItems: 'center',
    height: height * 0.0555,
    justifyContent: 'center',
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    backgroundColor: colors.red,
  },
  webQrCode: {
    width: '100%',
    marginTop: 40,
    alignItems: 'center',
  },
});
