import React from 'react';
import { View } from 'react-native';
import { Field, reduxForm } from 'redux-form';

import { InviteNetworkStyle } from './InviteNetworkStyle';

import Input from 'components/form/input/Input';
import { MobileView, BrowserView } from 'components/device';

const validate = (values) => {
  const errors = {};

  if (!values.name || values.name.length === 0) {
    errors.name = 'Obligatoire';
  }
  if (!values.email || values.email.length === 0) {
    errors.email = 'Obligatoire';
  }
  if (!values.request || values.request.length === 0) {
    errors.request = 'Obligatoire';
  }

  return errors;
};

function InviteNetworkForm() {
  return (
    <View style={InviteNetworkStyle.form}>
      {/* MOBILE */}
      <MobileView>
        <View>
          <Field
            style={{ marginLeft: 0, paddingLeft: 0 }}
            name="name"
            label="Nom du groupement à inviter"
            autoCapitalize="none"
            component={Input}
            width="100%"
            required={true}
          />
          <Field
            style={{ marginLeft: 0, paddingLeft: 0 }}
            name="email"
            textContentType={'email'}
            label="Email du groupement"
            autoCapitalize="none"
            component={Input}
            width="100%"
            required={true}
          />
        </View>
      </MobileView>
      {/* BROWSER */}
      <BrowserView>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: '48%' }}>
            <Field
              style={{ marginLeft: 0, paddingLeft: 0 }}
              name="name"
              label="Nom du groupement à inviter"
              component={Input}
              width="100%"
              required={true}
            />
          </View>
          <View style={{ width: '48%', marginLeft: '4%' }}>
            <Field
              style={{ marginLeft: 0, paddingLeft: 0 }}
              name="email"
              label="Email du groupement"
              autoCapitalize="none"
              component={Input}
              width="100%"
              required={true}
            />
          </View>
        </View>
      </BrowserView>
      <Field
        style={{ marginLeft: 0, paddingLeft: 0 }}
        name="message"
        label="Votre message au groupement"
        component={Input}
        height={200}
        multiline={true}
        required={true}
      />
    </View>
  );
}

export default reduxForm({ form: 'inviteNetwork', validate, enableReinitialize: true })(InviteNetworkForm);
