import { View } from 'react-native';
import GoogleButton from './GoogleButton';
import { isBrowser, isIOS } from '../../../components/device';
import AppleButton from './AppleButton';
import React from 'react';

const ExternLogin = ({ navigate, push, type, onSucceed }) => {
  return (
    <View>
      <GoogleButton navigate={navigate} push={push} type={type} onSucceed={onSucceed} />
      {(isBrowser || isIOS) && <AppleButton navigate={navigate} push={push} type={type} onSucceed={onSucceed} />}
    </View>
  );
};

export default ExternLogin;
