import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Text, View } from 'native-base';
import { SafeAreaView } from 'react-native';

import Api from 'services/api';

import { IconCheck } from 'styles/svg';
import { contactUs } from 'store/actions/account';
import { TextStyles, ContainerStyles } from 'styles';
import { toastError, toastSuccess } from 'services/toasts';

import ReportForm from './ReportForm';
import { ReportStyle } from './ReportStyle';

import Logo from 'components/logo';
import BasicLayout from 'components/layouts/basicLayout';
import MultiColorButton from 'components/buttons/multiColorButton';
import { isBrowser, isMobile, BrowserView, MobileView } from 'components/device';

const Report = ({ route, navigation: { push, reset }, submit }) => {
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const report = async (values) => {
    try {
      setIsLoading(true);
      const { mediumId, userId, eventId } = route.params;
      await Api.misc.report({
        mediumId,
        userId,
        eventId,
        ...values,
      });
      if (isMobile) {
        setIsSend(true);
      }
      if (isBrowser) {
        push('Home');
        toastSuccess('Votre message a bien été envoyé!');
      }
    } catch (err) {
      if (isBrowser) {
        toastError('Une erreur est survenue.');
      }
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        reset({
          index: 0,
          routes: [{ name: 'Home' }],
        });
      }, 3000);
    }
  };

  console.log('params', route.params);

  return (
    <BasicLayout push={push} disableMenu={true}>
      {
        isSend && isMobile ? (
          <SafeAreaView style={ContainerStyles.requestFormMobileSentContainer}>
            <IconCheck width="20%" height="20%"/>
            <Text style={TextStyles({ marginTop: 20 }).regular16}>
              Votre signalement a bien été envoyé
            </Text>
            <Text style={TextStyles({ textAlign: 'center', paddingHorizontal: 20, }).regular16}>
              Merci d'avoir participé à la préservation du contenu diffusé sur Othr
            </Text>
          </SafeAreaView>
        ) : (
          <View style={{ width: '100%' }}>
            {/* MOBILE */}
            <MobileView>
              <SafeAreaView style={ContainerStyles.requestFormMobileContainer}>
                <Logo />
                <ReportForm onSubmit={report} />
                <MultiColorButton style={ReportStyle.mobileSubmitButton} loading={isLoading} text="Envoyer" onPress={() => submit('contactUs')} />
              </SafeAreaView>
            </MobileView>
            {/* BROWSER */}
            <BrowserView>
              <View style={ContainerStyles.requestFormWebContainer}>
                <Text style={TextStyles().black23}>Nous Contacter</Text>
                <ReportForm onSubmit={report} />
                <MultiColorButton text="Envoyer" loading={isLoading} onPress={() => submit('contactUs')} style={ContainerStyles.requestFormWebSubmitButton} />
              </View>
            </BrowserView>
          </View>
        )
      }
    </BasicLayout>
  );
};

const actionProps = { contactUs, submit };

export default connect(null, actionProps)(Report);
