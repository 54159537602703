'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.Events = void 0;
var index_1 = require('./index');
var Events = /** @class */ (function (_super) {
  __extends(Events, _super);
  function Events() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Events.prototype.getAll = function () {
    return this.requester.get('/events');
  };
  Events.prototype.getMine = function () {
    return this.requester.get('/events/me');
  };
  Events.prototype.getNetworkEvents = function (networkId) {
    return this.requester.get('/events/network/' + networkId);
  };
  Events.prototype.get = function (id, publicPage) {
    if (publicPage) {
      return this.requester.get('/events/' + id + '/public');
    }
    return this.requester.get('/events/' + id);
  };
  Events.prototype.create = function (data) {
    return this.requester.post('/events', data);
  };
  Events.prototype.edit = function (id, data) {
    return this.requester.patch('/events/' + id, data);
  };
  Events.prototype['delete'] = function (id) {
    return this.requester['delete']('/events/' + id);
  };
  Events.prototype.closeInscription = function (id) {
    return this.requester.put('/events/' + id + '/close-inscription');
  };
  return Events;
}(index_1.Component));
exports.Events = Events;
