import Api from 'services/api';

export function getMedia(type) {
  return async (dispatch) => {
    try {
      const response = await Api.media.getMedia(type);
      if (response?.status === 200) {
        const media = response.data;
        dispatch({
          type: 'LOAD_MEDIA',
          payload: media,
        });
        return media;
      } else {
        console.error('Get medium error:', response);
        return null;
      }
    } catch (err) {
      console.error('Error getting medium', err);
      return null;
    }
  };
}

export function createMedium(values) {
  return async () => {
    try {
      const response = await Api.media.createMedium(values);
      if (response?.status === 201) {
        return response;
      } else {
        console.error('Create medium error:', response);
        return null;
      }
    } catch (err) {
      console.error('Error creating medium', err);
      return null;
    }
  };
}

export function getMedium(id, publicPage = false) {
  return async (dispatch) => {
    try {
      const response = await Api.media.getMedium(id, publicPage);
      if (response?.status === 200) {
        const medium = response.data;
        dispatch({
          type: 'LOAD_MEDIUM',
          payload: medium,
        });
      } else {
        console.error('Get medium error:', response);
        return null;
      }
    } catch (err) {
      console.error('Error getting medium', err);
      return null;
    }
  };
}

export function getMyArticles() {
  return async (dispatch) => {
    try {
      const response = await Api.media.getMyArticles();
      if (response?.status === 200) {
        const articles = response.data;
        dispatch({
          type: 'MY_ARTICLES',
          articles,
        });
        return articles;
      } else {
        console.error('Get my articles error:', response);
        return null;
      }
    } catch (err) {
      console.error('Error getting my articles', err);
      return null;
    }
  };
}

export function getMyVideos() {
  return async (dispatch) => {
    try {
      const response = await Api.media.getMyVideos();
      if (response?.status === 200) {
        const videos = response.data;
        dispatch({
          type: 'MY_VIDEOS',
          videos,
        });
        return videos;
      } else {
        console.error('Get my videos error:', response);
        return null;
      }
    } catch (err) {
      console.error('Error getting my videos', err);
      return null;
    }
  };
}
export function getMyPodcasts() {
  return async (dispatch) => {
    try {
      const response = await Api.media.getMyPodcasts();
      if (response?.status === 200) {
        const podcasts = response.data;
        dispatch({
          type: 'MY_PODCASTS',
          podcasts,
        });
        return podcasts;
      } else {
        console.error('Get my podcasts error:', response);
        return null;
      }
    } catch (err) {
      console.error('Error getting my podcasts', err);
      return null;
    }
  };
}
