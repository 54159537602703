import React, { useState } from 'react';

import { submit } from 'redux-form';
import { connect } from 'react-redux';
import { Text, Image, View } from 'native-base';
import { Platform, SafeAreaView } from 'react-native';

import { fonts } from 'styles';
import { IconCheck } from 'styles/svg';
import RecommendationResponseForm from './RecommendationResponseForm';
import { RecommendationResponseStyle } from './RecommendationResponseStyle';
import { acceptRecommendation, refuseRecommendation } from 'store/actions/recommendation';

import Svg from 'components/svg';
import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';
import { toastError, toastSuccess } from '../../../services/toasts';

const InviteNetwork = ({ navigation: { push, goBack, navigate }, route, submit, acceptRecommendation, refuseRecommendation }) => {
  const { recommendationId, responseType, userFirstname } = route.params;

  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = async (values) => {
    try {
      setIsLoading(true);
      if (responseType === 'ACCEPTED') {
        await acceptRecommendation(recommendationId, values.message);
      } else {
        await refuseRecommendation(recommendationId, values.message);
      }
      if (Platform.OS !== 'web') {
        setIsSend(true);
        setTimeout(() => navigate('Recommendations'), 3000);
      } else {
        push('Recommendations', { section: 2 });
        toastSuccess('Réponse envoyée!');
      }
    } catch (err) {
      if (Platform.OS === 'web') {
        toastError('Une erreur est survenue.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BasicLayout
      push={push}
      disableMenu={true}
      disableGoBack={true}
      mobilePadding={false}
      title={'Envoyer votre réponse'}
      breadCrumb={<Breadcrumb push={push} goBack={goBack} menuSelect="Othrs" />}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
    >
      {
        isSend ? (
          <SafeAreaView style={RecommendationResponseStyle.container}>
            <View style={Platform.OS !== 'web' ? { width: '20%', height: '8%' } : { width: 70, height: 50 }}>
              <Svg src={IconCheck}/>
            </View>
            <Text style={{
              fontSize: 16,
              marginTop: 20,
              fontFamily: fonts.lato.regular,
            }}>
              Votre réponse a bien été envoyée
            </Text>
          </SafeAreaView>
        ) : (
          <View style={{ width: Platform.OS === 'web' ? '50%' : '90%', marginLeft: Platform.OS === 'web' ? '25%' : '0%', marginTop: '5%', alignItems: 'center' }}>
            <Text style={{ fontFamily: fonts.lato.black, fontSize: 23 }}>Envoyer un message à {userFirstname}</Text>
            <RecommendationResponseForm
              responseType={responseType}
              onSubmit={sendRequest}
              isLoading={isLoading}
              initialValues={{ message: responseType === 'ACCEPTED' ? 'Merci beaucoup pour ta recommandation !' : null }}
            />
          </View>
        )
      }
    </BasicLayout>
  );
};

const mapStateToProps = () => ({});

const actionProps = {
  acceptRecommendation,
  refuseRecommendation,
  submit,
};

export default connect(mapStateToProps, actionProps)(InviteNetwork);
