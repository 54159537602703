import { colors } from 'styles';
import { StyleSheet } from 'react-native';

const AudioPlayerStyle = (props) => {
  return StyleSheet.create({
    container: {
      height: 70,
      width: props?.width || '90%',
      borderRadius: 40,
      overflow: 'hidden',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 20,
    },
    buttonsContainer: {
      width: 40,
      height: 40,
      borderRadius: 20,
      marginHorizontal: '5%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.burgundy,
    },
    pauseIcon: {
      marginLeft: 2,
    },
    playIcon: {
      marginLeft: 5,
    },
  });
};

export default AudioPlayerStyle;
