import React from 'react';
import { View } from 'native-base';
import arrayMutators from 'final-form-arrays';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { Dimensions, Text } from 'react-native';
import { FieldArray } from 'react-final-form-arrays';

import { fonts } from 'styles';
import propsAreEqual from 'services/propsAreEqual';
import { CreateMediumStyle } from './CreateMediumStyle';
import { articleValidation, podcastValidation, videoValidation } from './formValidation';

import Input from 'components/form/input';
import ImagePicker from 'components/form/imagePicker';
import GrayButton from 'components/buttons/grayButton';
import DocumentPicker from 'components/form/documentPicker';
import EncartTitle from '../../../components/form/encartTitle';
import { FieldWrapperStyle } from 'components/form/fieldWrapper/FieldWrapperStyle';

const { width, height } = Dimensions.get('window');

const selector = {
  'article': {
    title: 'Titre de l\'article',
    content: 'Contenu de l\'article',
  },
  'podcast': {
    title: 'Titre du podcast',
    content: 'Description du podcast',
  },
  'video': {
    title: 'Titre de la vidéo',
    content: 'Description de la vidéo',
  },
};

const focusOnError = createDecorator();

const EncartsFieldArray = ({ push }) => (
  <FieldArray name="customItems">
    {({ fields, meta: { error } }) => (
      <>
        {fields.map((name, index) => (
          <View key={fields.value[index].id} style={{ width: '100%' }}>
            <EncartTitle index={index} onRemove={() => fields.remove(index)} name={'Encart'} />
            <Field
              name={`${name}.title`}
              label="Sous-titre"
              component={Input}
              width="100%"
              required
            />
            <Field
              name={`${name}.content`}
              label="Contenu"
              width="100%"
              component={Input}
              height={height * 0.2}
              multiline
              required
            />
            <Field
              name={`${name}.picture_url`}
              titre="Illustration"
              style={{ borderRadius: 30 }}
              height={width * 0.25}
              width="100%"
              component={ImagePicker}
              type={'image'}
            />
          </View>
        ))}
        {error && typeof(error) === 'string' && <Text style={FieldWrapperStyle.error}>{error}</Text>}
      </>
    )}
  </FieldArray>
);

function CreateMediumForm({ type, Button, onSubmit, initialValues }) {
  const FormContent = React.memo(({ form, push }) => (
    <View style={[CreateMediumStyle.containerForm, { alignItems: 'center' }]}>
      <Text selectable style={{ marginBottom: 20, fontSize: 25, fontFamily: fonts.lato.bold, width: '90%', textAlign: 'center' }}>
          Publier un {type}
      </Text>
      <View style={{ width: '42%' }}>
        <Text selectable style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Titre</Text>
        <Field
          name="title"
          label={selector[type].title}
          component={Input}
          required={true}
          width="100%"
        />
        <Text selectable style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Courte Introduction</Text>
        <Field
          name="introduction"
          label="Introduction"
          component={Input}
          multiline
          height={height * 0.175}
          required={true}
          width="100%"
        />
        <Text selectable style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Photo</Text>
        <Field
          name='picture_url'
          style={{ borderRadius: 30 }}
          height={width * 0.25}
          width="100%"
          titre={type === 'article' ? 'Photo principale' : 'Illustration'}
          component={ImagePicker}
          type={'image'}
        />
        {type === 'podcast' &&
            <View>
              <Text selectable style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Podcast</Text>
              <Field
                name='audio_url'
                images={null}
                style={{ borderRadius: 30 }}
                height={height * 0.185}
                width="100%"
                required
                titre="Podcast"
                component={DocumentPicker}
                type={'audio/mp3'}
              />
            </View>
        }
        {type === 'video' &&
            <View>
              <Text selectable style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Vidéo</Text>
              <Field
                name='video_url'
                style={{ borderRadius: 30 }}
                height={height * 0.185}
                width="100%"
                titre="Vidéo"
                component={DocumentPicker}
                type={'video/mp4'}
              />
            </View>
        }
        {type === 'article' && (
          <View style={{ width: '100%', marginTop: 20, alignItems: 'center' }}>
            <EncartsFieldArray push={push} />
            <GrayButton
              width='40%'
              titre="+ Ajouter un encart"
              style={{ marginBottom: 40, marginTop: 20 }}
              onPress={() => push('customItems', { id: Math.round(Math.random() * 100), title: '', content: '' })}
            />
          </View>
        )}
      </View>
      <Button onClick={form.submit} />
    </View>
  ), propsAreEqual);

  return (
    <Form
      onSubmit={onSubmit}
      validate={type === 'video' ? videoValidation : type === 'podcast' ? podcastValidation : articleValidation}
      initialValues={initialValues}
      decorators={[focusOnError]}
      mutators={{ ...arrayMutators }}
      onSubmitFail={(error) => console.log(error)}
      render={({
        form,
        pristine,
        submitting,
        form: {
          mutators: { push }
        },
      }) => (
        <FormContent form={form} push={push} />
      )}
    />
  );
}

export default CreateMediumForm;
