import React, { useEffect, useState } from 'react';
import { Platform, RefreshControl, SafeAreaView, useWindowDimensions } from 'react-native';

import Loader from '../../loader/Loader';
import BasicLayout from '../basicLayout';

const LoadResourceLayout = ({
  push,
  children,
  addListener,
  breadCrumb = null,
  publicPage = false,
  headerSection = null,
  title = undefined,
  loadResources: loadResource,
  disableMenu = false,
  mobilePadding = true,
  disableHeader = false,
  disableGoBack = false,
  disableFooter = false,
  disableScroll = false,
  disableMarginTop = false,
  onScrollEnd = undefined,
  pagingEnabled = undefined,
  BottomComponent = undefined,
  contentInsetAdjustmentBehavior = undefined,
}) => {
  const { height } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  const loadResourceAsync = async () => {
    setRefreshing(true);
    await loadResource();
    setRefreshing(false);
    setLoading(false);
  };

  useEffect(() => {
    loadResourceAsync();
    if (addListener) {
      return addListener('focus', loadResource);
    }
  }, []);

  if (loading) {
    return (
      <BasicLayout
        push={push}
        title={title}
        disableMenu={true}
        disableGoBack={true}
        breadCrumb={breadCrumb}
        publicPage={publicPage}
        onScrollEnd={onScrollEnd}
        headerSection={headerSection}
        mobilePadding={mobilePadding}
        disableHeader={disableHeader}
        pagingEnabled={pagingEnabled}
        disableFooter={disableFooter}
        disableScroll={disableScroll}
        disableMarginTop={disableMarginTop}
        contentInsetAdjustmentBehavior={contentInsetAdjustmentBehavior}
      >
        <SafeAreaView style={{ height: height * 0.68, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Loader />
        </SafeAreaView>
      </BasicLayout>
    );
  }

  if (Platform.OS !== 'web') {
    return (
      <BasicLayout
        push={push}
        title={title}
        publicPage={publicPage}
        disableMenu={disableMenu}
        onScrollEnd={onScrollEnd}
        headerSection={headerSection}
        disableGoBack={disableGoBack}
        disableHeader={disableHeader}
        mobilePadding={mobilePadding}
        disableFooter={disableFooter}
        disableScroll={disableScroll}
        pagingEnabled={pagingEnabled}
        BottomComponent={BottomComponent}
        disableMarginTop={disableMarginTop}
        contentInsetAdjustmentBehavior={contentInsetAdjustmentBehavior}
        refreshControl={loadResource ? <RefreshControl refreshing={refreshing} onRefresh={loadResourceAsync}/> : undefined}
      >
        {children}
      </BasicLayout>
    );
  }

  return (
    <BasicLayout
      push={push}
      title={title}
      breadCrumb={breadCrumb}
      onScrollEnd={onScrollEnd}
      disableMenu={disableMenu}
      disableFooter={disableFooter}
      headerSection={headerSection}
      disableHeader={disableHeader}
      disableMarginTop={disableMarginTop}
      contentInsetAdjustmentBehavior={contentInsetAdjustmentBehavior}
    >
      {children}
    </BasicLayout>
  )
  ;
};

export default LoadResourceLayout;
