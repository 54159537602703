import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Image, Pressable } from 'native-base';
import { Modal, Text, View } from 'react-native';

import Api from 'services/api';
import { TextStyles } from 'styles';
import { createNetwork } from 'store/actions/network';
import { toastError, toastSuccess } from 'services/toasts';
import CloseIcon from '../../../../assets/images/closeButton.png';

import AddParentNetworkForm from './AddParentNetworkForm';

import MultiColorButton from 'components/buttons/multiColorButton/MultiColorButton';

const AddParentNetworkModal = ({ submit, visible, setVisible, networkTypes }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await Api.parent_networks.create(values);
      toastSuccess('Votre groupement mère a bien été crée.');
    } catch (err) {
      toastError('Une erreur est survenue.');
    } finally {
      setLoading(false);
      setVisible(false);
    }
  };

  return (
    <Modal
      visible={visible}
      transparent={true}
      onRequestClose={() => setVisible(false)}
    >
      <View style={{ backgroundColor: 'white', padding: 40, marginTop: 120, borderRadius: 10, marginHorizontal: '20%', width: '60%' }}>
        <Pressable style={{ marginLeft: 'auto' }} onPress={() => setVisible(false)}>
          <Image alt="Change me" src={CloseIcon} resizeMethod="contain" style={{ width: 24, height: 24 }} />
        </Pressable>
        <Text style={TextStyles({ lineHeight: 20, marginBottom: 20 }).bold20}>
          Ajouter un groupement mère
        </Text>
        <AddParentNetworkForm onSubmit={onSubmit} networkTypes={networkTypes} />
        <MultiColorButton
          onPress={() => submit('createParentNetwork')}
          text="Ajouter"
          style={{ width: '20%', marginTop: '2%' }}
          loading={loading}
        />
      </View>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
});

const actionProps = {
  submit,
  createNetwork,
};

export default connect(mapStateToProps, actionProps)(AddParentNetworkModal);
