import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Image, Pressable, Text, View } from 'native-base';
import { isTablet, isMobile } from 'react-device-detect';

import { fonts, colors, TextStyles } from 'styles';

import { ResponsiveView } from 'components/device';
import Logo from '../../logo';

import PlayStoreButton from '../../../../assets/images/play_store_button.png';
import AppleStoreButton from '../../../../assets/images/apple_store_button.png';

const WebFooter = ({ publicPage = false }) => {
  const { push } = useNavigation();

  return (
    <View
      style={{
        height: 240,
        width: '100%',
        marginTop: 100,
        backgroundColor: '#ffffff',
      }}
    >
      {
        /*
      <ResponsiveView style={{ flexDirection: 'row', marginTop: 40 }}>
        <View>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15 }}>Société</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 20 }}>A propos</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 20 }}>Offres D'emplois</Text>
        </View>
        <View style={{ marginLeft: '10%' }}>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15 }}>Communauté</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 20 }}>Pour les artistes</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 20 }}>Développeurs</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 20 }}>Campagnes publicitaires</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 20 }}>Investisseurs</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 20 }}>Fournisseurs</Text>
        </View>
        <View style={{ marginLeft: '10%' }}>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15 }}>Liens Utiles</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 20 }}>Assistance</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 20 }}>Appli mobile gratuite</Text>
        </View>
      </ResponsiveView>
         */
      }
      <View style={{ backgroundColor: colors.whiteShadow, width: '90%', height: 2, marginHorizontal: '5%', marginTop: 30, borderRadius: 10 }} />
      <View style={{ width: '90%', marginTop: 30, marginHorizontal: '5%', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
        <Logo width={100} height={32} color="black"/>
        <View style={{ marginLeft: 30 }}>
          <Text style={TextStyles().black17}>Téléchargez l'application Othr</Text>
          <Text style={TextStyles().regular15}>Accédez à votre outil Othr depuis votre mobile !</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto' }}>
          <Image alt="Change me" source={AppleStoreButton} resizeMode="contain" style={{ width: 150, height: 75 }} />
          <Image alt="Change me" source={PlayStoreButton} resizeMode="contain" style={{ width: 170, height: 75, marginLeft: 20 }} />
        </View>
      </View>
      <View style={{ backgroundColor: colors.whiteShadow, width: '90%', height: 2, marginHorizontal: '5%', marginTop: 30, borderRadius: 10 }} />
      <ResponsiveView style={{ flexDirection: isMobile ? 'column' : 'row', marginTop: 40 }}>
        <Pressable onPress={() => window.open('https://api.othr.pro/static/cgu.pdf', '_blank')}>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 12 }}>Mentions légales - CGV - Politique de confidentialité</Text>
        </Pressable>
        <Pressable onPress={() => publicPage ? window.open('https://api.othr.pro/static/cgu.pdf', '_blank') : push('ContactUs')}>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 12 }}>{isMobile ? '' : ' - '}Nous contacter</Text>
        </Pressable>
        <Text style={{ fontFamily: fonts.lato.bold, fontSize: 12, marginLeft: 'auto' }}>© Othr 2023</Text>
      </ResponsiveView>
    </View>
  );
};

export default WebFooter;
