import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Text, View } from 'native-base';
import { SafeAreaView } from 'react-native';

import { IconCheck } from 'styles/svg';
import { TextStyles, ContainerStyles } from 'styles';
import { inviteNetwork } from 'store/actions/network';
import { toastSuccess, toastError } from 'services/toasts';

import InviteNetworkForm from './InviteNetworkForm';
import { InviteNetworkStyle } from './InviteNetworkStyle';

import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import { isMobile, isBrowser, BrowserView, MobileView } from 'components/device';

const InviteNetwork = ({ navigation: { push, goBack, canGoBack, navigate }, submit, inviteNetwork }) => {
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = async (values) => {
    try {
      setIsLoading(true);
      await inviteNetwork(values);
      setIsSend(true);
      if (isBrowser) {
        push('Network');
        toastSuccess('Invitation envoyée!');
      } else {
        setTimeout(() => navigate('Network'), 3000);
      }
    } catch (err) {
      console.error('Error sending invite network', err);
      if (isBrowser) {
        toastError('Une erreur est survenue.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BasicLayout
      push={push}
      disableMenu={true}
      mobilePadding={false}
      title={'Invitez un groupement'}
      headerSection={<HeaderSection push={push} menuSelect="Groupements" redirect="Network"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Groupements" />}
    >
      {
        isSend && isMobile ? (
          <SafeAreaView style={ContainerStyles.requestFormMobileSentContainer}>
            <IconCheck width="20%" height="20%" />
            <Text style={TextStyles({ marginTop: 20 }).regular16}>
              Votre invitation a bien été envoyée
            </Text>
            <Text style={TextStyles().regular16}>
              Merci !
            </Text>
          </SafeAreaView>
        ) : (
          <View style={{ flex: 1, width: '100%' }}>
            {/* MOBILE */}
            <MobileView>
              <View style={ContainerStyles.requestFormMobileContainer}>
                <Text style={ContainerStyles.descriptionText}>Invitez un groupement à rejoindre la communauté Othr afin qu'il puisse poster ses évènements et agrandir sa communauté !</Text>
                <InviteNetworkForm onSubmit={sendRequest} />
                <MultiColorButton loading={isLoading} text="Envoyer" onPress={() => submit('inviteNetwork')} style={InviteNetworkStyle.submit} />
              </View>
            </MobileView>
            {/* BROWSER */}
            <BrowserView>
              <View style={ContainerStyles.requestFormWebContainer}>
                <Text style={TextStyles().black23}>Invitez un groupement à rejoindre Othr</Text>
                <Text style={ContainerStyles.descriptionText}>Invitez un groupement à rejoindre la communauté Othr afin qu'il puisse poster ses évènements et agrandir sa communauté !</Text>
                <InviteNetworkForm onSubmit={sendRequest} />
                <MultiColorButton loading={isLoading} text="Envoyer" onPress={() => submit('inviteNetwork')} style={ContainerStyles.requestFormWebSubmitButton}/>
              </View>
            </BrowserView>
          </View>
        )
      }
    </BasicLayout>
  );
};

const mapStateToProps = () => ({});

const actionProps = { inviteNetwork, submit };

export default connect(mapStateToProps, actionProps)(InviteNetwork);
