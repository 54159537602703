const promoData = [
  {
    networkId: '16',
    type: 'network',
    sphere: 'Réseau d\'affaire',
    title: '',
    logoImage: 'https://media.othr.pro/868b9f8f-4b19-406f-8069-f73cb7651e76',
    image: require('../../../assets/images/RESEAUX.jpg'),
    // description: 'Le 1er réseau d\'affaires en France, en Europe et dans le monde. BNI est une méthode structurée pour aider les professionnels à bâtir un solide réseau professionnel favorisant le développement de leurs chiffres d’affaires.',
  }
];

export default promoData;
