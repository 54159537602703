import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { View, Text, Alert } from 'react-native';

import { Swap } from 'styles/svg';
import { NfcModal, readNfc } from 'services/nfc';
import { ConnexionUsersStyle } from './ConnexionUsersStyle';

import BombButton from 'components/buttons/bombButton';
import MenuButton from 'components/buttons/menuButton';
import LinearBackground from 'components/backgrounds/linearBackground';

const prefix = 'api.othr.pro/application/users/';

function Connect(props) {
  const { push } = props;
  const [ showModal, setShowModal ] = useState(false);

  const scanCard = async () => {
    try {
      setShowModal(true);
      const card = await readNfc();
      setShowModal(false);
      if (!card) {
        Alert.alert('Erreur', 'Erreur de lecture, veuillez réessayer');
        return;
      }
      const payload = card?.ndefMessage?.[0]?.payload;
      if (!payload) {
        Alert.alert('Erreur', 'Carte invalide');
        return;
      }
      const str = String.fromCharCode(...payload);
      if (!str.includes('api.othr.pro/application/users')) {
        Alert.alert('Erreur', 'Carte invalide');
        return;
      }
      const data = str.split(prefix)[1];
      const [ userId, token ] = data.split('/swap?token=');
      return push('UserSwap', { userId, token });
    } catch (err) {
      setShowModal(false);
      console.error('Error reading nfc', err);
    }
  };

  return (
    <View style={ConnexionUsersStyle.contentView}>
      <Text style={ConnexionUsersStyle.titleUser}>Swap</Text>
      <View style={ConnexionUsersStyle.contentBtn}>
        <BombButton
          svg={Swap}
          width={200}
          height={200}
          titre={null}
          images={null}
          svgWidth={70}
          onPress={scanCard}
          borderRadius={100}
        />
      </View>
      <View style={ConnexionUsersStyle.contentDescription}>
        <Text style={ConnexionUsersStyle.descriptionUser}>Approchez la carte NFC de votre futur contact</Text>
      </View>
      <MenuButton push={push} />
      <NfcModal visible={showModal} close={() => setShowModal(false)}/>
    </View>
  );
}

function ConnexionUsers({ navigation: { push } }) {
  useEffect(() => {
    const AbortRelationsController = new AbortController();
    return () => {
      AbortRelationsController.abort();
    };
  }, []);

  return (
    <View style={ConnexionUsersStyle.container}>
      <LinearBackground />
      <Connect data={null} push={push} />
    </View>
  );
}

const mapStateToProps = (state) => ({});

const ActionProps = {};

export default connect(mapStateToProps, ActionProps)(ConnexionUsers);
