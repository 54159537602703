import React from 'react';
import { View } from 'react-native';
import { Field, reduxForm } from 'redux-form';

import styles from './CreateNetworkAdhesionStyle';

import Input from 'components/form/input/Input';
import { isMobile, isBrowser } from 'components/device';

const validate = (values) => {
  const errors = {};

  if (!values.message || values.message.length === 0) {
    errors.message = 'Obligatoire';
  }

  return errors;
};

function CreateNetworkAdhesionForm({ name }) {
  return (
    <View style={[styles.form, { marginTop: isMobile ? 20 : 25 }]}>
      <Field
        multiline
        width="100%"
        component={Input}
        name="message"
        style={styles.field}
        required={true}
        height={isBrowser ? 200 : undefined}
        label={`Expliquez à ${name} pourquoi vous souhaitez les rejoindre`}
      />
    </View>
  );
}

export default reduxForm({ form: 'networkAdhesion', validate, enableReinitialize: true })(CreateNetworkAdhesionForm);
