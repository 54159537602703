import { StyleSheet } from 'react-native';

import { colors, fonts } from 'styles';

import { isBrowser } from 'components/device';

export default StyleSheet.create({
  device: {
    flexDirection: 'column',
    alignItems: 'center',
    height: 53,
    justifyContent: 'space-between',
  },
  border: {
    width: 15,
    height: 3,
    backgroundColor: colors.black,
    borderRadius: 2,
  },
  container: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: 40,
  },
  headerContainer: {
    width: '100%',
    height: 400,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  ticketContainer: {
    width: '100%',
    borderRadius: 30,
    overflow: 'hidden',
    justifyContent: 'flex-end',
    height: 142,
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  dateContainer: {
    position: 'absolute',
    top: 45,
    right: '5%',
    width: '15%',
  },
  dayContainer: {
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },
  day: {
    marginTop: isBrowser ? 5 : 0,
    color: colors.greyDayColor,
    fontSize: 12,
  },
  separator: {
    marginVertical: 5,
    backgroundColor: colors.greyDayColor,
    width: '70%',
    height: 2,
  },
  number: {
    fontSize: 21,
    fontFamily: fonts.lato.bold,
    color: colors.black,
  },
  month: {
    width: '100%',
    textAlign: 'center',
    fontFamily: fonts.lato.bold,
    color: colors.black,
    fontSize: 12,
    marginBottom: isBrowser ? 5 : 0,
  },
  hour: {
    color: colors.white,
    fontFamily: fonts.lato.regular,
    width: '100%',
    marginTop: 5,
    fontSize: 16,
    textAlign: 'center',
  },
  infosContainer: {
    marginBottom: 20,
    marginLeft: '10%',
    justifyContent: 'flex-end',
  },
  logo: {
    width: '45%',
    resizeMode: 'cover',
    height: 55,
  },
  title: {
    paddingTop: 2,
    fontSize: 24,
    color: colors.white,
    fontFamily: fonts.lato.bold,
    maxWidth: '80%',
    lineHeight: 30,
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginLeft: -5,
  },
  location: {
    color: colors.whiteShadow,
    fontFamily: fonts.lato.light,
    fontSize: 16,
  },
  placesHollowContainer: {
    height: 86,
    width: '90%',
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  placesContainer: {
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  placesLogo: {
    height: '100%',
    aspectRatio: 1,
    resizeMode: 'center',
    borderRadius: 15,
  },
  placesTextContainer: {
    width: '50%',
    alignItems: 'flex-start',
    marginLeft: 15
  },
  participants: {
    fontSize: 14,
    fontFamily: fonts.lato.regular,
  },
  bold: {
    fontFamily: fonts.lato.bold,
  },
  gold: {
    color: colors.orangeBrown,
  },
  descriptionTitle: {
    fontSize: 20,
    fontFamily: fonts.lato.bold,
    marginBottom: '3%',
  },
  descriptionContainer: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: !isBrowser ? '10%' : '4%',
  },
  descriptionText: {
    lineHeight: 19,
    color: colors.greyDark,
    fontFamily: fonts.lato.regular,
  },
  multiColorButton: {
    width: '70%',
    height: 70,
    marginTop: 30,
    marginBottom: 40,
  },
  mobileMapContainer: {
    width: '90%',
    borderRadius: 15,
    overflow: 'hidden',
    marginBottom: 50,
  },
  organizerContainer: {
    width: '75%',
    height: 135,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    overflow: 'hidden',
  },
  organizerLogo: {
    height: 80,
    width: 80,
    borderRadius: 15,
    aspectRatio: 1,
  },
  organizerText: {
    width: '75%',
    marginBottom: 20,
    fontFamily: fonts.lato.regular,
    fontSize: 14,
    color: colors.grey,
    marginTop: '5%',
  },
  whiteButton: {
    width: '75%',
    height: 70,
    marginTop: 20,
  },
  editContainer: {
    width: 60,
    height: 60,
    borderRadius: 10,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
  },
  reportContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 20,
  },
  reportText: {
    fontFamily: fonts.lato.regular,
    textDecorationLine: 'underline',
    color: colors.greyMedium,
  }
});
