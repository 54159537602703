import { StyleSheet } from 'react-native';
import { colors, fonts, images } from 'styles';

export default StyleSheet.create({
  container: {
    marginTop: '15%',
    flex: 1,
    alignItems: 'center',
    width: '100%',
  },
  containerRelations: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: 10,
  },
  scrollContainerAlphabet: {
    marginLeft: '5%',
    width: '7%',
    height: '100%',
  },
  containerAlphabet: {
    alignItems: 'center',
  },
  searchContainer: {
    width: '90%',
    height: 52,
    borderRadius: 30,
    overflow: 'hidden',
    justifyContent: 'center',
    marginBottom: 20,
  },
  searchInput: {
    paddingLeft: 25,
    height: '100%',
    fontFamily: fonts.lato.regular,
    fontSize: 14,
  },
  usersScroll: {
    width: '87%',
  },
  usersScrollContainer: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: 100,
  },
  itemProf: {
    borderRadius: 10,
    backgroundColor: colors.green,
    width: 150,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20,
    paddingTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
    alignItems: 'center',
  },
  textStat: {
    fontSize: 13,
    fontFamily: fonts.lato.bold,
  },
  textProf: {
    color: colors.white,
    fontSize: 14,
    width: '80%',
    fontFamily: fonts.lato.bold,
  },
  itemsProfesionnel: {
    marginRight: 10,
  },
  vertLineProf: {
    width: 5,
    height: 33,
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: 3,
  },
  itemsProfesionnelContainer: {
    flexDirection: 'row',
  },
  containtSlider: {
    paddingLeft: 20,
    paddingBottom: 20,
  },
  containtDataSlider: {
    marginTop: 20,
  },
  containerInfo: {
    borderRadius: 30,
    marginBottom: 10,
    width: '90%',
    height: 58,
    overflow: 'hidden',
  },
  itemUser: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    paddingRight: 20,
  },
  imageContainer: {
    borderRadius: 70,
    marginLeft: 5,
  },
  imageProfile: {
    height: 50,
    marginRight: 5,
    borderRadius: 55,
    aspectRatio: 1,
  },
  infosContainer: {
    width: '65%',
  },
  nameNetwork: {
    fontFamily: fonts.lato.bold,
    fontSize: 14,
    color: colors.grey,
  },
  typeNetwork: {
    fontFamily: fonts.lato.italic,
    fontSize: 12,
    color: colors.grey,
  },
  ProfessionUser: {
    fontFamily: fonts.lato.italic,
    fontSize: 11,
    color: colors.grey,
  },
  vertLineUser: {
    width: 5,
    height: 33,
    borderRadius: 3,
    backgroundColor: colors.grey,
    opacity: 0.1,
  },
  alphabet: {
    textAlign: 'center',
    borderRadius: 20,
    alignItems: 'center',
    marginBottom: 10,
    width: 23,
    overflow: 'hidden',
  },
  texteAlphabet: {
    color: colors.white,
    fontSize: 12,
    fontFamily: fonts.lato.regular,
  },
});
