import { ARRAY_ERROR } from 'final-form';

export const articleValidation = (values) => {
  const errors = {};

  if (!values.title || values.title.length < 1) {
    errors.title = 'Titre obligatoire';
  }
  if (!values.introduction || values.introduction.length < 1) {
    errors.introduction = 'Introduction obligatoire';
  }
  if (!values.picture_url) {
    errors.picture_url = 'Photo obligatoire';
  }
  if (!values.customItems || values.customItems.length < 1) {
    errors.customItems = {};
    errors.customItems[ARRAY_ERROR] = 'Vous devez ajouter au moins un encart' ;
  } else {
    errors.customItems = [];
    values.customItems.map((item, i) => {
      errors.customItems[i] = {};
      if (!item.title) errors.customItems[i].title = 'Obligatoire';
      if (!item.content) errors.customItems[i].content = 'Obligatoire';
    });
  }
  return errors;
};

export const podcastValidation = (values) => {
  const errors = {};

  if (!values.title || values.title.length < 1) {
    errors.title = 'Titre obligatoire';
  }
  if (!values.introduction || values.introduction.length < 1) {
    errors.introduction = 'Introduction obligatoire';
  }
  if (!values.picture_url) {
    errors.picture_url = 'Photo obligatoire';
  }
  if (!values.audio_url) {
    errors.audio_url = 'Podcast obligatoire';
  }
  return errors;
};

export const videoValidation = (values) => {
  const errors = {};

  if (!values.title || values.title.length < 1) {
    errors.title = 'Titre obligatoire';
  }
  if (!values.introduction || values.introduction.length < 1) {
    errors.introduction = 'Introduction obligatoire';
  }
  if (!values.picture_url) {
    errors.picture_url = 'Photo obligatoire';
  }
  if (!values.video_url) {
    errors.video_url = 'Video obligatoire';
  }
  return errors;
};
