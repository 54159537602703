import { Platform, View } from 'react-native';
import { Checkbox, Pressable, Text } from 'native-base';
import React, { useEffect, useState } from 'react';

import { reduxForm, Field } from 'redux-form';

import Api from 'services/api';
import { TextStyles, colors } from 'styles';

import styles from '../stepOne/StepOneStyle';

import Input from 'components/form/input/Input';
import PickerField from 'components/form/picker/PickerField';
import * as WebBrowser from 'expo-web-browser';

const asyncValidate = async (values) => {
  if (values.coupon) {
    return Api.payments.checkCoupon(values.coupon).catch(() => {
      throw { coupon: 'Coupon invalide' };
    });
  }
};

const validate = (values) => {
  const errors = {};

  if (!values.zone) {
    errors.zone = 'Obligatoire';
  }

  if (!values.cgu) {
    errors.cgu = 'Obligatoire';
  }

  return errors;
};

const CguBox = ({ input }) => {
  const [values, setValues] = useState([]);
  useEffect(() => {
    input.onChange(values.includes('one'));
  }, [ values ]);

  return (
    <Checkbox.Group onChange={setValues} value={values}>
      <Checkbox style={{ marginTop: '6%' }} value={'one'}>
        <Pressable onPress={() => WebBrowser.openBrowserAsync('https://api.othr.pro/static/cgu.pdf')}>
          <Text style={TextStyles({ color: colors.blue }).regular14}>
            J'ai lu et et j'accepte les CGU
          </Text>
        </Pressable>
      </Checkbox>
    </Checkbox.Group>
  );
};

function StepThreeForm() {
  const [ zones, setZones ] = useState([]);

  const loadZones = async () => {
    try {
      const { data } = await Api.misc.getOpenZones();
      setZones(data);
    } catch (err) {
      console.error('Error getting zones', err);
    }
  };

  useEffect(() => {
    loadZones();
  }, []);

  return (
    <View style={styles.form}>
      <Field
        centered={true}
        name="zone"
        label="Département"
        items={zones.map((z) => ({ ...z, id: z.id.toString(), label: `${z.code} - ${z.name}` }))}
        labelKey='label'
        valueKey='id'
        component={PickerField}
        width="100%"
        required
      />
      {Platform.OS !== 'ios' && <Field
        centered={true}
        name="coupon"
        label="Coupon de réduction"
        component={Input}
        width="100%"
      />}
      <Field
        centered={true}
        name="cgu"
        label="Cgu"
        component={CguBox}
        width="100%"
      />
    </View>
  );
}

export default reduxForm ({
  form: 'StepThree', validate, asyncValidate, enableReinitialize: true,
  onSubmitFail: console.log, onSubmitSuccess: console.log
})(StepThreeForm);
