import React from 'react';

import {
  Events,
  ConsultEvents,
  CreateEvent,
  Tickets,
  MyEvents,
  Networks,
  ConsultNetworks,
  AllNetworks,
  InviteNetwork,
  CreateNetwork,
  Magazine,
  ConsultArticles,
  CreateArticle,
  MyArticles,
  Podcasts,
  ConsultPodcasts,
  CreatePodcast,
  MyPodcasts,
  Videos,
  ConsultVideos,
  CreateVideo,
  MyVideos,
  Account,
  EditAccount,
  Settings,
  Logout,
  ContactUs,
  User,
  UserBlack,
  Relations,
  Recommendations,
  SwapRequests,
  Swap,
  BurgundyConsultEvents,
  BurgundyCreateEvent,
  BurgundyTickets,
  BurgundyMyEvents,
  BurgundyConsultNetworks,
  BurgundyAllNetworks,
  BurgundyInviteNetwork,
  BurgundyCreateNetwork,
  BurgundyConsultArticles,
  BurgundyCreateArticle,
  BurgundyMyArticles,
  BurgundyConsultPodcasts,
  BurgundyCreatePodcast,
  BurgundyMyPodcasts,
  BurgundyConsultVideos,
  BurgundyCreateVideo,
  BurgundyMyVideos,
  BurgundyEditAccount,
  BurgundySettings,
  BurgundyLogout,
  BurgundyContactUs,
  BurgundyUserBlack,
  BurgundyRelations,
  BurgundyRecommendations,
  WhiteConsultEvents,
  WhiteCreateEvent,
  WhiteTickets,
  WhiteMyEvents,
  WhiteConsultNetworks,
  WhiteAllNetworks,
  WhiteInviteNetwork,
  WhiteCreateNetwork,
  WhiteConsultArticles,
  WhiteCreateArticle,
  WhiteMyArticles,
  WhiteConsultPodcasts,
  WhiteCreatePodcast,
  WhiteMyPodcasts,
  WhiteConsultVideos,
  WhiteCreateVideo,
  WhiteMyVideos,
  WhiteEditAccount,
  WhiteSettings,
  WhiteLogout,
  WhiteContactUs,
  WhiteUserBlack,
  WhiteRelations,
  WhiteRecommendations
} from '../../styles/svg';
import { isBrowser } from '../device';

const menus = {
  'Events': {
    title: 'Events',
    image: Events,
    children: [
      {
        image: ConsultEvents,
        text: 'Consulter',
        target: 'Event',
        related: ['EventDetails', 'EventBooking'],
        imageBurgundy: BurgundyConsultEvents,
        imageWhite: WhiteConsultEvents,
      },
      {
        image: CreateEvent,
        text: 'Créer',
        target: 'CreateEvent',
        imageBurgundy: BurgundyCreateEvent,
        imageWhite: WhiteCreateEvent,
        isAvailable: (user) => {
          return (!!user.admin_city_group_id || !!user.admin_zone_id || (user.administratedNetworks?.length));
        },
      },
      {
        image: Tickets,
        text: 'Billets',
        target: 'Tickets',
        related: ['TicketDetails'],
        imageBurgundy: BurgundyTickets,
        imageWhite: WhiteTickets,
      },
      {
        image: MyEvents,
        text: 'Mes Events',
        target: 'MyEvents',
        related: ['EditEvent', 'ValidationEvent', 'ValidationEventBooking'],
        imageBurgundy: BurgundyMyEvents,
        imageWhite: WhiteMyEvents,
      },
    ],
  },
  'Othrs': {
    title: 'Othrs',
    image: User,
    children: [
      {
        image: UserBlack,
        text: 'Consulter',
        target: 'Others',
        related: ['UserDetails'],
        imageBurgundy: BurgundyUserBlack,
        imageWhite: WhiteUserBlack
      },
      {
        image: Relations,
        text: 'Mes Contacts',
        target: 'Relations',
        imageBurgundy: BurgundyRelations,
        imageWhite: WhiteRelations
      },
      {
        image: Recommendations,
        text: 'Mes Recos',
        target: 'Recommendations',
        related: ['RecommendationResponse', 'RecommendationDetails'],
        imageBurgundy: BurgundyRecommendations,
        imageWhite: WhiteRecommendations,
      },
      {
        image: SwapRequests,
        text: 'Mes Swap',
        target: 'SwapRequests',
        imageBurgundy: BurgundyRecommendations,
        imageWhite: WhiteRecommendations,
      },
    ],
  },
  'Groupements': {
    title: 'Groupements',
    image: Networks,
    children: [
      {
        image: ConsultNetworks,
        text: 'Consulter',
        target: 'Network',
        related: ['NetworkDetails'],
        imageBurgundy: BurgundyConsultNetworks,
        imageWhite: WhiteConsultNetworks,
      },
      {
        image: AllNetworks,
        text: 'Rejoindre',
        target: 'AllNetworks',
        imageBurgundy: BurgundyAllNetworks,
        imageWhite: WhiteAllNetworks,
      },
      {
        image: InviteNetwork,
        text: 'Inviter',
        target: 'NetworkInvitation',
        imageBurgundy: BurgundyInviteNetwork,
        imageWhite: WhiteInviteNetwork,
      },
      /*
      {
        image: CreateNetwork,
        text: 'Créer',
        target: 'AddNetwork',
        imageBurgundy: BurgundyCreateNetwork,
        imageWhite: WhiteCreateNetwork,
      },
       */
    ]
  },
  'Account': {
    title: 'Profil',
    image: Account,
    children: [
      {
        image: EditAccount,
        text: 'Mon profil',
        target: 'UserDetails',
        account: true,
        imageBurgundy: BurgundyEditAccount,
        imageWhite: WhiteEditAccount,
      },
      {
        image: Settings,
        text: 'Paramètres',
        target: 'Settings',
        imageBurgundy: BurgundySettings,
        imageWhite: WhiteSettings,
      },
      {
        image: Logout,
        text: 'Déconnexion',
        logout: true,
        imageBurgundy: BurgundyLogout,
        imageWhite: WhiteLogout,
      },
      {
        image: ContactUs,
        text: 'Contact',
        target: 'ContactUs',
        imageBurgundy: BurgundyContactUs,
        imageWhite: WhiteContactUs,
      },
    ]
  },
  'Magazine': {
    title: 'Magazine',
    image: Magazine,
    children: [
      {
        image: ConsultArticles,
        target: 'Articles',
        text: 'Consulter',
        related: ['ArticleDetails'],
        imageBurgundy: BurgundyConsultArticles,
        imageWhite: WhiteConsultArticles,
      },
      {
        image: CreateArticle,
        text: 'Publier',
        target: 'AddArticle',
        imageBurgundy: BurgundyCreateArticle,
        imageWhite: WhiteCreateArticle,
      },
      {
        image: MyArticles,
        text: 'Mes Articles',
        target: 'MyArticles',
        related: ['EditArticle'],
        imageBurgundy: BurgundyMyArticles,
        imageWhite: WhiteMyArticles,
      },
    ]
  },
  'Podcasts': {
    title: 'Podcasts',
    image: Podcasts,
    children: [
      {
        image: ConsultPodcasts,
        text: 'Consulter',
        target: 'Podcasts',
        related: ['PodcastDetails'],
        imageBurgundy: BurgundyConsultPodcasts,
        imageWhite: WhiteConsultPodcasts,
      },
      {
        image: CreatePodcast,
        text: 'Publier',
        target: 'AddPodcast',
        imageBurgundy: BurgundyCreatePodcast,
        imageWhite: WhiteCreatePodcast,

      },
      {
        image: MyPodcasts,
        text: 'Mes Podcasts',
        target: 'MyPodcasts',
        related: ['EditPodcast'],
        imageBurgundy: BurgundyMyPodcasts,
        imageWhite: WhiteMyPodcasts,
      },
    ]
  },
  'Othr TV': {
    title: 'Othr TV',
    image: Videos,
    children: [
      {
        image: ConsultVideos,
        text: 'Consulter',
        target: 'Videos',
        related: ['VideoDetails'],
        imageBurgundy: BurgundyConsultVideos,
        imageWhite: WhiteConsultVideos,
      },
      {
        image: CreateVideo,
        text: 'Publier',
        target: 'AddVideo',
        imageBurgundy: BurgundyCreateVideo,
        imageWhite: WhiteCreateVideo,

      },
      {
        image: MyVideos,
        text: 'Mes Vidéos',
        target: 'MyVideos',
        related: ['EditVideo'],
        imageBurgundy: BurgundyMyVideos,
        imageWhite: WhiteMyVideos,
      },
    ]
  },
  'Swap': {
    image: Swap,
    round: true,
    title: 'Swap',
    target: 'ConnexionUser',
  }
};

export default menus;
