import React, { useMemo } from 'react';
import { Image, View, Text, TouchableOpacity } from 'react-native';

import { colors } from 'styles';
import { MagazineWhite, PodcastWhite, VideoWhite } from 'styles/svg';
import { pushToMedia, pushToNetwork, pushToUser } from 'services/navigationPush';

import styles from './MediumStyle';

import { isBrowser } from 'components/device';
import RoundedLogo from 'components/roundedLogo';
import Statistics from 'components/mediaStatistics';
import HollowBackground from 'components/backgrounds/hollowBackground';
import DarkBackground, { DisableCardBackground } from 'components/backgrounds/darkBackground/DarkBackground';

const selector = {
  'article': {
    redirect: 'ArticleDetails',
    icon: MagazineWhite,
    title: 'Magazine',
  },
  'podcast': {
    redirect: 'PodcastDetails',
    icon: PodcastWhite,
    title: 'Podcast',
  },
  'video': {
    redirect: 'VideoDetails',
    icon: VideoWhite,
    title: 'TV',
  },
};

export const AuthorHeader = ({ push, statsHeight, user, network, views, likes, inCard = true }) => {
  const author = useMemo(() => {
    return {
      lastname: user?.lastname,
      firstname: user?.firstname,
      id: user?.id || network?.id,
      name: user?.firstname || network?.name,
      job: user?.job || network?.type?.name,
      picture: user?.picture_url || network?.logo_url,
    };
  }, [ user, network ]);

  return (
    <View style={[(!isBrowser || inCard) && styles.horizontal, inCard && { height: isBrowser ? '90%' : '100%' }]}>
      {!!author?.picture &&
        <TouchableOpacity
          style={[
            styles.logoContainer,
            isBrowser && !inCard && { width: 200, height: 200, marginBottom: 20 },
            isBrowser && inCard && { alignItem: 'center', justifyContent: 'center' },
          ]}
          onPress={() => user ? pushToUser(push, author) : pushToNetwork(push, author)}>
          <RoundedLogo uri={author.picture} width="100%" style={[styles.logo, isBrowser && inCard && { width: '85%', height: '100%' }]} />
        </TouchableOpacity>}
      <View style={styles.infosContainer}>
        <Text style={[styles.name, isBrowser && { minHeight: 20 } ]} numberOfLines={1}>{author.name}</Text>
        <Text style={[styles.type, isBrowser && { minHeight: 20 } ]} numberOfLines={1}>{author.job}</Text>
        <Statistics height={statsHeight} number={views || 0} numberText="AUDIENCES" colors={author?.color || colors.burgundy} width="40%" />
        <Statistics height={statsHeight} number={likes} numberText="PERTINENCE" colors={author?.color || colors.burgundy} width="50%" />
      </View>
    </View>
  );
};

const MediumCard = ({ onPress, id, push, disabled = false, items, picture_url, user, network, views, likes, sphere, noRadius, height = 400, type, hideAuthor = false }) => {
  const media = useMemo(() => {
    return {
      title: items.find(i => i.type === 'title')?.content,
      introduction: items.find(i => i.type === 'introduction')?.content,
    };
  }, [ items ]);

  const Icon = useMemo(() => (
    (props) => selector[type].icon(props)
  ), [ type ]);

  const onPressFunc = () => {
    if (onPress) {
      onPress();
    } else {
      pushToMedia(push, type, { ...media, id });
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={1}
      delayPressIn={50}
      onPress={onPressFunc}
      disabled={disabled}
      style={[styles.hollowContainer, { borderRadius: noRadius ? 0 : 15, height: !isBrowser ? height : '100%' }]}
    >
      <HollowBackground />
      <View style={styles.container}>
        <View
          style={[
            styles.imageContainer,
            { height: hideAuthor ? '100%' : '70%' }
          ]}
        >
          <Image alt="Change me" source={{ uri: picture_url }} style={styles.imageBackground} />
          {
            !disabled && (
              <DarkBackground />
            )
          }
          {
            isBrowser ? (
              <Image alt="Change me" source={selector[type].icon} style={styles.imageIcon} />
            ) : (
              <Icon style={styles.imageIcon} />
            )
          }
          <Text style={styles.imageTopTitle}>{selector[type].title}</Text>
          <Text style={styles.imageTitle} numberOfLines={2}>{media.title}</Text>
          <Text style={styles.imageSphere} numberOfLines={1}>{sphere?.name}</Text>
          {/* <Text style={styles.imageDescription} numberOfLines={2}>{media.introduction}</Text> */}
        </View>
        {!hideAuthor && <View style={{ height: !isBrowser ? '23%' : '26%', width: '92%', marginBottom: 25 }}>
          <AuthorHeader
            push={push}
            user={user}
            likes={likes}
            views={views}
            network={network}
            statsHeight={!isBrowser ? height / 22 : 14}
          />
        </View>}
      </View>
      {
        disabled && (
          <DisableCardBackground />
        )
      }
    </TouchableOpacity>
  );
};

export default MediumCard;
