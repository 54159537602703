import React, { useRef, useState } from 'react';
import { View } from 'native-base';
import { Feather } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import { TouchableOpacity, Image, Modal } from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import { colors } from 'styles';
import { AddIcon } from 'styles/svg';

import { isBrowser } from 'components/device';
import FieldWrapper from 'components/form/fieldWrapper';
import HollowButton from 'components/buttons/hollowButton';

const TestModal = ({ isOpen, setIsOpen, img }) => {
  const [crop, setCrop] = useState();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [completedCrop, setCompletedCrop] = useState();

  if (!isOpen) {
    return null;
  }

  const aspect = 4 / 4;

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setWidth(width);
    setHeight(height);
    setCrop(centerAspectCrop(width, height, aspect));
  };

  return (
    <Modal visible={isOpen} onRequestClose={() => setIsOpen(false)}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent' }}>
        <ReactCrop
          crop={crop}
          aspect={4 / 4}
          style={{ backgroundColor: 'yellow', width: 700 }}
          onComplete={(c) => {
            setCompletedCrop(c);
          }}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
        >
          <img
            src={img}
            alt="Crop me"
            onLoad={onImageLoad}
          />
        </ReactCrop>
        {
          completedCrop && (
            <View style={{ width: 400, height: 400, backgroundColor: 'red', overflow: 'hidden' }}>
              <img
                src={img}
                alt="cropped"
                style={{
                  objectFit: 'cover',
                  width: completedCrop.width,
                  height: completedCrop.height,
                  //marginTop: -completedCrop.y,
                  //marginLeft: -completedCrop.x,
                }}
              />
            </View>
          )
        }
      </View>
    </Modal>
  );
};

export default (props) => {
  const launchPicker = async () => {
    const { granted } = await ImagePicker.getMediaLibraryPermissionsAsync();
    if (!granted) {
      await ImagePicker.requestMediaLibraryPermissionsAsync();
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: props.type === 'video' ? ImagePicker.MediaTypeOptions.Videos : ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1,
    });
    const { type, uri } = result;
    const data = await ImageManipulator.manipulateAsync(
      uri,
      [ { resize: { width: 1920 } }],
      { base64: true, format: ImageManipulator.SaveFormat.JPEG, compress: 0.5 },
    );
    onImageSelected({ ...data, type });
  };

  const onImageDeleted = () => {
    if (props.onImageDeleted) {
      props.onImageDeleted();
    }
    props.input.onChange(null);
  };

  const onImageSelected = (response) => {
    if (response.didCancel) {
      return onImageDeleted();
    }
    if (props.onImageSelected) {
      props.onImageSelected(response);
    }
    props.input.onChange(`data:image/jpeg;base64,${response.base64}`);
  };

  const image = props.input.value;

  return (
    <FieldWrapper {...props}>
      <HollowButton
        onPress={launchPicker}
        {...props}
        svg={image ? null : AddIcon}
        svgWidth={props.height > 200 ? 65 : 37}
        svgHeight={props.height > 200 ? 65 : 37}
        titre={image ? null : props.titre}
        width='100%'
        style={{ borderRadius: 15 }}
        image={image}
      >
        {(props.type !== 'video') && !!image && <Image alt="Change me" source={{ uri: image }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />}
        {(props.type !== 'video') && !!image && <TouchableOpacity onPress={launchPicker} style={{ position: 'absolute', top: 15, right: 15, width: 35, height: 35, borderRadius: 35, backgroundColor: '#FEFEFE88', alignItems: 'center', justifyContent: 'center' }}>
          <Feather name="edit-2" size={16} color={colors.black} />
        </TouchableOpacity>}
        {props.required && (
          <View
            style={{
              position: 'absolute',
              width: 3,
              height: 13,
              right: 5,
              margin: 10,
              borderRadius: 20,
              backgroundColor: colors.firebrick,
              borderColor: isBrowser ? colors.whiteShadow : 'transparent',
            }}
          />
        )}
      </HollowButton>
      {/* <TestModal isOpen={isOpen} setIsOpen={setIsOpen} img={img} /> */}
    </FieldWrapper>
  );
};
