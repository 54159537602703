import moment from 'moment';
import 'moment/locale/fr';

import { connect } from 'react-redux';
import { Pressable } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  View,
  Text,
  Image,
  FlatList,
  Animated,
  SectionList,
  RefreshControl,
  ImageBackground,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';

import { colors, fonts } from 'styles';
import { svgtopng } from 'styles/images';
import { getEvents } from 'store/actions/event';

import Slider from 'components/slider';
import EventCard from 'components/cards/event';
import EventPromoCard from 'components/webCarousel/EventCard';
import TextSection from 'components/textSection';
import { pushToEvent } from 'services/navigationPush';
import MenuButton from 'components/buttons/menuButton';
import SquareButton from 'components/buttons/squareButton';
import HeaderSection from 'components/layouts/headerSection';
import { isBrowser, ResponsiveView } from 'components/device';
import CustomFlatList from 'components/webLists/CustomFlatList';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import NetworkCard from '../../components/webCarousel/NetworkCard';

function Events({
  events,
  getEvents,
  navigation: { push, goBack, canGoBack },
}) {
  moment.locale('fr');
  const [month, setMonth] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const scrollViewRef = useRef(null);

  useEffect(() => {
    loadEvents();
  }, []);

  const data = useMemo(() => {
    if (!events) {
      return;
    }
    return events.map((e) => ({ ...e, start_date: moment(e.start_date) }));
  }, [events]);

  const loadEvents = async () => {
    try {
      setRefreshing(true);
      await getEvents();
    } catch (err) {
      console.error('Error fetching home events', err);
    } finally {
      setRefreshing(false);
    }
  };

  const { width } = useWindowDimensions();

  const months = useMemo(() => {
    let now = moment().date(1);
    const inOneYear = moment();
    inOneYear.year(inOneYear.year() + 1).month(inOneYear.month() - 1);
    const data = [];
    while (now < inOneYear) {
      const monthName = moment(now).format('MMMM');
      data.push({
        value: moment(now).month(),
        label: monthName.slice(0, 1).toUpperCase() + monthName.slice(1),
      });
      now.month(now.month() + 1);
    }
    setMonth(null);
    return data;
  }, []);

  const OPACITY = 0.95;

  const opacity = new Animated.Value(OPACITY);

  const showButton = () => {
    Animated.timing(opacity, { toValue: OPACITY, duration: 150, useNativeDriver: true, delay: 300 }).start();
  };

  const hideButton = () => {
    Animated.timing(opacity, { toValue: 0, duration: 50, useNativeDriver: true }).start();
  };

  const RenderWebContent = () => {
    const dates = [];
    const current = new Date();
    const formattedData = data.filter((d) => d.type !== 'month').map((d) => {
      return {
        ...d,
        type: 'event',
        start_date: new Date(d.start_date),
      };
    });
    formattedData.forEach((d) => {
      if (d.start_date < current) {
        return;
      }
      const isAlready = dates.filter((da) => da.month === d.start_date.getMonth() && da.year === d.start_date.getFullYear());
      if (isAlready.length > 0) {
        return;
      }
      const literal = d.start_date.toLocaleString('fr', { month: 'long' });
      dates.push({
        month: d.start_date.getMonth(),
        year: d.start_date.getFullYear(),
        literalMonth: literal.charAt(0).toUpperCase() + literal.slice(1)
      });
    });
    return (
      <View>
        <NetworkCard
          push={push}
          network={{
            image: require('../../../assets/images/EVENT.jpg'),
            // title: 'La Garden des réseaux',
            // sphere: 'Communication',
            // date: 'jeudi 15 avril',
            // description: 'Venez rencontrer l’ensemble des réseaux et entrepreneurs partenaires Othr lors de la Garden annuelle !',
            // author: {
            //   name: 'Othr',
            //   picture_url: 'https://media.othr.pro/add26738-912e-4648-a1a2-7c4387031c10',
            // }
          }}
        />
        <CustomFlatList
          push={push}
          type="event"
          resources={formattedData}
          settings={{
            filters: ['startDate', 'month'],
            sortField: 'title',
            searchFields: ['title', 'network.name', 'user.firstname', 'user.lastname']
          }}
        />
      </View>
    );
  };

  const renderItem = ({ item }) => (
    <View style={{ height: 400, width: width * 0.9, marginBottom: 20 }}>
      <EventCard {...item} push={push} />
    </View>
  );

  const renderMonth = ({ item }) => {
    const title = item.title === 'all' ? 'Tous les Events' : item.label;
    if ((item.title === 'all' && month === null) || month === item.value) {
      return (<SquareButton titre={title} />);
    }
    const available = item.title === 'all' || data?.some(e => moment(e.start_date).month() === item.value);
    return (
      <TouchableOpacity
        disabled={!available}
        onPress={() => setMonth(item?.value || null)}
        style={{ height: 45, justifyContent: 'center', alignItems: 'center' }}
      >
        <Text selectable={true} style={{ color: available ? colors.grey : colors.greyLight, fontFamily: fonts.lato.bold, fontSize: 14, textTransform: 'uppercase', }}>{title}</Text>
      </TouchableOpacity>
    );
  };

  const renderSection = ({ section }) => (
    <View style={{ height: 70, width: '100%', backgroundColor: colors.whiteShadow }}>
      <FlatList
        data={[{ title: 'all' }, ...months ]}
        renderItem={renderMonth}
        ItemSeparatorComponent={() => <View style={{ width: 20 }}/>}
        horizontal={true}
        keyExtractor={(item) => (item === null ? 'all' : item.label)}
        showsHorizontalScrollIndicator={false}
        style={{ flex: 1, height: '100%' }}
        contentContainerStyle={{ height: '100%', paddingHorizontal: '5%', alignItems: 'center' }}
      />
    </View>
  );

  if (!isBrowser) {
    if (!data) return null;
    return (
      <View style={{ alignItems: 'center', width: '100%', height: '100%', paddingTop: 50 }}>
        <TextSection titleSection={'Events'} noBar />
        <SectionList
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={loadEvents} />}
          ListHeaderComponent={
            <View>
              <Slider height={width * 1.35} enableMarginTop={false} contentWidth='100%'>
                {events.map((e, i) => (
                  <EventCard key={`header-${i}`} {...e} push={push} noRadius paddingTop />
                ))}
              </Slider>
            </View>
          }
          sections={[{ title: 'section', data: data.filter((e) => month === null || moment(e.start_date).month() === month) }]}
          stickySectionHeadersEnabled={true}
          renderSectionHeader={renderSection}
          keyExtractor={(item) => item.id}
          ref={scrollViewRef}
          renderItem={renderItem}
          showsVerticalScrollIndicator={false}
          style={{ width: '100%' }}
          contentContainerStyle={{ width: '100%', alignItems: 'center', paddingBottom: 100 }}
          snapToOffsets={[width * 1.35]}
          snapToEnd={false}
          onScrollBeginDrag={hideButton}
          onScrollEndDrag={showButton}
        />
        <MenuButton push={push} opacity={opacity} />
      </View>
    );
  } else {
    return (
      <LoadResourceLayout
        push={push}
        title="Events"
        pagingEnabled={true}
        loadResources={loadEvents}
        mobilePadding={false}
        disableMarginTop={true}
        breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Events" />}
        headerSection={<HeaderSection push={push} menuSelect="Events" redirect="Event"/>}
      >
        <RenderWebContent />
      </LoadResourceLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.Event.events?.filter((e) => new Date(e.start_date) > new Date()),
});

const ActionProps = {
  getEvents,
};

export default connect(mapStateToProps, ActionProps)(Events);
