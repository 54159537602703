import { connect } from 'react-redux';
import * as WebBrowser from 'expo-web-browser';
import React, { useEffect, useMemo, useState } from 'react';
import { Dimensions, RefreshControl, useWindowDimensions } from 'react-native';
import { Modal, View, ScrollView, Text, Alert, Image, Pressable } from 'native-base';

import Api from 'services/api';
import { Location, Pencil } from 'styles/svg';
import { colors, fonts } from 'styles';
import NetworkDetailsStyle from './GroupDetailsStyle';
import { Network, JoinNetwork } from 'store/actions/network';

import Loader from 'components/loader';
import WebMap from 'components/map/WebMap';
import TicketPrice from 'components/ticketPrice';
import GrayButton from 'components/buttons/grayButton';
import BombButton from 'components/buttons/bombButton';
import WebSoloList from 'components/webLists/SoloList';
import { pushToNetwork } from 'services/navigationPush';
import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import HollowBackground from 'components/backgrounds/hollowBackground';
import * as Linking from 'expo-linking';
import { Icon } from 'react-native-elements';

function TextCount(props) {
  const { title, count, colorText } = props;
  return (
    <View style={NetworkDetailsStyle.textCountContainer}>
      <Text selectable style={[NetworkDetailsStyle.textCount, { color: colorText }]}>{count}</Text>
      <Text selectable style={NetworkDetailsStyle.titleCount}>{title}</Text>
    </View>
  );
}

const NetworkImage = ({ network }) => {
  const { width, height } = useWindowDimensions();

  return (
    <View style={{
      minWidth: 300,
      minHeight: 360 + 100,
      borderRadius: 20,
      alignItems: 'center',
      width: width * 0.25,
      height: width * 0.3 + 100,
    }}>
      {network && !!network.picture_url && (
        <Image
          alt="Change me"
          source={{ uri: network.picture_url }}
          style={{
            width: '100%',
            minHeight: 360,
            borderRadius: 20,
            height: width * 0.3,
            resizeMode: 'cover',
          }}
        />
      )}
      <View style={[NetworkDetailsStyle.containerCount, { width: '100%', marginTop: 20 }]}>
        <HollowBackground />
        <TextCount title="Othr" count={network.users?.length} colorText={colors.burgundy} />
        <TextCount title="Events" count={network.events?.length} colorText={colors.burgundy} />
        <TextCount title="Membres" count={network.members} colorText={colors.burgundy} />
      </View>
    </View>
  );
};

const NetworkDescription = ({ network, publicPage, isMember, push }) => {
  const { width, height } = useWindowDimensions();

  return (
    <View style={{
      height: '100%',
      marginLeft: '5%',
      width: width * 0.51,
    }}>
      <View style={{ width: '80%', flexDirection: 'row', alignItems: 'center' }}>
        <Image
          alt="Change me"
          source={{ uri: !!(network?.logo_url || network?.picture_url) ? network?.logo_url || network?.picture_url : null }}
          style={{
            borderRadius: 15,
            width: width * 0.05,
            height: width * 0.05,
          }}
        />
        <View style={{ width: '100%', marginLeft: 15, flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ width: '60%' }}>
            <Text selectable style={{ fontFamily: fonts.lato.regular, fontSize: 17, lineHeight: 18 }}>{network?.type?.name}</Text>
            <Text selectable style={{ fontFamily: fonts.lato.black, fontSize: 25 }}>{network?.name}</Text>
          </View>
          {
            /*
                    <View style={{ width: '40%' }}>
            <ShareButtons title={false} />
          </View>
             */
          }
        </View>
      </View>
      <Text selectable style={{ fontFamily: fonts.lato.regular, fontSize: 23, marginTop: 20 }}>Qui est {network?.name} ?</Text>
      <Text selectable style={{ width: '80%', fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 20 }}>{network?.description}</Text>
      <View style={{ flexDirection: 'row', width: '80%', marginTop: 40 }}>
        <View style={{ width: '40%', minHeight: 100 }}>
          <GrayButton onPress={() => {
            WebBrowser.openBrowserAsync(network.website.includes('http') ? network.website : `https://${network.website}`);
          }} titre={network.website} width="100%" style={{ marginBottom: '1%' }} />
          {!publicPage && !isMember && <GrayButton onPress={() => push('CreateNetworkAdhesion', { networkId: network.id, type: 'EXTERN' })} titre="Adhérer" width="100%" style={{ marginBottom: '1%', marginTop: '5%' }} />}
          {!publicPage && !isMember && <GrayButton onPress={() => push('CreateNetworkAdhesion', { networkId: network.id, type: 'INTERN' })} titre="Je fais partie de ce groupement" width="100%" style={{ marginBottom: '1%', marginTop: '5%' }} />}
        </View>
        <View style={{ height: 100, justifyContent: 'center', width: '50%', marginLeft: '10%', borderRadius: 20, overflow: 'hidden' }}>
          <HollowBackground />
          <Pressable
            disabled={!network.contact_phone}
            onPress={() => Linking.openURL(`sms:${network.contact_phone}`)}
            style={{ marginLeft: 30, flexDirection: 'row', alignItems: 'center' }}
          >
            <Icon
              size={25}
              name="screen-smartphone"
              type="simple-line-icon"
              color={colors.grey}
            />
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15, marginLeft: 10, width: '80%' }}>{network.contact_phone}</Text>
          </Pressable>
          <Pressable
            disabled={!network.contact_email}
            onPress={() => Linking.openURL(`mailto:${network.contact_email}`)}
            style={{ marginLeft: 30, marginTop: '3%', flexDirection: 'row', alignItems: 'center' }}
          >
            <Icon
              size={25}
              name="envelope"
              type="simple-line-icon"
              color={colors.grey}
            />
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15, marginLeft: 10, width: '80%' }}>{network.contact_email}</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

const NetworkWorths = ({ network }) => (
  network?.worths?.length > 0 &&
    <View style={{ alignItems: 'center', width: '48%', paddingTop: 40, paddingBottom: 40 }}>
      <View style={{ width: '100%' }}>
        <Text selectable style={{ fontFamily: fonts.lato.bold, fontSize: 20, textDecorationLine: 'underline', textUnderlineOffset: 6, paddingBottom: 15 }}>Valeurs {network?.name}</Text>
        {network?.worths?.map((w, index) => (
          <View>
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15, color: colors.burgundy, marginTop: index === 0 ? 10 : 40 }}>{w.name}</Text>
            <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 10 }}>{w.description}</Text>
          </View>
        ))}
      </View>
    </View>
);

const NetworkServices = ({ network }) => (
  network?.services?.length > 0 &&
  <View style={{ alignItems: 'center', width: '48%', marginLeft: '4%', paddingTop: 40, paddingBottom: 40 }}>
    <View style={{ width: '100%' }}>
      <Text selectable style={{ fontFamily: fonts.lato.bold, fontSize: 20, textDecorationLine: 'underline', textUnderlineOffset: 6, paddingBottom: 15 }}>Services</Text>
      {network?.services?.map((w, index) => (
        <View>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15, color: colors.burgundy, marginTop: index === 0 ? 10 : 40 }}>{w.name}</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, marginTop: 10 }}>{w.description}</Text>
        </View>
      ))}
    </View>
  </View>
);

const NetworkRequirements = ({ network, setIsModalRecapVisible }) => (
  network?.requirements?.length > 0 ?
    <View style={{ marginTop: 20 }}>
      <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, marginLeft: '20%', marginBottom: 20 }}>Les conditions d'entrée</Text>
      <View style={{ backgroundColor: 'white', alignItems: 'center', paddingTop: 40, paddingBottom: 40 }}>
        <View style={{ width: '45%' }}>
          {
            network?.requirements?.map((r) => (
              <View>
                <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15 }}>{r.title}</Text>
                <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15 }}>{r.description}</Text>
              </View>
            ))
          }
        </View>
        <View style={{ width: '45%' }}>
          <View style={{ height: '2%', width: '60%' }}/>
          {network.fees?.length > 0 && network.fees.map((fee) => (
            <TicketPrice key={fee.id} name={fee.name} description={fee.description} price={parseInt(fee.price)} />
          ))}
          <MultiColorButton onPress={() => setIsModalRecapVisible(true)} style={{ width: '30%', marginTop: 10 }} text="Rejoindre" />
        </View>
      </View>
    </View> : null
);

function NetworkDetails({
  network,
  userId,
  Network,
  route,
  JoinNetwork,
  publicPage = false,
  navigation: { addListener, push, goBack },
}) {
  const { width, height } = useWindowDimensions();

  const [ refreshing, setRefreshing ] = useState(false);
  const [ isModalRecapVisible, setIsModalRecapVisible ] = useState(false);

  const loadNetwork = async () => {
    setRefreshing(true);
    try {
      await Network(route.params.networkId.split('-').slice(-1)[0], publicPage);
    } catch (err) {
      console.error('Error loading event', err);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    return addListener('focus', loadNetwork);
  }, [addListener, network]);

  const joinNetwork = async () => {
    try {
      const result = await Api.payments.paymentIntentNetwork(network.id);
      const {
        paymentIntent,
        ephemeralKey,
        customer,
      } = result.data;
      const { error } = await initPaymentSheet({
        customerId: customer,
        customerEphemeralKeySecret: ephemeralKey,
        paymentIntentClientSecret: paymentIntent,
      });
      if (error) {
        throw error;
      }
      const { error: err } = await presentPaymentSheet({ clientSecret: paymentIntent });
      if (err) {
        throw err;
      }
      Alert.alert(
        'Félicitations !',
        'Vous avez bien rejoint le groupement', [
          {
            text: 'Ok',
            onPress: () => setIsModalRecapVisible(false),
          },
        ]);
    } catch (err) {
      console.error('Error in payment', err);
    }
  };

  const isAdmin = useMemo(() => {
    return network?.canEdit;
  }, [ userId, network?.canEdit ]);

  const [ articles, podcasts, videos ] = useMemo(() => (network?.posts ? [
    network.posts.filter((p) => (p.type === 'article')),
    network.posts.filter((p) => (p.type === 'podcast')),
    network.posts.filter((p) => (p.type === 'video')),
  ] : [ null, null, null ]), [ network ]);

  if (!network?.fees) {
    return <Loader/>;
  }

  const WebMapContainer = () => (
    network?.address && (
      <View style={{ width: width * 0.25, overflow: 'hidden', marginTop: '2%', borderRadius: 15, height: height * 0.31 }}>
        <HollowBackground />
        <View style={{ width: '96%', height: height * 0.213, margin: '2%' }}>
          <WebMap address={network?.address} />
        </View>
        <View style={{ marginTop: 5, marginBottom: 5, marginLeft: 20, alignItems: 'center', flexDirection: 'row' }}>
          <BombButton
            height={40}
            borderRadius={10}
            width={40}
            images={null}
            svg={Location}
            titre={null}
          />
          <View style={{ marginLeft: 20, width: '100%' }}>
            <Text style={{ width: '70%', marginVertical: '0.5%', fontFamily: fonts.lato.bold, fontSize: 14, lineHeight: 15 }}>{`${network?.address.number} ${network?.address.street}`}</Text>
            <Text style={{ width: '70%', marginVertical: '0.5%', fontFamily: fonts.lato.bold, fontSize: 14, lineHeight: 15 }}>{`${network?.address.zip_code} ${network?.address.city}`}</Text>
          </View>
        </View>
      </View>
    )
  );

  return (
    <BasicLayout
      push={push}
      publicPage={publicPage}
      disableHeader={publicPage}
      breadCrumb={<Breadcrumb push={push} goBack={goBack} menuSelect="Groupements" />}
      headerSection={<HeaderSection push={push} menuSelect="Groupements" redirect="Network"/>}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ alignItems: 'center', marginTop: publicPage ? 70 : 0 }}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={loadNetwork}
          />
        }
      >
        {isAdmin && (
          <View style={{ width: '75%' }}>
            <Pressable
              width="18%"
              height={47}
              onPress={() => pushToNetwork(push, network, 'edit')}
              style={{ marginBottom: 20, marginLeft: 'auto', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 30, overflow: 'hidden' }}
            >
              <HollowBackground />
              <Image alt="Change me" source={Pencil} style={{ height: 13, width: 13 }} />
              <Text style={{ fontFamily: fonts.lato.bold, fontSize: 17, marginLeft: 10 }}>Modifier le groupement</Text>
            </Pressable>
          </View>
        )}
        <View style={{ flexDirection: 'row', width: '75%', marginBottom: 20 }}>
          <NetworkImage network={network} />
          <NetworkDescription
            push={push}
            network={network}
            isMember={false}
            // isMember{network.users.some((u) => u.id === userId) || isAdmin}
            publicPage={publicPage}
          />
        </View>
        <View style={{ width: '100%', backgroundColor: 'white' }}>
          <View style={{ width: '75%', flexDirection: 'row', marginHorizontal: '12.5%' }}>
            <NetworkWorths network={network} />
            <NetworkServices network={network} />
          </View>
        </View>
        {
          network?.address && (
            <View style={{ width: '75%', marginTop: '3%' }}>
              <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20 }}>Siège du Réseau</Text>
              <WebMapContainer network={network} />
            </View>
          )
        }
        {
          !publicPage && network?.admins && network.admins.length > 0 && (
            <View style={{ width: '100%' }}>
              <Text style={{ marginLeft: '12.5%', marginBottom: '1%', marginTop: '2%', fontFamily: fonts.lato.bold, fontSize: 20 }}>Equipes</Text>
              <WebSoloList
                showSide
                push={push}
                resources={network.admins.map((n) => {
                  return {
                    ...n,
                    type: 'user'
                  };
                })}
                type="user"
              />
            </View>
          )
        }

        {
          !publicPage && network?.users && network.users.length > 0 && (
            <View style={{ width: '100%' }}>
              <Text style={{ marginLeft: '12.5%', marginBottom: '1%', marginTop: '2%', fontFamily: fonts.lato.bold, fontSize: 20 }}>Membres</Text>
              <WebSoloList
                showSide
                push={push}
                resources={network.users.map((n) => {
                  return {
                    ...n,
                    type: 'user'
                  };
                })}
                type="user"
              />
            </View>
          )
        }

        {
          !publicPage && network?.events && network.events.length > 0 && (
            <View style={{ width: '100%' }}>
              <Text style={{ marginLeft: '12.5%', marginBottom: '1%', marginTop: '2%', fontFamily: fonts.lato.bold, fontSize: 20 }}>Events</Text>
              <WebSoloList
                showSide
                push={push}
                resources={network.events.map((e) => {
                  return {
                    ...e,
                    type: 'event'
                  };
                })}
                type="event"
              />
            </View>
          )
        }
        {
          !publicPage && !!podcasts && podcasts.length > 0 && (
            <View style={{ width: '100%' }}>
              <Text style={{ marginLeft: '12.5%', marginTop: '3%', marginBottom: '1%', fontFamily: fonts.lato.bold, fontSize: 20 }}>Ses Podcasts</Text>
              <WebSoloList showSide push={push} resources={podcasts} />
            </View>
          )
        }
        {
          !publicPage && !!articles && articles.length > 0 && (
            <View style={{ width: '100%' }}>
              <Text style={{ marginLeft: '12.5%', marginTop: '3%', marginBottom: '1%', fontFamily: fonts.lato.bold, fontSize: 20 }}>Ses Articles</Text>
              <WebSoloList showSide push={push} resources={articles} />
            </View>
          )
        }
        {
          !publicPage && !!videos && videos.length > 0 && (
            <View style={{ width: '100%' }}>
              <Text style={{ marginLeft: '12.5%', marginTop: '3%', marginBottom: '1%', fontFamily: fonts.lato.bold, fontSize: 20 }}>Ses Videos</Text>
              <WebSoloList showSide push={push} resources={videos} />
            </View>
          )
        }
        {
          /*
                  {!!podcasts && podcasts.length > 0 && <Slider height={width * 1.15} title="Podcasts" svg={<Podcasts/>}>
          {podcasts.map((p) => (
            <Podcast {...p} key={p.id} push={push} network={network}/>
          ))}
        </Slider>}

        {!!articles && articles.length > 0 && <Slider height={450} title="Articles" svg={<Magazine/>}>
          {articles.map((p) => (
            <Article {...p} key={p.id} push={push} network={network}/>
          ))}
        </Slider>}

        {!!videos && videos.length > 0 && <Slider height={350} title={network.name + ' TV'} svg={<Videos/>}>
          {videos.map((p) => (
            <Video {...p} key={p.id} push={push} network={network}/>
          ))}
        </Slider>}
        <View style={{ width: '100%' }}>
          <NetworkRequirements network={network} setIsModalRecapVisible={setIsModalRecapVisible} />
        </View>
           */
        }

      </ScrollView>

      {/* Modal Recap NetWork */}
      <Modal
        animationType="slide"
        transparent={false}
        onRequestClose={() => setIsModalRecapVisible(!isModalRecapVisible)}
        visible={isModalRecapVisible}
      >
        <View style={NetworkDetailsStyle.modal}>
          <View style={NetworkDetailsStyle.logoContainer}>
            <HollowBackground />
            <Image
              alt="Change me"
              source={{ uri: network.logo_url }}
              style={{ width: '100%', height: '100%', resizeMode: 'center', position: 'absolute', top: 0, left: 0 }}
            />
          </View>
          {!!network.fees && network.fees.map((fee) => (
            <TicketPrice key={fee.id} name={fee.name} description={fee.description} price={parseInt(fee.price)} ticketSelected/>
          ))}
          <MultiColorButton
            onPress={joinNetwork}
            style={{
              ...NetworkDetailsStyle.multiColorButton, position: 'absolute', bottom: '5%', height: 40,
            }}
            text={`Payer ${network.fees?.reduce((acc, cur) => (acc + cur.price), 0)} €`}
          />
        </View>
      </Modal>
    </BasicLayout>
  );
}

const mapStateToProps = (state, props) => {
  const network = state.Network.networks?.find((e) => (e.id?.toString() === props.route.params.networkId?.toString().split('-').slice(-1)[0]));
  return ({
    userId: state.Account.userId,
    network,
  });
};
const mapDispatchToProps = {
  Network,
  JoinNetwork,
};
export default connect(mapStateToProps, mapDispatchToProps)(NetworkDetails);
