import moment from 'moment';
import { FontAwesome, Ionicons } from '@expo/vector-icons';
import React, { useContext } from 'react';
import { Text, TouchableOpacity, View, ActivityIndicator, Platform } from 'react-native';

import { colors, TextStyles } from 'styles';

import AudioPlayerStyle from './AudioPlayerStyle';

import { isBrowser } from '../device';
import { AudioPlayerContext } from './AudioPlayerContext';
import Slider from 'react-slider';
import styles from '../audioSlider/AudioSliderStyle';

const AudioPlayer = ({ width = undefined }) => {
  const {
    audio,
    status,
    current,
    duration,
    togglePlayAudio,
    setPosition,
  } = useContext(AudioPlayerContext);

  if (!audio) {
    return null;
  }

  const renderTrack = (props, state) => {
    return (
      <div {...props} style={{
        ...props.style,
        ...styles().playerBar,
        backgroundColor: state.index === 0 ? colors.burgundy : colors.black
      }}/>
    );
  };

  const renderThumb = (props, state) => {
    return (
      <div {...props} style={{ ...props.style, ...styles().playerDuration, marginTop: -4 }}/>
    );
  };

  const handleChange = (value) => {
    setPosition(Math.round(value * duration));
  };

  return (
    <View style={[
      AudioPlayerStyle({ width }).container,
      isBrowser ? {
        width: 300, display: 'flex', marginLeft: '90', zIndex: 100, marginTop: 20,
      } : {
        width: '30%',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 0,
      }]}
    >
      <TouchableOpacity
        style={AudioPlayerStyle().buttonsContainer}
        onPress={togglePlayAudio}
      >
        {status?.isPlaying ? (
          <Ionicons name="ios-pause" size={23} color={colors.white} style={AudioPlayerStyle().pauseIcon} />
        ) : (
          !!audio ? (
            <FontAwesome name="play" size={18} color={colors.white} style={AudioPlayerStyle().playIcon} />
          ) : (
            <ActivityIndicator color={colors.white} />
          )
        )}
      </TouchableOpacity>
      <View style={AudioPlayerStyle().playerContainer}>
        <View style={AudioPlayerStyle().playerTimerContainer}>
          <Text style={TextStyles().bold10}>{current >= 0 ? moment.utc(current * 1000).format('mm:ss') : '__:__'}</Text>
          <Text style={TextStyles({ color: colors.greyDayColor }).bold10}>{duration ? moment.utc(duration * 1000).format('mm:ss') : '__:__'}</Text>
        </View>
      </View>
      {Platform.OS === 'web' && <View style={{ width: '80%', paddingLeft: 10 }}>
        <Slider
          // disabled={disabled}
          style={{ alignItems: 'center' }}
          // className="slider"
          value={Math.round(current / duration * 1000)}
          min={0}
          max={1000}
          defaultValue={0}
          renderTrack={renderTrack}
          renderThumb={renderThumb}
          onChange={handleChange}
        />
      </View>}
    </View>
  );
};

export default AudioPlayer;
