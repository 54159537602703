import React from 'react';
import { View } from 'native-base';

import User from '../cards/user/User';
import Event from '../cards/event/Event';
import VideoCard from '../cards/medium/Video';
import Network from '../cards/network/Network';
import ArticleCard from '../cards/medium/Article';
import PodcastCard from '../cards/medium/Podcast';
import { DisableCardBackground } from 'components/backgrounds/darkBackground/DarkBackground';

const WebItem = ({ disabled, r, push, onPress }) => {
  if (r.shadow) {
    return (
      <View style={{ width: '100%', height: '100%', borderRadius: 15, overflow: 'hidden' }}>
        <DisableCardBackground />
      </View>
    );
  }
  switch (r.type) {
  case 'user':
    return (
      <User {...r} disabled={disabled} push={push} onPress={onPress} />
    );
  case 'event':
    return (
      <Event {...r} disabled={disabled} push={push} onPress={onPress} />
    );
  case 'network':
    return (
      <Network {...r} disabled={disabled} push={push} onPress={onPress} />
    );
  case 'ticket':
    return (
      <Event {...r} disabled={disabled} push={push} onPress={onPress} numberOfTickets={r.numberOfTickets} />
    );
  case 'article':
    return (
      <ArticleCard {...r} disabled={disabled} push={push} onPress={onPress} />
    );
  case 'podcast':
    return (
      <PodcastCard {...r} disabled={disabled} push={push} onPress={onPress} />
    );
  case 'video':
    return (
      <VideoCard {...r} disabled={disabled} push={push} onPress={onPress} />
    );
  default:
    console.log('Unknown resource type', r.type);
    return null;
  }
};

export default WebItem;
