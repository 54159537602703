import React from 'react';
import { connect } from 'react-redux';
import { Pressable, Text, View } from 'native-base';

import { APP_URL } from 'config';
import { colors, fonts } from 'styles';
import { pushToUser } from 'services/navigationPush';
import { toastError, toastInfo } from 'services/toasts';
import { Logout as logout, manageBilling } from 'store/actions/account';
import { AccountMail, AccountProfil, AccountLogout, Icon, Settings } from 'styles/svg';

import Svg from 'components/svg';
import { ResponsiveView } from 'components/device';
import * as WebBrowser from 'expo-web-browser';

const AccountModal = ({ user, push, setIsAccountModalOpen, manageBilling, logout }) => {
  const goToStripeManagementPage = async () => {
    const data = await manageBilling(`${APP_URL}/home`);
    await WebBrowser.openBrowserAsync(data);
  };

  return (
    <ResponsiveView style={{ zIndex: 3 }}>
      <View
        style={{
          width: 300,
          height: 200,
          marginTop: -50,
          paddingTop: 55,
          marginLeft: 'auto',
          alignItems: 'center',
        }}
        onMouseLeave={() => setIsAccountModalOpen(false)}
      >
        <View
          style={{
            height: 160,
            width: '70%',
            borderRadius: 10,
            justifyContent: 'center',
            backgroundColor: colors.white,
          }}
        >
          <View style={{ height: '20%', marginTop: 20 }}>
            <Pressable onPress={() => pushToUser(push, user)}>
              <View
                style={{
                  width: '80%',
                  marginLeft: '20%',
                  alignItems: 'center',
                  flexDirection: 'row',
                  borderColor: colors.greyLight,
                }}
              >
                <View style={{ width: 30 }}>
                  <Svg style={{ width: 12, height: 15 }} src={AccountProfil} />
                </View>
                <div className="hover-underline-animation">
                  <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15 }}>
                    Mon Profil
                  </Text>
                </div>
              </View>
            </Pressable>
          </View>
          <View style={{ height: '20%' }}>
            <Pressable onPress={() => push('Settings')}>
              <View
                style={{
                  width: '80%',
                  marginLeft: '20%',
                  alignItems: 'center',
                  flexDirection: 'row',
                  borderColor: colors.greyLight,
                }}
              >
                <View style={{ width: 30 }}>
                  <Svg style={{ width: 12, height: 15 }} src={Settings} />
                </View>
                <div className="hover-underline-animation">
                  <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15 }}>
                    Paramètres
                  </Text>
                </div>
              </View>
            </Pressable>
          </View>
          <View style={{ height: '20%' }}>
            <Pressable onPress={async () => {
              if (user.subbedMethod === 'stripe') {
                await goToStripeManagementPage();
              } else if (user.subbedMethod === 'apple') {
                toastInfo('Les souscriptions apple doivent être gérées depuis votre appareil.');
              } else {
                toastError('Une erreur est survenue. Veuillez nous contacter');
              }
            }}>
              <View
                style={{
                  width: '80%',
                  marginLeft: '20%',
                  alignItems: 'center',
                  flexDirection: 'row',
                  borderColor: colors.greyLight,
                }}
              >
                <View style={{ width: 30 }}>
                  <Svg style={{ width: 18, height: 15, marginLeft: -3 }} src={Icon} />
                </View>
                <div className="hover-underline-animation">
                  <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15 }}>
                    Souscription
                  </Text>
                </div>
              </View>
            </Pressable>
          </View>
          <View style={{ height: '20%' }}>
            <Pressable onPress={async () => {
              await logout();
              setTimeout(() => window.location.replace('/login'), 400);
            }}>
              <View
                style={{
                  width: '80%',
                  marginLeft: '20%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderColor: colors.greyLight,
                }}
              >
                <View style={{ width: 30 }}>
                  <Svg style={{ width: 13, height: 16.5 }} src={AccountLogout} />
                </View>
                <div className="hover-underline-animation">
                  <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15 }}>
                    Déconnexion
                  </Text>
                </div>
              </View>
            </Pressable>
          </View>
          <View style={{
            height: '25%',
            paddingBottom: 17,
            backgroundColor: '#DEDEDE',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}>
            <Pressable style={{ marginTop: 5 }} onPress={() => push('ContactUs')}>
              <View
                style={{
                  width: '100%',
                  alignItems: 'center',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'center',
                  borderColor: colors.greyLight,
                }}
              >
                <View style={{ width: 30, marginTop: 5 }}>
                  <Svg style={{ width: 14, height: 12 }} src={AccountMail} />
                </View>
                <View style={{ marginTop: 4 }}>
                  <div className="hover-underline-animation">
                    <Text style={{ fontFamily: fonts.lato.regular, fontSize: 14 }}>
                      Nous contacter
                    </Text>
                  </div>
                </View>
              </View>
            </Pressable>
          </View>
        </View>
      </View>
    </ResponsiveView>
  );
};

const mapDispatchToProps = {
  logout,
  manageBilling,
};

export default connect(null, mapDispatchToProps)(AccountModal);
