import { StyleSheet, Platform, Dimensions } from 'react-native';
import { colors, fonts } from 'styles';

const { width } = Dimensions.get('screen');

export default StyleSheet.create({
  scrollView: { flex: 1, height: '100%', width: '100%' },
  scrollContainer: { width: '100%', paddingBottom: 40, alignItems: Platform.OS === 'web' ? 'center' : 'flex-start' },
  authorContainer: { flex: 1, height: 100, width: '100%', marginBottom: 0, paddingHorizontal: 25 },
  editedAt: { fontFamily: fonts.lato.italic, fontSize: 15 },
  title: { width: '100%', fontSize: 40, marginBottom: 20, marginTop: 40, paddingHorizontal: 25 },
  introduction: { width: '100%', marginBottom: 20, paddingHorizontal: 25 },
  image: { width: Platform.OS === 'web' ? '100%' : width, height: 250, marginTop: 20 },
  likesContainer: {
    width: '100%',
    height: 90,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 60,
    borderRadius: Platform.OS === 'web' ? 10 : 0
  },
  likeButton: {
    marginLeft: '5%',
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  likesText: {
    fontFamily: fonts.lato.regular,
    fontSize: 14,
    marginLeft: '0%',
    width: '60%',
    textAlign: 'left',
  },
  likesCount: {
    color: colors.burgundy,
    marginRight: 5,
  },
  shareStyle: {
    marginRight: '10%',
  },
  reportContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 20,
  },
  reportText: {
    fontFamily: fonts.lato.regular,
    textDecorationLine: 'underline',
    color: colors.greyMedium,
  }
});
