import React, { useEffect } from 'react';
import { Text } from 'native-base';
import { connect } from 'react-redux';
import { View, TouchableOpacity } from 'react-native';

import { colors, TextStyles } from 'styles';
import { pushToNetwork } from 'services/navigationPush';
import { ParentNetwork } from 'store/actions/parent_network';

import { isBrowser } from 'components/device';
import RoundedLogo from 'components/roundedLogo';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';

const NetworkDetails = ({ parentNetwork, ParentNetwork, route, navigation: { addListener, push, goBack } }) => {
  const loadNetwork = async () => {
    try {
      await ParentNetwork(route.params.networkId.split('-').slice(-1)[0]);
    } catch (err) {
      console.error('Error loading event', err);
    } finally {
    }
  };

  useEffect(() => {
    return addListener('focus', loadNetwork);
  }, [addListener, parentNetwork]);

  return (
    <LoadResourceLayout
      push={push}
      loadResources={() => {}}
      breadCrumb={<Breadcrumb push={push} goBack={goBack} menuSelect="Groupements" />}
      headerSection={<HeaderSection push={push} menuSelect="Groupements" redirect="Network"/>}
    >
      <View style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Text selectable style={TextStyles({ color: colors.grey, marginBottom: 10, marginTop: isBrowser ? '10%' : '0%' }).bold22}>Sélectionner un groupe</Text>
        <View style={{ flexWrap: 'wrap', justifyContent: 'center', width: '90%', marginHorizontal: '5%', flexDirection: 'row', marginTop: isBrowser ? '5%' : '0%', paddingBottom: isBrowser ? '10%' : '0%' }}>
          {parentNetwork?.networks.map((n) => (
            <TouchableOpacity key={`network-${n.id}`} onPress={() => pushToNetwork(push, n)} style={{ margin: 10, alignItems: 'center' }}>
              <RoundedLogo uri={n.logo_url} style={isBrowser ? { height: 150, width: 150 } : { height: 100 }}/>
              <Text style={{ width: 100, textAlign: 'center' }}>{n.name}</Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    </LoadResourceLayout>
  );
};

const mapStateToProps = (state, props) => {
  const parentNetwork = state.ParentNetwork.parentNetworks?.find((e) => (e.id?.toString() === props.route.params.networkId?.toString().split('-').slice(-1)[0]));
  return ({
    parentNetwork,
  });
};

const mapDispatchToProps = {
  ParentNetwork,
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkDetails);
