import { StyleSheet } from 'react-native';

export const WebUserImageStyle = StyleSheet.create({
  contentOtherSlideNew: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentSlideText: {
    borderRadius: 10,
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 30,
    width: '100%'
  },
});
