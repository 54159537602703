'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.Tickets = void 0;
var index_1 = require('./index');
var Tickets = /** @class */ (function (_super) {
  __extends(Tickets, _super);
  function Tickets() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Tickets.prototype.download = function (id) {
    return this.requester.get('/tickets/' + id + '/download', { responseType: 'blob' });
  };
  Tickets.prototype.send = function (id, email) {
    return this.requester.post('/tickets/' + id + '/send', { email: email });
  };
  Tickets.prototype.register = function (id, owners) {
    return this.requester.post('/tickets/' + id + '/register', { owners: owners });
  };
  return Tickets;
}(index_1.Component));
exports.Tickets = Tickets;
