export default function Magazine(
  state = {
    showMenu: false,
  },
  action,
) {
  switch (action.type) {
  case 'SHOW_MENU':
    return {
      ...state,
      showMenu: true,
    };
  case 'HIDE_MENU':
    return {
      ...state,
      showMenu: false,
    };
  default:
    return state;
  }
}
