import { colors, fonts } from 'styles';
import { StyleSheet, Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollContainer: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: 20,
  },
  headerContainer: {
    height: 220,
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 40,
  },
  headerBg: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  headerLogo: {
    width: '40%',
    resizeMode: 'contain',
    marginBottom: 10,
  },
  headerTitle: {
    fontFamily: fonts.lato.bold,
    fontSize: 22,
    color: colors.white,
    width: '80%',
    textAlign: 'left',
    marginBottom: 26,
  },
  dateContainer: {
    width: '80%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  locationContainer: {
    width: '80%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  placesNumber: {
    width: '45%',
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    overflow: 'hidden',
    marginHorizontal: '5%',
  },
  placesText: {
    fontSize: 18,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.greyDayColor,
    borderRadius: 20,
    height: 20,
    width: 20,
    cursor: 'pointer',
  },
  placesMainContainer: {
    flexDirection: 'row',
    marginTop: 27,
    justifyContent: 'space-between',
    width: '75%',
    alignItems: 'center',
  },
  placesContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  webContainer: {
    width: '100%',
    marginTop: 70,
    alignItems: 'center',
  },
  webDescriptionContainer: {
    height: '100%',
    marginLeft: '5%',
    width: width * 0.3,
  },
  webEventImageContainer: {
    borderRadius: 15,
    width: width * 0.25,
    alignItems: 'center',
    height: height * 0.546,
  },
  webEventInsideImageContainer: {
    width: '100%',
    height: '100%',
    marginBottom: 20,
    paddingLeft: '10%',
    justifyContent: 'flex-end',
  },
});
