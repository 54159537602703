import Constants from 'expo-constants';

const {
  GOOGLE_API_KEY,
  STRIPE_CLIENT_SECRET,
  API_URL = 'https://api.othr.pro',
  APP_URL = 'https://app.othr.pro',
  ADMIN_URL = 'https://admin.othr.pro',
  DASHBOARD_URL = 'https://dashboard.othr.pro',
} = Constants.manifest2?.extra?.expoClient?.extra || Constants.manifest?.extra;
const version = Constants.manifest2?.extra?.expoClient?.version || Constants?.manifest?.version;

console.log('API_URL', API_URL);
console.log('APP_URL', APP_URL);
console.log('ADMIN_URL', ADMIN_URL);
console.log('GOOGLE_API_KEY set ?', !!GOOGLE_API_KEY);
console.log('STRIPE_CLIENT_SECRET set ?', !!STRIPE_CLIENT_SECRET);
console.log('version', version);

export {
  API_URL,
  APP_URL,
  ADMIN_URL,
  DASHBOARD_URL,
  GOOGLE_API_KEY,
  STRIPE_CLIENT_SECRET,
  version,
};
