import { StyleSheet, Dimensions } from 'react-native';

import { colors, fonts } from 'styles';

const widthScreen = Dimensions.get('window').width;

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  textTitle: {
    fontFamily: fonts.lato.bold,
    fontSize: 14,
  },
  textDescription: {
    fontFamily: fonts.lato.regular,
    fontSize: 8,
  },
  containerCount: {
    width: '90%',
    height: 85,
    borderRadius: 50,
    marginTop: '10%',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  containerInfo: {
    paddingLeft: 20,
    width: '60%',
  },
  containerInfoPrice: {
    width: '30%',
    height: 60,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  containerButton: {
    position: 'absolute',
    bottom: 15,
    left: (widthScreen * 0.5) - (169 / 2),
    width: 169,
    backgroundColor: colors.white,
    alignItems: 'center',
    padding: 20,
    borderRadius: (widthScreen * 0.25) / 2,
  },
  textCountContainer: {
    alignItems: 'center',
    width: '30%',
  },
  textCount: {
    fontWeight: 'bold',
    fontSize: 30,
  },
  titleCount: {
    fontSize: 14,
    color: colors.grey,
    marginTop: '2%',
  },
  descriptionContainer: {
    width: '100%',
    alignItems: 'center',
  },
  descriptionText: {
    width: '70%',
    marginTop: '10%',
    lineHeight: 19,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.whiteShadow,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: '10%',
  },
  title: {
    fontSize: 22,
    fontFamily: fonts.lato.bold,
    paddingTop: '10%',
    paddingBottom: 20,
  },
  button: {
    width: 250,
    alignContent: 'center',
    backgroundColor: colors.palevioletred,
    borderRadius: 10,
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  containtSlider: {
    paddingLeft: 20,
    marginBottom: 30,
  },
  verticalLine: {
    height: 11,
    width: 3,
    borderRadius: 3,
    backgroundColor: colors.white,
    opacity: 0.1,
  },
  textBtn: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    fontSize: 12,
    textAlign: 'center',
    marginRight: 10,
  },
  containtBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.palevioletred,
    paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 66,
    paddingRight: 66,
    borderRadius: 150,
    textAlign: 'center',
  },
  centerStyle: {
    alignItems: 'center',
    marginBottom: 20,
  },
  valuesHollowContainer: {
    width: '100%',
    alignItems: 'center',
    marginBottom: '5%',
  },
  valuesContainer: {
    width: '70%',
    paddingTop: '7%',
    paddingBottom: '2%',
  },
  valueItemContainer: {
    marginBottom: '5%',
  },
  valueTitle: {
    fontFamily: fonts.lato.bold,
    color: colors.burgundy,
    fontSize: 16,
    marginBottom: '1%',
    textTransform: 'uppercase',
  },
  valueText: {
    fontFamily: fonts.lato.regular,
    fontSize: 14,
    lineHeight: 19,
  },
  valuesSubtitle: {
    marginBottom: '2%',
    fontFamily: fonts.lato.bold,
    fontSize: 16,
    width: '70%',
  },
  multiColorButton: {
    width: '70%',
    height: 70,
    marginBottom: '5%',
    marginTop: '10%',
  },
  modal: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    backgroundColor: colors.whiteShadow,
  },
  logoContainer: {
    width: '90%',
    marginTop: '15%',
    marginBottom: '10%',
    height: '20%',
    borderRadius: 30,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },

  advicesContainer: {
    borderRadius: 30,
    overflow: 'hidden',
    width: '90%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentAdvice: {
    marginBottom: 40,
    width: '100%',
    alignItems: 'center',
  },
  adviceItem: {
    width: '100%',
    padding: 20,
  },

  networkContainer: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 30,
  },
  networkImage: {
    width: '70%',
    height: 200,
    resizeMode: 'contain',
  },
  networkDescription: {
    fontFamily: fonts.lato.regular,
    fontSize: 14,
    width: '70%',
    textAlign: 'center',
    color: colors.greyDayColor,
  },
  contentSlideText: {
    borderRadius: 0,
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 15,
    width: '100%'
  },
  sizeDevice: {
    flexDirection: 'row',
    marginTop: 17,
    width: '75%',
    alignItems: 'center',
  },
  border: {
    width: 15,
    height: 3,
    backgroundColor: colors.white,
    borderRadius: 2,
  },
  swap: {
    flexDirection: 'column',
    alignItems: 'center',
    height: 50,
    width: 50,
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 50,
  },
  device: {
    flexDirection: 'column',
    alignItems: 'center',
    height: 53,
    justifyContent: 'space-between',
  },
  deviceCenter: {
    marginHorizontal: 50,
  },
  contentOtherSlideNew: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentText: {
    alignItems: 'center',
    width: '100%',
  },
  nameHeader: {
    color: colors.white,
    fontSize: 24,
    fontFamily: fonts.lato.bold,
    textAlign: 'center',
  },
  jobHeader: {
    fontSize: 14,
    color: colors.white,
    marginTop: 10,
    fontFamily: fonts.lato.bold,
    marginBottom: 5,
  },
  addressHeader: {
    fontSize: 12,
    color: colors.white,
    fontFamily: fonts.lato.bold,
  },
  srLabelContainer: {
    bottom: 0,
    width: '100%',
    position: 'absolute',
    backgroundColor: colors.whiteShadow,
  },
  srTouchableContainer: {
    height: 80,
    alignItems: 'center',
    backgroundColor: colors.green,
  },
  srTextContainer: {
    width: '100%',
    marginTop: 20,
    marginBottom: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  srText: {
    fontSize: 16,
    marginLeft: 12,
    color: colors.white,
    fontFamily: fonts.lato.bold,
  },
  reportContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 10,
  },
  reportText: {
    fontFamily: fonts.lato.regular,
    textDecorationLine: 'underline',
    color: colors.greyMedium,
  }
});
