import React from 'react';
import { Image, Pressable, Text } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, useWindowDimensions } from 'react-native';

import { colors, fonts, TextStyles } from 'styles';

import { ResponsiveView } from '../device';
import { pushToNetwork } from '../../services/navigationPush';

const EventCard = ({ push, event }) => {
  const { height } = useWindowDimensions();

  return (
    <ImageBackground
      source={{ uri: event.picture_url }}
      style={{ width: '100%', height: height * 0.8 > 600 ? height * 0.8 : 600, paddingTop: 80 }}
    >
      <LinearGradient colors={['rgba(0,0,0,0.5)', 'rgba(0,0,0,0)']} start={[0.0, 0.5]} end={[1.0, 0.5]} locations={[0.0, 1.0]} style={{ width: '100%', height: '100%' }}>
        <ResponsiveView style={{ justifyContent: 'center', height: '100%' }}>
          <Text style={TextStyles({ color: colors.white, textUnderlineOffset: 6, textDecorationLine: 'underline' }).black27}>{('l\'event du mois').toUpperCase()}</Text>
          <Image alt="Change me" source={{ uri: event.author.picture_url }} style={{ marginTop: '2.5%', width: 130, height: 130, borderRadius: 15, resizeMode: 'stretch' }}/>
          <Text style={{ marginTop: '0.5%', fontFamily: fonts.lato.black, fontSize: 19, color: colors.white, textUnderlineOffset: 6, textDecorationLine: 'underline' }}>{event.sphere}</Text>
          <Text style={{ marginTop: '2%', fontFamily: fonts.lato.black, fontSize: 42, color: colors.white }}>{event.title}</Text>
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 20, color: colors.white }} numberOfLines={3}>{event.description}</Text>
          {/*<Pressable*/}
          {/*  onPress={() => {}}*/}
          {/*  // onPress={() => pushToNetwork(push, { id: event.eventId })}*/}
          {/*  style={{ marginTop: '3%', backgroundColor: colors.white, borderRadius: 30, width: '12%', height: 35, alignItems: 'center', justifyContent: 'center' }}*/}
          {/*>*/}
          {/*  <Text style={{ fontFamily: fonts.lato.bold, fontSize: 18 }}>*/}
          {/*      Découvrir*/}
          {/*  </Text>*/}
          {/*</Pressable>*/}
        </ResponsiveView>
      </LinearGradient>
    </ImageBackground>
  );
};

export default EventCard;
