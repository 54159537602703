import React from 'react';
import { View } from 'native-base';
import {  submit } from 'redux-form';
import { connect } from 'react-redux';
import { Dimensions, Platform, Text } from 'react-native';

import StepTwoForm from './StepTwoForm';
import styles from './StepTwoStyle';

import Logo from 'components/logo';
import { isBrowser } from 'components/device';
import AuthGoBack from 'components/authGoBack';
import TextSection from 'components/textSection';
import GoBack from 'components/buttons/goBackButton';
import BombButton from 'components/buttons/bombButton';
import LinearBackground from 'components/backgrounds/linearBackground';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';

import ExternLogin from '../../login/ExternLogin';

function StepOne({
  navigation: { navigate, push },
  submit,
}) {

  const register = async (values) => {
    console.log('values', values);
    navigate('StepOne', { registerType: 'form' });
  };

  const handleExternalSignup = ({ registerType, credential }) => {
    console.log('credential', credential);
    navigate('StepOne', {
      registerType,
      credential,
      ...(registerType === 'apple' ? {
        firstname: credential?.fullName?.givenName,
        lastname: credential?.fullName?.familyName,
      } : {
        firstname: credential?.user?.givenName,
        lastname: credential?.user?.familyName,
      }),
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <LinearBackground />
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        {isBrowser ? <View style={{ marginTop: '2%', marginLeft: '2%', width: '100%' }}>
          <AuthGoBack goBack={() => navigate('Login')} />
        </View> : <GoBack/>}
        <View style={styles.container}>
          <Logo style={styles.logo} />
          <View style={{
            width: '100%',
            alignItems: 'center',
            ...isBrowser && {
              width: 450,
              paddingTop: 50,
              borderRadius: 30,
              paddingBottom: 50,
              backgroundColor: 'white',
            }
          }}>
            {
              isBrowser && (
                <View style={{ flexDirection: 'column', alignItems: 'center', marginBottom: 40, width: '100%' }}>
                  <TextSection titleSection="Créez votre compte" noBar />
                  <View style={{ width: 30, height: 1, marginTop: 20, backgroundColor: 'black' }} />
                </View>
              )
            }
            <StepTwoForm onSubmit={register} />
            <BombButton
              height={52}
              width={255}
              images={null}
              titre="Etape suivante"
              titrePosition="middle"
              onPress={() => submit('StepTwo')}
              style={{ marginTop: 10 }}
            />
            <View style={styles.separatorContainer}>
              <View style={styles.separator} />
              <Text style={styles.separatorText}>ou</Text>
              <View style={styles.separator} />
            </View>
            <ExternLogin navigate={navigate} push={push} type={'signup'} onSucceed={handleExternalSignup}/>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
}

const mapStateToProps = (state) => ({
});

const actionProps = {
  submit,
};

export default connect(mapStateToProps, actionProps)(StepOne);
