import { StyleSheet, Dimensions } from 'react-native';

import { fonts, colors } from 'styles';

const widthScreen = Dimensions.get('screen').width;
const heightScreen = Dimensions.get('screen').height;

export const NetworkStyle = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.whiteShadow,
    justifyContent: 'center',
  },
  containerButton: {
    position: 'absolute',
    bottom: 15,
    left: (widthScreen * 0.5) - (169 / 2),
    width: 169,
    backgroundColor: colors.white,
    alignItems: 'center',
    padding: 20,
    borderRadius: 10,
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.whiteShadow,
    alignItems: 'center',
  },
  contentSlider: {
    alignItems: 'center',
    paddingBottom: 30,
    width: '100%',
  },
  networkContainer: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 30,
  },
  networkNumberContainer: {
    width: '40%',
    height: 91,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.orange,
    borderRadius: 30,
    borderWidth: 3,
  },
  networkNumber: {
    color: colors.orange,
    fontSize: 35,
    fontFamily: fonts.lato.bold,
  },
  networkImage: {
    width: '70%',
    height: 200,
    resizeMode: 'contain',
  },
  networkDescription: {
    fontFamily: fonts.lato.regular,
    fontSize: 14,
    width: '70%',
    textAlign: 'center',
    color: colors.greyDayColor,
  },
});
