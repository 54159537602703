import { Dimensions, StyleSheet } from 'react-native';

import { colors } from 'styles';

const widthScreen = Dimensions.get('screen').width;

export default StyleSheet.create({
  buttonContent: {
    backgroundColor: colors.white,
  },
  buttonContainer: {},
  button: {
    width: '100%',
    height: 75,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerButton: {
    position: 'absolute',
    bottom: 25,
    left: (widthScreen * 0.5) - (169 / 2),
    width: 169,
    height: 70,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    paddingBottom: 25,
    borderRadius: (widthScreen * 0.25) / 2,
    shadowRadius: 6,
    shadowOpacity: 1,
    shadowColor: '#00000029',
    shadowOffset: { width: 0, height: 3 },
  },
  imgStyle: {
    width: 94,
    height: 35,
  },
});
