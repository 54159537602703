import React from 'react';
import { View } from 'react-native';
import { reduxForm, Field } from 'redux-form';

import styles from './LoginStyle';

import Input from 'components/form/input';

const validate = (values) => {
  const errors = {};

  if (!values.email || values.email.length === 0) {
    errors.email = 'Obligatoire';
  } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email invalide';
  }

  if (!values.password || values.password.length === 0) {
    errors.password = 'Obligatoire';
  } else if (values.password.length < 6) {
    errors.password = 'Minimum 6 caractères';
  }
  return errors;
};

function LoginForm() {
  return (
    <View style={styles.form}>
      <Field
        centered
        width="100%"
        name="email"
        label="Email"
        autoCapitalize="none"
        autoCompleteType="email"
        // textContentType="emailAddress"
        keyboardType="email-address"
        component={Input}
      />
      <Field
        centered
        width="100%"
        name="password"
        label="Mot de passe"
        autoCorrect={false}
        autoCapitalize="none"
        autoCompleteType="password"
        component={Input}
      />
    </View>
  );
}

export default reduxForm({ form: 'login', validate, enableReinitialize: true })(LoginForm);
