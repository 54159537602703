import React from 'react';
import { View, TouchableOpacity, Image, Text } from 'react-native';

import { SquareButtonStyle } from './SquareButtonStyle';

export default function SquareButton({
  icon,
  width,
  style,
  titre,
  images,
  onPress,
  children,
  textStyle,
  height = 45,
}) {
  return (
    <View style={{
      ...SquareButtonStyle.container, width, height, ...style,
    }}
    >
      <TouchableOpacity
        onPress={onPress}
        style={SquareButtonStyle.button}
      >
        <View style={SquareButtonStyle.content}>
          {!!images && (
            <Image
              style={SquareButtonStyle.image}
              source={images}
            />
          )}
          {!!titre && (
            <>
              <Text style={[SquareButtonStyle.text, textStyle]}>{titre}</Text>
              {children}
            </>
          )}
          {!!icon && icon}
        </View>
      </TouchableOpacity>
    </View>
  );
}
