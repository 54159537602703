import { StyleSheet, Platform } from 'react-native';

export const VideoPlayerStyle = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: 1920 / 1080,
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    flex: 1,
    borderRadius: Platform.OS === 'web' ? 15 : 0,
    backgroundColor: 'black',
    alignItems: Platform.OS !== 'web' ? 'center' : undefined,
    justifyContent: Platform.OS !== 'web' ? 'center' : undefined,
  },
  button: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f004',
  },
  icon: {
    width: 50,
    height: 50,
    backgroundColor: 'white',
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
