import React from 'react';
import { View } from 'native-base';
import { Platform } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

export default ({ corner }) => (
  <View
    style={{
      flex: 1, zIndex: Platform.OS === 'web' ? -1 : undefined, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#f2f2f2',
    }}
  >
    <LinearGradient
      // Oblic Top Left
      colors={['#ccc', '#ffffff00']}
      style={{
        height: 30,
        width: Platform.OS === 'ios' ? 60 : 13,
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: Platform.OS === 'ios' ? (corner || 0.5) : (corner || 0.2),
      }}
      start={{ x: 0.1, y: 0 }}
      end={{ x: 0.45, y: 0.7 }}
    />
    {Platform.OS === 'android' && (
      <LinearGradient
      // Oblic Top Left
        colors={['#ccc', '#ffffff00']}
        style={{
          height: 40,
          width: 80,
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: (corner || 0.4),
        }}
        start={{ x: 0, y: 0 }}
        end={{ x: 0.4, y: 0.6 }}
      />
    )}

    <LinearGradient
      // Left
      colors={['#ccc', '#ffffff00']}
      style={{
        height: '100%',
        width: Platform.OS === 'ios' ? 10 : 10,
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: Platform.OS === 'ios' ? 0.4 : 0.3,
      }}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
    />
    <LinearGradient
      // Top
      colors={['#ccc', '#ffffff00']}
      style={{
        height: Platform.OS === 'ios' ? 10 : 10,
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0.4,
      }}
      start={{ x: 0, y: 0 }}
      end={{ x: 0, y: 1 }}
    />

    <LinearGradient
      // Bot Side
      colors={['#f2f2f200', '#ffffff']}
      style={{
        height: 20,
        width: '100%',
        position: 'absolute',
        bottom: 0,
        right: 0,
        opacity: 0.9,
      }}
      start={{ x: 1, y: 0 }}
      end={{ x: 1, y: 1 }}
    />
    <LinearGradient
      // Right Side
      colors={['#f2f2f200', '#ffffff']}
      style={{
        height: '100%',
        width: 20,
        position: 'absolute',
        bottom: 0,
        right: 0,
        opacity: 0.9,
      }}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 1 }}
    />
    <LinearGradient
      // Oblic Bottom Right
      colors={['#f2f2f200', '#ffffff']}
      style={{
        height: 25,
        width: 25,
        position: 'absolute',
        bottom: 0,
        right: 0,
        opacity: Platform.OS === 'ios' ? 0.9 : 0.4,
      }}
      start={{ x: 0.7, y: 0 }}
      end={{ x: 1, y: 1 }}
    />
  </View>
);
