import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Checkbox, Pressable, Text, View } from 'native-base';
import { useWindowDimensions, Alert, Image, SafeAreaView } from 'react-native';

import Api from 'services/api';
import { colors, TextStyles } from 'styles';
import { MeetWhite, Swap } from 'styles/svg';
import { networkAdhesion } from 'store/actions/user';
import { toastError, toastSuccess } from 'services/toasts';

import styles from './CreateNetworkAdhesionStyle';
import NetworkAdhesionForm from './CreateNetworkAdhesionForm';

import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import BackgroundLinearGradient from 'components/backgrounds/backgroundLinearGradient';
import { isMobile, isBrowser, MobileView, BrowserView, ResponsiveView } from 'components/device';

const NetworkAdhesion = ({ route, navigation: { push, goBack }, submit, networkAdhesion }) => {
  const { width, height } = useWindowDimensions();
  const [isSend, setIsSend] = useState(false);
  const [checkbox, setCheckbox] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [network, setNetwork] = useState(undefined);

  const loadUser = async () => {
    try {
      const response = await Api.networks.get(route.params.networkId);
      setNetwork(response.data);
    } catch (err) {
      console.error('Error loading user', err);
    }
  };

  const sendRequest = async (values) => {
    setIsLoading(true);
    try {
      await networkAdhesion(values.message, route.params.networkId, route.params.type);
      if (isMobile) {
        setIsSend(true);
        setTimeout(goBack, 2000);
      }
      if (isBrowser) {
        goBack();
        toastSuccess('Demande d\'Adhésion envoyée!');
      }
      setIsLoading(false);
    } catch (err) {
      console.log('Error', err.response);
      if (err.response?.status === 409) {
        if (isMobile) {
          Alert.alert('Vous avez déjà envoyé une demande', 'demande en attente');
        }
        if (isBrowser) {
          toastError('Vous avez déjà effectué une demande qui est en attente');
        }
      } else {
        if (isMobile) {
          Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
        }
        if (isBrowser) {
          toastError('Une erreur est survenue, veuillez réessayer plus tard');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  let titleText;
  if (route.params.type === 'INTERN') {
    titleText = 'Vous faites partie de ce groupement ?';
  } else if (route.params.type === 'EXTERN') {
    titleText = 'Demande d\'Adhésion';
  }

  const NetworkAdhesionContainer = () => (
    <ResponsiveView style={isBrowser ? { } : styles.container}>
      {/* MOBILE */}
      <MobileView>
        <BackgroundLinearGradient
          image={{ uri: network?.picture_url }}
          width="100%"
          height={450}
          styles={styles.header}
        >
          <Text style={styles.headerText}>Rejoindre {network?.name}</Text>
        </BackgroundLinearGradient>
        <Text style={styles.title}>{titleText}</Text>
        {
          route.params.type === 'EXTERN' ? (
            <>
              <NetworkAdhesionForm onSubmit={sendRequest} name={network?.name} />
              <Text style={styles.explainer}>Vos cordonnées seront transmises à {network?.name} lors de cette demande afin de prendre contact avec vous</Text>
              <MultiColorButton style={styles.button} loading={isLoading} text="Envoyer" onPress={() => submit('networkAdhesion')} />
            </>
          ) : (
            <>
              <Checkbox.Group style={{ marginTop: 25, marginBottom: 25 }} onChange={setCheckbox} value={checkbox}>
                <Checkbox value={'valid'}>
                  <Text style={TextStyles({ color: colors.blue }).regular14}>
                    Je confirme avoir une adhésion en cours au sein du groupe {network?.name}
                  </Text>
                </Checkbox>
              </Checkbox.Group>
              <Text style={styles.explainer}>Vos cordonnées seront transmises à {network?.name} lors de cette demande afin de prendre contact avec vous</Text>
              <MultiColorButton disabled={!checkbox.includes('valid')} style={styles.button} loading={isLoading} text="Envoyer" onPress={() => sendRequest({
                message: '',
              })} />
            </>
          )
        }
      </MobileView>
      {/* BROWSER */}
      <BrowserView>
        <View style={styles.webContainer}>
          <Image
            alt="Change me"
            source={{ uri: network?.picture_url }}
            style={{
              width: width * 0.25,
              height: width * 0.3,
              borderRadius: 15,
            }}
          />
          <View style={styles.webRightPartContainer}>
            <View style={styles.webInformationContainer}>
              <Image
                alt="Change me"
                source={{ uri: !!(network?.logo_url || network?.picture_url) ? network?.logo_url || network?.picture_url : null }}
                style={{
                  borderRadius: 15,
                  width: width * 0.05,
                  height: width * 0.05,
                }}
              />
              <View style={{ width: '60%', marginLeft: 20 }}>
                <Text style={TextStyles({ lineHeight: 18, color: colors.grey }).regular17}>Groupe</Text>
                <Text style={TextStyles().black25}>{network?.name}</Text>
              </View>
            </View>
            <Text style={TextStyles({ marginTop: 20 }).regular23}>{titleText}</Text>
            {
              route.params.type === 'EXTERN' ? (
                <>
                  <NetworkAdhesionForm type={route.params.type} name={network?.name} onSubmit={sendRequest} />
                  <Text style={TextStyles({  marginTop: 20, marginBottom: 45, width: '57%', color: colors.greyDayColor }).regular12}>
                    Vos cordonnées seront transmises à {network?.name} lors de cette demande afin de prendre contact avec vous
                  </Text>
                  <MultiColorButton loading={isLoading} style={{ width: '60%' }} text={`Adhérer à ${network?.name}`} onPress={() => submit('networkAdhesion')}  />
                </>
              ) : (
                <>
                  <Checkbox.Group style={{ marginTop: 25, marginBottom: 25 }} onChange={setCheckbox} value={checkbox}>
                    <Checkbox value={'valid'}>
                      <Text style={TextStyles({ color: colors.blue }).regular14}>
                        Je confirme avoir une adhésion en cours au sein du groupe {network?.name}
                      </Text>
                    </Checkbox>
                  </Checkbox.Group>
                  <Text style={TextStyles({  marginTop: 20, marginBottom: 45, width: '57%', color: colors.greyDayColor }).regular12}>
                    Vos cordonnées seront transmises à {network?.name} lors de cette demande afin de prendre contact avec vous
                  </Text>
                  <MultiColorButton disabled={!checkbox.includes('valid')} loading={isLoading} style={{ width: '60%' }} text={`Adhérer à ${network?.name}`} onPress={() => sendRequest({
                    message: '',
                  })} />
                </>
              )
            }
          </View>
        </View>
      </BrowserView>
    </ResponsiveView>
  );

  const NetworkAdhesionConfirmation = () => (
    <SafeAreaView style={[styles.container, { justifyContent: 'center' }]}>
      <Swap width="20%" height="20%" />
      <Text style={TextStyles().regular16}>
        Demande d'Adhésion envoyée !
      </Text>
    </SafeAreaView>
  );

  return (
    <LoadResourceLayout
      push={push}
      mobilePadding={false}
      loadResources={loadUser}
      breadCrumb={<Breadcrumb push={push} goBack={goBack} menuSelect="Othrs" />}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
      disableMenu={true}
    >
      {
        isSend && isMobile ? (
          <NetworkAdhesionConfirmation />
        ) : (
          <NetworkAdhesionContainer />
        )
      }
    </LoadResourceLayout>
  );
};

const actionProps = { networkAdhesion, submit };

export default connect(null, actionProps)(NetworkAdhesion);
