'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
  function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
    function rejected(value) { try { step(generator['throw'](value)); } catch (e) { reject(e); } }
    function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
  var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
  return g = { next: verb(0), 'throw': verb(1), 'return': verb(2) }, typeof Symbol === 'function' && (g[Symbol.iterator] = function() { return this; }), g;
  function verb(n) { return function (v) { return step([n, v]); }; }
  function step(op) {
    if (f) throw new TypeError('Generator is already executing.');
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y['return'] : op[0] ? y['throw'] || ((t = y['return']) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
      case 0: case 1: t = op; break;
      case 4: _.label++; return { value: op[1], done: false };
      case 5: _.label++; y = op[1]; op = [0]; continue;
      case 7: op = _.ops.pop(); _.trys.pop(); continue;
      default:
        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
        if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
        if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
        if (t[2]) _.ops.pop();
        _.trys.pop(); continue;
      }
      op = body.call(thisArg, _);
    } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
    if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
  }
};
exports.__esModule = true;
exports.Auth = void 0;
var index_1 = require('./index');
var Auth = /** @class */ (function (_super) {
  __extends(Auth, _super);
  function Auth() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Auth.prototype.setToken = function (token) {
    this.requester.defaults.headers.common['authorization'] = token;
  };
  Auth.prototype.getAuthCode = function () {
    return this.requester.get('/auth/auth-code');
  };
  Auth.prototype.refreshToken = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2 /*return*/, this.requester.get('/auth/refresh-token')];
      });
    });
  };
  Auth.prototype.signup = function (data) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var response;
      return __generator(this, function (_b) {
        switch (_b.label) {
        case 0: return [4 /*yield*/, this.requester.post('/auth/signup', data)];
        case 1:
          response = _b.sent();
          if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.token) {
            this.requester.defaults.headers.common['authorization'] = response.data.token;
          }
          return [2 /*return*/, response];
        }
      });
    });
  };
  Auth.prototype.signin = function (email, password) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var response;
      return __generator(this, function (_b) {
        switch (_b.label) {
        case 0: return [4 /*yield*/, this.requester.post('/auth/signin', { email: email, password: password })];
        case 1:
          response = _b.sent();
          if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.token) {
            this.requester.defaults.headers.common['authorization'] = response.data.token;
          }
          return [2 /*return*/, response];
        }
      });
    });
  };
  Auth.prototype.forgotPassword = function (email) {
    return this.requester.post('/auth/forgot-password', { email: email });
  };
  Auth.prototype.resetPasswordToken = function (token, password) {
    return this.requester.patch('/auth/reset-password/' + token, { password: password });
  };
  Auth.prototype.googleSignin = function (data) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var response, err_1;
      return __generator(this, function (_b) {
        switch (_b.label) {
        case 0:
          _b.trys.push([0, 2, , 3]);
          return [4 /*yield*/, this.requester.post('/auth/google-signin', data)];
        case 1:
          response = _b.sent();
          console.log('rawResponse', response);
          if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.token) {
            this.requester.defaults.headers.common['authorization'] = response.data.token;
          }
          return [2 /*return*/, response];
        case 2:
          err_1 = _b.sent();
          return [2 /*return*/, err_1];
        case 3: return [2 /*return*/];
        }
      });
    });
  };
  Auth.prototype.googleSignup = function (data) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var response, err_2;
      return __generator(this, function (_b) {
        switch (_b.label) {
        case 0:
          _b.trys.push([0, 2, , 3]);
          return [4 /*yield*/, this.requester.post('/auth/google-signup', data)];
        case 1:
          response = _b.sent();
          if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.token) {
            this.requester.defaults.headers.common['authorization'] = response.data.token;
          }
          return [2 /*return*/, response];
        case 2:
          err_2 = _b.sent();
          return [2 /*return*/, err_2];
        case 3: return [2 /*return*/];
        }
      });
    });
  };
  Auth.prototype.appleSignin = function (data) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var response, err_3;
      return __generator(this, function (_b) {
        switch (_b.label) {
        case 0:
          _b.trys.push([0, 2, , 3]);
          return [4 /*yield*/, this.requester.post('/auth/apple-signin', data)];
        case 1:
          response = _b.sent();
          if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.token) {
            this.requester.defaults.headers.common['authorization'] = response.data.token;
          }
          return [2 /*return*/, response];
        case 2:
          err_3 = _b.sent();
          return [2 /*return*/, err_3];
        case 3: return [2 /*return*/];
        }
      });
    });
  };
  Auth.prototype.appleSignup = function (data) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var response, err_4;
      return __generator(this, function (_b) {
        switch (_b.label) {
        case 0:
          _b.trys.push([0, 2, , 3]);
          return [4 /*yield*/, this.requester.post('/auth/apple-signup', data)];
        case 1:
          response = _b.sent();
          if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.token) {
            this.requester.defaults.headers.common['authorization'] = response.data.token;
          }
          return [2 /*return*/, response];
        case 2:
          err_4 = _b.sent();
          return [2 /*return*/, err_4];
        case 3: return [2 /*return*/];
        }
      });
    });
  };
  return Auth;
}(index_1.Component));
exports.Auth = Auth;
