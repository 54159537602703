import { View } from 'react-native';
import { Select, Icon } from 'native-base';
import React, { useState } from 'react';
import { MaterialIcons } from '@expo/vector-icons';

import { colors, fonts } from 'styles';

import PickerStyles from './PickerStyles';

import { isBrowser } from 'components/device';
import FieldWrapper from 'components/form/fieldWrapper';
import HollowBackground from 'components/backgrounds/hollowBackground';

export default function PickerField(props) {
  const {
    input: { onChange, onBlur, value: initialValue },
    label,
    items,
    labelKey,
    valueKey,
    styleContainer,
    centered = false,
    required,
  } = props;

  const [value, setValue] = useState(initialValue);

  const _items = items ? items.map((item) => ({
    label: item[labelKey],
    value: item[valueKey],
  })) : [];

  const handleChange = (value) => {
    setValue(value);
    onBlur();
    onChange(value);
  };

  return (
    <FieldWrapper {...props}>
      <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', marginBottom: isBrowser ? 10 : 0 }}>
        <View style={[PickerStyles.container, styleContainer, { width: '100%', alignItems: centered ? 'center' : 'flex-start', justifyContent: 'center' }]}>
          <HollowBackground />
          <Select
            variant="unstyled"
            minWidth={centered ? '100%' : required ? '115%' : '120%'}
            height={52}
            backgroundColor="transparent"
            placeholder={label}
            selectedValue={value}
            defaultValue={value}
            onValueChange={handleChange}
            borderStyle={undefined}
            color={colors.grey}
            borderColor="white"
            boxShadow={'none'}
            textAlign={centered ? 'center' : undefined}
            justifyContent="center"
            borderWidth={0}
            fontFamily={fonts.lato.regular}
            fontSize={14}
            isFocusVisible={false}
            paddingLeft={centered ? 10 : 5}
            // dropdownIcon={<MaterialIcons size={25} name={'keyboard-arrow-down'} style={{ marginRight: 25, color: colors.secondary }}/>}
            dropdownIcon={<MaterialIcons size={25} name={'keyboard-arrow-down'} style={{ marginRight: 0, color: '#ffffff00' }}/>}
          >
            {
              _items.map((i, index) => (
                <Select.Item key={index} label={i.label} value={i.value} />
              ))
            }
          </Select>
          {required && (
            <View
              style={{
                width: 3,
                right: 5,
                margin: 10,
                height: 13,
                borderRadius: 20,
                position: 'absolute',
                backgroundColor: colors.firebrick,
                borderColor: isBrowser ? colors.whiteShadow : 'transparent',
              }}
            />
          )}
        </View>
      </View>
    </FieldWrapper>
  );
}
