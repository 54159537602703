import React from 'react';
import { useWindowDimensions } from 'react-native';
import { View, Pressable, ChevronLeftIcon, ChevronRightIcon } from 'native-base';

const Stepper = ({ onClick, position = 'left', numberOfItem = 5, marginHorizontal = 0 }) => {
  const { width } = useWindowDimensions();

  const leftMarginLeft = (98 - (marginHorizontal / 2)) / 100;
  const rightMarginLeft = (2 + (marginHorizontal / 2)) / 100;

  return (
    <Pressable
      onPress={() => onClick()}
      style={{
        width: 40,
        zIndex: 100,
        height: '100%',
        position: 'absolute',
        justifyContent: 'center',
        marginLeft: position === 'left' ? width * rightMarginLeft : width * leftMarginLeft,
        // marginLeft: position === 'left' ? width - bp : marginLeft[numberOfItem - 1],
      }}
    >{({
        isHovered,
        isFocused,
        isPressed
      }) => (
        isHovered ? (
          position === 'left' ? (
            <ChevronLeftIcon style={{ width: 30, height: 30, marginLeft: -15 }} color="#D0D0D0" />
          ) : (
            <ChevronRightIcon style={{ width: 30, height: 30, marginLeft: -10 }} color="#D0D0D0" />
          )
        ) : (
          <View
            style={{
              borderColor: '#707070',
              backgroundColor: '#D0D0D0',
              borderRadius: 3,
              height: 60,
              width: 6
            }}
          />
        )
      )}
    </Pressable>
  );
};

export default Stepper;
