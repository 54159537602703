import React from 'react';
import { Animated } from 'react-native';
import MenuButton from '../buttons/menuButton';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';

const OPACITY = 0.95;

export default (props) => {
  const opacity = new Animated.Value(OPACITY);

  const showButton = () => {
    Animated.timing(opacity, { toValue: OPACITY, duration: 150, useNativeDriver: true, delay: 300 }).start();
    if (props.onScrollEndDrag) props.onScrollEndDrag();
  };

  const hideButton = () => {
    Animated.timing(opacity, { toValue: 0, duration: 50, useNativeDriver: true }).start();
  };

  return (
    <>
      <KeyboardAwareScrollView
        {...props}
        onScrollBeginDrag={hideButton}
        onScrollEndDrag={showButton}
        showsVerticalScrollIndicator={false}
      />
      <MenuButton opacity={opacity} />
    </>
  );
};
