import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import Account from './account';
import Network from './network';
import Event from './event';
import User from './user';
import Recommendation from './recommendation';
import Media from './media';
import Menu from './menu';
import ParentNetwork from './parent_network';

export default combineReducers({
  Account,
  Network,
  Recommendation,
  Event,
  User,
  Media,
  Menu,
  ParentNetwork,
  form,
});
