import React, { useState } from 'react';
import * as Linking from 'expo-linking';
import { View, TouchableOpacity, Share, Platform, Alert } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { colors } from 'styles';
import TextSection from '../textSection';
import styles from './ShareButtonsStyles';
import { Fb, In, Twitter, Upload } from 'styles/svg';
import HollowBackground from '../backgrounds/hollowBackground';
import { isBrowser } from '../device';

export default ({ title = true, url }) => {
  const [index, setIndex] = useState(0);
  const [ showActions, setShowActions ] = useState(false);

  const onPress = [
    () => Linking.openURL('https://www.facebook.com'),
    () => Linking.openURL('https://othr.com'),
    () => Linking.openURL('https://www.linkedin.com/shareArticle'), // Did not find how to provide
    () => Linking.openURL(`https://twitter.com/intent/tweet?text=${url}`),
    () => Share.share({ message: url }),
    () => {
      if (isBrowser) {
        window.scrollTo(0, 0);
        setShowActions(true);
      }
    },
  ];

  return (
    <View style={{ height: title ? 120 : Platform.OS !== 'web' ? 45 : 40, width: '100%', alignItems: 'center' }}>
      {title ? <TextSection titleSection={'Partager'} noBar={true}/> : null }
      <View style={styles.containerEventValidation}>
        <TouchableOpacity
          style={[styles.imgStyle, { backgroundColor: colors.blueFacebook }, Platform.OS !== 'web' ? {} : { width: 40, height: 40, marginHorizontal: 4 }]}
          onPress={onPress[0]}
        >
          {Platform.OS !== 'web' ? <Fb width={25} height={25} styles={styles.svgStyle} /> : <img src={Fb} width={22} height={22} styles={styles.svgStyle} />}
        </TouchableOpacity>
        {/*<TouchableOpacity*/}
        {/*  style={[styles.imgStyle, { backgroundColor: colors.white }]}*/}
        {/*  onPress={onPress[1]}*/}
        {/*>*/}
        {/*  {Platform.OS !== 'web' ? <User width={25} height={25} styles={styles.svgStyle} /> : <img src={User} width={25} height={25} styles={styles.svgStyle} />}*/}
        {/*</TouchableOpacity>*/}
        <TouchableOpacity
          style={[styles.imgStyle, { backgroundColor: colors.blueLinkedin }, Platform.OS !== 'web' ? {} : { width: 40, height: 40, marginHorizontal: 4 }]}
          onPress={onPress[2]}
        >
          {Platform.OS !== 'web' ? <In width={25} height={25} styles={styles.svgStyle} /> : <img src={In} width={22} height={22} styles={styles.svgStyle} />}
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.imgStyle, { backgroundColor: colors.blueTwitter }, Platform.OS !== 'web' ? {} : { width: 40, height: 40, marginHorizontal: 4 }]}
          onPress={onPress[3]}
        >
          {Platform.OS !== 'web' ? <Twitter width={25} height={25} styles={styles.svgStyle} /> : <img src={Twitter} width={22} height={22} styles={styles.svgStyle} />}
        </TouchableOpacity>
        {
          Platform.OS !== 'web' ? (
            <TouchableOpacity
              style={[styles.imgStyle, { backgroundColor: colors.white, borderRadius: 10, overflow: 'hidden' }]}
              onPress={onPress[4]}
            >
              <HollowBackground/>
              {Platform.OS !== 'web' ? <Upload width={25} height={25} styles={styles.svgStyle} /> : <img src={Upload} width={25} height={25} styles={styles.svgStyle} />}
            </TouchableOpacity>
          ) : null
        }
        <TouchableOpacity
          style={[styles.imgStyle, { backgroundColor: colors.white, borderRadius: 10, overflow: 'hidden' }, Platform.OS !== 'web' ? {} : { width: 40, height: 40, marginHorizontal: 4 }]}
          onPress={onPress[5]}
        >
          <HollowBackground/>
          <Entypo name="dots-three-horizontal" size={Platform.OS !== 'web' ? 24 : 22} color={colors.grey} />
        </TouchableOpacity>
      </View>
    </View>
  );
};
