import React from 'react';
import { Dimensions } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import Login from '../login';
import SplashScreens from '../splash';
import StepTwo from '../register/stepTwo';
import StepOne from '../register/stepOne';
import ResetPassword from '../resetPassword';
import ResetPasswordSuccess from '../resetPassword/ResetPasswordSuccess';
import StepThree from '../register/stepThree';
import ForgotPassword from '../forgotPassword';
import EventsList from 'screens/events/eventsList';
import EventDetails from 'screens/events/eventDetails';
import EventBooking from 'screens/events/eventBooking';
import GroupDetails from 'screens/networks/groupDetails';
import ContributionPayment from 'screens/payments/Contribution';
import UserPublicDetails from 'screens/users/userDetails/public';
import SubscriptionCancel from 'screens/payments/SubscriptionCancel';
import SubscriptionSuccess from 'screens/payments/SubscriptionSuccess';
import VideoDetails from 'screens/media/mediumDetails/VideoDetails';
import ArticleDetails from 'screens/media/mediumDetails/ArticleDetails';
import PodcastDetails from 'screens/media/mediumDetails/PodcastDetails';

import { isBrowser, isMobile } from 'components/device';

const { width } = Dimensions.get('screen');

const Stack = createStackNavigator();
const Tabs = createMaterialTopTabNavigator();

function AuthRoutes() {
  // Avoid perf warning, inline function would cause re-render of the component
  const PublicEventsList = (props) => <EventsList {...props} publicPage={true} />;
  const PublicEventDetails = (props) => <EventDetails {...props} publicPage={true} />;
  const PublicEventBooking = (props) => <EventBooking {...props} publicPage={true} />;
  const PublicGroupDetails = (props) => <GroupDetails {...props} publicPage={true} />;
  const PublicArticleDetails = (props) => <ArticleDetails {...props} publicPage={true} />;
  const PublicPodcastDetails = (props) => <PodcastDetails {...props} publicPage={true} />;
  const PublicVideoDetails = (props) => <VideoDetails {...props} publicPage={true} />;
  const PublicContribution = (props) => <ContributionPayment {...props} publicPage={true} />;

  return (
    <Stack.Navigator initialRouteName={isMobile ? 'Splash' : 'Login' } screenOptions={{ headerShown: false }}>
      {/* MOBILE SPLASH SCREENS */}
      {isMobile && <Stack.Screen name="Splash" component={SplashScreens} />}
      <Stack.Screen name="StepTwo" component={StepTwo} />
      <Stack.Screen name="StepOne" component={StepOne} />
      <Stack.Screen name="StepThree" component={StepThree} />
      <Stack.Screen name="Login" component={Login} />
      <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
      <Stack.Screen name="ResetPassword" component={ResetPassword} />
      <Stack.Screen name="ResetPasswordSuccess" component={ResetPasswordSuccess} />
      {isBrowser && <Stack.Screen name="EventDetails" component={PublicEventDetails} />}
      {isBrowser && <Stack.Screen name="EventBooking" component={PublicEventBooking} />}
      {isBrowser && <Stack.Screen name="EventsList" component={PublicEventsList} />}
      {isBrowser && <Stack.Screen name="GroupDetails" component={PublicGroupDetails} />}
      {isBrowser && <Stack.Screen name="UserDetails" component={UserPublicDetails} />}
      {isBrowser && <Stack.Screen name="UserSwap" component={UserPublicDetails} />}
      {isBrowser && <Stack.Screen name="SubscriptionSuccess" component={SubscriptionSuccess} />}
      {isBrowser && <Stack.Screen name="SubscriptionCancel" component={SubscriptionCancel} />}
      {isBrowser && <Stack.Screen name="ArticleDetails" component={PublicArticleDetails} />}
      {isBrowser && <Stack.Screen name="PodcastDetails" component={PublicPodcastDetails} />}
      {isBrowser && <Stack.Screen name="VideoDetails" component={PublicVideoDetails} />}
      {isBrowser && <Stack.Screen name="ContributionPayment" component={PublicContribution} />}
    </Stack.Navigator>
  );
}

export default AuthRoutes;
