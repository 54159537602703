import JwtDecode from 'jwt-decode';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as ExpoNotifications from 'expo-notifications';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Home from '../screens/home';
import Others from '../screens/users';
import Event from '../screens/events';
import Account from '../screens/account';
import Network from '../screens/networks';
import Tickets from '../screens/events/tickets';
import AuthRoutes from '../screens/auth/routes';
import UserSwap from '../screens/users/userSwap';
import MyEvents from '../screens/events/myEvents';
import Relations from '../screens/users/relations';
import Settings from '../screens/account/settings';
import ContactUs from '../screens/account/contactUs';
import EditEmail from '../screens/account/settings/editEmail';
import EditPassword from '../screens/account/settings/editPassword';
import Notifications from '../screens/account/notifications';

import CreateSwapRequest from '../screens/users/createSwapRequest';
import SwapRequests from '../screens/users/swapResquests';
import UserDetails from '../screens/users/userDetails';
import AddNetwork from '../screens/networks/addGroup';
import CreateEvent from '../screens/events/createEvent';
import EventDetails from '../screens/events/eventDetails';
import ValidationSwapRequest from '../screens/users/validationSwapRequest';

import Articles from '../screens/media/Articles';
import Podcasts from '../screens/media/Podcasts';
import Videos from '../screens/media/Videos';

import AddArticle from '../screens/media/addMedium/AddArticle';
import AddPodcast from '../screens/media/addMedium/AddPodcast';
import AddVideo from '../screens/media/addMedium/AddVideo';

import MyArticles from '../screens/media/myMedia/MyArticles';
import MyPodcasts from '../screens/media/myMedia/MyPodcasts';
import MyVideos from '../screens/media/myMedia/MyVideos';

import EditArticle from '../screens/media/addMedium/EditArticle';
import EditPodcast from '../screens/media/addMedium/EditPodcast';
import EditVideo from '../screens/media/addMedium/EditVideo';

import ArticleDetails from '../screens/media/mediumDetails/ArticleDetails';
import PodcastDetails from '../screens/media/mediumDetails/PodcastDetails';
import VideoDetails from '../screens/media/mediumDetails/VideoDetails';

import EventBooking from '../screens/events/eventBooking';
import AllNetworks from '../screens/networks/allNetworks';
import ConnexionUsers from '../screens/users/connexionUser';
import ContractSigned from '../screens/recommendations/contractSigned';
import Recommendations from '../screens/recommendations';
import GroupDetails from '../screens/networks/groupDetails';
import NetworkDetails from '../screens/networks/networkDetails';
import EditEvent from '../screens/events/createEvent/EditEvent';
import ValidationEvent from '../screens/events/validationEvent';
import NetworkInvitation from '../screens/networks/inviteNetwork';
import ContactNetwork from '../screens/networks/contactNetwork';
import AddRecommendation from '../screens/recommendations/addRecommendation';
import TicketDetails from '../screens/events/tickets/ticketDetails';
import EditGroup from '../screens/networks/addGroup/EditGroup';
import ContributionPayment from '../screens/payments/Contribution';
import RecommendationDetails from '../screens/recommendations/recommendationDetails';
import RecommendationResponse from '../screens/recommendations/recommendationResponse';
import ValidationEventBooking from '../screens/events/validationEventBooking';
import ValidationContractSigned from '../screens/recommendations/validationContractSigned';
import ValidationAddRecommendation from '../screens/recommendations/validationAddRecommendation';

import Report from '../screens/moderation/report';

import { loadToken as LoadToken, refreshToken as RefreshToken } from 'store/actions/account';
import { hideMenu } from 'store/actions/menu';

import { isMobile } from 'components/device';
import NetworkAdhesion from '../screens/networks/createNetworkAdhesion';

const Stack = createNativeStackNavigator();

if (Platform.OS !== 'web') {
  ExpoNotifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });
}

const config = {
  screens: {
    // HOME
    Home: 'home',
    // NETWORK
    Network: 'networks',
    AllNetworks: 'networks/all',
    AddNetwork: 'networks/add',
    EditGroup: 'networks/edit',
    NetworkInvitation: 'networks/invite',
    CreateNetworkAdhesion: 'networks/adhesion',
    NetworkDetails: 'networks/:networkId',
    GroupDetails: 'groups/:networkId',
    ContactNetwork: 'networks/:networkId/contact',
    // EVENTS
    Event: 'events',
    MyEvents: 'events/mine',
    EditEvent: 'events/edit',
    EventBooking: 'events/booking',
    CreateEvent: 'events/create',
    EventDetails: 'events/:eventId',
    EventsList: 'events/network/:networkId',
    // TICKETS
    Tickets: 'tickets',
    TicketDetails: 'tickets/event',
    // USERS
    Others: 'users',
    Relations: 'users/relations',
    UserDetails: 'users/:userId',

    Recommendations: 'recommendations',
    AddRecommendation: 'recommendations/add',
    RecommendationResponse: 'recommendations/response',
    RecommendationDetails: 'recommendations/:recommendationId',
    ContractSigned: 'recommendations/contract',
    ValidationContractSigned: 'recommendations/contract/signed',
    CreateSwapRequest: 'users/swap-requests/create',
    SwapRequests: 'users/swap-requests',
    ConnexionUsers: 'users/connexion',
    UserSwap: 'users/:userId/swap',
    ValidationSwapRequest: 'users/swap-requests/validation',

    Account: 'account',
    ContactUs: 'contact-us',
    Settings: 'settings',
    EditEmail: 'settings/edit-email',
    EditPassword: 'settings/edit-password',
    Notifications: 'notifications',

    // SUBSCRIPTION
    SubscriptionCancel: 'subscription-cancel',
    SubscriptionSuccess: 'subscription-success',
    ContributionPayment: 'contribution/payment',

    // AUTH
    Login: 'login',
    StepTwo: 'register',
    StepOne: 'register/name',
    StepThree: 'register/zone',
    Subscription: 'subscription',
    ResetPassword: 'reset-password',
    ResetPasswordSuccess: 'reset-password-success',
    ForgotPassword: 'forgot-password',

    // MEDIA
    Articles: 'articles',
    MyArticles: 'articles/me',
    AddArticle: 'articles/add',
    ArticleDetails: 'articles/:mediumId',
    EditArticle: 'articles/:mediumId/edit',

    Videos: 'videos',
    MyVideos: 'videos/me',
    AddVideo: 'videos/add',
    VideoDetails: 'videos/:mediumId',
    EditVideo: 'videos/:mediumId/edit',

    Podcasts: 'podcasts',
    MyPodcasts: 'podcasts/me',
    AddPodcast: 'podcasts/add',
    PodcastDetails: 'podcasts/:mediumId',
    EditPodcast: 'podcasts/:mediumId/edit',

    Report: 'moderation/report',
    NotFound: '*',
  },
};

const getLinking = (dispatch) => ({
  config,
  prefixes: isMobile && [Linking.createURL('/'), 'https://api.othr.pro/application'],
  async getInitialURL() {
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();

    if (url !== null) {
      return url;
    }
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }) => {
      console.log('onReceiveURL', url);
      dispatch(hideMenu());
      listener(url);
    };

    // Listen to incoming links from deep linking
    const linkingSubscription = Linking.addEventListener('url', onReceiveURL);

    // Listen to expo push notifications while app is open
    const subscription = ExpoNotifications.addNotificationResponseReceivedListener(
      response => {
        const url = response?.notification?.request?.content?.data?.link;
        listener(url);
      }
    );

    return () => {
      // Clean up the event listeners
      linkingSubscription.remove();
      subscription.remove();
    };
  },
});

const Routes = ({ setAudioUrl }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.Account.token);

  const [ authenticated, setAuthenticated ] = useState(false);

  const isTokenValid = () => {
    if (token) {
      try {
        const { exp } = JwtDecode(token);
        const valid = (exp * 1000) > new Date().getTime();
        return valid;
      } catch (err) {
        console.log('verify token failed:', err);
        return false;
      }
    }
    return false;
  };

  const lastNotificationResponse = ExpoNotifications.useLastNotificationResponse();

  const handleTokenChange = async () => {
    const tokenValid = isTokenValid();
    setAuthenticated(tokenValid);
    if (tokenValid) {
      dispatch(LoadToken(token));
      dispatch(RefreshToken());
    }
  };

  useEffect(() => {
    handleTokenChange();
  }, [token]);

  useEffect(() => {
    const link = lastNotificationResponse?.notification?.request?.content?.data?.link?.split('/application/')?.[1];
    if (link
      && lastNotificationResponse.actionIdentifier === ExpoNotifications.DEFAULT_ACTION_IDENTIFIER
    ) {
      Linking.openURL(link);
    }
  }, [lastNotificationResponse]);

  const linking = getLinking(dispatch);

  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: (options, route) =>
          'Othr',
      }}
    >
      {!authenticated ? (
        <AuthRoutes />
      ) : (
        <Stack.Navigator initialRouteName="Home" screenOptions={{ headerShown: false, animation: 'none' }}>
          {/* /-------- Home --------/ */}
          <Stack.Screen name="Home" component={Home} />

          {/* /-------- Networks --------/ */}
          <Stack.Screen name="Network" component={Network} />
          <Stack.Screen name="AddNetwork" component={AddNetwork} />
          <Stack.Screen name="EditGroup" component={EditGroup} />
          <Stack.Screen name="AllNetworks" component={AllNetworks} />
          <Stack.Screen name="NetworkDetails" component={NetworkDetails} />
          <Stack.Screen name="GroupDetails" component={GroupDetails} />
          <Stack.Screen name="ContactNetwork" component={ContactNetwork} />
          <Stack.Screen name="NetworkInvitation" component={NetworkInvitation} />
          <Stack.Screen name="CreateNetworkAdhesion" component={NetworkAdhesion} />

          {/* /-------- Events --------/ */}
          <Stack.Screen name="Event" component={Event} />
          <Stack.Screen name="MyEvents" component={MyEvents} />
          <Stack.Screen name="EditEvent" component={EditEvent} />
          <Stack.Screen name="CreateEvent" component={CreateEvent} />
          <Stack.Screen name="EventBooking" component={EventBooking} />
          <Stack.Screen name="EventDetails" component={EventDetails} />
          <Stack.Screen name="ValidationEvent" component={ValidationEvent} />
          <Stack.Screen name="ValidationEventBooking" component={ValidationEventBooking} />

          {/* /-------- Tickets --------/ */}
          <Stack.Screen name="Tickets" component={Tickets} />
          <Stack.Screen name="TicketDetails" component={TicketDetails} />

          {/* /-------- Othr --------/ */}
          <Stack.Screen name="Others" component={Others} />
          <Stack.Screen name="UserSwap" component={UserSwap} />
          <Stack.Screen name="Relations" component={Relations} />
          <Stack.Screen name="UserDetails" component={UserDetails} />
          <Stack.Screen name="SwapRequests" component={SwapRequests} />
          <Stack.Screen name="ConnexionUsers" component={ConnexionUsers} />
          <Stack.Screen name="ContractSigned" component={ContractSigned} />
          <Stack.Screen name="Recommendations" component={Recommendations} />
          <Stack.Screen name="CreateSwapRequest" component={CreateSwapRequest} />
          <Stack.Screen name="AddRecommendation" component={AddRecommendation} />
          <Stack.Screen name="ValidationSwapRequest" component={ValidationSwapRequest} />
          <Stack.Screen name="RecommendationDetails" component={RecommendationDetails} />
          <Stack.Screen name="RecommendationResponse" component={RecommendationResponse} />
          <Stack.Screen name="ValidationContractSigned" component={ValidationContractSigned} />
          <Stack.Screen name="ValidationAddRecommendation" component={ValidationAddRecommendation} />

          {/* /-------- Account --------/ */}
          <Stack.Screen name="Account" component={Account} />
          <Stack.Screen name="Settings" component={Settings} />
          <Stack.Screen name="ContactUs" component={ContactUs} />
          <Stack.Screen name="EditEmail" component={EditEmail} />
          <Stack.Screen name="EditPassword" component={EditPassword} />
          <Stack.Screen name="Notifications" component={Notifications} />

          {/* /-------- Articles --------/ */}
          <Stack.Screen name="Articles" component={Articles} />
          <Stack.Screen name="MyArticles" component={MyArticles} />
          <Stack.Screen name="AddArticle" component={AddArticle} />
          <Stack.Screen name="EditArticle" component={EditArticle} />
          <Stack.Screen name="ArticleDetails" component={ArticleDetails} />

          {/* /-------- Podcasts --------/ */}
          <Stack.Screen name="Podcasts" component={Podcasts} />
          <Stack.Screen name="MyPodcasts" component={MyPodcasts} />
          <Stack.Screen name="AddPodcast" component={AddPodcast} />
          <Stack.Screen name="EditPodcast" component={EditPodcast} />
          <Stack.Screen name="PodcastDetails" component={PodcastDetails} />

          {/* /-------- Videos --------/ */}
          <Stack.Screen name="Videos" component={Videos} />
          <Stack.Screen name="MyVideos" component={MyVideos} />
          <Stack.Screen name="AddVideo" component={AddVideo} />
          <Stack.Screen name="EditVideo" component={EditVideo} />
          <Stack.Screen name="VideoDetails" component={VideoDetails} />

          {/* /-------- Moderation --------/ */}
          <Stack.Screen name="Report" component={Report} />

          {/* /-------- PAYMENTS --------/ */}
          <Stack.Screen name="ContributionPayment" component={ContributionPayment} />

          {/* <Stack.Screen name="NotFound" component={NotFound} /> */}
        </Stack.Navigator>
      )
      }
    </NavigationContainer>
  );
};

export default Routes;
