export const colors = {
  primary: '#2A3F58',
  secondary: '#aaaaaa',
  white: '#fff',
  green: '#28CB80',
  grey: '#3E3E3E',
  liteGrey: '#E2E2E2',
  grayOpacity30: '#6A798B',
  blue: '#008AD1',
  red: '#FF2F53',
  yellow: '#9E9200',
  sienna: '#a0522d',
  firebrick: '#b22222',
  palevioletred: '#7B444B',
  violet: '#8C60BF',
  whiteShadow: '#F2F2F2',
  blueFacebook: '#4B69B0',
  blueLinkedin: '#0678B6',
  blueTwitter: '#37B1E1',
  grayTitle: '#717171',
  borderCustomer: '#70707080',
  yellowDark: '#D3C952',
  burgundy: '#CD004B',
  bar: '#D0D0D0',
  orange: '#F78B14',
  orangeBrown: '#DBA246',
  greyLight: '#D0D0D0',
  greyDark: '#4D4D4D',
  greyDayColor: '#A3A3A3',
  greyPodcast: '#7E7D7D',
  greyMedium: '#7E7D7D',
  greyMedium2: '#5D5D5D',
  black: '#141414',
};
