import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Animated, Modal, TouchableOpacity } from 'react-native';

import Menu from 'components/menu';
import Logo from 'components/logo';
import styles from './MenuButtonStyle';
import { showMenu, hideMenu } from 'store/actions/menu';
import { GetEssentialUser } from 'store/actions/account';
import { AudioPlayerContext } from '../../audioPlayer/AudioPlayerContext';

function MenuButton({ opacity, GetEssentialUser, modalVisible, showMenu, hideMenu }) {
  const { status: playerStatus } = useContext(AudioPlayerContext);

  const handlePress = () => {
    GetEssentialUser();
    showMenu();
  };

  return (
    <>
      <Animated.View style={{ opacity, ...styles.containerButton }}>
        <TouchableOpacity onPress={handlePress}>
          <Logo width={94} height={23} />
        </TouchableOpacity>
      </Animated.View>
      <Modal
        animationType="slide"
        transparent={false}
        visible={modalVisible}
        onRequestClose={hideMenu}
      >
        <Menu onPress={hideMenu} />
      </Modal>
      {
        /*
        playerStatus?.isPlaying && (
          // playing gif
        )
         */
      }
    </>
  );
}

export default connect((state) => ({ modalVisible: state.Menu.showMenu }), { GetEssentialUser, showMenu, hideMenu })(MenuButton);
