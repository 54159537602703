export default function Network(
  state = {
    networks: null,
    moreSwaaprs: [],
    moreEvents: [],
    moreMembers: [],
    networkTypes: null,
    homeNetworks: null,
  },
  action,
) {
  switch (action.type) {
  case 'LOAD_NETWORKS':
    return {
      ...state,
      networks: action.networks,
    };
  case 'LOAD_NETWORK':
    return {
      ...state,
      networks: state.networks ? [ ...state.networks.filter((e) => e.id !== action.network.id), action.network ] : [ action.network ],
    };
  case 'REMOVE_NETWORK':
    return {
      ...state,
      network: null,
    };
  case 'LOAD_MORE_SWAAPRS':
    return {
      ...state,
      moreSwaaprs: action.moreSwaaprs,
    };
  case 'LOAD_MORE_EVENTS':
    return {
      ...state,
      moreEvents: action.moreEvents,
    };
  case 'LOAD_MORE_MEMBERS':
    return {
      ...state,
      moreMembers: action.moreMembers,
    };
  case 'LOAD_NETWORK_TYPES':
    return {
      ...state,
      networkTypes: action.networkTypes,
    };
  case 'LOAD_HOME_NETWORKS':
    return {
      ...state,
      homeNetworks: action.homeNetworks,
    };
  case 'HEADER_NETWORKS':
    return {
      ...state,
      networksHeader: action.networksHeader,
    };
  default:
    return state;
  }
}
