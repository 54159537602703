import React, { useEffect } from 'react';
import { Image, Text } from 'native-base';
import { Platform, SafeAreaView } from 'react-native';

import { fonts } from 'styles';
import { IconCheck } from 'styles/svg';
import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';

const ValidationAddRecommendation = ({ navigation: { push , goBack } }) => {
  useEffect(() => {
    setTimeout(() => {
      push('Recommendations');
    }, 2000);
  }, []);
  return (
    <BasicLayout
      push={push}
      breadCrumb={<Breadcrumb push={push} goBack={goBack} menuSelect="Othrs"/>}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
    >
      <SafeAreaView
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {
          Platform.OS !== 'web' ? (
            <IconCheck width="20%" height="20%"/>
          ) : (
            <Image alt="Change me" source={IconCheck} style={{ width: 70, height: 50 }}/>
          )
        }
        <Text style={{
          fontSize: 16,
          marginTop: 20,
          fontFamily: fonts.lato.regular,
        }}>
        Reco envoyé !
        </Text>
      </SafeAreaView>
    </BasicLayout>);
};

export default ValidationAddRecommendation;
