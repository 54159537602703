import { Alert } from 'react-native';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';

import Api from 'services/api';
import { GetUser } from 'store/actions/account';
import { pushToUser } from 'services/navigationPush';
import { toastSuccess, toastError } from 'services/toasts';

import AccountReduxForm from './AccountForm';
import { AccountStyles } from './AccountStyles';

import { isBrowser, isMobile } from 'components/device';
import MultiColorButton from 'components/buttons/multiColorButton';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';

function Account({ navigation, GetUser, user }) {
  const [updateLoading, setUpdateLoading] = useState(false);

  const loadUser = async () => {
    try {
      await GetUser();
    } catch (err) {
      console.log('Error while getting user', err);
    }
  };

  const updateAccount = async (values) => {
    try {
      setUpdateLoading(true);
      if (!values.activities) values.activities = [];
      if (!values.arugments) values.arugments = [];
      if (!values.services) values.services = [];
      if (!values.anecdotes) values.anecdotes = [];
      await Api.profile.update(values);
      if (isMobile) {
        Alert.alert(
          'Succès !',
          'Vos changements ont bien été enregistrés', [{
            text: 'Ok',
            onPress: () => {
              if (!user.picture_url) {
                navigation.reset({
                  index: 0,
                  routes: [{ name: 'Home' }],
                });
              } else {
                navigation.goBack();
              }
            },
          }],
        );
      }
      if (isBrowser) {
        if (!user.picture_url) {
          navigation.push('Home');
        } else {
          pushToUser(navigation.push, user);
        }
        toastSuccess('Vos changements ont bien été enregistrés');
      }
    } catch (err) {
      console.error('Error updating account', err);
      if (isMobile) {
        Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
      }
      if (isBrowser) {
        toastError('Une erreur est survenue');
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  const getInitialValues = ({ iban, surname, video_url, address, color, description, email_contact, firstname, lastname, picture_url, logo_url, phone, job, sphere, services, arguments: args, website, company, activities, anecdotes }) => ({
    phone,
    color,
    website,
    company,
    address,
    logo_url,
    lastname,
    firstname,
    video_url,
    job,
    surname,
    description,
    picture_url,
    email_contact,
    // sphere: sphere,
    sphere: sphere?.id?.toString(),
    anecdotes,
    activities,
    arguments: args,
    services,
    iban,
  });
  console.log('initialValues', getInitialValues(user)?.sphere);

  return (
    <LoadResourceLayout push={navigation.push} loadResources={loadUser} disableMenu disableGoBack={!user?.picture_url} title={'Profil'} mobilePadding={false}>
      <KeyboardAwareScrollView
        contentContainerStyle={[AccountStyles.container, isBrowser && AccountStyles.webContainer]}
        showsVerticalScrollIndicator={false}
      >
        <AccountReduxForm
          onSubmit={updateAccount}
          initialValues={getInitialValues(user)}
          isCompleted={!!user.picture_url}
          Button={({ onClick }) => (
            <MultiColorButton
              onPress={onClick}
              loading={updateLoading}
              style={AccountStyles.submitButton}
              text={isMobile ? 'Finaliser' : 'Mettre à jour mon profil'}
            />
          )}
        />
      </KeyboardAwareScrollView>
    </LoadResourceLayout>
  );
}

const mapStateToProps = (state) => ({
  user: state.Account.currentUser,
});

const mapDispatchToProps = {
  submit,
  GetUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
