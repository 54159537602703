import { submit } from 'redux-form';
import { connect } from 'react-redux';
import * as WebBrowser from 'expo-web-browser';
import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Alert, Platform } from 'react-native';

import styles from './LoginStyle';
import LoginForm from './LoginForm';

import { toast } from 'react-toastify';
import { Logo, Icon } from 'styles/svg';
import { toastError, toastSuccess } from 'services/toasts';
import { Login as login, AppleLogin, GoogleLogin } from 'store/actions/account';

import Svg from 'components/svg';
import ExternLogin from './ExternLogin';
import TextSection from 'components/textSection';
import BombButton from 'components/buttons/bombButton';
import { isTablet, isMobile } from 'react-device-detect';
import { isBrowser, BrowserView } from 'components/device';
import LinearBackground from 'components/backgrounds/linearBackground';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';
import { version } from 'config';
import MultiColorButton from '../../../components/buttons/multiColorButton';

function Login({
  submit, login, AppleLogin, GoogleLogin, navigation: { navigate, push }, route,
}) {
  const [ loading, setLoading ] = useState(false);
  const [error, setError] = useState('');

  const actionLogin = async (values) => {
    setLoading(true);
    if (isBrowser) {
      try {
        await toast.promise(async () => {
          try {
            const { isSub, id, token } = await login(values);
            if (!isSub) {
              navigate('StepThree', { userId: id, token });
            }
            setLoading(false);
            return Promise.resolve();
          } catch (err) {
            setLoading(false);
            return Promise.reject(err);
          }
        }, {
          loading: 'Connexion...',
          pending: 'Connexion...',
          success: 'Connexion réussie',
          error: {
            render({ data }) {
              return data?.response?.data?.message || 'Email ou mot de passe incorrect';
            },
          },
        });
      } catch (err) {
        setLoading(false);
      }
    } else {
      try {
        const { isSub, id, token } = await login(values);
        setLoading(false);
        if (!isSub) {
          Alert.alert(
            'Souscription',
            'Vous n\'êtes pas abonné au service Othr', [
              {
                text: 'Ok',
                onPress: () => {
                  if (Platform.OS === 'ios') {
                    navigate('StepThree', { userId: id, token });
                  }
                },
              },
            ],
          );
        }
      } catch (err) {
        setLoading(false);
        setError('Email ou mot de passe incorrect');
        setTimeout(() => {
          setError('');
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (isBrowser && route.params?.redirect) {
      if (route.params?.redirect === 'success') {
        toastSuccess('Votre compte a bien été créé, vous pouvez maintenant vous connecter');
      } else if (route.params?.redirect === 'error') {
        toastError('Le paiement de votre abonnement a échoué, veuillez vous connecter afin de finaliser votre abonnement');
      }
    }
    const appleLogin = async (code) => {
      await toast.promise(async () => {
        try {
          const { isSub, id, token } = await AppleLogin({ authorizationCode: code });
          if (!isSub) {
            push('StepThree', { userId: id, token });
          }
          return Promise.resolve();
        } catch (err) {
          return Promise.reject(err);
        }
      }, {
        loading: 'Connexion...',
        pending: 'Connexion...',
        success: 'Connexion réussie',
        error: 'Une erreur est survenue. Veuillez réessayer',
      });
    };
    if (isBrowser) {
      const params = window.location.href.split('?');
      if (params.length > 1) {
        const code = params[1].split('=');
        if (code[0] === 'code' && code.length > 1) {
          appleLogin(code[1]);
        }
      }
    }
  }, []);

  const handleExternalLogin = async ({ registerType, credential }) => {
    try {
      let result;
      if (registerType === 'apple') {
        result = await AppleLogin({ authorizationCode: credential.authorizationCode, origin: 'APP' });
      } else  if (registerType === 'google') {
        result = await GoogleLogin({ credential: isBrowser ? credential : credential.serverAuthCode });
      }
      const { isSub, id, token } = result;
      if (!isSub) {
        if (Platform.OS === 'web') {
          toastSuccess('Vous n\'êtes pas abonné au service Othr, vous allez être redirigé vers la page de souscription');
          navigate('StepThree', { userId: id, token });
        } else {
          Alert.alert(
            'Souscription',
            Platform.OS !== 'android' ?
              'Vous n\'êtes pas abonné au service Othr' :
              'Vous n\'êtes pas abonné au service Othr. Veuillez souscrire sur l\'application web', [
              {
                text: 'Ok',
                onPress: () => {
                  if (Platform.OS !== 'android') navigate('StepThree', { userId: id, token });
                },
              },
            ],
          );
        }
      }
    } catch (err) {
      if (Platform.OS === 'web') {
        toastError(`Aucun compte ${registerType} correspondant trouvé`);
      } else {
        Alert.alert(
          'Erreur de connexion',
          `Aucun compte ${registerType} correspondant trouvé`,
        );
      }
    }
  };

  if (!isTablet && isMobile) {
    return (
      <View style={{ flex: 1 }}>
        <LinearBackground />
        <KeyboardAwareScrollView style={{ flex: 1 }}>
          <View style={[styles.container, { justifyContent: 'flex-start' }]}>
            {error.length > 0 && (
              <View style={styles.notificationContent}>
                <Text style={styles.notificationMessage}>{error}</Text>
              </View>
            )}
            <View style={{
              height: '100%',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
              ...isBrowser && {
                width: '70%',
                paddingTop: 50,
                borderRadius: 30,
                paddingBottom: 50,
              }
            }}>
              <MultiColorButton
                width={120}
                height={120}
                svgWidth={'110%'}
                onPress={() => navigate('StepTwo')}
                text={'Créer un compte'}
              />
              {/*<View className="hover-underline-animation">*/}
              {/*  <TouchableOpacity onPress={async () => {*/}
              {/*    navigate('StepTwo');*/}
              {/*  }}>*/}
              {/*    <Text style={styles.signUpBtnText}>Créer un compte</Text>*/}
              {/*  </TouchableOpacity>*/}
              {/*</View>*/}
            </View>
          </View>
        </KeyboardAwareScrollView>
        <View style={[styles.logo, { position: 'absolute', top: 50, width: '100%', alignItems: 'center' }]}>
          <Svg src={Logo} style={{ width: '40%' }}/>
        </View>
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <LinearBackground />
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <View style={styles.container}>
          <View style={styles.logo}>
            <Svg src={Logo}/>
          </View>
          {error.length > 0 && (
            <View style={styles.notificationContent}>
              <Text style={styles.notificationMessage}>{error}</Text>
            </View>
          )}
          <View style={{
            width: '100%',
            alignItems: 'center',
            ...isBrowser && {
              width: 450,
              paddingTop: 50,
              borderRadius: 30,
              paddingBottom: 50,
              backgroundColor: 'white',
            }
          }}>
            <BrowserView style={{ width: '100%', height: 'auto' }}>
              <View style={{ flexDirection: 'column', alignItems: 'center', marginBottom: 40, width: '100%' }}>
                <TextSection titleSection="Connectez-vous" noBar />
                <View style={{ width: 30, height: 1, marginTop: 20, backgroundColor: 'black' }} />
              </View>
            </BrowserView>
            <LoginForm onSubmit={actionLogin} initialValues={{ username: route.params?.username || '', password: route.params?.password || '' }} />
            <TouchableOpacity onPress={() => { navigate('ForgotPassword'); }}>
              <Text style={styles.forgotTxt}> Mot de passe oublié ?</Text>
            </TouchableOpacity>
            <BombButton
              svg={Icon}
              width={120}
              height={120}
              svgWidth={'110%'}
              style={{ marginBottom: 20 }}
              onPress={() => submit('login')}
              loading={loading}
            />
            <View style={styles.separatorContainer}>
              <View style={styles.separator} />
              <Text style={styles.separatorText}>ou</Text>
              <View style={styles.separator} />
            </View>
            <ExternLogin navigate={navigate} push={push} type={'signin'} onSucceed={handleExternalLogin} />
          </View>
          <View style={{ position: 'absolute', bottom: 30, alignItems: 'center' }} className="hover-underline-animation">
            <TouchableOpacity onPress={async () => {
              if (Platform.OS !== 'android') {
                navigate('StepTwo');
              } else {
                WebBrowser.openBrowserAsync('https://app.othr.pro/register');
              }
            }}>
              <Text style={styles.signUpBtnText}>Créer un compte</Text>
            </TouchableOpacity>
          </View>
          <Text style={styles.versionText}>v{version}</Text>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
}

const mapStateToProps = () => ({});

const actionProps = { login, submit, AppleLogin, GoogleLogin };

export default connect(mapStateToProps, actionProps)(Login);
