import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';

import Svg from '../../svg/Svg';
import { HollowButtonStyle } from './HollowButtonStyle';

import { isMobile } from 'components/device';
import HollowBackground from 'components/backgrounds/hollowBackground';

export default function HollowButton({
  svg,
  svgWidth,
  svgHeight,
  style,
  titre,
  width,
  corner,
  height,
  onPress,
  textStyle,
  children = null,
  ContainerStyles,
}) {
  return (
    <TouchableOpacity
      onPress={() => onPress()}
      style={[
        { width, height },
        HollowButtonStyle.containerNewButton,
        { ...ContainerStyles },
        { ...style },
      ]}
    >
      <HollowBackground corner={corner || (isMobile && 0.8)} />
      <View style={HollowButtonStyle.content}>
        {!!svg && <Svg src={svg} width={svgWidth || '35%'} height={ svgHeight || '35%'}/>}
        {!!titre && <Text style={[HollowButtonStyle.textStyleButton, { ...textStyle }, { marginTop: svg ? 7 : '5%' }]}>
          {titre}
        </Text>}
        {children}
      </View>
    </TouchableOpacity>
  );
}
