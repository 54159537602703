export default (values) => {
  const errors = {};

  if (!values.title || values.title.length < 1) {
    errors.title = 'titre non valide';
  }
  if (!values.client_name || values.client_name.length < 1) {
    errors.client_name = 'nom du client non valide';
  }
  if (!values.expected_action || values.expected_action.length < 1) {
    errors.expected_action = 'action non valide';
  }
  if (values.phone && (values.phone.length < 1 || values.phone.length > 10)) {
    errors.phone = 'téléphone non valide';
  }
  if (!values.client_email || values.client_email.length < 1) {
    errors.client_email = 'email non valide';
  }

  return errors;
};
