import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import { View, TouchableOpacity, Text, FlatList, SafeAreaView, Platform, ScrollView } from 'react-native';

import SubMenu from './SubMenu';
import MenuItem from './MenuItem';
import { MenuStyle } from './MenuStyle';
import { colors } from 'styles';
import { Logout as logout } from 'store/actions/account';
import { useNavigation } from '@react-navigation/native';
import { version } from 'config';

import Svg from 'components/svg';
import menus from 'components/menu/config';
import GoBack from 'components/buttons/goBackButton';
import Logo from '../logo';
import { Notification } from 'styles/svg';
import { formatString } from 'services/navigationPush';
import { AudioPlayerContext } from 'components/audioPlayer/AudioPlayerContext';
import AudioPlayer from 'components/audioPlayer/GlobalAudioPlayer';
import HollowBackground from '../backgrounds/hollowBackground';
import Api from '../../services/api';

const Menu = ({ onPress, logout, user }) => {
  const navigation = useNavigation();
  const [selected, setSelected] = useState(null);
  const { status: playerStatus, title, mediumId } = useContext(AudioPlayerContext);
  const [ notifications, setNotifications ] = useState(0);

  const loadNotifications = async () => {
    try {
      const { data } = await Api.profile.getNotifications(false);
      setNotifications(data?.filter((n) => (!n.seen))?.length);
    } catch (err) {
      console.error('Error loading notifications', err);
    }
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  const closeAndNavigate = (target, params) => {
    onPress();
    setTimeout(() => {
      if (target === 'UserDetails') {
        navigation.reset({
          index: 1,
          routes: [
            { name: 'Home' },
            { name: 'UserDetails', params: {
              userId: `${formatString(user?.firstname)}-${formatString(user.lastname)}-${user.id.toString()}`,
            } },
          ],
        });
      } else {
        navigation.reset({
          index: 1,
          routes: [
            { name: 'Home' },
            { name: target, params },
          ]
        });
      }
    }, 100);
  };

  const handleLogout = () => {
    onPress();
    logout();
  };

  const renderSubMenu = ({ item, index }) => (
    <SubMenu item={item} index={index} onPress={closeAndNavigate} logout={handleLogout} />
  );

  return (
    <View style={{ backgroundColor: colors.whiteShadow }}>
      <ScrollView contentContainerStyle={MenuStyle.container} style={{ height: '100%' }}>
        <TouchableOpacity
          style={MenuStyle.containerLogo}
          onPress={() => closeAndNavigate('Home')}
        >
          <Logo />
        </TouchableOpacity>
        <TouchableOpacity style={MenuStyle.notifications} onPress={() => closeAndNavigate('Notifications')}>
          <Svg src={Notification} height={25} width={25} />
          {!!notifications && <View style={MenuStyle.notificationsChip}>
            <Text style={MenuStyle.notificationsText}>{notifications}</Text>
          </View>}
        </TouchableOpacity>
        {playerStatus?.isLoaded && (
          <TouchableOpacity style={MenuStyle.podcastContainer} onPress={() => closeAndNavigate('PodcastDetails', { mediumId })}>
            <HollowBackground />
            <AudioPlayer />
            <Text numberOfLines={2} style={MenuStyle.playerText}>{title}</Text>
          </TouchableOpacity>
        )}
        {selected ? (
          <TouchableOpacity activeOpacity={1} style={MenuStyle.selectedContainer} onPress={() => setSelected(null)}>
            <View style={MenuStyle.selectedImage}>
              <Svg src={menus[selected].image}/>
            </View>
            <Text style={MenuStyle.text}>{menus[selected].title}</Text>
            <FlatList
              data={menus[selected].children.filter(e => !e.isAvailable || e.isAvailable(user))}
              numColumns={2}
              contentContainerStyle={{ marginTop: 25 }}
              columnWrapperStyle={{ justifyContent: 'center' }}
              keyExtractor={(item, index) => index.toString()}
              renderItem={renderSubMenu}
            />
          </TouchableOpacity>
        ) : (
          <View style={MenuStyle.containerMenu}>
            <View style={MenuStyle.lineMenu}>
              <MenuItem onPress={() => setSelected('Events')} data={menus['Events']} />
              <MenuItem onPress={() => setSelected('Othrs')} data={menus['Othrs']} />
            </View>
            <View style={MenuStyle.lineMenu}>
              <MenuItem onPress={() => setSelected('Groupements')} data={menus['Groupements']} />
              <MenuItem onPress={() => setSelected('Magazine')} data={menus['Magazine']} />
            </View>
            <View style={MenuStyle.lineMenu}>
              <MenuItem onPress={() => setSelected('Podcasts')} data={menus['Podcasts']} />
              <MenuItem onPress={() => setSelected('Othr TV')} data={menus['Othr TV']} />
            </View>
            <View style={MenuStyle.lineMenu}>
              <MenuItem onPress={() => setSelected('Account')} data={menus['Account']} />
              <MenuItem onPress={() => { onPress(); navigation.push('ConnexionUsers'); }} data={menus['Swap']} />
            </View>
          </View>
        )}
        {!selected && <TouchableOpacity onPress={() => {
          WebBrowser.openBrowserAsync('https://api.othr.pro/static/cgu.pdf');
        }}>
          <Text style={MenuStyle.legal}><Text style={{ textDecorationLine: 'underline' }}>Mentions légales</Text> - v{version}</Text>
        </TouchableOpacity>}
      </ScrollView>
      <GoBack styles={{ top: Platform.OS === 'ios' ? 45 : 20 }} onPress={() => selected ? setSelected(null) : onPress()}/>
    </View>
  );
};

const mapStateToProps = (state) => ({
  user: state.Account.currentUser,
});
const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
