import moment from 'moment';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState, useEffect, useMemo } from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import { EvilIcons, SimpleLineIcons } from '@expo/vector-icons';
import { isTablet, isMobile } from 'react-device-detect';

import Api from 'services/api';
import { APP_URL } from 'config';
import { toastError } from 'services/toasts';
import { GetUser } from 'store/actions/account';
import { BuyTicket } from 'store/actions/event';
import { colors, fonts, TextStyles } from 'styles';

import styles from './EventBookingStyle';
import BookingForm from './BookingForm';

import TicketPrice from 'components/ticketPrice';
import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import BackgroundLinearGradient from 'components/backgrounds/backgroundLinearGradient';

const EventBooking = ({ navigation: { push, goBack, canGoBack }, route, GetUser, user, publicPage = false }) => {
  const [loading, setLoading] = useState(false);
  const [nbPlaces, setPlaces] = useState(undefined);

  const { width } = useWindowDimensions();

  const { event, ticketName, isInternal } = route.params;
  const isPublicPage = route.params?.publicPage === 'true' || publicPage;

  const ticket = event.tickets?.find((t) => (t.title === ticketName));

  useEffect(() => {
    if (!user) {
      GetUser();
    }
  }, []);

  const price = useMemo(() => (
    isInternal && (ticket.internal_price !== null && ticket.internal_price !== undefined) ? ticket.internal_price : ticket?.price
  ), [ isInternal, ticket ]);

  const buyTicket = async (values) => {
    try {
      setLoading(true);
      if (price === 0) {
        await Api.tickets.register(ticket.id, values.owners);
        window.location.replace(`${APP_URL}/tickets?status=success`);
      } else {
        let checkout;
        if (publicPage) {
          checkout = await Api.payments.guestBuyTicket(
            values.owners,
            values.nbPlaces,
            event.id,
            ticket.id,
            `${APP_URL}/events/${event.id}?status=success`,
            `${APP_URL}/events/${event.id}?status=error`,
          );
        } else {
          checkout = await Api.payments.buyTicket(
            values.owners,
            values.nbPlaces,
            event.id,
            ticket.id,
            `${APP_URL}/tickets?status=success`,
            `${APP_URL}/events/${event.id}?status=error`,
          );
        }
        window.location.replace(checkout.data.url);
      }
    } catch (err) {
      console.error('Error in payment', err);
      toastError('Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  const EventImage = () => (
    <View style={[styles.webEventImageContainer, {
      minWidth: 300,
      minHeight: 360,
      width: mobile ? '100%' : width * 0.25,
      borderRadius: mobile ? 0 : 15,
    }]}>
      {event && !!event.picture_url && (
        <BackgroundLinearGradient
          image={{ uri: event?.picture_url }}
          width="100%"
          height={mobile ? 360 : width * 0.3}
          styles={{ borderRadius: mobile ? 0 : 15 }}
        >
          <View style={[styles.webEventInsideImageContainer, {
            height: width * 0.26,
            minHeight: mobile ? 0 : 310,
          }]}>
            <Text numberOfLines={2} selectable style={TextStyles({
              color: colors.white,
              maxWidth: '80%',
              lineHeight: 45,
            }).bold40}>{event?.title}</Text>
          </View>
        </BackgroundLinearGradient>
      )}
    </View>
  );

  return (
    <BasicLayout
      push={push}
      disableMenu={true}
      mobilePadding={false}
      disableHeader={isPublicPage}
      breadCrumb={!isPublicPage && <Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Events" />}
      headerSection={!isPublicPage && <HeaderSection push={push} menuSelect="Events" redirect="Event"/>}
    >
      <View style={[styles.webContainer, { marginTop: mobile ? 0 : 70 }]}>
        <View style={{ width: mobile ? '100%' : '60%', flexDirection: mobile ? 'column' : 'row' }}>
          <EventImage />
          <View style={[styles.webDescriptionContainer, { width: mobile ? '100%' : width * 0.3 }]}>
            <View style={styles.dateContainer}>
              <SimpleLineIcons name="clock" size={20} color="black" />
              <Text selectable style={{ marginLeft: 10, textTransform: 'capitalize' }}>{moment(event.start_date).format('dddd DD MMMM yyyy')}</Text>
              <Text selectable> de {moment(event.start_date).format('HH[h]mm')} à {moment(event.end_date).format('HH[h]mm')}</Text>
            </View>
            <View style={styles.locationContainer}>
              <EvilIcons name="location" size={35} color="black" style={{ marginLeft: -8 }} />
              <View style={{ marginLeft: 2 }}>
                <Text selectable style={{ marginLeft: 0, fontFamily: fonts.lato.bold }}>{event.address?.city}</Text>
                <Text selectable numberOfLines={1} style={{ marginLeft: 0, fontFamily: fonts.lato.regular }}>{event.address?.number} {event.address?.street}, {event.address?.zip_code} {event.address?.city}</Text>
              </View>
            </View>
            <TicketPrice name={ticket?.title} description={ticket?.description} price={price} ticketSelected/>
            <BookingForm
              onSubmit={buyTicket}
              initialValues={{
                owners: [{ name: !publicPage && user ? `${user.firstname} ${user.lastname}` : '', job: !publicPage && user?.job || '' }]
              }}
              nbRemainingPlaces={event.total_places - event?.user_tickets?.length}
              isFree={price === 0}
              Button={({ onClick, nbPlaces, isFree }) => (
                <MultiColorButton
                  loading={loading}
                  onPress={onClick}
                  style={{ marginTop: 40 }}
                  text={isFree ? 'Réserver ma place' : `Payer ${nbPlaces * price},00€ TTC`}
                />
              )}
            />
          </View>
        </View>
      </View>
    </BasicLayout>
  );
};

const mobile = isMobile && !isTablet;

const mapStateToProps = (state) => ({
  user: state.Account.currentUser,
});
const mapDispatchToProps = {
  BuyTicket,
  GetUser,
  submit,
};
export default connect(mapStateToProps, mapDispatchToProps)(EventBooking);
