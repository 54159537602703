import { StyleSheet } from 'react-native';
import { colors } from 'styles';
import { ifIphoneX } from 'react-native-iphone-x-helper';

export const GoBackStyle = StyleSheet.create({
  container: {
    position: 'absolute',
    left: ifIphoneX(25, 25),
    top: ifIphoneX(45, 25),
    borderRadius: 50,
    backgroundColor: '#FEFEFE',
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
    padding: 5,
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.white,
  },
  contentClose: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
