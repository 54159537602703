import { Platform, StyleSheet } from 'react-native';

export default StyleSheet.create({
  form: {
    marginTop: '10%',
    width: Platform.OS === 'web' ? '50%' : '90%',
  },
  input: {
    marginLeft: 0,
    paddingLeft: 0,
  },
});
