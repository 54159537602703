import React from 'react';
import { Text, View } from 'native-base';
import { useWindowDimensions } from 'react-native';

import { fonts, colors } from 'styles';
import WebUserImage from '../../../components/cards/WebUserImage';

const RecommendationDetailsForm = ({ userId, recommendation }) => {
  const { width, height } = useWindowDimensions();
  const user = recommendation.user.id === userId ? recommendation.worker : recommendation.user;
  const id = recommendation.user.id === userId ? recommendation.recommended_user_id : recommendation.user_author_id;

  return (
    <View style={{ flexDirection: 'row', width: '70%' }}>
      <View style={{ width: width * 0.25, minWidth: 300 }}>
        <WebUserImage user={{ ...user, id }} height={width * 0.3 > 360 ? width * 0.3 : 360} />
      </View>
      <View style={{ marginLeft: 35, width: '32%' }}>
        <Text selectable style={{ fontFamily: fonts.lato.black, fontSize: 23, marginBottom: 20 }}>
          {recommendation.user.firstname} vous recommande auprès de:
        </Text>
        <Text selectable style={{ fontFamily: fonts.lato.black, fontSize: 15, color: colors.burgundy }}>
          Contact
        </Text>
        <Text selectable style={{ fontFamily: fonts.lato.regular, fontSize: 23, marginBottom: 20 }}>
          {recommendation.client_name}
        </Text>
        <Text selectable style={{ fontFamily: fonts.lato.bold, fontSize: 23, lineHeight: 26, marginBottom: 24 }}>
          Projet: {recommendation.title}
        </Text>
        <Text selectable style={{ fontFamily: fonts.lato.regular, fontSize: 15, lineHeight: 25 }}>
          {recommendation.description}
        </Text>
      </View>
      <View style={{ marginLeft: 20, marginTop: height * 0.15 }}>
        <Text selectable style={{ fontFamily: fonts.lato.black, color: '#CD004B', fontSize: 15 }}>
          Action attendue
        </Text>
        <Text selectable style={{ fontFamily: fonts.lato.regular, fontSize: 20 }}>
          {recommendation.expected_action}
        </Text>
        <Text selectable style={{ fontFamily: fonts.lato.black, color: '#CD004B', fontSize: 15, marginTop: 10 }}>
          Téléphone
        </Text>
        <Text selectable style={{ fontFamily: fonts.lato.regular, fontSize: 20 }}>
          {recommendation.client_phone ? recommendation.client_phone : 'Non renseigné'}
        </Text>
        <Text selectable style={{ fontFamily: fonts.lato.black, color: '#CD004B', fontSize: 15, marginTop: 10 }}>
          Email
        </Text>
        <Text selectable style={{ fontFamily: fonts.lato.regular, fontSize: 20 }}>
          {recommendation.client_email ? recommendation.client_email : 'Non renseigné'}
        </Text>
      </View>
    </View>
  );
};

export default RecommendationDetailsForm;
