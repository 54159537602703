import { Dimensions, StyleSheet } from 'react-native';
import { colors, fonts } from 'styles';

const { width: screenWidth, height } = Dimensions.get('screen');

export default StyleSheet.create({
  contentContainer: {
    alignItems: 'center',
    paddingVertical: 60,
  },
  ticketContainer: {
    width: screenWidth - 30,
    borderRadius: 15,
    overflow: 'hidden',
    justifyContent: 'flex-end',
    height: 450,
    marginTop: 20,
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  dateContainer: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: '15%',
  },
  dayContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },
  day: {
    color: colors.greyDayColor,
    fontSize: 12,
  },
  separator: {
    marginVertical: 5,
    backgroundColor: colors.greyDayColor,
    width: '70%',
    height: 2,
  },
  number: {
    fontSize: 21,
    fontFamily: fonts.lato.bold,
    color: colors.black,
  },
  month: {
    width: '100%',
    textAlign: 'center',
    fontFamily: fonts.lato.bold,
    color: colors.black,
    fontSize: 12,
  },
  hour: {
    color: colors.white,
    fontFamily: fonts.lato.regular,
    width: '100%',
    marginTop: 5,
    fontSize: 14,
    textAlign: 'center',
  },
  infosContainer: {
    marginBottom: 25,
    marginLeft: '10%',
    justifyContent: 'flex-end',
    width: '60%'
  },
  logo: {
    width: '40%',
    resizeMode: 'cover',
    height: 45,
  },
  title: {
    fontSize: 18,
    color: colors.white,
    fontFamily: fonts.lato.bold,
    maxWidth: '100%',
  },
  userContainer: {
    alignSelf: 'flex-start',
    backgroundColor: colors.white,
    borderRadius: 30,
    height: 25,
    maxWidth: '70%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10
  },
  user: {
    fontFamily: fonts.lato.bold,
    color: colors.greyDark,
    fontSize: 16,
    textAlign: 'center',
  },
  webHeader: {
    marginBottom: 20,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    height: height * 0.05,
  },
  webHeaderImage: {
    width: 40,
    height: 40,
    marginRight: 5,
  },
  webPastTicketsContainer: {
    marginTop: 60,
  },
  webPastTicketsSubContainer: {
    width: '13%',
    borderRadius: 30,
    marginBottom: 20,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    height: height * 0.05,
    justifyContent: 'center',
  },
  webPastTicketImage: {
    width: 32,
    height: 30,
    marginRight: 5,
    marginLeft: -10,
  }
});
