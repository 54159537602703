import { submit } from 'redux-form';
import { Alert } from 'react-native';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { View, Text } from 'native-base';

import { TextStyles } from 'styles';
import AddGroupForm from './AddGroupForm';
import { AddGroupStyles } from './AddGroupStyles';
import { createNetwork } from 'store/actions/network';
import { pushToNetwork } from 'services/navigationPush';
import { toastError, toastSuccess } from 'services/toasts';

import { isBrowser, isMobile } from 'components/device';
import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import BreadCrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';

function AddNetwork({ submit, navigation: { pop, push, goBack, canGoBack }, createNetwork }) {
  const [loading, setLoading] = useState(false);

  const addNetwork = async (values) => {
    setLoading(true);
    try {
      const network = (await createNetwork(values)).data?.network;
      if (isMobile) {
        Alert.alert(
          'Votre demande a bien été envoyée aux administrateurs',
          'Nous vous contacterons très rapidement pour discuter de votre groupement', [
            {
              text: 'Ok',
              onPress: pop,
            },
          ]);
      }
      if (isBrowser) {
        pushToNetwork(push, network);
        toastSuccess('Votre groupement a bien été crée.');
      }
    } catch (err) {
      if (isMobile) {
        if (err.response.data) {
          const desc = err.response.data['hydra:description'];
          Alert.alert('Erreur', desc.slice(desc.indexOf(': ') + 2));
        } else {
          Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
        }
        if (isBrowser) {
          toastError('Une erreur est survenue.');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout
      push={push}
      disableMenu={true}
      mobilePadding={false}
      title={'Créer un groupement'}
      headerSection={<HeaderSection push={push} menuSelect="Groupements" redirect="Groupements"/>}
      breadCrumb={<BreadCrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Groupements" />}
    >
      <View style={{ flex: 1 }}>
        <KeyboardAwareScrollView
          contentContainerStyle={isMobile && AddGroupStyles.flexGrowOne}
          showsVerticalScrollIndicator={false}
        >
          <AddGroupForm onSubmit={addNetwork} initialValues={{
            values: [],
            services: [],
          }}/>
          <View style={{ marginLeft: '20%' }}>
            <Text style={TextStyles({ lineHeight: 20, margin: 20, marginBottom: 20 }).bold20}>
              Votre demande sera envoyée pour approbation aux équipes Othr
            </Text>
          </View>
          <MultiColorButton
            onPress={() => submit('createNetwork')}
            text="Soumettre ma demande"
            style={isMobile ? {
              width: '80%', height: 70, marginBottom: 150, marginTop: '20%',
            } : {
              width: '16%', marginLeft: '20%'
            }}
            loading={loading}
          />
        </KeyboardAwareScrollView>
      </View>
    </BasicLayout>
  );
}

const mapStateToProps = (state) => ({
});

const actionProps = {
  submit,
  createNetwork,
};

export default connect(mapStateToProps, actionProps)(AddNetwork);
