import React, { useState } from 'react';
import { Image, Text, View } from 'react-native';

import Api from 'services/api';
import { svgtopng } from 'styles/images';
import { TextStyles, ContainerStyles } from 'styles';

import promoData from './promoData';
import UsersStyle from './UsersStyle';

import Slider from 'components/slider';
import User from 'components/cards/user';
import NoResources from 'components/noResources';
import GrayButton from 'components/buttons/grayButton';
import PromoCarousel from 'components/webCarousel/Promo';
import { BrowserView, MobileView } from 'components/device';
import HeaderSection from 'components/layouts/headerSection';
import CustomFlatList from 'components/webLists/CustomFlatList';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';

function Users({ navigation: { push, goBack, canGoBack } }) {
  const [users, setUsers] = useState([]);
  const [spheres, setSpheres] = useState([]);

  const loadResources = async () => {
    try {
      const { data: spheres } = await Api.misc.getSpheres();
      setSpheres(spheres);
      const { data: users } = await Api.users.getHome();
      setUsers(users);
    } catch (err) {
      console.error('Error getting spheres', err);
    }
  };

  const RenderMobileContent = () => (
    <View style={UsersStyle.slider}>
      {
        users.map((u, i) => (
          <View key={i}>
            <Slider height={400} push={push} noBar enableMarginTop={i !== 0}>
              {[ u, ...users.filter((us) => (us.sphere?.id === u.sphere?.id && us.id !== u.id))].slice(0, 5).map((u, i) => (
                <View key={u.id}>
                  <User {...u} swapPossible={true} push={push}/>
                </View>
              ))}
            </Slider>
          </View>
        ))
      }
    </View>
  );

  const RenderWebContent = () => (
    <View style={ContainerStyles.web}>
      <PromoCarousel push={push} data={promoData} />
      <CustomFlatList
        push={push}
        type="user"
        resources={users.map((u) => {
          return { ...u, type: 'user' };
        })}
        settings={{
          spheres,
          sortField: 'firstname',
          filters: ['asc', 'spheres'],
          searchFields: ['firstname', 'lastname', 'job'],
        }}
      />
      {/*
      <Text style={{ marginTop: '1%', fontFamily: fonts.lato.bold, fontSize: 25, marginLeft: '5%' }}>Je peux les rencontrer</Text>
      <View style={{ zIndex: 0, marginTop: '2%' }}>
        <WebSoloList push={push} resources={users.map((u) => {
          return { ...u, type: 'user' };
        })} />
      </View>
      */}
    </View>
  );

  return (
    <LoadResourceLayout
      push={push}
      disableGoBack={true}
      mobilePadding={false}
      loadResources={loadResources}
      title="Je peux les rencontrer"
      disableMarginTop={true}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Othrs" />}
    >
      {
        users?.length === 0 ? (
          /* NO RESSOURCES COMPONENT */
          <NoResources
            noResourceText="Vous ne pouvez pas encore rencontrer d'Othr"
            findResourceText="Une fois que vous aurez votre premier contact, vous pourrez retrouver leurs Othrs ici pour élargir votre réseau"
            buttons={[
              () => <GrayButton width="100%" onPress={() => push('Event')} titre={'Participer à un event'} />,
              () => <GrayButton width="100%" onPress={() => push('Network')} titre={'Rejoindre un groupement'} />,
            ]}
          />
        ) : (
          <View>
            {/* MOBILE */}
            <MobileView>
              <RenderMobileContent />
            </MobileView>
            {/* BROWSER */}
            <BrowserView>
              <RenderWebContent />
            </BrowserView>
          </View>
        )
      }
    </LoadResourceLayout>
  );
}

export default Users;
