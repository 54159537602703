import moment from 'moment';
import { View } from 'native-base';
import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { Image, Text, Modal, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';

import { colors } from 'styles';

import InputStyles from 'components/form/input/InputStyles';
import FieldWrapper from 'components/form/fieldWrapper';
import HollowBackground from 'components/backgrounds/hollowBackground';

import fr from 'date-fns/locale/fr';

export default (props) => {
  const {
    meta: { error },
    input,
    label,
    height = 52,
    img,
    mode,
    ...inputProps
  } = props;

  const [ visible, setVisible ] = useState(false);

  const handleChange = (date) => {
    input.onChange(date);
    input.onBlur();
    setVisible(false);
  };

  const FORMAT = 'DD-MM-YYYY';

  return (
    <FieldWrapper {...props}>
      <View style={[InputStyles.itemStyle, { borderColor: 'transparent' }]}>
        <View style={[InputStyles.containerInput, { height }]}>
          <HollowBackground />
          {!!img && <Image alt="Change me" style={InputStyles.imgInput} source={img} />}
          <TouchableOpacity style={{ flex: 1, alignItems: 'center', justifyContent: 'center', width: '100%' }} onPress={() => setVisible(!visible)}>
            <Text style={[InputStyles.inputStyle, { textAlign: 'left', color: input.value ? colors.grey : colors.secondary }]}>{input.value ? moment(input.value).format(FORMAT) : label}</Text>
          </TouchableOpacity>
          {props.required ? (
            <View
              style={{
                width: 3,
                right: 5,
                margin: 10,
                height: 13,
                borderRadius: 20,
                position: 'absolute',
                borderColor: colors.whiteShadow,
                backgroundColor: colors.firebrick,
              }}
            />
          ) : null}
        </View>
      </View>
      <Modal
        visible={visible}
        transparent={true}
      >
        <TouchableWithoutFeedback onPress={() => setVisible(false)}>
          <View style={InputStyles.modal}>
            <View style={InputStyles.datePicker}>
              <div onClick={(e) => {
                e.stopPropagation();
              }}>
                <DayPicker
                  locale={fr}
                  mode={'single'}
                  onSelect={handleChange}
                  selected={input.value || null}
                  styles={{ backgroundColor: 'white' }}
                />
              </div>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </FieldWrapper>
  );
};
