import IBAN from 'iban';
import Api from '../../services/api';

export const validate = async (values) => {
  if (!values) return null;
  const errors = {};

  if (values.video_url && !values.video_url.includes('youtube.com')) {
    errors.video_url = 'Votre lien doit provenir de youtube';
  }
  if (!values.picture_url) {
    errors.picture_url = 'Obligatoire';
  }
  if (!values.firstname) {
    errors.firstname = 'Obligatoire';
  }
  if (!values.lastname) {
    errors.lastname = 'Obligatoire';
  }
  if (!values.job) {
    errors.job = 'Obligatoire';
  }
  // if (!values.company) {
  //   errors.company = 'Obligatoire';
  // }
  if (!values.sphere) {
    errors.sphere = 'Obligatoire';
  }
  // if (!values.description) {
  //   errors.description = 'Obligatoire';
  // }
  if (!values.address) {
    errors.address = 'Obligatoire';
  }
  // if (values.address && values.address.placeId) {
  //   try {
  //     await Api.address.verify(values.address.placeId);
  //   } catch (err) {
  //     errors.address = 'L\'adresse doit être  comme "123 rue du Moulin, Paris France"';
  //   }
  // }
  // if (values.iban && !IBAN.isValid(values.iban)) {
  //   errors.iban = 'Iban Invalide';
  // }

  return errors;
};
