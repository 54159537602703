import { Modal, Platform, Text, View } from 'react-native';
import NfcManager, { NfcTech, Ndef } from 'react-native-nfc-manager';
import { colors, fonts } from '../styles';
import { ScanNfc } from '../styles/svg';
import WhiteButton from '../components/buttons/whiteButton';

if (Platform.OS !== 'web') {
  NfcManager.start();
}

const NfcModal = (props) => {
  if (Platform.OS !== 'android') return null;
  return (
    <Modal
      visible={props.visible}
      onRequestClose={props.close}
      transparent={true}
    >
      <View style={{ flex: 1, width: '100%', height: '100%', alignItems: 'center', justifyContent: 'flex-end', padding: 20, backgroundColor: '#00000022' }}>
        <View style={{ width: '100%', minHeight: '40%', alignItems: 'center', justifyContent: 'space-between', padding: 20, backgroundColor: colors.white,  borderRadius: 30, }}>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, textAlign: 'center', maxWidth: '80%' }}>Prêt à scanner</Text>
          <ScanNfc width={'50%'} height={80} style={{ marginVertical: 20 }} />
          <Text style={{ fontFamily: fonts.lato.regular, fontSize: 16, textAlign: 'center', maxWidth: '80%' }}>Approchez la carte du dos du téléphone...</Text>
          <WhiteButton onPress={props.close} text={'Annuler'} noHollow color={colors.liteGrey} style={{ width: '80%', marginTop: 30 }} />
        </View>
      </View>
    </Modal>
  );
};

async function readNfc() {
  try {
    console.log('readNdef');
    // register for the NFC tag with NDEF in it
    await NfcManager.requestTechnology(NfcTech.Ndef, {
      alertMessage: 'Scannez la carte Othr de votre futur contact'
    });
    // the resolved tag object will contain `ndefMessage` property
    const tag = await NfcManager.getTag();
    return tag;
  } catch (ex) {
    console.warn('Oops!');
    console.warn(ex);
  } finally {
    await NfcManager.cancelTechnologyRequest();
  }
}

async function writeNfc(url) {
  let result = false;

  try {
    await NfcManager.requestTechnology(NfcTech.Ndef, {
      alertMessage: 'Scannez votre carte Othr pour la synchroniser'
    });

    const bytes = Ndef.encodeMessage([Ndef.uriRecord(url)]);
    console.log('bytes', bytes.length);
    let i = 0;
    while (result === false && i < 3) {
      try {
        if (bytes) {
          await NfcManager.ndefHandler // STEP 2
            .writeNdefMessage(bytes); // STEP 3
          console.log('success at i = ', i);
          result = true;
        }
      } catch (err) {
        console.log(err);
        console.log('i', i);
        i += 1;
      }
    }
  } catch (ex) {
    console.error('NFC writing warning', ex.toString());
    console.log(ex);
    result = false;
  } finally {
    await NfcManager.cancelTechnologyRequest();
  }

  return result;
}

// async function writeNfc(url) {
//   let result = false;
//   return result;
// }

export {
  NfcModal,
  readNfc,
  writeNfc,
};
