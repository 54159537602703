import React from 'react';
import { Text } from 'react-native';

import { IconCheck } from 'styles/svg';
import { ValidationEventStyle } from './ValidationEventStyle';

import ShareButtons from 'components/shareButtons';
import GrayButton from 'components/buttons/grayButton';
import MenuScrollView from 'components/menuScrollView';

export default function Index({ navigation: { push } }) {
  return (
    <MenuScrollView push={push} style={ValidationEventStyle.container} contentContainerStyle={ValidationEventStyle.content}>
      <IconCheck width={'26%'} height={'15%'} />
      <Text style={ValidationEventStyle.textOne}>Félicitations</Text>
      <Text style={ValidationEventStyle.textTwo}>
        Votre inscription a été prise en compte
      </Text>
      <GrayButton onPress={() => push('Tickets')} titre="Mes billets" width="70%" style={{ marginVertical: '20%' }} />
      {/*<ShareButtons />*/}
    </MenuScrollView>
  );
}
