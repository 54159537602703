import { Text } from 'native-base';
import { Platform, View } from 'react-native';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';

import { colors, fonts } from 'styles';
import ContractSignedForm from './ContractSignedForm';
import { recommendationContractSigned } from 'store/actions/recommendation';

import GoBack from 'components/buttons/goBackButton';
import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton/MultiColorButton';
import { toastSuccess, toastError } from '../../../services/toasts';
import { isBrowser } from '../../../components/device';

const ContractSigned = ({ route, navigation: { push, goBack }, submit, recommendation, recommendationContractSigned }) => {
  const [loading, setLoading] = useState(false);

  const contractSigned = async (values) => {
    try {
      setLoading(true);
      await recommendationContractSigned(route.params.recommendationId, values.contractPrice);
      if (Platform.OS !== 'web') {
        push('ValidationContractSigned');
      } else {
        push('Recommendations', { section: 2 });
        toastSuccess('Devis bien enregistré!');
      }
    } catch (err) {
      if (Platform.OS === 'web') {
        toastError('Une erreur est survenue.');
      }
      console.log('Error while sending contract', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout
      push={push}
      breadCrumb={<Breadcrumb push={push} goBack={goBack} menuSelect="Othrs" />}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
      disableGoBack={false}
    >
      {!isBrowser && (
        <GoBack styles={{ top: 55, left: 25 }} color={colors.black} onPress={goBack} />
      )}
      <View
        style={
          Platform.OS !== 'web' ? {
            width: '70%',
            height: '100%',
            justifyContent: 'center',
          } : {
            width: '70%',
            height: '100%',
            marginLeft: '15%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
      >
        <Text style={{ fontSize: 22, fontFamily: fonts.lato.regular }}>
          Dites à {recommendation?.user?.firstname} quel montant a été généré par cette Recommandation !
        </Text>
        <Text style={{ marginTop: 10, fontSize: 14, fontFamily: fonts.lato.italic, color: colors.grey }}>
          Cette information ne sera visible que pour la personne qui vous a recommandé
        </Text>
        <ContractSignedForm onSubmit={contractSigned} loading={loading} />
      </View>
    </BasicLayout>
  );
};

const mapStateToProps = (state, props) => ({
  recommendation: state.Recommendation.recommendations?.find((e) => e.id.toString() === props.route.params.recommendationId.toString()),
});

const mapDispatchToProps = {
  submit,
  recommendationContractSigned,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractSigned);
