import moment from 'moment';
import { geocodeAddress } from '../components/map/geocodeAddress';

export function isObjectNull(object) {
  if (Object.entries(object).length === 0) {
    return true;
  }
  return false;
}

export function toInt(text = '0') {
  const trim = text.trim();
  return parseInt(trim, 10);
}

export function dateToHour(date) {
  return moment(date).format('HH:mm:ss');
}

export function dateToString(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function dateToStringFr(date) {
  return moment(date).format('dddd DD MMM');
}

export function dayOfdateShort(date) {
  return moment(date).format('DD');
}

export function dayOfdateLong(date) {
  return moment(date).format('ddd');
}

export function dateToHourFr(date) {
  return moment(date).format('HH:mm');
}

export function dateToHourShortH(date) {
  return moment(date).format('HH');
}

export function dateToHourShortM(date) {
  return moment(date).format('MMM');
}

export function creatDate(day, hour) {
  return moment(`${day} ${hour}`).format();
}

export function countItems(items) {
  return items.length;
}

export function filterNetworksType(networksTypes, filter) {
  const items = networksTypes.filter((network) => network.name === filter);
  return items[0].networks.map((network) => {
    const { description } = items[0];
    const { color } = items[0];
    return {
      id: network.id,
      background: network.imageRepresentation.url,
      name: network.name,
      description,
      color,
    };
  });
}

export function pad(num) {
  return (`0${num}`).slice(-2);
}

export function formatTime(secs) {
  let minutes = Math.floor(secs / 60);
  secs -= minutes * 60;
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  return `${minutes}:${Math.trunc(secs)}`;
}
// ------------------------------------------------------------------//
// ------------------------ Get State data --------------------------//
// ------------------------------------------------------------------//
/**
 * used by selectors
 * @param state
 * @param path
 * @param errorMessageIfNotFound
 * @returns {*}
 */
export function getData(state, path, errorMessageIfNotFound = false) {
  let data;
  try {
    if (typeof state === 'function') {
      throw new Error(
        'The state parameter must not be a function. The error is usually the usage of getState instead of getState(). Path is',
        path,
      );
    }
    data = path.split('.').reduce((res, prop) => res[prop], state);
    if (errorMessageIfNotFound && data == null) {
      throw new Error('errorMessageIfNotFound');
    }
  } catch (error) {
    console.error(error);
    return null;
  }
  return data;
}
