import React from 'react';
import { Image, Text, View } from 'react-native';

import { TextStyles } from 'styles';

import { svgtopng } from 'styles/images';

import { ResponsiveView } from 'components/device';
import CustomFlatList from 'components/webLists/CustomFlatList';
import NoResources from '../../../components/noResources';

const Relations = ({
  users,
  spheres,
  navigation: { push },
}) => (
  <View style={{ width: '100%' }}>
    <ResponsiveView style={{ marginBottom: '1%' }}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image alt="Change me" resizeMode="contain" style={{ width: 30, height: 30 }} source={svgtopng.other.contact} />
        <Text style={TextStyles({ marginLeft: 15 }).bold30}>Mes contacts</Text>
      </View>
    </ResponsiveView>
    {
      users.length > 0 ? (
        <CustomFlatList
          push={push}
          type="user"
          resources={users.map((u) => {
            return { ...u, type: 'user' };
          })}
          settings={{
            spheres,
            sortField: 'firstname',
            filters: ['asc', 'dsc', 'spheres'],
            searchFields: ['firstname', 'lastname', 'job'],
          }}
        />
      ) : (
        <NoResources
          noResourceText="Vous n'avez pas encore de contact"
          findResourceText="Une fois que vous aurez ajouté votre premier contact, vous pourrez le retrouver ici"
        />
      )
    }
  </View>
);

export default Relations;
