import React from 'react';
import { View } from 'native-base';
import { Dimensions } from 'react-native';
import { Icon } from 'react-native-elements';
import { Field, reduxForm } from 'redux-form';

import { AddGroupStyles } from './AddGroupStyles';

import { colors } from 'styles';

import Input from 'components/form/input/Input';
import PickerField from 'components/form/picker';
import ImagePicker from 'components/form/imagePicker/ImagePicker';

const { height } = Dimensions.get('screen');

const formValidation = (values) => {
  const errors = {};

  if (!values.picture_url) {
    errors.picture_url = 'Obligatoire';
  }
  if (!values.logo_url) {
    errors.logo_url = 'Obligatoire';
  }
  if (!values.description) {
    errors.description = 'Obligatoire';
  }
  if (!values.type) {
    errors.description = 'Obligatoire';
  }
  return errors;
};

function AddParentNetworkForm(props) {
  return (
    <View style={AddGroupStyles.containerForm}>
      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ width: '42%' }}>
          <Field
            name='picture_url'
            images={null}
            svg={<Icon
              name="plus"
              type="simple-line-icon"
              size={70}
              color={colors.greyDark}
            />}
            style={AddGroupStyles.borderStyleComponent}
            height={height * 0.546}
            width="100%"
            titre="Photo de Profil"
            component={ImagePicker}
          />
        </View>
        <View style={{ width: '26%', marginLeft: '5%' }}>
          <Field
            name="description"
            label="Descriptif de votre groupement mère"
            width="100%"
            component={Input}
            required={true}
          />
          <Field
            name="type"
            label="Type du groupement"
            width="100%"
            autoCapitalize="none"
            component={PickerField}
            items={props.networkTypes}
            labelKey='name'
            valueKey='id'
            required
          />
          <Field
            name='logo_url'
            images={null}
            svg={<Icon
              name="plus"
              type="simple-line-icon"
              size={50}
              color={colors.greyDark}
            />}
            style={AddGroupStyles.borderStyleComponent}
            height={height * 0.277}
            width="100%"
            titre="Logo du groupement"
            component={ImagePicker}
          />
        </View>
      </View>
    </View>
  );
}

export default reduxForm({ form: 'createParentNetwork', validate: formValidation, enableReinitialize: true })(AddParentNetworkForm);
