export const formatString = (str) => (str?.toString()?.replace(/\s/g, '_')?.normalize('NFD')?.replace(/\p{Diacritic}/gu, '')?.toLowerCase());

export const pushToUser = (push, user, type = 'details') => {
  let redirect = '';
  if (type === 'details') {
    redirect = 'UserDetails';
  }
  push(redirect, {
    userId: `${formatString(user?.firstname)}-${formatString(user.lastname)}-${user.id.toString()}`
  });
};

export const pushToEvent = (push, event, type = 'details') => {
  console.log('pushToEvent', event);
  let redirect = '';
  if (type === 'details') {
    redirect = 'EventDetails';
  }
  if (type === 'validation') {
    redirect = 'ValidationEvent';
  }
  if (type === 'edit') {
    redirect = 'EditEvent';
  };
  if (event.network) {
    push(redirect, {
      eventId: `${formatString(event.network.name)}-${formatString(event?.title)}-${formatString(event?.id.toString())}`
    });
  } else if (event.user) {
    push(redirect, {
      eventId: `${formatString(event.user.firstname)}_${formatString(event.user.lastname)}-${formatString(event?.title)}-${formatString(event?.id.toString())}`
    });
  } else if (event.city_group_author_id) {
    push(redirect, {
      eventId: `othr_${formatString(event.cityGroupAuthor?.name)}-${event.id.toString()}`,
    });
  } else {
    push(redirect, {
      eventId: `othr_${formatString(event.zone?.code)}-${event.id.toString()}`,
    });
  }
};

export const pushToParentNetwork = (push, network, type = 'details') => {
  push('NetworkDetails', { networkId: `${formatString(network?.description)}-${network?.id.toString()}` });
};

export const pushToNetwork = (push, network, type = 'details') => {
  let redirect = '';
  if (type === 'details') {
    redirect = 'GroupDetails';
  }
  if (type === 'edit') {
    redirect = 'EditGroup';
  }
  push(redirect, { networkId: `${formatString(network?.name)}-${network?.id.toString()}` });
};

export const pushToMedia = (push, mediaType, media, type = 'details') => {
  let redirect = '';
  if (type === 'details') {
    if (mediaType === 'article') {
      redirect = 'ArticleDetails';
    }
    if (mediaType === 'video') {
      redirect = 'VideoDetails';
    }
    if (mediaType === 'podcast') {
      redirect = 'PodcastDetails';
    }
  }
  if (type === 'edit') {
    if (mediaType === 'article') {
      redirect = 'EditArticle';
    }
    if (mediaType === 'video') {
      redirect = 'EditVideo';
    }
    if (mediaType === 'podcast') {
      redirect = 'EditPodcast';
    }
  }
  push(redirect, { mediumId: `${formatString(media?.title || (media?.items?.find((i) => i.type === 'title')).content)}-${media?.id.toString()}` });
};
