import React from 'react';
import { View, Text } from 'react-native';

import { StatisticsStyle } from './StatisticsStyle';

export default function Statistics({
  number, numberText, colors, width, height = 35,
}) {
  return (
    <View style={[StatisticsStyle.containerStyleAudience, { height }]}>
      <View style={[StatisticsStyle.AudienceStyle, { backgroundColor: colors, width, paddingRight: height / 2 }]}>
        <Text style={StatisticsStyle.audiencePod}>{number}</Text>
      </View>
      <View style={StatisticsStyle.containerTextAudience}>
        <Text style={StatisticsStyle.textAudience}>{numberText}</Text>
      </View>
    </View>
  );
}
