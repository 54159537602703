import { Platform, StyleSheet } from 'react-native';

import { colors, fonts } from 'styles';

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    borderRadius: 15,
    overflow: 'hidden',
    justifyContent: 'flex-end',
  },
  image: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    position: 'absolute',
  },
  dateContainer: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: 65,
  },
  dayContainer: {
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },
  day: {
    marginTop: Platform.OS === 'web' ? 5 : 0,
    color: colors.greyDayColor,
    fontSize: Platform.select({
      web: 12,
      android: 14,
      ios: 16,
    })
  },
  separator: {
    marginVertical: 5,
    backgroundColor: colors.greyDayColor,
    width: '70%',
    height: 2,
  },
  number: {
    fontSize: Platform.select({
      web: 21,
      android: 26,
      ios: 30,
    }),
    fontFamily: fonts.lato.bold,
    color: colors.grey,
  },
  month: {
    width: '100%',
    textAlign: 'center',
    fontFamily: fonts.lato.bold,
    color: colors.black,
    marginBottom: Platform.OS === 'web' ? 5 : 0,
    fontSize: Platform.OS !== 'web' ? undefined : 12,
  },
  hour: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    width: '100%',
    marginTop: 5,
    fontSize: Platform.OS !== 'web' ? 18 : 14,
    textAlign: 'center',
  },
  infosContainer: {
    flex: 1,
    paddingBottom: '10%',
    paddingHorizontal: '10%',
    justifyContent: 'flex-end',
  },
  logo: {
    width: '45%',
    resizeMode: 'cover',
    height: '30%',
  },
  title: {
    fontSize: Platform.OS === 'ios' ? 30 : 24,
    color: colors.white,
    fontFamily: fonts.lato.bold,
    marginBottom: '5%',
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  location: {
    color: colors.white,
    fontFamily: fonts.lato.regular,
    fontSize: Platform.OS === 'ios' ? 14 : 12,
  },
});
