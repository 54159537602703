import { connect } from 'react-redux';
import { default as GB } from 'react-google-button';
import { useGoogleLogin } from '@react-oauth/google';

import { GoogleLogin } from 'store/actions/account';

export const GoogleButton = ({ onSucceed, type }) => {
  const login = useGoogleLogin({
    onSuccess: async codeResponse => {
      console.log('codeReponse', codeResponse);
      onSucceed({ registerType: 'google', credential: codeResponse.code });
    },
    flow: 'auth-code',
  });

  return (
    <GB
      onClick={() => login()}
      label={type === 'signin' ? 'Se connecter avec Google' : 'S\'inscire avec Google'}
      style={{
        width: 250,
        marginBottom: 20,
      }}
    />
  );
};

const mapStateToProps = () => ({});

const actionProps = { GoogleLogin };

export default connect(mapStateToProps, actionProps)(GoogleButton);
