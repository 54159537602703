import React from 'react';
import { View } from 'native-base';
import { EvilIcons } from '@expo/vector-icons';
import { Image, Text, TouchableOpacity } from 'react-native';

import styles from './UserStyles';
import { pushToUser } from 'services/navigationPush';
import { IconSwapBlanc, IconSwapCouleur } from 'styles/svg';

import DarkBackground, { DisableCardBackground } from 'components/backgrounds/darkBackground';

export default (props) => {
  const onPress = () => {
    if (props.onPress) {
      props.onPress();
    } else {
      pushToUser(props.push, props);
    }
  };

  return (
    <TouchableOpacity disabled={props.disabled} activeOpacity={1} style={[styles.container, props.bg && { backgroundColor: props.bg }]} onPress={onPress}>
      <Image alt="Change me" source={{ uri: props.picture_url }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}/>
      {
        !props.disabled && (
          <DarkBackground />
        )
      }
      {props.swap && (
        <TouchableOpacity style={styles.swapContainer}>
          <IconSwapCouleur height={40} width={40} />
        </TouchableOpacity>
      )}
      <View style={styles.infosContainer}>
        {/*{!!props.logo_url && <RoundedLogo uri={props.logo_url} style={{ marginBottom: 10 }} width={isBrowser ? 50 : 80} borderRadius={isBrowser ? 5 : 10} />}*/}
        {!!props.surname && <Text numberOfLines={1} selectable style={styles.surname}>{props.surname.charAt(0).toUpperCase() + props.surname.slice(1)}</Text>}
        <Text selectable style={styles.name}>{props.firstname} {props.lastname}</Text>
        {!!props.job && <Text style={styles.job}>{props.job}</Text>}
        {!!props.link ? (
          <TouchableOpacity style={styles.linkContainer}>
            <IconSwapBlanc height={20} width={20} />
            <Text style={styles.link}>{props.link}</Text>
          </TouchableOpacity>
        ) : (
          <View style={styles.locationContainer}>
            {!!props.address && <EvilIcons name="location" size={20} color="white" />}
            {!!props.address && <Text style={styles.location}>{props.address?.city}</Text>}
          </View>
        )}
      </View>
      {
        props.disabled && (
          <DisableCardBackground />
        )
      }
    </TouchableOpacity>
  );
};
