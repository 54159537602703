import { StyleSheet } from 'react-native';

import { colors, fonts } from 'styles';

export default StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
  },
  cardContainer: {
    height: 400,
    width: '90%',
  },
  text: {
    fontFamily: fonts.lato.bold,
    fontSize: 20,
    color: colors.grey,
    marginTop: 40,
    width: '60%',
    textAlign: 'center',
    marginBottom: 10,
  },
  description: {
    fontFamily: fonts.lato.regular,
    fontSize: 14,
    color: colors.greyDayColor,
    width: '80%',
    textAlign: 'center',
    marginBottom: 43,
  },
  hollowContainer: {
    width: '100%',
    marginTop: 40,
    marginBottom: 80,
    alignItems: 'center',
  },
  redText: {
    color: colors.burgundy,
  },
  bottomSpacer: {
    height: 40,
  },
});
