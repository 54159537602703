import { CopyToClipboard } from 'react-copy-to-clipboard';

import { toastSuccess } from 'services/toasts';

const CopyToClip = ({ children, text, copyText = 'Copié !' }) => (
  <CopyToClipboard text={text} onCopy={() => toastSuccess(copyText)}>
    {children}
  </CopyToClipboard>
);

export default CopyToClip;
