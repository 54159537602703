import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Alert } from 'react-native';

import { toastError, toastSuccess } from 'services/toasts';
import { ResetPassword as resetPassword } from 'store/actions/account';

import ResetPasswordForm from './ResetPasswordForm';
import styles from '../register/stepOne/StepOneStyle';

import Logo from 'components/logo';
import Svg from 'components/svg/Svg';
import AuthGoBack from 'components/authGoBack';
import TextSection from 'components/textSection';
import BombButton from 'components/buttons/bombButton';
import { isBrowser, BrowserView, isMobile } from 'components/device';
import LinearBackground from 'components/backgrounds/linearBackground';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';

function ResetPassword({ submit, resetPassword, navigation: { push, pop, goBack }, route }) {
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');

  const actionResetPassword = async (values) => {
    try {
      setLoading(true);
      console.log(values);
      console.log(route);
      await resetPassword({
        ...values,
        token: route.params.token,
      });
      if (isMobile) {
        Alert.alert(
          'Succès',
          'Mot de passe réinitialisé !'
            [
              {
                onPress: pop,
              }
            ],
        );
      }
      if (isBrowser) {
        push('ResetPasswordSuccess');
        toastSuccess('Mot de passe réinitialisé !');
      }
    } catch (err) {
      console.log('err', err);
      setError('Email incorrect');
      setTimeout(() => {
        setError('');
      }, 3000);
      if (isBrowser) {
        toastError('Une erreur est survenu, veuillez nous contacter');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <LinearBackground />
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <View style={{ marginTop: '2%', marginLeft: '2%', width: '100%' }}>
          <AuthGoBack goBack={goBack} />
        </View>
        <View style={styles.container}>
          <View style={styles.logo}>
            <Logo/>
          </View>
          <View style={{
            width: '100%',
            alignItems: 'center',
            ...isBrowser && {
              width: 450,
              paddingTop: 50,
              borderRadius: 30,
              paddingBottom: 50,
              backgroundColor: 'white',
            }
          }}>
            <BrowserView style={{ height: 'auto' }}>
              <View style={{ flexDirection: 'column',  width: '100%', alignItems: 'center', marginBottom: 40 }}>
                <TextSection titleSection="Réinitialisation du mot de passe" noBar />
                <View style={{ width: 30, height: 1, marginTop: 20, backgroundColor: 'black' }} />
              </View>
            </BrowserView>
            <ResetPasswordForm onSubmit={actionResetPassword} />
            <Text style={styles.forgotTxt}>Entrez l'email de votre compte afin de recevoir un lien de réinitialisation de mot de passe.</Text>
            <BombButton
              height={66}
              width={255}
              images={null}
              loading={loading}
              titre="Réinitialiser"
              titrePosition="middle"
              onPress={() => submit('resetPassword')}
              style={{ marginTop: '20%', marginBottom: 10 }}
            />
            <TouchableOpacity style={{ marginTop: 40 }} onPress={() => push('Login')}>
              <Text style={styles.forgotTxt}> Se connecter</Text>
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
}

const mapStateToProps = () => ({});

const actionProps = { resetPassword, submit };

export default connect(mapStateToProps, actionProps)(ResetPassword);
