import { Text, View } from 'react-native';
import { colors, TextStyles } from '../../../styles';
import { Pressable } from 'native-base';
import React from 'react';

const EncartTitle = ({ onRemove, index, name }) => (
  <View style={{ width: '90%', marginTop: 20, marginBottom: 5, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
    <Text style={TextStyles({ margin: 20, marginLeft: 20 }).bold16}>
      {name} #{index + 1}
    </Text>
    {!!onRemove && <Pressable onPress={() => onRemove()} style={{ marginLeft: 'auto' }}>
      <Text style={TextStyles({ color: colors.burgundy }).bold14}>
        Supprimer
      </Text>
    </Pressable>}
  </View>
);

export default EncartTitle;
