import Api from 'services/api';

export function createNetwork(values) {
  return async (dispatch, getState) => {
    try {
      const data = {
        ...values,
      };
      const response = await Api.networks.create(data);
      if (response?.status === 201) {
        return response;
      } else {
        console.error('Create event:', response);
        return null;
      }
    } catch (err) {
      console.log('err', err);
      return null;
    }
  };
}

export function editNetwork(values) {
  return async (dispatch, getState) => {
    try {
      const response = await Api.networks.edit(values.id, values);
      if (response?.status === 201) {
        return response;
      } else {
        console.error('Create event:', response);
        return null;
      }
    } catch (err) {
      console.error('err', err);
      return null;
    }
  };
}

export function Networks() {
  return async (dispatch) => {
    const response = await Api.networks.getAll();
    if (response?.status === 200) {
      const networks = response.data;
      dispatch({
        type: 'LOAD_NETWORKS',
        networks,
      });
      return networks;
    }
  };
}

export function Network(id, isPublic = false) {
  return async (dispatch) => {
    let response;
    if (isPublic) {
      response = await Api.networks.getPublic(id);
    } else {
      response = await Api.networks.get(id);
    }
    if (response?.status === 200) {
      const network = response.data;
      dispatch({
        type: 'LOAD_NETWORK',
        network,
      });
    }
  };
}

export function HomeNetworks() {
  return async (dispatch) => {
    const response = await Api.networks.getAll();
    if (response?.status === 200) {
      const homeNetworks = response.data;
      dispatch({
        type: 'LOAD_HOME_NETWORKS',
        homeNetworks,
      });
      return homeNetworks;
    }

    return [];
  };
}

export function JoinNetwork(id) {
  return async () => {
    const response = await Api.networks.join(id);
    if (response?.status === 200) {
      const res = response.data;
      return res;
    }
    return null;
  };
}

export function inviteNetwork(data) {
  return async () => {
    try {
      const response = await Api.networks.invite(data.name, data.email, data.message);
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in invite network', err);
      return Promise.reject(err);
    }
  };
}

export function contactNetwork(networkId, data) {
  return async () => {
    try {
      const response = await Api.networks.contact(networkId, data.title, data.message);
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in invite network', err);
      return Promise.reject(err);
    }
  };
}
