import React from 'react';
import { View, Text } from 'native-base';
import { TouchableOpacity, Alert, Dimensions, Platform } from 'react-native';

import { MenuStyle } from './MenuStyle';

import Svg from 'components/svg';
import BombButton from 'components/buttons/bombButton';
import HollowBackground from 'components/backgrounds/hollowBackground';

const { height } = Dimensions.get('window');

export default function MenuItem({ data, onPress, svgSize = 49, containerSize = undefined }) {
  const defaultContainerSize = height * 0.112;

  return (
    <View>
      {!data.round ? (
        <TouchableOpacity
          onPress={() => {
            onPress();
          }}
          style={[MenuStyle.containerDataBloc, Platform.OS === 'web' ? { width: containerSize || defaultContainerSize, height: containerSize || defaultContainerSize } : null]}
        >
          <HollowBackground />
          <View style={MenuStyle.imageContainer}>
            <Svg src={data.image} width={svgSize} height={svgSize} />
          </View>
          <Text style={MenuStyle.text}>{data.title}</Text>
        </TouchableOpacity>
      ) : (
        <BombButton
          onPress={() => {
            if (data.disabled) {
              Alert.alert('Bientôt disponible !', 'Cette fonctionnalité est encore en cours de développement');
              return;
            }
            onPress();
          }}
          style={{ margin: 10 }}
          borderRadius={Platform !== 'web' ? height * 0.075 : 30}
          height={Platform.OS !== 'web' ? height * 0.15 : height * 0.12}
          width={Platform.OS !== 'web' ? height * 0.15 : height * 0.12}
          images={null}
          svg={data.image}
          titre={data.title}
        />
      )}
    </View>
  );
}
