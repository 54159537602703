'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.Misc = void 0;
var index_1 = require('./index');
var Misc = /** @class */ (function (_super) {
  __extends(Misc, _super);
  function Misc() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Misc.prototype.getSpheres = function () {
    return this.requester.get('/misc/spheres');
  };
  Misc.prototype.getZones = function () {
    return this.requester.get('/misc/zones');
  };
  Misc.prototype.getOpenZones = function () {
    return this.requester.get('/misc/zones?open=true');
  };
  Misc.prototype.getNetworkTypes = function () {
    return this.requester.get('/misc/types');
  };
  Misc.prototype.getHome = function () {
    return this.requester.get('/misc/home');
  };
  Misc.prototype.report = function (data) {
    return this.requester.post('/misc/report', data);
  };
  return Misc;
}(index_1.Component));
exports.Misc = Misc;
