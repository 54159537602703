import React from 'react';
import { View } from 'react-native';

import { reduxForm, Field } from 'redux-form';

import Input from 'components/form/input';
import styles from '../register/stepOne/StepOneStyle';

const validate = (values) => {
  const errors = {};

  if (!values.password || values.password.length === 0) {
    errors.password = 'Obligatoire';
  } else if (values.password.length < 6) {
    errors.password = 'Miniumum 6 caractères';
  }

  if (!values.confirmPassword || values.confirmPassword.length === 0) {
    errors.confirmPassword = 'Obligatoire';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Les mots de passe ne correspondent pas';
  }

  return errors;
};

function ResetPasswordForm() {
  return (
    <View style={styles.form}>
      <Field
        centered
        name="password"
        label="Mot de passe"
        secureTextEntry
        autoCorrect={false}
        autoCapitalize="none"
        component={Input}
        required={true}
        width="100%"
      />
      <Field
        centered
        name="confirmPassword"
        label="Répéter Mot de passe"
        secureTextEntry
        autoCorrect={false}
        autoCapitalize="none"
        component={Input}
        required={true}
        width="100%"
      />
    </View>
  );
}

export default reduxForm({ form: 'resetPassword', validate, enableReinitialize: true })(ResetPasswordForm);
