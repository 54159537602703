import React from 'react';
import { View } from 'react-native';
import { Field, reduxForm } from 'redux-form';

import styles from './CreateSwapRequestStyle';

import Input from 'components/form/input/Input';
import { isMobile, isBrowser } from 'components/device';

const validate = (values) => {
  const errors = {};

  if (!values.description || values.description.length === 0) {
    errors.description = 'Obligatoire';
  }

  return errors;
};

function CreateSwapRequestForm({ name }) {
  return (
    <View style={[styles.form, { marginTop: isMobile ? 20 : 25 }]}>
      <Field
        multiline
        width="100%"
        required={true}
        component={Input}
        name="description"
        style={styles.field}
        height={isBrowser ? 200 : undefined}
        label={`Expliquez à ${name} pourquoi vous souhaitez le ou la rencontrer`}
      />
    </View>
  );
}

export default reduxForm({ form: 'swapRequest', validate, enableReinitialize: true })(CreateSwapRequestForm);
