import { StyleSheet, Dimensions, Platform } from 'react-native';

import { colors, fonts } from 'styles';

import { isMobile } from 'components/device';

const widthScreen = Dimensions.get('screen').width;

export const AccountStyles = StyleSheet.create({
  container: {
    paddingTop: 25,
    alignItems: 'center',
  },
  imgClose: {
    width: 150,
    height: 150,
    resizeMode: 'contain',
  },
  containerForm: {
    alignItems: 'center',
    paddingHorizontal: 25,
    backgroundColor: 'transparent',
    width: Platform.OS !== 'web' ? '100%' : '50%',
  },
  containerImgClose: {
    top: 50,
    left: 50,
  },
  errorStyle: {
    top: 20,
    width: 8,
    height: 30,
    right: -20,
    borderRadius: 5,
    position: 'absolute',
    backgroundColor: colors.burgundy,
  },
  containerInput: {
    height: 76,
    width: '85%',
    borderWidth: 7,
    borderRadius: 30,
    overflow: 'hidden',
    flexDirection: 'row',
    borderTopColor: '#aaa',
    borderLeftColor: '#aaa',
    justifyContent: 'center',
    borderRightColor: '#fff',
    borderBottomColor: '#fff',
    backgroundColor: colors.whiteShadow,
  },
  inputStyle: {
    width: '100%',
    elevation: 70,
    marginLeft: -50,
    shadowRadius: 2,
    borderRadius: 30,
    shadowOpacity: 1,
    fontWeight: 'bold',
    overflow: 'hidden',
    alignSelf: 'center',
    textAlign: 'center',
    shadowColor: colors.whiteShadow,
    shadowOffset: { width: 0, height: 1 },
  },
  textClose: {
    fontSize: 25,
    color: colors.grey,
    textAlign: 'center',
  },
  titre: {
    fontSize: 20,
    marginTop: 60,
    marginBottom: 10,
    color: colors.grey,
    fontFamily: fonts.lato.bold,
  },
  containerVideoLink: {
    marginTop: 60,
  },
  imgInput: {
    width: 50,
    height: 50,
    marginLeft: 10,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  borderStyleComponent: {
    borderRadius: 30,
  },
  componentDescription: {
    marginVertical: 30,
    backgroundColor: 'red'
  },
  centerStyleComponent: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorStylePhotos: {
    top: 200,
    right: 0,
    width: 3,
    height: 13,
    borderRadius: 5,
    position: 'absolute',
    backgroundColor: colors.burgundy,
  },
  StyleColor: {
    top: 440,
    left: 30,
    width: 29,
    zIndex: 2,
    height: 29,
    borderRadius: 18,
    position: 'absolute',
    borderColor: colors.white,
    backgroundColor: colors.red,
  },
  btnFinalize: {
    top: 90,
    zIndex: 2,
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.white,
    position: 'absolute',
    left: widthScreen * 0.35,
  },
  fieldsContainer: {
    width: '100%',
    alignItems: 'center',
  },
  emailInput: {
  },
  subField: {
    width: Platform.OS !== 'web' ? '90%' : '100%'
  },
  subFieldButton: {
    marginTop: 10,
    marginBottom: 40,
  },
  mobileGoBack: {
    position: 'absolute',
    top: 45,
    left: 25
  },
  webContainer: {
    width: '60%',
    paddingTop: 0,
    marginBottom: 20,
    marginLeft: '20%',
    alignItems: 'left',
  },
  submitButton: {
    marginTop: 30,
    marginBottom: 50,
    width: isMobile ? '70%' : '45%',
    marginHorizontal: isMobile ? 25 : 0,
  },
  webFormContainer: {
    width: '100%',
  },
  webFormFieldsContainer: {
    width: '100%',
    flexDirection: 'row',
  },
  webFormFirstFields: {
    width: '42%',
  },
  webFormSecondFields: {
    width: '53%',
    marginLeft: '2.5%',
  },
  webFormInformation: {
    flexDirection: 'row',
  },
  webFormInformationFirstInput: {
    width: '48%',
  },
  webFormInformationSecondInput: {
    width: '48%',
    marginLeft: '4%',
  },
  webSubFieldContainer: {
    width: '100%',
  },
  webSubFieldDescription: {
    marginBottom: 20,
  },
  webSubFieldAddButton: {
    marginTop: 10,
    marginBottom: 40,
  }
});
