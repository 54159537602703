import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import { View, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { colors } from 'styles';

import { GoBackStyle } from './GoBackStyle';

export default function GoBack({ styles, onPress }) {
  const navigation = useNavigation();

  function onClick() {
    if (onPress) {
      onPress();
    } else {
      navigation.goBack();
    }
  }

  if (!navigation.canGoBack() && !onPress) return null;
  return (
    <TouchableOpacity onPress={() => onClick()} style={[GoBackStyle.container, styles]}>
      <View style={GoBackStyle.contentClose}>
        <Ionicons name="arrow-back-outline" size={25} color={colors.grey} />
      </View>
    </TouchableOpacity>
  );
}
