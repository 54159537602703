import { connect } from 'react-redux';
import React, { useState } from 'react';
import { ImageBackground, Text, View } from 'react-native';
import { ChevronDownIcon, ChevronUpIcon, Pressable } from 'native-base';

import { TextStyles } from 'styles';
import { pushToUser } from 'services/navigationPush';
import { acceptSwapRequest, rejectSwapRequest } from 'store/actions/user';

import ConfirmationModal from './ConfirmationModal';
import SwapRequestsStyles from './SwapRequestsStyles';

import GrayButton from 'components/buttons/grayButton';
import HollowBackground from 'components/backgrounds/hollowBackground/HollowBackground';
import DarkBackground from '../../../components/backgrounds/darkBackground';

const WebSwapRequestCard = ({ item, push, acceptSwapRequest, rejectSwapRequest }) => {
  const [isMessageOpen, setMessageOpen] = useState(false);
  const [confirmationId, setConfirmationId] = useState('');
  const [confirmationName, setConfirmationName] = useState('');
  const [confirmationType, setConfirmationType] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [acceptSwapResponseLoading, setAcceptSwapResponseLoading] = useState(false);
  const [refuseSwapResponseLoading, setRefuseSwapResponseLoading] = useState(false);

  if (item.empty) {
    return <View style={SwapRequestsStyles.swapRequestContainer} />;
  }

  const currentDate = new Date();
  const itemDate = new Date(item.createdAt);
  let receivedDay = currentDate.getTime() - itemDate.getTime();
  receivedDay = Math.round(receivedDay / (1000 * 3600 * 24));

  if (receivedDay === 0) {
    receivedDay = 'Demande reçue aujourd\'hui';
  } else if (receivedDay === 1) {
    receivedDay = 'Demande reçue hier';
  } else {
    receivedDay = `Demande reçue il y a ${receivedDay} jours`;
  }

  const ResponseButtons = ({ id, name }) => (
    <View style={SwapRequestsStyles.swapRequestCardButtonContainer}>
      <GrayButton
        width="48%"
        titre="Accepter"
        loading={acceptSwapResponseLoading}
        onPress={() => {
          setConfirmationId(id);
          setConfirmationName(name);
          window.scrollTo(0, 0);
          setConfirmationType('accepted');
          setConfirmationModalOpen(true);
        }}
      />
      <GrayButton
        width="48%"
        titre="Refuser"
        loading={refuseSwapResponseLoading}
        style={SwapRequestsStyles.swapRequestRefuseButton}
        onPress={() => {
          setConfirmationId(id);
          setConfirmationName(name);
          window.scrollTo(0, 0);
          setConfirmationType('refused');
          setConfirmationModalOpen(true);
        }}
      />
    </View>
  );

  const CloseContainer = () => (
    <Pressable style={SwapRequestsStyles.swapRequestContainer} onPress={() => pushToUser(push, item.author)}>
      <View style={SwapRequestsStyles.swapRequestCloseImageContainer}>
        <ImageBackground
          source={{ uri: item.author?.picture_url }}
          style={SwapRequestsStyles.swapRequestCloseImage}
          imageStyle={SwapRequestsStyles.swapRequestCloseImageStyle}
        >
          <DarkBackground />
          <View style={SwapRequestsStyles.swapRequestCloseDayContainer}>
            <Text selectable numberOfLines={1} style={TextStyles(SwapRequestsStyles.closeColor).bold30}>{item.author?.firstname} {item.author?.lastname}</Text>
            <Text selectable numberOfLines={1} style={TextStyles(SwapRequestsStyles.closeColor).bold16}>{item.author?.job}</Text>
            <Text selectable numberOfLines={1} style={TextStyles(SwapRequestsStyles.swapRequestDayText).regular12}>
              {receivedDay}
            </Text>
          </View>
        </ImageBackground>
      </View>
      <Pressable onPress={() => setMessageOpen(true)} style={SwapRequestsStyles.swapRequestCloseIconContainer}>
        <ChevronUpIcon size={18} style={SwapRequestsStyles.closeColor} />
        <Text selectable style={TextStyles(SwapRequestsStyles.closeColor).bold20}>Voir le message</Text>
      </Pressable>
      <ResponseButtons id={item.id} name={`${item.author?.firstname} ${item.author?.lastname}`} />
    </Pressable>
  );

  const OpenContainer = () => (
    <View style={SwapRequestsStyles.swapRequestContainer}>
      <Pressable onPress={() => setMessageOpen(false)} style={SwapRequestsStyles.swapRequestOpenIconContainer}>
        <ChevronDownIcon size={18} style={SwapRequestsStyles.closeColor} />
      </Pressable>
      <View style={SwapRequestsStyles.swapRequestDescriptionContainer}>
        <HollowBackground />
        <Text selectable style={TextStyles(SwapRequestsStyles.swapRequestDescriptionText).regular15}>
          {item.description || 'Aucune description n\'a été renseignée'}
        </Text>
      </View>
      <ResponseButtons id={item.id} name={`${item.author?.firstname} ${item.author?.lastname}`} />
    </View>
  );

  return (
    <>
      <ConfirmationModal
        id={confirmationId}
        name={confirmationName}
        type={confirmationType}
        isOpen={confirmationModalOpen}
        setIsOpen={setConfirmationModalOpen}
        rejectSwapRequest={rejectSwapRequest}
        acceptSwapRequest={acceptSwapRequest}
        setRefuseSwapResponseLoading={setRefuseSwapResponseLoading}
        setAcceptSwapResponseLoading={setAcceptSwapResponseLoading}
      />
      {!isMessageOpen ? (
        <CloseContainer />
      ) : (
        <OpenContainer />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const ActionsProps = {
  acceptSwapRequest,
  rejectSwapRequest
};

export default connect(mapStateToProps, ActionsProps)(WebSwapRequestCard);
