import { connect } from 'react-redux';
import React, { useState } from 'react';
import { ScrollView, Text, View } from 'native-base';
import { Image, Platform, TouchableOpacity } from 'react-native';

import { fonts, colors } from 'styles';
import { getGivenRecommendations, getReceivedRecommendations } from 'store/actions/recommendation';

import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import HollowBackground from 'components/backgrounds/hollowBackground';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';

import { svgtopng } from '../../styles/images';
import NoResources from '../../components/noResources';
import GivenRecommendations from './GivenRecommendations';
import GrayButton from '../../components/buttons/grayButton';
import ReceivedRecommendation from './ReceivedRecommendation';

const Recommendations = ({
  getGivenRecommendations,
  getReceivedRecommendations,
  receivedRecommendations,
  givenRecommendations,
  navigation: { push, goBack },
  route,
}) => {
  const [section, setSection] = useState(parseInt(route.params?.section || 1));

  const fetchRecommendations = async () => {
    await getGivenRecommendations();
    await getReceivedRecommendations();
  };

  const Choice = ({ title, color, textColor, onPress }) => (
    <TouchableOpacity
      style={{
        width: '70%',
        padding: 10,
        height: '75%',
        marginLeft: '5%',
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color,
      }}
      onPress={onPress}
    >
      <Text style={{ fontFamily: fonts.lato.bold, fontSize: 16, color: textColor }}>{title}</Text>
    </TouchableOpacity>
  );

  const RecommendationsSectionChoice = () => (
    <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'space-between', width: '100%' }}>
      <View
        style={{
          height: 60,
          width: '47.5%',
          borderRadius: 50,
          overflow: 'hidden',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <HollowBackground />
        {
          section === 1 ? (
            <Choice title="Données" color={colors.burgundy} onPress={() => setSection(1)} textColor={colors.white} />
          ) : (
            <Choice title="Données" color={colors.white} onPress={() => setSection(1)} textColor={colors.black} />
          )
        }
        <View style={{ alignItems: 'center', marginRight: '10%', width: '20%' }}>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 16 }}>{givenRecommendations.length}</Text>
        </View>
      </View>
      <View
        style={{
          height: 60,
          width: '47.5%',
          borderRadius: 50,
          overflow: 'hidden',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <HollowBackground />
        {
          section === 2 ? (
            <Choice title="Reçues" color={colors.blue} onPress={() => setSection(2)} textColor={colors.white} />
          ) : (
            <Choice title="Reçues" color={colors.white} onPress={() => setSection(2)} textColor={colors.black} />
          )
        }
        <View style={{ alignItems: 'center', marginRight: '10%', width: '20%' }}>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 16 }}>{receivedRecommendations.length}</Text>
        </View>
      </View>
    </View>
  );

  const RecommendationsContainer = () => {
    if (givenRecommendations.length === 0 && receivedRecommendations.length === 0) {
      return (
        <NoResources
          noResourceText="Vous n'avez pas encore de recommandation"
          findResourceText="Une fois que vous aurez reçu ou donné une recommandation, vous pourrez la retrouver ici"
          buttons={[
            () => <GrayButton width="100%" onPress={() => push('Relations', { onSelect: (user) => push('AddRecommendation', { userId: user.id }) })} titre="Recommander un de mes contacts" />,
          ]}
        />
      );
    } else {
      return (
        <View style={{ flex: 1, width: '100%', paddingHorizontal: 20 }}>
          {
            Platform.OS !== 'web' ? (
              <RecommendationsSectionChoice />
            ) : (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ width: '25%', marginLeft: '10%' }}>
                  <RecommendationsSectionChoice />
                </View>
                <View style={{ width: '90%' }}>
                  {
                    section === 1 ? (
                      <View style={{ marginLeft: 'auto', marginRight: '25%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '52.5%' }}>
                        <Text style={{ fontSize: 16, fontFamily: fonts.lato.regular, marginRight: 4 }}>Chiffre d'affaire</Text>
                        <Text style={{ fontSize: 16, fontFamily: fonts.lato.bold, marginRight: 15 }}>distribué</Text>
                        <View style={{
                          height: 60,
                          width: '20%',
                          borderRadius: 50,
                          overflow: 'hidden',
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}>
                          <HollowBackground />
                          <Text style={{ fontSize: 16, fontFamily: fonts.lato.bold, color: colors.burgundy }}>
                            {givenRecommendations.reduce((prev, next) => prev + next.contract_amount || 0, 0)}€
                          </Text>
                          <Text style={{ fontSize: 13, fontFamily: fonts.lato.bold, marginLeft: 5 }}>
                            HT
                          </Text>
                        </View>
                      </View>
                    ) : (
                      <View style={{ marginLeft: 'auto', marginRight: '25%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '52.5%' }}>
                        <Text style={{ fontSize: 16, fontFamily: fonts.lato.regular, marginRight: 4 }}>Chiffre d'affaire</Text>
                        <Text style={{ fontSize: 16, fontFamily: fonts.lato.bold, marginRight: 15 }}>généré</Text>
                        <View style={{
                          height: 60,
                          width: '20%',
                          borderRadius: 50,
                          overflow: 'hidden',
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}>
                          <HollowBackground />
                          <Text style={{ fontSize: 16, fontFamily: fonts.lato.bold, color: colors.blue }}>
                            {receivedRecommendations.reduce((prev, next) => prev + next.contract_amount || 0, 0)}€
                          </Text>
                          <Text style={{ fontSize: 13, fontFamily: fonts.lato.bold, marginLeft: 5 }}>
                            HT
                          </Text>
                        </View>
                      </View>
                    )
                  }
                </View>
              </View>
            )
          }
          {
            Platform.OS !== 'web' && (
              <View style={{ flexDirection: 'row', marginTop: '5%', marginBottom: '5%', marginLeft: '2%' }}>
                <View style={{ width: '70%' }}>
                  <Text style={{ fontFamily: fonts.lato.bold, fontSize: 14 }}>Contacts</Text>
                </View>
                <View style={{ width: '20%' }}>
                  <Text style={{ fontFamily: fonts.lato.bold, fontSize: 14 }}>Statut</Text>
                </View>
              </View>
            )
          }
          {
            Platform.OS === 'web' && (
              <View style={{ marginTop: '1%', marginBottom: '2%', width: '80%', marginLeft: '10%' }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Image alt="Change me" resizeMode="contain" style={{ width: 30, height: 30 }} source={svgtopng.other.other} />
                  <Text style={{ fontFamily: fonts.lato.bold, fontSize: 30, marginLeft: 15 }}>Recommandations {section === 1 ? 'envoyées' : 'reçues'}</Text>
                </View>
              </View>
            )
          }
          <ScrollView style={{ width: '100%', height: '100%' }} contentContainerStyle={{ width: '100%', alignItems: Platform.OS !== 'web' ? 'center' : 'flex-start' }}>
            {
              section === 1 ? (
                givenRecommendations.length > 0 ? (
                  <GivenRecommendations recommendations={givenRecommendations} push={push} />
                ) : (
                  <NoResources
                    noResourceText="Vous n'avez pas encore envoyé de recommendation"
                    findResourceText="Une fois que vous aurez envoyé votre première recommendation, vous pourrez la retrouver ici"
                    buttons={[
                      () => <GrayButton width="100%" onPress={() => push('Relations')} titre="Recommander un contact" />,
                    ]}
                  />
                )
              ) : (
                receivedRecommendations.length > 0 ? (
                  <ReceivedRecommendation recommendations={receivedRecommendations} push={push} />
                ) : (
                  <NoResources
                    noResourceText="Vous n'avez pas encore reçu de recommendation"
                    findResourceText="Une fois que vous aurez recu votre première recommendation, vous pourrez la retrouver ici"
                  />
                )
              )
            }
          </ScrollView>
        </View>
      );
    }
  };

  return (
    <LoadResourceLayout
      push={push}
      disableGoBack={true}
      mobilePadding={false}
      title="Recommandations"
      loadResources={fetchRecommendations}
      breadCrumb={<Breadcrumb push={push} goBack={goBack} menuSelect="Othrs" />}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
    >
      <RecommendationsContainer />
    </LoadResourceLayout>
  );
};

const mapStateToProps = (state) => ({
  givenRecommendations: state.Recommendation.givenRecommendations,
  receivedRecommendations: state.Recommendation.receivedRecommendations,
});

const mapDispatchToProps = {
  getGivenRecommendations,
  getReceivedRecommendations,
};

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);
