import React from 'react';
import moment from 'moment';
import { FlatList, Text, View } from 'native-base';
import { Image, Platform, TouchableOpacity } from 'react-native';

import { fonts, colors } from 'styles';
import RelationsStyle from '../users/relations/RelationsStyle';
import WebRecommendationCard from './WebRecommendationCard';
import HollowBackground from 'components/backgrounds/hollowBackground/HollowBackground';

const RecommendationStatus = ({ status, amount }) => {
  if (status === 'PENDING') {
    return <Text style={{ fontFamily: fonts.lato.bold, fontSize: 14 }}>En attente</Text>;
  } else if (status === 'ACCEPTED') {
    return <Text style={{ fontFamily: fonts.lato.bold, fontSize: 14, color: colors.blue }}>Accepté</Text>;
  } else if (status === 'REFUSED') {
    return <Text style={{ fontFamily: fonts.lato.bold, fontSize: 14, color: colors.red }}>Refusé</Text>;
  }
  if (!amount) {
    return <Text style={{ fontFamily: fonts.lato.bold, fontSize: 14, color: colors.orange }}>Erreur</Text>;
  }
  return <Text style={{ fontFamily: fonts.lato.bold, fontSize: 14, color: colors.blue }}>{amount}€</Text>;
};

const formatData = (recommendations) => {
  const pending = recommendations.filter((r) => r.status === 'PENDING');
  const accepted = recommendations.filter((r) => r.status === 'ACCEPTED');
  const refused = recommendations.filter((r) => r.status === 'REFUSED');
  const signed = recommendations.filter((r) => r.status === 'CONTRACT_SIGNED');
  return pending.concat(accepted.concat(refused.concat(signed)));
};

const MobileReceivedRecommendations = ({ recommendations, push }) => (
  formatData(recommendations).map((recommendation, index) => (
    <TouchableOpacity onPress={() => push('RecommendationDetails', { recommendationId: recommendation.id })} key={index} style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
      <View style={[RelationsStyle.containerInfo, { width: '68%' }]}>
        <HollowBackground />
        <View style={RelationsStyle.itemUser}>
          <View style={RelationsStyle.imageContainer}>
            <Image
              alt="Change me"
              source={{ uri: `${recommendation.user.picture_url}` }}
              style={RelationsStyle.imageProfile}
            />
          </View>
          <View style={RelationsStyle.textUser}>
            <Text style={RelationsStyle.nameUser} numberOfLines={1}>
              {recommendation.user.firstname} {recommendation.user.lastname}
            </Text>
            <Text style={RelationsStyle.ProfessionUser}>
              {recommendation.user.job}
            </Text>
            <Text style={RelationsStyle.recommendationDate}>
              {moment(recommendation.createdAt).format('DD-MM-YYYY')}
            </Text>
          </View>
        </View>
        <View style={RelationsStyle.vertLineUser} />
      </View>
      <View style={[RelationsStyle.containerInfo, { marginLeft: '3%', width: '27%', justifyContent: 'center' }]}>
        <HollowBackground />
        <RecommendationStatus status={recommendation.status} amount={recommendation.contract_amount} />
      </View>
    </TouchableOpacity>
  ))
);

const WebReceivedRecommendations = ({ recommendations, push }) => (
  <View style={{ width: '85%', marginLeft: '10%' }}>
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      {
        recommendations.map((r) => (
          <TouchableOpacity
            onPress={() => push('RecommendationDetails', { recommendationId: r.id })}
            style={{ marginRight: 20, marginBottom: 20 }}
          >
            <WebRecommendationCard self recommendation={r} />
          </TouchableOpacity>
        ))
      }
    </View>
  </View>
);

const ReceivedRecommendations = ({ recommendations, push }) => {
  if (Platform.OS !== 'web') {
    return <MobileReceivedRecommendations recommendations={recommendations} push={push} />;
  }
  return <WebReceivedRecommendations recommendations={recommendations} push={push} />;
};

export default ReceivedRecommendations;
