import React from 'react';
import { Text } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';
import { ActivityIndicator, TouchableOpacity, View } from 'react-native';

import { colors, fonts } from 'styles';

import styles from './WhiteButtonStyle';

import Svg from 'components/svg';
import { MobileView, BrowserView } from 'components/device';
import HollowBackground from 'components/backgrounds/hollowBackground';

const MobileContent = (props) => (
  <TouchableOpacity style={{ ...styles.button, backgroundColor: props.color || colors.white }} onPress={props.onPress} disabled={props.loading || false}>
    {props.loading ? (
      <ActivityIndicator color={props.destructive ? colors.red : colors.grey} />
    ): (
      <View style={{ alignItems: 'center', flexDirection: 'row', }}>
        {!!props.svg && <Svg src={props.svg} style={styles.svg}/>}
        <Text numberOfLines={1} style={{ color: props.destructive ? colors.red : colors.grey, fontFamily: fonts.lato.bold, fontSize: Platform.OS === 'ios' ? 16 : 14 }}>
          {props.text}
        </Text>
      </View>
    )}
  </TouchableOpacity>
);

const WebContent = (props) => (
  <TouchableOpacity style={styles.button} onPress={props.onPress} disabled={props.loading || false}>
    {props.loading ? (
      <ActivityIndicator color="white"/>
    ): (
      <View style={{ alignItems: 'center', flexDirection: 'row', }}>
        {!!props.svg && <Svg src={props.svg} style={styles.svg}/>}
        <Text numberOfLines={1} style={{ color: props.destructive ? colors.red : colors.grey, fontFamily: fonts.lato.bold, fontSize: 16 }}>
          {props.text}
        </Text>
      </View>
    )}
  </TouchableOpacity>
);

export default (props) => (
  <View style={{ ...styles.container, ...props.style }}>
    {props.noHollow ? null : <HollowBackground />}
    <View style={styles.buttonContainer}>
      <MobileView>
        <MobileContent {...props} />
      </MobileView>
      <BrowserView>
        <WebContent {...props} />
      </BrowserView>
    </View>
  </View>
);
