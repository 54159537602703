import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  gradient: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  }
});
