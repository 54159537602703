import {
  Lato_100Thin,
  Lato_100Thin_Italic,
  Lato_300Light,
  Lato_300Light_Italic,
  Lato_400Regular,
  Lato_400Regular_Italic,
  Lato_700Bold,
  Lato_700Bold_Italic,
  Lato_900Black,
  Lato_900Black_Italic,
} from '@expo-google-fonts/lato';
import {
  Sacramento_400Regular
} from '@expo-google-fonts/sacramento';

export const loadFonts = {
  Ionicons: require('../../assets/fonts/Ionicons.ttf'),
  AlexanderLettering: require('../../assets/fonts/AlexanderLettering.ttf'),
  Lato_900Black,
  Lato_900Black_Italic,
  Lato_700Bold,
  Lato_700Bold_Italic,
  Lato_400Regular,
  Lato_400Regular_Italic,
  Lato_300Light,
  Lato_300Light_Italic,
  Lato_100Thin,
  Lato_100Thin_Italic,
  Sacramento_400Regular,
};

export const fonts = {
  ionicons: 'Ionicons',
  lato: {
    black: 'Lato_900Black',
    blackItalic: 'Lato_900Black_Italic',
    bold: 'Lato_700Bold',
    boldItalic: 'Lato_700Bold_Italic',
    regular: 'Lato_400Regular',
    italic: 'Lato_400Regular_Italic',
    light: 'Lato_300Light',
    lightItalic: 'Lato_300Light_Italic',
    thin: 'Lato_100Thin',
    thinItalic: 'Lato_100Thin_Italic',
  },
  alexanderLettering: 'AlexanderLettering',
};
