import { StyleSheet, Dimensions } from 'react-native';
import { colors, fonts } from 'styles';

const widthScreen = Dimensions.get('screen').width;
const heightScreen = Dimensions.get('screen').height;
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  textTitle: {
    fontFamily: fonts.lato.bold,
    fontSize: 14,
  },
  textDescription: {
    fontFamily: fonts.lato.regular,
    fontSize: 8,
  },
  containerCount: {
    width: '90%',
    height: 75,
    borderRadius: 50,
    margin: '5%',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  containerInfo: {
    paddingLeft: 20,
    width: '60%',
  },
  containerInfoPrice: {
    width: '30%',
    height: 60,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  containerButton: {
    position: 'absolute',
    bottom: 15,
    left: (widthScreen * 0.5) - (169 / 2),
    width: 169,
    backgroundColor: colors.white,
    alignItems: 'center',
    padding: 20,
    borderRadius: (widthScreen * 0.25) / 2,
  },
  textCountContainer: {
    alignItems: 'center',
    width: '30%',
  },
  textCount: {
    fontWeight: 'bold',
    fontSize: 26,
  },
  titleCount: {
    fontSize: 14,
    color: colors.grey,
    marginTop: '2%',
  },
  descriptionContainer: {
    width: '100%',
    alignItems: 'center',
  },
  descriptionText: {
    width: '70%',
    marginVertical: 20,
    fontFamily: fonts.lato.regular,
    lineHeight: 22,
    color: colors.greyMedium2,
  },
  content: {
    flexDirection: 'column',
    backgroundColor: colors.whiteShadow,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: '10%',
  },
  title: {
    fontSize: 25,
    fontFamily: fonts.lato.bold,
    paddingTop: 40,
    paddingBottom: 20,
  },
  button: {
    width: 250,
    alignContent: 'center',
    backgroundColor: colors.palevioletred,
    borderRadius: 10,
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  containtSlider: {
    paddingLeft: 20,
    marginBottom: 30,
  },
  verticalLine: {
    height: 11,
    width: 3,
    borderRadius: 3,
    backgroundColor: colors.white,
    opacity: 0.1,
  },
  textBtn: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    fontSize: 12,
    textAlign: 'center',
    marginRight: 10,
  },
  containtBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.palevioletred,
    paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 66,
    paddingRight: 66,
    borderRadius: 150,
    textAlign: 'center',
  },
  centerStyle: {
    alignItems: 'center',
    marginBottom: 20,
  },
  valuesHollowContainer: {
    width: '100%',
    alignItems: 'center',
  },
  valuesContainer: {
    width: '75%',
    paddingVertical: 20,
  },
  valueItemContainer: {
    marginVertical: '5%',
  },
  valueTitle: {
    fontFamily: fonts.lato.bold,
    color: colors.burgundy,
    fontSize: 16,
    marginBottom: 7,
    textTransform: 'uppercase'
  },
  valueText: {
    fontFamily: fonts.lato.regular,
    fontSize: 14,
    lineHeight: 20,
    color: colors.greyMedium2
  },
  valuesSubtitle: {
    marginTop: '5%',
    fontFamily: fonts.lato.bold,
    fontSize: 16,
    width: '70%',
    paddingBottom: '5%',
  },
  multiColorButton: {
    width: '70%',
    height: 70,
  },
  modal: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    backgroundColor: colors.whiteShadow,
  },
  logoContainer: {
    width: '90%',
    marginTop: '15%',
    marginBottom: '10%',
    height: '20%',
    borderRadius: 30,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentSlideText: {
    borderRadius: 0,
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 15,
    width: '100%'
  },
  sizeDevice: {
    flexDirection: 'row',
    width: '75%',
    alignItems: 'center',
  },
  border: {
    width: 15,
    height: 3,
    backgroundColor: colors.white,
    borderRadius: 2,
  },
  device: {
    flexDirection: 'column',
    alignItems: 'center',
    height: 53,
    justifyContent: 'space-between',
  },
  deviceCenter: {
    marginHorizontal: 50,
  },
  contentOtherSlideNew: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentText: {
    alignItems: 'center',
    width: '100%',
  },
  nameHeader: {
    color: colors.white,
    fontSize: 24,
    fontFamily: fonts.lato.bold,
    textAlign: 'center',
  },
  groupName: {
    fontSize: 20,
    color: colors.white,
    marginTop: 5,
    fontFamily: fonts.lato.bold,
    marginBottom: 10,
  },
  jobHeader: {
    fontSize: 16,
    color: colors.white,
    marginTop: 5,
    fontFamily: fonts.lato.bold,
    marginBottom: 25,
  },
  addressHeader: {
    color: colors.white,
    fontFamily: fonts.lato.regular,
  },
});
