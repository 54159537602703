import { submit } from 'redux-form';
import { Alert } from 'react-native';
import { connect } from 'react-redux';
import React, { useMemo, useState } from 'react';
import { AlertDialog, Button, View } from 'native-base';

import CreateMediumForm from './CreateMediumForm';
import { CreateMediumStyle } from './CreateMediumStyle';

import Api from 'services/api';
import { toastSuccess, toastError } from 'services/toasts';

import CreateAs from 'components/createAs';
import GrayButton from 'components/buttons/grayButton';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import { isMobile, isBrowser, BrowserView } from 'components/device';
import BasicLayout from 'components/layouts/basicLayout/BasicLayout';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';
import MultiColorButton from 'components/buttons/multiColorButton/MultiColorButton';

const selector = {
  'article': {
    name: 'article',
    my: 'MyArticles',
    title: 'Magazine',
    update: 'EditArticle',
    details: 'ArticleDetails',
  },
  'podcast': {
    name: 'podcast',
    my: 'MyPodcasts',
    title: 'Podcasts',
    update: 'EditPodcast',
    details: 'PodcastDetails',
  },
  'video': {
    name: 'vidéo',
    my: 'MyVideos',
    title: 'Othr TV',
    update: 'EditVideo',
    details: 'VideoDetails',
  },
};

function AddMedium({ user, essentialUser, submit, navigation, type, mode, medium }) {
  const [createAs, setCreateAs] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [isWebDeleteDialogueOpen, setIsWebDeleteDialogueOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadMedium = async (file) => {
    const mimeType = type === 'video' ? 'video/mp4' : 'audio/mp3';
    const { data: { fileName, uploadId } } = await Api.media.startUpload(mimeType);
    setProgress(0.1);
    const fileSize = file.size;
    const CHUNK_SIZE = 8000000;
    const chunksCount = Math.floor(fileSize / CHUNK_SIZE) + 1;
    let start;
    let end;
    let blob;
    const chunks = [];
    for (let index = 1; index < chunksCount + 1; index++) {
      start = (index - 1) * CHUNK_SIZE;
      end = index * CHUNK_SIZE;
      blob = index < chunksCount ? file.slice(start, end) : file.slice(start);
      chunks.push({
        index,
        blob,
      });
    }

    const responses = await Promise.all(chunks.map(async (chunk) => {
      const { data: { presignedUrl } } = await Api.media.partUpload(fileName, chunk.index, uploadId);
      try {
        const result = await fetch(presignedUrl, {
          method: 'PUT',
          body: chunk.blob,
          headers: { 'Content-Type': mimeType, 'Access-Control-Expose-Headers': 'Etag' },
        });
        setProgress(progress + (1 / chunksCount));
        console.log(`Part number ${chunk.index} uploaded`);
        return result;
      } catch (error) {
        if (error.response) {
          console.log('Error axios.put', error.response);
        }
      }
    }));
    setProgress(0.95);
    await new Promise((r) => setTimeout(r, 600));
    const parts = responses.map((r, i) => ({
      ETag: r.headers.get('etag'),
      PartNumber: i + 1,
    }));
    const { data: { url } } = await Api.media.completeUpload(fileName, parts, uploadId);
    return url;
  };

  const createMedium = async (values) => {
    try {
      setPublishLoading(true);
      values.user = user;
      values.type = type;
      values.items = [
        { type: 'title', content: values.title },
        { type: 'introduction', content: values.introduction },
      ];
      if (!values.customItems) values.customItems = [];
      values.customItems.map((i) => {
        values.items.push({ type: 'subtitle', content: i.title });
        values.items.push({ type: 'paragraph', content: i.content });
        values.items.push({ type: 'picture', content: i.picture_url });
      });
      if (type === 'video' && values.video_url && typeof(values.video_url) !== 'string') {
        values.video_url = await uploadMedium(values.video_url);
      } else if (type === 'podcast' && values.audio_url && typeof(values.audio_url) !== 'string') {
        values.audio_url = await uploadMedium(values.audio_url);
      }
      await Api.media.createMedium({
        type,
        items: values.items,
        picture_url: values.picture_url,
        audio_url: values.audio_url,
        video_url: values.video_url,
        createAs,
      });
      if (isMobile) {
        Alert.alert(
          'Succès !',
          `Votre ${selector[type].name} a bien été publié${type === 'video' ? 'e' : ''}`, [
            {
              text: 'Ok',
              onPress: () => navigation.reset({
                index: 0,
                routes: [{ name: selector[type].my }],
              }),
            },
          ],
        );
      }
      if (isBrowser) {
        navigation.reset({
          index: 0,
          routes: [{ name: selector[type].my }],
        });
        toastSuccess(`Votre ${selector[type].name} a bien été publié${type === 'video' ? 'e' : ''}`);
      }
    } catch (err) {
      console.error('Error uploading media', err);
      if (isMobile) {
        Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
      }
      if (isBrowser) {
        toastError('Une erreur est survenue');
      }
    } finally {
      setPublishLoading(false);
    }
  };

  const updateMedium = async (values) => {
    try {
      setPublishLoading(true);
      values.user = user;
      values.type = type;
      values.items = [
        { type: 'title', content: values.title },
        { type: 'introduction', content: values.introduction },
      ];
      values.customItems?.map((i) => {
        values.items.push({ type: 'subtitle', content: i.title });
        values.items.push({ type: 'paragraph', content: i.content });
        values.items.push({ type: 'picture', content: i.picture_url });
      });
      if (type === 'video' && values.video_url && typeof(values.video_url) !== 'string') {
        values.video_url = await uploadMedium(values.video_url);
      } else if (type === 'podcast' && values.audio_url && typeof(values.audio_url) !== 'string') {
        values.audio_url = await uploadMedium(values.audio_url);
      }
      await Api.media.updateMedium(medium?.id, values);
      if (isMobile) {
        Alert.alert(
          'Succès !',
          `Votre ${selector[type].name} a bien été modifié${type === 'video' ? 'e' : ''}`, [
            {
              text: 'Ok',
              onPress: () => navigation.reset({
                index: 0,
                routes: [{ name: selector[type].my }],
              }),

            },
          ],
        );
      }
      if (isBrowser) {
        navigation.reset({
          index: 0,
          routes: [{ name: selector[type].my }],
        });
        toastSuccess(`Votre ${selector[type].name} a bien été modifié${type === 'video' ? 'e' : ''}`);
      }
    } catch (err) {
      console.error('Error updating media', err);
      if (isMobile) {
        Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
      }
      if (isBrowser) {
        toastError('Une erreur est survenue');
      }
    } finally {
      setPublishLoading(false);
    }
  };

  const confirmDeleteMobile = () => new Promise((resolve, reject) => {
    Alert.alert(
      'Attention',
      `Voulez-vous vraiment supprimer votre ${selector[type].name} ?`, [
        {
          text: 'Ok',
          onPress: resolve,
        },
        {
          text: 'Annuler',
          onPress: reject,
          style: 'destructive',
        },
      ],
    );
  });

  const deleteMedium = async (values) => {
    if (isMobile) {
      try {
        await confirmDeleteMobile();
      } catch (err) {
        return;
      }
    }
    try {
      setDeleteLoading(true);
      await Api.media.deleteMedium(medium?.id, values);
      Alert.alert(
        'Succès !',
        `Votre ${selector[type].name} a bien été supprimé`, [
          {
            text: 'Ok',
            onPress: () => navigation.reset({
              index: 0,
              routes: [{ name: selector[type].my }],
            }),
          },
        ],
      );
    } catch (err) {
      console.error('Error deleting media', err);
      Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
    } finally {
      setDeleteLoading(false);
    }
  };

  const initialValues = useMemo(() => {
    if (!medium) return { customItems: [{}] };
    const values = {
      ...medium,
      title: medium.items.find(i => i.type === 'title')?.content,
      introduction: medium.items.find(i => i.type === 'introduction')?.content,
      customItems: [],
    };
    const subtitles = medium.items.filter(i => i.type === 'subtitle');
    const paragraphs = medium.items.filter(i => i.type === 'paragraph');
    const pictures = medium.items.filter(i => i.type === 'picture');
    const customItemsLength = Math.min(subtitles.length, paragraphs.length, pictures.length);
    for (let i = 0; i < customItemsLength; i++) {
      values.customItems.push({
        id: medium.items[i].id,
        title: subtitles[i].content,
        content: paragraphs[i].content,
        picture_url: pictures[i].content,
      });
    }
    return values;
  }, [ medium ]);

  const AddMediumContainer = ({ children }) => {
    if (!isBrowser) {
      return (
        <KeyboardAwareScrollView
          contentContainerStyle={[CreateMediumStyle.container]}
          showsVerticalScrollIndicator={false}
        >
          {children}
        </KeyboardAwareScrollView>
      );
    }
    return (
      <View
        style={[CreateMediumStyle.container, { width: '100%', paddingBottom: 0, paddingTop: 0, alignItems: 'center' }]}
      >
        {children}
      </View>
    );
  };

  const WebConfirmDeleteDialogue = () => (
    <AlertDialog isOpen={isWebDeleteDialogueOpen} onClose={() => setIsWebDeleteDialogueOpen(false)} leastDestructiveRef={undefined}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{`Supprimer ${type === 'video' ? 'cette' : 'cet'} ${selector[type].name}`}</AlertDialog.Header>
        <AlertDialog.Body>
          Cette action supprimera définitivement votre {selector[type].name}.
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" colorScheme="coolGray" onPress={() => setIsWebDeleteDialogueOpen(false)}>
              Annuler
            </Button>
            <Button isLoading={deleteLoading} colorScheme="danger" onPress={async () => {
              setDeleteLoading(true);
              try {
                await Api.media.deleteMedium(medium?.id);
                setIsWebDeleteDialogueOpen(false);
                navigation.push(selector[type].my);
                toastSuccess(`Votre ${selector[type].name} a bien été supprimé`);
              } catch (err) {
                toastError('Une erreur est survenue');
              }            }}>
              Supprimer
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );

  return (
    <BasicLayout
      disableMenu={true}
      mobilePadding={false}
      push={navigation.push}
      title={selector[type].title}
      headerSection={<HeaderSection push={navigation.push} menuSelect={selector[type].title} redirect={selector[type].redirect} />}
      breadCrumb={<Breadcrumb canGoBack={navigation.canGoBack} push={navigation.push} goBack={navigation.goBack} menuSelect={selector[type].title} />}
    >
      {
        mode !== 'edit' && !createAs ? (
          <CreateAs rightType="MEDIAS" user={essentialUser} setCreateAs={setCreateAs} type={selector[type].title} />
        ) : (
          <View>
            <BrowserView>
              <WebConfirmDeleteDialogue />
            </BrowserView>
            <AddMediumContainer>
              <CreateMediumForm
                type={type}
                initialValues={initialValues}
                onSubmit={mode === 'edit' ? updateMedium : createMedium}
                Button={({ onClick }) => (
                  <MultiColorButton
                    loading={publishLoading}
                    progress={progress}
                    style={{
                      marginBottom: 20,
                      marginTop: !isBrowser ? 40 : 30,
                      width: !isBrowser ? '80%' : '15%',
                    }}
                    onPress={onClick}
                    text={mode === 'edit' ? 'Enregistrer' : 'Publier'}
                  />
                )}
              />
              {
                mode === 'edit' && (
                  <GrayButton
                    titre="Supprimer"
                    loading={deleteLoading}
                    width={!isBrowser ? '60%' : '15%'}
                    style={{ marginBottom: !isBrowser ? 50 : 0 }}
                    onPress={!isBrowser ? deleteMedium : () => {
                      window.scrollTo(0, 0);
                      setIsWebDeleteDialogueOpen(true);
                    }}
                  />
                )
              }
            </AddMediumContainer>
          </View>
        )
      }
    </BasicLayout>
  );
}

const mapStateToProps = (state, props) => ({
  user: state.Account.currentUser,
  essentialUser: state.Account.essentialUser,
  medium: props.mode === 'edit' ? state.Media.media?.find((medium) => (medium?.id.toString() === props.route.params.mediumId.toString().split('-').slice(-1)[0])) : null,
});

const actionProps = { submit };

export default connect(mapStateToProps, actionProps)(AddMedium);
