import { connect } from 'react-redux';
import { FlatList } from 'native-base';
import React, { useState } from 'react';
import { View, Dimensions, Image, Text } from 'react-native';

import { getMedia } from 'store/actions/media';

import Api from 'services/api';
import { svgtopng } from 'styles/images';

import MediaStyle from './MediaStyle';
import { articleData, videoData, podcastData } from './promoData';

import VideoCard from 'components/cards/medium/Video';
import MediaCard from 'components/webCarousel/MediaCard';
import ArticleCard from 'components/cards/medium/Medium';
import PodcastCard from 'components/cards/medium/Podcast';
import { MobileView, BrowserView } from 'components/device';
import HeaderSection from 'components/layouts/headerSection';
import CustomFlatList from 'components/webLists/CustomFlatList';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import NetworkCard from '../../components/webCarousel/NetworkCard';

const selector = {
  'article': {
    title: 'Magazine',
    redirect: 'Articles',
    card: ArticleCard,
    promoData: articleData,
    promoLogo: svgtopng.magazine.magazine,
  },
  'podcast': {
    title: 'Podcasts',
    redirect: 'Podcasts',
    card: PodcastCard,
    promoData: articleData,
    promoLogo: svgtopng.podcast.podcast,
  },
  'video': {
    title: 'Othr TV',
    redirect: 'Videos',
    card: VideoCard,
    promoData: articleData,
    promoLogo: svgtopng.otherTV.otherTV,
  },
};

function Media({ navigation: { push, goBack, canGoBack }, getMedia, type }) {
  const [media, setMedia] = useState([]);
  const [spheres, setSpheres] = useState([]);

  const loadSpheres = async () => {
    try {
      const { data } = await Api.misc.getSpheres();
      setSpheres(data);
    } catch (err) {
      console.error('Error getting spheres', err);
    }
  };

  const loadMedia = async () => {
    try {
      const media = await getMedia(type);
      setMedia(media);
    } catch (err) {
      console.error('Error loading networks', err);
    }
  };

  const loadResources = async () => {
    await loadMedia();
    await loadSpheres();
  };

  const { height } = Dimensions.get('window');

  const MediumCard = selector[type].card;
  const RenderMobileContent = () => (
    <View style={MediaStyle.slider}>
      <FlatList
        data={media}
        renderItem={({ item }) => (
          <MediumCard {...item} push={push} disableMarginTop height={450} />
        )}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id}
        style={{ flex: 1, width: '90%', height: '100%' }}
        contentContainerStyle={{ width: '100%' }}
      />
    </View>
  );

  const RenderWebContent = () => {
    console.log(media);
    return (
      <View>
        <NetworkCard push={push} network={{
          id: '1',
          type: 'event',
          mediaType: 'article',
          title: 'Le 1er media business',
          sphere: 'Communication',
          image: require('../../../assets/images/MEDIA.jpg'),
          description: 'Des articles, des vidéos et des podcasts faits par des entrepreneurs\n' +
            'pour des entrepreneurs. Crée ton propre média et développe ta communauté\n' +
            'qui te suit pour tes expertises et connaissances professionnelles',
          author: {
            name: 'Luc Haté',
            picture_url: 'https://media.othr.pro/7eb86220-727c-4328-958c-7a31d5fa0d98',
          }
        }}/>
        <CustomFlatList
          push={push}
          type="media"
          resources={media}
          settings={{
            spheres,
            sortField: 'title',
            filters: ['createdAt', 'spheres'],
            searchFields: ['title', 'user.firstname', 'user.lastname'],
          }}
        />
        {/* <PromoCarousel push={push} data={selector[type].promoData} /> */}
      </View>
    );
  };

  return (
    <LoadResourceLayout
      push={push}
      disableGoBack={true}
      mobilePadding={false}
      disableMarginTop={true}
      title={selector[type].title}
      loadResources={loadResources}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect={selector[type].title} />}
      headerSection={<HeaderSection push={push} menuSelect={selector[type].title} redirect={selector[type].redirect} />}
    >
      {/* MOBILE */}
      <MobileView>
        <RenderMobileContent />
      </MobileView>
      {/* BROWSER */}
      <BrowserView>
        <RenderWebContent />
      </BrowserView>
    </LoadResourceLayout>
  );
}

const mapStateToProps = (state) => ({
  media: state.Media.media,
});

const ActionProps = {
  getMedia,
};

export default connect(mapStateToProps, ActionProps)(Media);
