import { Alert, Platform } from 'react-native';
import { toast } from 'react-toastify';

function toastInfo(message) {
  return (
    toast.info(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  );
}

function toastSuccess(message) {
  if (Platform.OS === 'web') {
    return (
      toast.success(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    );
  } else {
    Alert.alert('Succès', message);
  }
}

function toastError(message) {
  if (Platform.OS === 'web') {
    return (
      toast.error(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    );
  } else {
    Alert.alert('Erreur', message);
  }
}

export {
  toastSuccess,
  toastError,
  toastInfo,
};
