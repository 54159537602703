import moment from 'moment';
import { Audio } from 'expo-av';
import { FontAwesome, Ionicons } from '@expo/vector-icons';
import React, { useEffect, useContext, useState } from 'react';
import { Text, TouchableOpacity, View, ActivityIndicator } from 'react-native';

import { colors, TextStyles } from 'styles';
import AudioPlayerStyle from './AudioPlayerStyle';
import { AudioPlayerContext } from './AudioPlayerContext';
import HollowBackground from 'components/backgrounds/hollowBackground/HollowBackground';
import AudioSlider from 'components/audioSlider';

const AudioPlayer = ({ audio_url, title, width = undefined, mediumId }) => {
  const {
    audio,
    status,
    togglePlayAudio,
    loadAudio,
    current,
    duration,
    audioUrl,
    setPosition,
    loading,
  } = useContext(AudioPlayerContext);

  const [playerAudio, setPlayerAudio] = useState(null);
  const [playerCurrent, setPlayerCurrent] = useState(0);
  const [playerDuration, setPlayerDuration] = useState(0);

  const createAudio = async () => {
    try {
      if (audio_url) {
        await Audio.setAudioModeAsync({
          playsInSilentModeIOS: true,
          staysActiveInBackground: true,
        });
        const { sound: audio } = await Audio.Sound.createAsync(
          { uri: audio_url },
        );
        let status = await audio.getStatusAsync();
        while(!status.durationMillis) {
          await (new Promise(r => setTimeout(r, 300)));
          status = await audio.getStatusAsync();
        }
        setPlayerAudio(audio);
        setPlayerCurrent(0);
        setPlayerDuration(status.durationMillis / 1000);
      } else {
        if (audio) {
          await audio.unloadAsync();
        }
        setPlayerAudio(null);
      }
    } catch (err) {
      console.error('Error handling audio', err);
    }
  };

  useEffect(() => {
    createAudio();
  }, [audio_url]);

  const setGlobalAudio = () => {
    if (!playerAudio) return;
    loadAudio(playerAudio, title, playerDuration, audio_url, mediumId);
  };

  const playerButton = () => {
    if (!playerAudio || loading) return <ActivityIndicator color={colors.white} />;
    if (matchingAudioUrl) {
      return (
        status?.isPlaying ? (
          <Ionicons name="ios-pause" size={23} color={colors.white} style={AudioPlayerStyle().pauseIcon} />
        ) : (
          <FontAwesome name="play" size={18} color={colors.white} style={AudioPlayerStyle().playIcon} />
        )
      );
    } else {
      return <FontAwesome name="play" size={18} color={colors.white} style={AudioPlayerStyle().playIcon} />;
    }
  };

  const handleChange = (value) => {
    setPosition(Math.round(value * duration));
  };

  const matchingAudioUrl = audioUrl === audio_url;
  const realCurrent = matchingAudioUrl ? (playerCurrent || current) : playerCurrent;
  const realDuration = matchingAudioUrl ? (playerDuration || duration) : playerDuration;

  return (
    <View style={AudioPlayerStyle({ width }).container}>
      <HollowBackground />
      <TouchableOpacity
        style={AudioPlayerStyle().buttonsContainer}
        onPress={() => {
          if (!audio || !matchingAudioUrl) {
            setGlobalAudio();
          } else {
            togglePlayAudio();
          }
        }}
      >
        {playerButton()}
      </TouchableOpacity>
      <AudioSlider realCurrent={realCurrent} realDuration={realDuration} onChange={handleChange} disabled={!audio} />
    </View>
  );
};

export default AudioPlayer;
