import { StyleSheet } from 'react-native';

const BackgroundLinearGradientStyle = (props) => StyleSheet.create({
  container: {
    zIndex: 1,
    width: props?.width || '100%',
    height: props?.height || '100%',
  },
  content: {
    paddingLeft: 15,
    paddingRight: 10,
  },
  backgroundImageStyle: {
    borderRadius: 10,
  },
  itemBillet2: {
    borderRadius: 10,
    marginBottom: 12.5,
    width: props?.width || '100%',
    height: props?.height || '100%',
  },
  gradient: {
    top: 0,
    left: 0,
    right: 0,
    borderRadius: 10,
    position: 'absolute',
    height: props && props.height,
  },
});

export default BackgroundLinearGradientStyle;
