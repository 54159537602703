import { Platform, StyleSheet } from 'react-native';
import { colors, fonts } from '../../../styles';

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginBottom: 20,
    width: Platform.OS === 'web' ? '25%' : '80%',
  },
  deleteContainer: {
    position: 'absolute',
    bottom: 40,
  },
  deleteText: {
    color: colors.secondary,
    fontFamily: fonts.lato.regular,
    fontSize: 13,
    textDecorationLine: 'underline'
  },
  headerText: {
    color: colors.grey,
    fontFamily: fonts.lato.bold,
    fontSize: 20,
    marginBottom: 20,
    marginTop: 30,
  }
});
