import { StyleSheet  } from 'react-native';
import { colors, fonts } from 'styles';

export default StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: colors.whiteShadow,
    marginTop: '15%',
    flex: 1,
    alignItems: 'center',
  },
  containerRelations: {
    flex: 1,
    alignItems: 'flex-end',
    width: '100%',
  },
  titleContainer: {
    marginLeft: '5%',
  },
  spheresListContainer: {
    height: 70,
    marginBottom: 10,
  },
  sphereBtn: {
    height: 45,
    borderRadius: 35,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    marginHorizontal: 5,
  },
  sphereText: {
    color: colors.greyLight,
    fontFamily: fonts.lato.bold,
    fontSize: 16,
    textTransform: 'uppercase',
  },
  usersScrollContainer: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: 100,
  },
  containerInfo: {
    marginBottom: 16,
    width: '90%',
    height: 65,
    borderRadius: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
  itemUser: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '90%',
  },
  nameUser: {
    fontFamily: fonts.lato.bold,
    fontSize: 16,
    color: colors.grey,
    maxWidth: '100%',
  },
  ProfessionUser: {
    fontFamily: fonts.lato.italic,
    fontSize: 12,
    color: colors.grey,
    lineHeight: 14,
  },
  recommendationDate: {
    fontFamily: fonts.lato.regular,
    marginTop: '-2%',
    fontSize: 11,
    color: colors.grey,
  },
  textUser: {
    marginLeft: 14,
    width: '60%',
  },
  vertLineUser: {
    width: 5,
    height: '38%',
    borderRadius: 3,
    backgroundColor: colors.grey,
    opacity: 0.1,
    marginRight: '8%',
  },
  imageContainer: {
    borderRadius: 70,
    marginLeft: 5,
  },
  imageProfile: {
    height: 55,
    borderRadius: 55,
    aspectRatio: 1,
  },
  containerUsers: {
    width: '100%',
  },
  scrollContainerAlphabet: {
    position: 'absolute',
    top: 0,
    left: '5%',
    width: '7%',
    height: '100%',
  },
  containerAlphabet: {
    alignItems: 'center',
    paddingBottom: 20,
  },
  alphabet: {
    textAlign: 'center',
    borderRadius: 20,
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
    overflow: 'hidden',
  },
  linearAlphabet: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  textAlphabet: {
    color: colors.white,
    fontSize: 13,
    fontFamily: fonts.lato.regular,
  },
  alphaUsers: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  containerButton: {
    position: 'absolute',
    bottom: 15,
    left: '50%',
    width: 169,
    backgroundColor: colors.white,
    alignItems: 'center',
    padding: 20,
    borderRadius: 12.5,
  },
  searchContainer: {
    width: '90%',
    height: 52,
    borderRadius: 30,
    overflow: 'hidden',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  searchInput: {
    paddingLeft: 25,
    height: '100%',
    fontFamily: fonts.lato.regular,
    fontSize: 14,
  },
  emptyContainer: {
    height: '30%',
    width: '100%',
  },
  emptyContentContainer: {
    marginTop: '-15%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyTitle: {
    fontFamily: fonts.lato.bold,
    fontSize: 17,
    width: '50%',
    textAlign: 'center',
    marginBottom: 25,
  },
  emptyDescription: {
    fontFamily: fonts.lato.italic,
    fontSize: 13,
    width: '80%',
    textAlign: 'center',
    lineHeight: 20,
  }
});
