import { StyleSheet } from 'react-native';

import { colors } from 'styles';

export default StyleSheet.create({
  sliderTip: {
    width: 6,
    backgroundColor: colors.greyDayColor,
    position: 'absolute',
    opacity: 0.6,
    borderRadius: 3,
  },
});
