import { Dimensions, StyleSheet } from 'react-native';
import { isIphoneX, getStatusBarHeight } from 'react-native-iphone-x-helper';

import { colors, fonts } from 'styles';

import { isMobile, isBrowser } from 'components/device';

const { height } = Dimensions.get('window');

export default StyleSheet.create({
  container: {
    flex: 1,
    minHeight: height,
    alignItems: 'center',
    justifyContent: isBrowser ? 'center' : 'flex-start',
    paddingTop: isMobile && getStatusBarHeight(true),
  },
  logo: {
    width: 159,
    height: 69,
    marginTop: (isMobile && isIphoneX() && '18%') || (isMobile && '5%') || '2%',
    marginBottom: (isMobile && isIphoneX() && '21%') || (isMobile && '15%') || '2%',
  },
  signUpBtnText: {
    fontSize: 15,
    color: colors.grey,
    textAlign: 'center',
  },
  forgotTxt: {
    color: colors.grey,
    marginTop: 0,
    marginBottom: 10,
    fontSize: 12,
    fontFamily: fonts.lato.regular,
    opacity: 0.5,
  },
  notificationContent: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  notificationMessage: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.red,
  },
  containerNewButton: {
    shadowColor: '#000',
    shadowOpacity: 1,
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
    elevation: 30,
    marginTop: 20,
    height: 100,
    padding: 20,
    borderRadius: 10,
    backgroundColor: 'rgba(255,255,255,0.65)',

  },
  newButton: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '70%',
  },
  separatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 30,
    marginTop: 10,
    width: 250,
  },
  separator: {
    flex: 1,
    height: 1,
    backgroundColor: colors.greyDayColor,
  },
  separatorText: {
    fontFamily: fonts.lato.regular,
    color: colors.greyMedium,
    fontSize: 14,
    marginHorizontal: 15,
    lineHeight: 14,
    marginBottom: 0,
  },
  versionText: {
    fontFamily: fonts.lato.italic,
    fontSize: 10,
    color: colors.greyMedium,
    marginTop: 10,
    position: 'absolute',
    bottom: 15,
    left: 15,
  }
});
