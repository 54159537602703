import React from 'react';
import { useWindowDimensions, View, Dimensions } from 'react-native';
import { SocialIcon } from 'react-native-elements';
import { Text } from 'native-base';
import { colors, TextStyles } from '../../styles';
import { isBrowser } from '../../components/device';
import Svg from '../../components/svg/Svg';
import { Icon } from '../../styles/svg';
import MultiColorButton from '../../components/buttons/multiColorButton';

const appleStoreUrl= 'https://apps.apple.com/fr/app/othr/id1609669447';
const playStoreUrl = 'https://play.google.com/store/apps/details?id=pro.othr.app';

const SubscriptionSuccess = (props) => {
  const { width, height } = useWindowDimensions();
  const isComputer = width > 800;
  console.log('isComputer', isComputer);

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Svg src={Icon} style={{ height: height * 0.08 }} />
      <Text style={TextStyles({ lineHeight: 40, width: !isComputer ? '80%' : '25%', textAlign: 'center', marginTop: 40 }).black30}>
        Bienvenue dans
      </Text>
      <Text style={TextStyles({ lineHeight: 40, width: !isComputer ? '80%' : '25%', textAlign: 'center', marginTop: 0 }).black30}>
        la communauté Othr !
      </Text>
      <Text style={TextStyles({ color: colors.greyPodcast, maxWidth: !isComputer ? '80%' : '30%', textAlign: 'center', marginTop: 10 }).regular16}>
        Votre paiement a bien été pris en compte
      </Text>
      {isComputer ? (
        <MultiColorButton style={{ width: '20%', marginTop: 40 }} text={'Connexion'} onPress={() => props.navigation.navigate('Login')} />
      ) : (
        <Text style={TextStyles({ color: colors.grey, marginBottom: 20, textAlign: 'center', width: '80%', marginTop: 40 }).bold14}>
          Vous pouvez désormais retourner sur votre application Othr pour vous connecter, ou bien la télécharger si vous ne l'avez pas encore
        </Text>
      )}
      <View style={{ position: 'absolute', bottom: 20, alignItems: 'center' }}>
        <Text style={TextStyles({ color: colors.grey, marginBottom: 20, textAlign: 'center', width: '100%' }).bold14}>
          Disponible sur iOS et Android
        </Text>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <SocialIcon
            type="apple"
            button={true}
            // title="Télécharger sur l'App store"
            onPress={() => window.location.replace(appleStoreUrl)}
            style={{
              margin: 0,
              width: 150,
              height: 40,
              marginBottom: 20,
              borderRadius: 10,
              backgroundColor: '#000000',
              marginRight: 20,
            }}
          />
          <SocialIcon
            type="play"
            button={true}
            // title="Télécharger sur Google Play"
            onPress={() => window.location.replace(playStoreUrl)}
            style={{
              margin: 0,
              width: 150,
              height: 40,
              marginBottom: 20,
              borderRadius: 10,
              backgroundColor: '#4285F4',
            }}
          />
        </View>
      </View>
    </div>
  );
};

export default SubscriptionSuccess;
