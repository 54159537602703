import { Dimensions, Platform, StyleSheet } from 'react-native';
import { colors, fonts } from 'styles';
const { height } = Dimensions.get('window');

export const MenuStyle = StyleSheet.create({
  container: {
    width: '100%',
    // height: '100%',
    // justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    paddingTop: 50,
    paddingBottom: 40,
  },
  containerDataBloc: {
    margin: 12,
    width: height * 0.148,
    height: height * 0.148,
    borderRadius: Platform.OS !== 'web' ? 25 : 15,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    width: height * 0.055,
    height: height * 0.055,
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerLogo: {
    alignItems: 'center',
    marginTop: 30,
  },
  containerMenu: {
    width: '100%',
    alignItems: 'center',
  },
  lineMenu: {
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  text: {
    fontSize: Platform.OS !== 'web' ? 15 : 16,
    marginTop: Platform.OS !== 'web' ? 5 : 4,
    // fontWeight: 'bold',
    color: colors.grey,
    textAlign: 'center',
    alignItems: 'center',
    fontFamily: fonts.lato.bold,
  },
  footerContainer: {
    borderTopWidth: 0.5,
    borderTopColor: colors.liteGrey,
    width: '100%',
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  containerBloc: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  containerBlocReverse: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  imgClose: {
    resizeMode: 'contain',
    width: 131,
    height: 61,
  },
  containerImgClose: {
    position: 'absolute',
    padding: 10,
    left: 25,
    top: 25,
    borderRadius: 50,
    backgroundColor: '#FEFEFE',
    zIndex: 2,
  },
  containerSousBloc: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignSelf: 'center',
  },
  containerDataChild: {
    paddingVertical: 10,
  },
  titleHollow: {
    fontSize: 14,
  },
  singleChild: { flexDirection: 'row', alignSelf: 'flex-start' },
  singleParent: { alignSelf: 'flex-start' },
  selectedContainer: {
    alignItems: 'center',
    width: '100%',
    paddingTop: 50,
    flex: 1,
  },
  selectedImage: {
    height: height * 0.075,
    width: height * 0.075,
    alignItems: 'center',
    justifyContent: 'center',
  },
  subMenu: {
    margin: 5,
  },
  legal: {
    color: colors.secondary,
    fontFamily: fonts.lato.regular,
    fontSize: 13,
  },
  notifications: {
    position: 'absolute',
    top: Platform.OS === 'ios' ? 50 : 25,
    right: 25,
  },
  notificationsChip: {
    position: 'absolute',
    top: -2,
    right: -2,
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: colors.burgundy,
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationsText: {
    color: colors.white,
    fontFamily: fonts.lato.black,
    fontSize: 10,
  },
  playerText: {
    color: colors.black,
    fontFamily: fonts.lato.italic,
    fontSize: 12,
    overflow: 'hidden',
    width: '60%',
    marginRight: '5%',
  },
  podcastContainer: {
    height: 70,
    width: '85%',
    borderRadius: 40,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10,
  }
});
