import React from 'react';
import { Text, View } from 'native-base';
import { EvilIcons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';

import { TextStyles } from 'styles';
import AuthGoBackStyle from './AuthGoBackStyle';
import { isBrowser } from '../device';

const AuthGoBack = ({ goBack }) => (
  <View style={{ width: '5%' }}>
    <TouchableOpacity onPress={goBack}>
      <View style={AuthGoBackStyle.container}>
        <EvilIcons style={AuthGoBackStyle.icon} name="chevron-left" size={30} color="black" />
        {isBrowser && (
          <div className="hover-underline-animation" style={{ height: '100%' }}>
            <Text style={TextStyles({ marginLeft: 4 }).bold16}>
            Retour
            </Text>
          </div>
        )}
      </View>
    </TouchableOpacity>
  </View>
);

export default AuthGoBack;
