import React from 'react';
import { View, Text } from 'native-base';
import { Dimensions } from 'react-native';

import { colors, TextStyles } from 'styles';

import { isBrowser } from 'components/device';

const { height } = Dimensions.get('screen');

const NoResources = ({ noResourceText, findResourceText, buttons }) => (
  <View style={{ width: '100%', height: height * 0.6, alignItems: 'center', justifyContent: 'center' }}>
    <Text style={TextStyles({ lineHeight: 25, width: !isBrowser ? '80%' : '30%', textAlign: 'center' }).bold20}>{noResourceText}</Text>
    <Text style={TextStyles({ color: colors.greyPodcast, maxWidth: !isBrowser ? '80%' : '30%', textAlign: 'center', marginTop: 20 }).italic14}>{findResourceText}</Text>
    <View style={{ flexDirection: isBrowser ? 'row' : 'column', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
      {buttons?.map((Button,  index) => (
        <View key={index} style={{ alignItems: 'center', width: !isBrowser ? '60%' : '15%', marginLeft: index > 0 && isBrowser ? 20 : 0, marginTop: index > 0 && !isBrowser ? 20 : 10 }}>
          <Button/>
        </View>
      ))}
    </View>
  </View>
);

export default NoResources;
