import React from 'react';
import { View } from 'native-base';
import { Dimensions, Platform, useWindowDimensions } from 'react-native';

export const isIOS = Platform.OS === 'ios';
export const isMobile = Platform.OS !== 'web';
export const isBrowser = Platform.OS === 'web';

export const BrowserView = ({ children, style }) => isBrowser && (
  <View style={[{ width: '100%', height: '100%' }, style]}>
    {children}
  </View>
);

export const MobileView = ({ children }) => isMobile && (
  <View style={{ width: '100%', flex: 1, alignItems: 'center' }}>
    {children}
  </View>
);

export const S = 0;
export const M = 1;
export const L = 2;
export const XL = 3;
export const responsiveBreakPoints = [0, 890, 1190, 1490];

export function getResponsiveBreakpoint(width) {
  if (width > responsiveBreakPoints[XL]) {
    return responsiveBreakPoints[XL];
  }
  if (width > responsiveBreakPoints[L]) {
    return responsiveBreakPoints[L];
  }
  return responsiveBreakPoints[M];
}

export function getWebListNumberOfItem(width) {
  if (width > responsiveBreakPoints[XL]) {
    return 5;
  }
  if (width > responsiveBreakPoints[L]) {
    return 4;
  }
  return 3;
}

export const ResponsiveView = ({ children, style }) => {
  const { width } = useWindowDimensions();

  let defaultWidth;
  let horizontalMargin;

  if (width > responsiveBreakPoints[XL]) {
    defaultWidth = 1600;
    horizontalMargin = (width - defaultWidth) / 2;
  } else if (width > responsiveBreakPoints[L]) {
    defaultWidth = 1400;
    horizontalMargin = (width - defaultWidth) / 2;
  } else {
    defaultWidth = 1050;
    horizontalMargin = (width - defaultWidth) / 2;
    if (horizontalMargin < 20) {
      horizontalMargin = 20;
    }
  }

  return (
    <View
      style={[
        { width: '90', marginHorizontal: '5%' },
        style
      ]}
    >
      {children}
    </View>
  );
};
