import { colors, fonts } from 'styles';
import { Dimensions, StyleSheet } from 'react-native';

const { width } = Dimensions.get('screen');

export default StyleSheet.create({
  container: {
    flex: 1,
    width,
  },
  listContainer: {
    width: '100%',
    paddingHorizontal: 25,
  },
  notifContainer: {
    flex: 1,
    width: '100%',
    height: 70,
    borderRadius: 35,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  image: {
    width: 60,
    height: 60,
    marginLeft: 5,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowOffset: { height: 6, width: 0 },
    shadowRadius: 10,
  },
  textContainer: {
    marginLeft: 10,
  },
  title: {
    fontFamily: fonts.lato.bold,
    fontSize: 16,
    color: colors.black,
  },
  description: {
    fontFamily: fonts.lato.regular,
    fontSize: 13,
    color: colors.black,
  },
  seen: {
    height: 5,
    width: 5,
    borderRadius: 5,
    backgroundColor: colors.burgundy,
    position: 'absolute',
    right: 25,
  },
  webContainer: {
    width: '80%',
    marginLeft: '10%',
  },
  webSwapRequestHeaderContainer: {
    marginBottom: '1%',
  },
  webSwapRequestHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  webSwapRequestHeaderImage: {
    width: 30,
    height: 30,
  },
  webListContainer: {
    marginTop: 20,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  swapRequestCardButtonContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    height: width * 0.04,
    justifyContent: 'center',
  },
  swapRequestRefuseButton: {
    marginLeft: '4%'
  },
  swapRequestContainer: {
    width: width * 0.19,
    height: width * 0.34,
    marginRight: 10,
  },
  swapRequestCloseImageContainer: {
    width: '100%',
    height: width * 0.23,
  },
  swapRequestCloseImage: {
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',
  },
  swapRequestCloseImageStyle: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  swapRequestDescriptionContainer: {
    width: '100%',
    overflow: 'hidden',
    height: width * 0.25,
    backgroundColor: 'white',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  swapRequestCloseDayContainer: {
    width: '100%',
    alignItems: 'center',
  },
  swapRequestCloseIconContainer: {
    width: '100%',
    height: width * 0.05,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: colors.green,
  },
  swapRequestOpenIconContainer: {
    width: '100%',
    height: width * 0.03,
    alignItems: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    justifyContent: 'center',
    backgroundColor: colors.green,
  },
  swapRequestDayText: {
    marginTop: 20,
    color: 'white',
    paddingBottom: 10,
  },
  swapRequestDescriptionText: {
    width: '90%',
    marginTop: '5%',
    marginLeft: '5%',
  },
  closeColor: {
    color: 'white',
  },
});
