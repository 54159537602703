import { Pressable, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { SafeAreaView, TouchableOpacity, View, useWindowDimensions } from 'react-native';

import Api from 'services/api';
import { isBrowser } from '../device';
import RoundedLogo from '../roundedLogo';
import { colors, fonts, TextStyles } from '../../styles';
import Loader from '../loader';
import Svg from '../svg/Svg';
import { OthrBg } from '../../styles/svg';

const CreateAs = ({ user, rightType, setCreateAs, type }) => {
  const { height } = useWindowDimensions();

  const [loaded, setLoaded] = useState(false);
  const [groups, setGroups] = useState(undefined);

  const loadData = async () => {
    const fetchedGroups = (await Api.profile.getEditableGroups(rightType)).data;
    setGroups(fetchedGroups);
    setLoaded(true);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!loaded) return;
    if (
      (!groups || groups.length === 0) &&
      !user.admin_zone_id &&
      !user.admin_city_group_id
    ) {
      setCreateAs('user');
    } else if (
      (groups && groups.length === 1) &&
      !user.admin_zone_id &&
      !user.admin_city_group_id
    ) {
      setCreateAs(`network-${groups[0].id}`);
    } else if (
      (!groups || groups.length === 0) &&
      (user.admin_zone_id || user.admin_city_group_id)
    ) {
      if (user.admin_city_group_id && !user.admin_zone_id) {
        setCreateAs(`city_group-${user.admin_city_group_id}`);
      } else if (user.admin_zone_id && !user.admin_city_group_id) {
        setCreateAs(`zone-${user.admin_zone_id}`);
      }
    }
  }, [ loaded, groups, user ]);

  if (!loaded || !groups) {
    return (
      <SafeAreaView style={{ height: height * 0.6, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </SafeAreaView>
    );
  }

  return (
    <View style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <Text selectable style={TextStyles({ color: colors.grey, marginBottom: 10, marginTop: isBrowser ? '10%' : '0%' }).bold22}>Créer un {type}</Text>
      <Text selectable style={TextStyles({ color: colors.greyMedium, marginBottom: 26 }).regular16}>Créer mon {type} en tant que</Text>
      <View style={{ flexWrap: 'wrap', justifyContent: 'center', width: '90%', marginHorizontal: '5%', flexDirection: 'row', marginTop: isBrowser ? '5%' : '0%', paddingBottom: isBrowser ? '10%' : '0%' }}>
        {type !== 'Event' && <TouchableOpacity onPress={() => {
          setCreateAs('user');
        }}>
          <RoundedLogo uri={user?.picture_url} style={isBrowser ? { height: 150, width: 150, margin: 10 } : { height: 100, margin: 10 }}/>
        </TouchableOpacity>}
        {groups.map((n) => (
          <TouchableOpacity
            key={`network-${n.id}-${n.name}`}
            onPress={() => {
              setCreateAs(`network-${n.id}`);
            }}
            style={{ margin: 10 }}
          >
            <RoundedLogo uri={n.logo_url || n.picture_url} style={isBrowser ? { height: 150, width: 150 } : { height: 100 }}/>
          </TouchableOpacity>
        ))}
        {user.admin_zone_id && (
          <Pressable onPress={() => setCreateAs(`zone-${user.admin_zone_id}`)} style={{ margin: 10, height: 150, width: 150, alignItems: 'center', justifyContent: 'flex-end' }}>
            <Svg src={OthrBg} height={150} width={150} style={{ borderRadius: 10, position: 'absolute', top: 0, left: 0, }}/>
            <Text style={{ color: colors.white, opacity: 0.5, fontSize: 50, fontFamily: fonts.lato.bold, marginBottom: 5 }}>{user?.administratedZone?.code}</Text>
          </Pressable>
        )}
        {user.admin_city_group_id && (
          <Pressable onPress={() => setCreateAs(`city_group-${user.admin_city_group_id}`)} style={{ margin: 10, height: 150, width: 150, alignItems: 'center', justifyContent: 'flex-end' }}>
            <Svg src={OthrBg} height={150} width={150} style={{ borderRadius: 10, position: 'absolute', top: 0, left: 0, }}/>
            <Text adjustsFontSizeToFit style={{ width: '90%', height: '40%', color: colors.white, opacity: 0.5, fontFamily: fonts.lato.bold, marginBottom: 5, textAlign: 'center', overflow: 'hidden' }}>{user?.administratedCityGroup?.name}</Text>
          </Pressable>
        )}
      </View>
    </View>
  );
};

export default CreateAs;
