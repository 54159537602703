import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { registerForPushNotificationsAsync } from 'services/notifications';

import Api from 'services/api';
import HomeContent from './Home';
import { GetEssentialUser, GetUser, Logout } from 'store/actions/account';

import { isBrowser } from 'components/device';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import Advert from '../../components/advert';
import { Platform } from 'react-native';

function Home({ navigation: { push, reset, goBack }, Logout, GetEssentialUser, GetUser }) {
  const [resources, setResources] = useState([]);
  const registerToken = async () => {
    if (!isBrowser) {
      const token = await registerForPushNotificationsAsync();
      await Api.profile.setToken(token);
    }

  };

  useEffect(() => {
    registerToken();
  }, []);

  const loadHome = async () => {
    try {
      // Redirect the user to the account edit page if he hasn't filled his profile picture
      const profile = await GetUser();
      if (!profile.picture_url) {
        reset({
          index: 0,
          routes: [
            { name: 'Account' }
          ]
        });
      } else {
        const { data: resources } = await Api.misc.getHome();
        // Exclude the Othr fake network
        setResources(resources.filter((r) => (r.type !== 'network' || r.id !== 20)));
      }
    } catch (err) {
      console.error('Error loading home', err);
      if (err.response?.status === 401) {
        Logout();
      }
    }
  };

  return (
    <LoadResourceLayout
      push={push}
      goBack={goBack}
      disableGoBack={true}
      mobilePadding={false}
      title="Dernières actus"
      loadResources={loadHome}
      disableMarginTop={true}
    >
      <HomeContent push={push} resources={resources}/>
      {Platform.OS !== 'web' && <Advert />}
    </LoadResourceLayout>
  );
};

export default connect(() => ({}), { Logout, GetEssentialUser, GetUser })(Home);
