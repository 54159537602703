import React from 'react';
import { connect } from 'react-redux';
import { Text, View } from 'native-base';
import { TouchableOpacity } from 'react-native';
import { useRoute } from '@react-navigation/native';

import { fonts } from 'styles';
import menus from 'components/menu/config';

import Svg from 'components/svg';

const BreadCrumb = ({ push, menuSelect, user }) => {
  const route = useRoute();

  return (
    <View style={{
      zIndex: 2,
      marginTop: 7,
      alignItems: 'center',
      flexDirection: 'row',
    }}>
      <View
        style={{
          width: '100%',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        {
          menus[menuSelect].children.filter(e => !e.isAvailable || e.isAvailable(user)).map((sm, index) => (
            <TouchableOpacity
              onPress={() => push(sm.target)}
              key={index}
              style={{
                alignItems: 'center',
                marginLeft: index === 0 ? 0 : 20,
              }}
            >
              <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                <View style={{ width: 15, height: 15, marginTop: -2 }}>
                  <Svg src={sm.image} width={15} height={15} />
                </View>
                <div className={sm.target !== route.name && !sm.related?.includes(route.name) ? 'hover-underline-animation' : ''} style={{ height: '100%', paddingBottom: 4 }}>
                  <Text
                    style={{
                      fontSize: 15,
                      marginLeft: 7,
                      color: 'black',
                      textDecorationThickness: '2.5px',
                      textUnderlineOffset: sm.target === route.name || sm.related?.includes(route.name) && 8,
                      textDecorationLine: sm.target === route.name || sm.related?.includes(route.name) && 'underline',
                      fontFamily: sm.target === route.name || sm.related?.includes(route.name) ? fonts.lato.bold : fonts.lato.regular,
                    }}
                  >
                    {sm.text}
                  </Text>
                </div>
              </View>
            </TouchableOpacity>
          ))
        }
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  user: state.Account.currentUser,
});

export default connect(mapStateToProps, null)(BreadCrumb);
