import React, { useState } from 'react';

import Api from 'services/api';

import UserDetails from './UserDetails';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';

const UserDetailsContainer = ({
  navigation: { goBack, addListener, push }, route,
  acceptSwapRequest,
  rejectSwapRequest,
}) => {
  const [user, setUser] = useState(null);

  const userId = route.params?.userId ? route.params?.userId?.toString()?.split('-')?.slice(-1)[0] : null;

  const loadUser = async () => {
    try {
      const { data } = await Api.users.getPublic(userId);
      setUser(data);
    } catch (err) {
      console.error('Error loading user', err);
    }
  };

  return (
    <LoadResourceLayout
      push={push}
      publicPage={true}
      disableMenu={true}
      disableHeader={true}
      mobilePadding={false}
      loadResources={loadUser}
      addListener={addListener}
    >
      <UserDetails
        user={user}
        push={push}
        userId={null}
        route={route}
        goBack={goBack}
        publicPage={true}
        currentUser={{ userId: null }}
        acceptSwapRequest={acceptSwapRequest}
        rejectSwapRequest={rejectSwapRequest}
      />
    </LoadResourceLayout>
  );
};

export default UserDetailsContainer;
