import moment from 'moment';
import QRCode from 'react-qr-code';
import { connect } from 'react-redux';
import React, { useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { View, FlatList, Text, AlertDialog, Button, Input } from 'native-base';

import { Tickets } from 'store/actions/event';
import { download, send } from 'store/actions/tickets';
import { toastError, toastSuccess } from 'services/toasts';
import { colors, ContainerStyles, fonts, TextStyles } from 'styles';

import styles from './TicketDetailsStyle';

import Logo from 'components/logo';
import RoundedLogo from 'components/roundedLogo';
import { ResponsiveView } from 'components/device';
import GrayButton from 'components/buttons/grayButton';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import Svg from '../../../../components/svg/Svg';
import { OthrBg } from '../../../../styles/svg';

const TicketDetailsWeb = ({
  send,
  route,
  tickets,
  Tickets,
  download,
  navigation: { push, goBack, canGoBack },
}) => {
  const { width } = useWindowDimensions();

  const [ticketToSendId, setTicketToSendId] = useState(0);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);

  const data = useMemo(() => {
    if (!tickets) return null;
    const data = tickets.filter(t => t.event?.id.toString() === route.params?.eventId?.toString());
    return data.map(d => ({
      ...d,
      organizer: (d.event?.user || d.event?.network) ? {
        ...(d.event?.user || d.event?.network),
        name: d.event?.network?.name || `${d.event?.user?.firstname} ${d.event?.user?.lastname}`,
      } : undefined,
      zone: d.event?.zone,
    }));
  }, [ tickets ]);

  const downloadTicket = async (id) => {
    try {
      await download(id);
    } catch (err) {
      toastError('Erreur lors du téléchargement de votre billet.');
    }
  };

  const SendEmailDialogue = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const sendTicket = async (id, email) => {
      try {
        setIsLoading(true);
        await send(id, email);
        toastSuccess('Billet envoyé !');
      } catch (err) {
        toastError('Erreur lors de l\'envoie de votre billet.');
      } finally {
        window.scrollTo(0, 0);
        setIsSendModalOpen(false);
        setIsLoading(false);
      }
    };

    return (
      <AlertDialog isOpen={isSendModalOpen} leastDestructiveRef={undefined} onClose={() => setIsSendModalOpen(false)}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Envoyer le Ticket</AlertDialog.Header>
          <AlertDialog.Body>
            Veuillez rentrer l'email du destinataire du ticket
            <Input marginTop={5} marginBottom={5} placeholder="Email" w="75%" maxWidth="300px" onChange={(e) => setEmail(e.target.value)} />
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button variant="unstyled" colorScheme="coolGray" onPress={() => setIsSendModalOpen(false)}>
                Annuler
              </Button>
              <Button isLoading={isLoading} color={colors.blue} onPress={() => sendTicket(ticketToSendId, email)}>
                Envoyer
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  return (
    <LoadResourceLayout
      push={push}
      disableMenu={true}
      mobilePadding={false}
      loadResources={Tickets}
      headerSection={<HeaderSection push={push} menuSelect="Events" redirect="Event"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Events" />}
    >
      <SendEmailDialogue />
      <ResponsiveView>
        <View style={{ width: '100%', alignItems: 'center' }}>
          <Text selectable style={TextStyles({ marginTop: 10 }).bold20}>{data?.length} Billets</Text>
        </View>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          {
            data?.map((item) => (
              <View key={item.id} style={[styles.webItemContainer, { width: 400, margin: 20 }]}>
                <View style={[styles.webItemSubContainer, { height: 650 }]}>
                  <View style={styles.webItemHeaderContainer}>
                    <View style={[styles.ticketHeader, { paddingHorizontal: 0 }]}>
                      <View style={styles.organizerContainer}>
                        {item.organizer ? (
                          <RoundedLogo uri={item.organizer?.logo_url} style={styles.webItemLogo}/>
                        ) : (
                          <View style={{ height: 80, width: 80, alignItems: 'center', justifyContent: 'flex-end', marginRight: 12 }}>
                            <Svg src={OthrBg} height={80} width={80} style={{ borderRadius: 10, position: 'absolute', top: 0, left: 0, }}/>
                            <Text style={{ color: colors.white, opacity: 0.5, fontSize: 25, fontFamily: fonts.lato.bold, marginBottom: 10 }}>{item.zone.code}</Text>
                          </View>
                        )}
                        <View>
                          <Text selectable style={styles.subtitle}>Organisateur</Text>
                          <Text selectable numberOfLines={2} style={styles.content}>{item.organizer?.name || 'Othr'}</Text>
                        </View>
                      </View>
                      <Logo height={40} width={75} />
                    </View>
                    <View style={styles.webItemDescriptionContainer}>
                      <View style={{ width: '58%' }}>
                        <Text selectable style={TextStyles({ color: colors.burgundy }).black13}>Event</Text>
                        <Text selectable style={TextStyles({ width: '99%' }).regular17}>{item.event?.title}</Text>
                      </View>
                      <View style={{ width: '40%', marginLeft: '2%' }}>
                        <Text selectable style={TextStyles({ color: colors.burgundy }).black13}>Date</Text>
                        <Text selectable style={TextStyles().regular17}>{moment(item.event.start_date).format('DD/MM/YYYY')} - {moment(item.event.start_date).format('HH[h]mm')} - {moment(item.event.end_date).format('HH[h]mm')}</Text>
                      </View>
                    </View>
                    <View style={{ marginTop: 20 }}>
                      <Text selectable style={TextStyles({ color: colors.burgundy }).black13}>Billet</Text>
                      <Text style={TextStyles().regular17}>{item.ticket?.title}</Text>
                    </View>
                    <View style={{ marginTop: 20 }}>
                      <Text style={TextStyles({ color: colors.burgundy }).black13}>Lieu</Text>
                      <Text style={TextStyles().regular17}>{item.event?.address.number} {item.event?.address.street}</Text>
                      <Text style={TextStyles().regular17}>{item.event?.address.zip_code} {item.event?.address.city}</Text>
                    </View>
                    <View style={[styles.webQrCode]}>
                      <QRCode value={item.uuid || item.id.toString()} size={width * 0.1} />
                    </View>
                  </View>
                  <View style={styles.webItemFooterContainer}>
                    <Text style={TextStyles({ color: colors.white }).black17}>{item.name}</Text>
                  </View>
                </View>
                <View style={[styles.buttonsContainer, { marginTop: 20, marginBottom: 0 }]}>
                  <GrayButton titre={'Envoyer'} width={'47.5%'} height={40} noBar onPress={() => {
                    setTicketToSendId(item.id);
                    setIsSendModalOpen(true);
                  }} />
                  <GrayButton titre={'Télécharger'} width={'47.5%'} style={{ marginLeft: '5%' }} height={40} noBar onPress={() => downloadTicket(item.id)} />
                </View>
              </View>
            ))
          }
        </View>
      </ResponsiveView>
    </LoadResourceLayout>
  );
};

const mapStateToProps = (state, props) => ({
  tickets: state.Event.tickets,
});

const actionProps = {
  send,
  Tickets,
  download,
};

export default connect(mapStateToProps, actionProps)(TicketDetailsWeb);
