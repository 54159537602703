import { Alert, Linking, Platform, Text, TouchableOpacity, View } from 'react-native';
import uuid from 'react-native-uuid';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import Api from 'services/api';
import { NfcModal, writeNfc } from 'services/nfc';
import { GetUser, manageBilling } from 'store/actions/account';

import SettingsStyles from './SettingsStyles';

import BasicLayout from 'components/layouts/basicLayout';
import WhiteButton from 'components/buttons/whiteButton';
import { APP_URL } from '../../../config';
import * as WebBrowser from 'expo-web-browser';

const Settings = ({ navigation: { push, goBack }, user, GetUser, manageBilling }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ showModal, setShowModal ] = useState(false);

  const fetchUser = async () => {
    if (!user || !user.id) {
      await GetUser();
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const setCard = async (url) => {
    setIsLoading(true);
    await Api.profile.setCard(url);
    setIsLoading(false);
  };

  const scanCard = async () => {
    const cardId = uuid.v4();
    const userId = user.id;
    const link = `https://api.othr.pro/application/users/${userId}/swap?token=${cardId}`;
    setShowModal(true);
    const result = await writeNfc(link);
    setShowModal(false);
    if (!result) {
      Alert.alert(
        'Oups ! Pas assez stable',
        '1 - Pose ta carte à plat en face de toi (sur une table, c\'est idéal !)\n2 - Scanne-la en plaçant ton téléphone à 1cm de la carte pour qu\'il ne bouge pas pendant l\'opération',
      );
    } else {
      await setCard(cardId);
      Alert.alert(
        'Votre carte a bien été enregistrée',
        'Vous pouvez maintenant l\'utiliser pour vous Swaper avec les autres Othrs !',
      );
    }
  };

  const subscriptionInfos = async () => {
    if (user.subbedMethod === 'stripe') {
      const url = await manageBilling();
      await WebBrowser.openBrowserAsync(url);
    } else {
      if (Platform.OS === 'ios') {
        await Linking.openURL('App-Prefs:APPLE_ACCOUNT&path=SUBSCRIPTIONS');
      } else {
        Alert.alert(
          'Souscription Apple',
          'Vous pouvez gérer votre souscription dans les paramètres de votre iPhone, dans la section achats',
        );
      }
    }
  };

  const deleteAccountConfirm = () => {
    if (Platform.OS === 'web') {
      push('ContactUs', { object: 'Supprimer mon compte' });
    } else {
      Alert.alert(
        'Voulez-vous vraiment supprimer votre compte ?',
        'Toutes vos données seront supprimées sous 48h. Cette action est irréversible.',
        [{
          text: 'Oui',
          style: 'destructive',
          onPress: () => push('ContactUs', { object: 'Supprimer mon compte' }),
        }, {
          text: 'Non',
          style: 'cancel',
        }]
      );
    }
  };

  return (
    <BasicLayout push={push} title={'Paramètres'} mobilePadding={false} disableMenu={true}>
      <View style={SettingsStyles.container}>
        <Text style={{ ...SettingsStyles.headerText, marginTop: 0 }}>Mes identifiants</Text>
        <WhiteButton style={SettingsStyles.button} text="Modifier mon adresse e-mail" onPress={() => push('EditEmail')} />
        <WhiteButton style={SettingsStyles.button} text="Modifier mon mot de passe" onPress={() => push('EditPassword')} />
        {Platform.OS !== 'web' && <Text style={SettingsStyles.headerText}>Ma carte</Text>}
        {Platform.OS !== 'web' && <WhiteButton style={SettingsStyles.button} loading={isLoading} text="Scanner ma carte" onPress={scanCard} />}
        <Text style={SettingsStyles.headerText}>Ma souscription</Text>
        <WhiteButton style={SettingsStyles.button} text="Gérer ma souscription" onPress={subscriptionInfos} />
        <TouchableOpacity style={SettingsStyles.deleteContainer} onPress={deleteAccountConfirm}>
          <Text style={SettingsStyles.deleteText}>Supprimer mon compte</Text>
        </TouchableOpacity>
      </View>
      <NfcModal visible={showModal} close={() => setShowModal(false)}/>
    </BasicLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.Account.currentUser,
});

const mapDispatchToPros = {
  GetUser,
  manageBilling,
};

export default connect(mapStateToProps, mapDispatchToPros)(Settings);
