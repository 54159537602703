import { GOOGLE_API_KEY } from 'config';

const geocodeAddress = async (address) => {
  try {
    const addressString = `${address.number} ${address.street} ${address.zip_code} ${address.city} ${address.country}`.replace(/\s/g, '+');
    const result = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&key=${GOOGLE_API_KEY}`);
    const data = await result.json();
    return data?.results[0]?.geometry.location;
  } catch (err) {
    console.error('Error geocoding address', err);
  }
};

export {
  geocodeAddress
};
