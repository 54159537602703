export default function ParentNetwork(
  state = {
    parentNetworks: null,
  },
  action,
) {
  switch (action.type) {
  case 'LOAD_PARENT_NETWORKS':
    return {
      ...state,
      parentNetworks: action.parentNetworks,
    };
  case 'LOAD_PARENT_NETWORK':
    return {
      ...state,
      parentNetworks: state.parentNetworks ? [ ...state.parentNetworks.filter((e) => e.id !== action.parentNetwork.id), action.parentNetwork ] : [ action.parentNetwork ],
    };
  default:
    return state;
  }
}
