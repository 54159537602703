import { connect } from 'react-redux';
import { SafeAreaView } from 'react-native';
import { View, Pressable } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useRoute } from '@react-navigation/native';
import { ifIphoneX } from 'react-native-iphone-x-helper';
import { TouchableOpacity, useWindowDimensions } from 'react-native';

import { APP_URL } from 'config';
import { GetEssentialUser } from 'store/actions/account';
import { HomeStyle } from '../../../screens/home/HomeStyle';

import WebFooter from './WebFooter';
import WebHeader from './WebHeader';
import WebMenuContainer from './WebMenuContainer';

import Loader from '../../loader';
import TextSection from 'components/textSection';
import GoBack from 'components/buttons/goBackButton';
import { isBrowser, isMobile } from 'components/device';
import MenuScrollView from 'components/menuScrollView/MenuScrollView';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';

const LayoutContainer = ({
  user,
  push,
  children,
  breadCrumb,
  disableFooter,
  headerSection,
  disableMarginTop,
}) => {
  const [burgerActive, setBurgerActive] = useState(false);

  const { width: screenWidth, height: screenHeight } = useWindowDimensions();

  return (
    <View style={[HomeStyle.container, burgerActive && { overflowY: 'hidden' }]}>
      {
        burgerActive && (
          <Pressable
            onPress={() => setBurgerActive(false)}
            style={{
              zIndex: 2,
              position: 'fixed',
              width: screenWidth,
              height: screenHeight,
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
          />
        )
      }
      <WebHeader
        user={user}
        push={push}
        breadCrumb={breadCrumb}
        burgerActive={burgerActive}
        headerSection={headerSection}
        setBurgerActive={setBurgerActive}
      />
      {
        burgerActive && (
          <WebMenuContainer push={push} />
        )
      }
      {
        burgerActive ? (
          <TouchableOpacity
            disabled={!burgerActive}
            onPress={() => setBurgerActive(!burgerActive)}
            style={{
              zIndex: 1,
              opacity: 1,
              backgroundColor: '#f2f2f2',
              paddingTop: disableMarginTop ? 0 : 120,
            }}
          >
            {children}
            {
              !disableFooter && (
                <WebFooter />
              )
            }
          </TouchableOpacity>
        ) : (
          <div style={{
            backgroundColor: '#f2f2f2',
            paddingTop: disableMarginTop ? 0 : 120,
          }}>
            {children}
            {
              !disableFooter && (
                <WebFooter />
              )
            }
          </div>
        )
      }
    </View>
  );
};

const BasicLayout = ({
  push,
  user,
  children,
  GetEssentialUser,
  breadCrumb = null,
  headerSection = null,
  publicPage = false,
  title = undefined,
  disableMenu = false,
  mobilePadding = true,
  disableHeader = false,
  disableFooter = false,
  disableGoBack = false,
  disableScroll = false,
  disableMarginTop = false,
  onScrollEnd = undefined,
  onPressGoBack = undefined,
  pagingEnabled = undefined,
  refreshControl = undefined,
  BottomComponent = undefined,
  contentInsetAdjustmentBehavior = undefined,
}) => {
  const { height } = useWindowDimensions();
  const [userLoading, setUserLoading] = useState(true);

  const fetchUser = async () => {
    await GetEssentialUser();
    setUserLoading(false);
  };

  let route = {};
  if (isBrowser) {
    route = useRoute();
  }

  useEffect(() => {
    if (!publicPage) {
      fetchUser();
    }
    if (isBrowser) {
      window.scrollTo(0, 0);
    }
  }, [ route.name ]);

  // useEffect(() => {
  //   if (isBrowser && user?.id && !user?.picture_url && route.name !== 'Account') {
  //     window.location.replace(APP_URL + '/account');
  //   }
  // }, [ route ]);

  if (isMobile) {
    const ScrollComponent = disableScroll ? (
      View
    ) : (
      disableMenu ? KeyboardAwareScrollView : MenuScrollView
    );
    return (
      <View style={HomeStyle.container}>
        {
          title && (
            <SafeAreaView style={{ alignItems: 'center', paddingTop: ifIphoneX(0, 20) }}>
              <TextSection titleSection={title} noBar />
            </SafeAreaView>
          )
        }
        {!disableGoBack && <GoBack onPress={onPressGoBack}/>}
        <ScrollComponent
          onScrollEndDrag={onScrollEnd}
          contentContainerStyle={{
            flexGrow: 1,
            marginVertical: 0,
            alignItems: 'center',
            paddingVertical: mobilePadding ? ifIphoneX(70, 40) : undefined,
            paddingBottom: disableMenu ? 0 : 70,
          }}
          showsVerticalScrollIndicator={false}
          push={push}
          refreshControl={refreshControl}
          pagingEnabled={pagingEnabled}
          contentInsetAdjustmentBehavior={contentInsetAdjustmentBehavior}
        >
          {children}
        </ScrollComponent>
        {BottomComponent}
      </View>
    );
  }

  if (disableHeader) {
    return (
      <View style={[HomeStyle.container, { backgroundColor: '#F2F2F2' }]}>
        <View style={{ zIndex: 1, backgroundColor: '#F2F2F2' }}>
          {children}
          {
            !disableFooter && (
              <WebFooter />
            )
          }
        </View>
      </View>
    );
  }

  return (
    <LayoutContainer
      user={user}
      push={push}
      breadCrumb={breadCrumb}
      disableFooter={disableFooter}
      headerSection={headerSection}
      disableMarginTop={disableMarginTop}
    >
      {
        userLoading ? (
          <SafeAreaView style={{ height: height * 0.68, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <Loader />
          </SafeAreaView>
        ) : (
          children
        )
      }
    </LayoutContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.Account.essentialUser,
});

const mapDispatchToProps = {
  GetEssentialUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicLayout);
