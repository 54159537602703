import React from 'react';
import { View, TouchableOpacity, Text, ActivityIndicator } from 'react-native';

import { colors } from 'styles';

import styles from './GrayButtonStyle';

import { isBrowser } from 'components/device';

export default function GrayButton({
  width,
  style,
  titre,
  onPress,
  textStyle,
  height = 45,
  icon: Icon,
  loading = false,
}) {
  return (
    <View style={[styles.container, style, { width, height }]}>
      <TouchableOpacity
        onPress={onPress}
        style={[styles.button, isBrowser && { height }]}
      >
        {loading ? (
          <View style={styles.content}>
            <ActivityIndicator color={colors.greyDayColor}/>
          </View>
        ) : (
          <View style={styles.content}>
            {!!Icon && <View style={styles.icon}>
              <Icon width={17} height={17} />
            </View>}
            {!!titre && <Text selectable style={[styles.text, textStyle]} numberOfLines={1}>{titre}</Text>}
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
}
