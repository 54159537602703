import React, { useState } from 'react';
import { Feather } from '@expo/vector-icons';
import { TouchableOpacity, Text } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';

import { colors, fonts } from 'styles';
import { AddIcon } from 'styles/svg';

import FieldWrapper from 'components/form/fieldWrapper';
import HollowButton from 'components/buttons/hollowButton';

export default (props) => {
  const [ name, setName ] = useState(props.input.value);

  const launchPicker = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        copyToCacheDirectory: true,
        type: props.type,
      });
      const { type, uri, name, size } = result;
      console.log('Document selected', type, uri, name, size);
      if (type === 'cancel') return;
      setName(`${name} (${size} B)`);
      const response = await fetch(uri);
      const data = await response.blob();
      onDocumentSelected(data);
    } catch (err) {
      console.log('Error in picker', err);
    }
  };

  const onDocumentDeleted = () => {
    if (props.onDocumentDeleted) {
      props.onDocumentDeleted();
    }
    props.input.onChange(null);
    setName(null);
  };

  const onDocumentSelected = (response) => {
    if (response.didCancel) {
      return onDocumentDeleted();
    }
    if (props.onDocumentSelected) {
      props.onDocumentSelected(response);
    }
    props.input.onChange(response);
  };

  const document = props.input.value;

  return (
    <FieldWrapper {...props}>
      <HollowButton
        onPress={launchPicker}
        {...props}
        svg={document ? null : AddIcon}
        svgWidth={50}
        svgHeight={50}
        titre={document ? null : props.titre}
        width='100%'
        style={{ borderRadius: 30 }}
      >
        {!!document && <TouchableOpacity onPress={launchPicker} style={{ position: 'absolute', top: 15, right: 15, width: 35, height: 35, borderRadius: 35, backgroundColor: '#FEFEFE88', alignItems: 'center', justifyContent: 'center' }}>
          <Feather name="edit-2" size={16} color={colors.black} />
        </TouchableOpacity>}
        {!!name && <Text style={{ fontFamily: fonts.lato.boldItalic, color: colors.grey, fontSize: 16, lineHeight: 24, width: '90%', textAlign: 'center' }}>{name}</Text>}
      </HollowButton>
    </FieldWrapper>
  );
};
