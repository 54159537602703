export default function Event(
  state = {
    events: null,
    eventPlace: {},
    createdEvent: {},
    userEventNetwork: {},
    homeEvents: null,
    headerEvents: null,
    networkEvents: {},
    ticketSelected: {},
    tickets: null,
    myEvents: null,
  },
  action,
) {
  switch (action.type) {
  case 'LOAD_EVENTS':
    return {
      ...state,
      events: action.events,
    };
  case 'MY_EVENTS':
    return {
      ...state,
      myEvents: action.events,
    };
  case 'CREAT_EVENT':
    return {
      ...state,
      createdEvent: action.createdEvent,
    };
  case 'LOAD_EVENT':
    return {
      ...state,
      events: state.events ? [ ...state.events.filter((e) => e.id !== action.event.id), action.event ] : [ action.event ],
    };
  case 'EVENT_PLACE':
    return {
      ...state,
      eventPlace: action.eventPlace,
    };
  case 'USER_EVENT_NETWORK':
    return {
      ...state,
      userEventNetwork: action.userEventNetwork,
    };
  case 'HOME_EVENTS':
    return {
      ...state,
      homeEvents: action.homeEvents,
    };
  case 'HEADER_EVENTS':
    return {
      ...state,
      headerEvents: action.headerEvents,
    };
  case 'NETWORK_EVENTS':
    const newState = { ...state };
    newState.networkEvents[action.id.toString()] = action.events;
    return newState;
  case 'TICKET_SELECTED':
    return {
      ...state,
      ticketSelected: action.ticketSelected,
    };
  case 'LOAD_TICKETS':
    return {
      ...state,
      tickets: action.tickets,
    };
  default:
    return state;
  }
}
