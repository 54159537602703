import { StyleSheet } from 'react-native';

import { colors, fonts } from 'styles';
import { isBrowser } from '../../device';

export default StyleSheet.create({
  hollowContainer: {
    width: '100%',
    height: 400,
    marginBottom: 40,
    alignItems: 'center',
    borderRadius: 15,
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imageContainer: {
    width: '100%',
    height: '70%',
    backgroundColor: '#000022',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    overflow: 'hidden',
  },
  imageBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.6,
  },
  imageIcon: {
    width: '13%',
    height: '15%',
  },
  imageTopTitle: {
    zIndex: 0,
    fontSize: 14,
    fontFamily: fonts.lato.bold,
    color: colors.white,
    textAlign: 'center',
    marginTop: 5,
  },
  imageTitle: {
    zIndex: 0,
    fontSize: 24,
    marginTop: '10%',
    fontFamily: fonts.lato.bold,
    color: colors.white,
    textAlign: 'center',
    marginBottom: 0,
    width: '90%',
  },
  imageSphere: {
    zIndex: 0,
    color: colors.white,
    fontFamily: fonts.lato.light,
    fontSize: 14,
    textAlign: 'center',
  },
  imageDescription: {
    zIndex: 0,
    color: colors.white,
    fontWeight: 'bold',
    fontFamily: fonts.lato.regular,
    fontSize: 12,
    marginTop: '10%',
    maxWidth: '70%',
    width: '70%',
    textAlign: 'center',
    marginBottom: '10%',
  },
  image2: {
    borderRadius: 15,
    width: '100%',
    height: 130,
    resizeMode: 'cover',
  },
  title: {
    fontSize: 16,
    fontFamily: fonts.lato.bold,
    width: '100%',
    marginTop: '10%',
    color: colors.greyDark,
    textAlign: 'center',
  },
  separator: {
    backgroundColor: colors.bar,
    height: 3,
    width: '10%',
    marginVertical: '5%',
  },
  text: {
    fontFamily: fonts.lato.regular,
    width: '100%',
    color: colors.greyDark,
    marginBottom: '5%',
  },
  horizontal: {
    width: '100%',
    height: '100%',
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  logoContainer: {
    width: '35%',
    height: '100%',
  },
  logo: {
    height: '100%',
    aspectRatio: 1,
    resizeMode: 'cover',
    borderRadius: 10,
  },
  infosContainer: {
    justifyContent: 'space-between',
    flex: 1,
    height: '100%',
  },
  name: {
    color: colors.burgundy,
    fontSize: 20,
    fontFamily: fonts.lato.bold,
    margin: 0,
  },
  type: {
    fontFamily: fonts.lato.regular,
    color: colors.greyPodcast,
    marginBottom: !isBrowser ? '5%' : 0,
  },
});
