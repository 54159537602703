export default function Account(
  state = {
    logged: false,
    loading: false,
    currentUser: {},
    essentialUser: {},
    userId: null,
    token: null,
  },
  action,
) {
  switch (action.type) {
  case 'LOGGED':
    return {
      ...state,
      logged: action.logged,
    };
  case 'LOADING':
    return {
      ...state,
      loading: action.loading,
    };
  case 'CURRENT_USER':
    return {
      ...state,
      currentUser: action.currentUser,
    };
  case 'ESSENTIAL_USER':
    return {
      ...state,
      essentialUser: action.essentialUser,
    };
  case 'SET_USER_ID':
    return {
      ...state,
      userId: action.payload,
    };
  case 'LOAD_TOKEN':
    return {
      ...state,
      token: action.token,
    };
  default:
    return state;
  }
}
