import { Bars } from 'react-loader-spinner';
import { useWindowDimensions } from 'react-native';
import React, { useContext, useMemo, useState } from 'react';
import { Image, Pressable, Text, View } from 'native-base';
import { HamburgerCollapseReverse } from 'react-animated-burgers';

import Api from 'services/api';
import { ADMIN_URL, DASHBOARD_URL } from 'config';
import { colors, fonts } from 'styles';
import { DefaultUser, Notification, OthrBg } from 'styles/svg';

import PlayerModal from './PlayerModal';
import AccountModal from './AccountModal';
import NotificationsModal from './NotificationsModal';

import Svg from 'components/svg';
import Logo from 'components/logo';
import { ResponsiveView } from 'components/device';
import { AudioPlayerContext } from '../../audioPlayer/AudioPlayerContext';

const WebHeaderWeb = ({
  user,
  push,
  breadCrumb,
  burgerActive,
  headerSection,
  setBurgerActive,
}) => {
  const { width } = useWindowDimensions();
  const { status: playerStatus } = useContext(AudioPlayerContext);
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] = useState(false);

  const networks = useMemo(() => {
    if (!user) return [];
    const data = [];
    [ ...(user.administratedParentNetworks || []), ...(user.ownedParentNetworks || []) ].forEach((parentNetwork) => {
      if (!data.some(({ id }) => (id === parentNetwork.id))) {
        data.push({
          id: parentNetwork.id,
          logo_url: parentNetwork.logo_url,
          name: parentNetwork.description,
        });
      }
    });
    return data.sort((a, b) => (a.name?.localeCompare(b.name)));
  }, [ user ]);

  const burgerMenuOnPress = () => {
    setIsAccountModalOpen(false);
    setIsNotificationsModalOpen(false);
    setBurgerActive(!burgerActive);
  };

  const openNetworkManager = async (id) => {
    try {
      const { data: { code } } = await Api.auth.getAuthCode();
      window.open(`${ADMIN_URL}?auth_code=${code}&network=${id}`, '_blank', 'noopener,noreferrer');
    } catch (err) {
      console.error('Error opening network manager', err);
    }
  };

  const openDashboard = async (id) => {
    try {
      const { data: { code } } = await Api.auth.getAuthCode();
      window.open(`${DASHBOARD_URL}?auth_code=${code}&id=${id}`, '_blank', 'noopener,noreferrer');
    } catch (err) {
      console.error('Error opening network manager', err);
    }
  };

  const worldAdmins = [ 'clement@othr.pro', 'luc@othr.pro', 'rizki@othr.pro', 'sabrina.sanjulian@gmail.com' ];

  return (
    <View style={{
      zIndex: 3,
      height: 80,
      width: '100%',
      position: 'fixed',
    }}>
      <div style={{ height: 80, width: '100%', backgroundColor: 'rgba(255,255,255,0.75)', backdropFilter: 'blur(10px)' }}>
        <View style={{ marginTop: 2.5 }}>
          <ResponsiveView
            style={{
              height: 80,
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <HamburgerCollapseReverse barColor={colors.black} isActive={burgerActive} toggleButton={burgerMenuOnPress} buttonStyle={{ padding: 0, margin: 0, width: 25, height: 30 }} buttonWidth={25} buttonHeight={30} />
              {
                headerSection || (
                  <Pressable
                    onPress={() => push('Home')}
                    style={{ cursor: 'pointer', width: 100, marginLeft: 15 }}
                  >
                    <Logo width={100} height={32} color="black"/>
                  </Pressable>
                )
              }
            </View>
            {
              width > 1000 && breadCrumb && (
                <View style={{ backgroundColor: colors.black, width: 1, height: 23, marginLeft: 20, marginRight: 20, borderRadius: 10 }} />
              )
            }
            {width > 1000 && breadCrumb}
            <View
              style={{ marginLeft: 'auto', height: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
            >
              <View
                onMouseEnter={() => {
                  if (isNotificationsModalOpen) {
                    setIsNotificationsModalOpen(false);
                  }
                  if (burgerActive) {
                    setBurgerActive(false);
                  }
                  setIsAccountModalOpen(!isAccountModalOpen);
                }}
                style={{ height: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
              >
                <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15, marginRight: 15, color: colors.black }}>Bonjour {user?.firstname} !</Text>
                {
                  user?.picture_url ? (
                    <Image alt="Change me" resizeMethod="contain" size={42} style={{ borderRadius: 5 }} source={{ uri: user?.picture_url }} />
                  ) : (
                    <View
                      style={{
                        width: 42,
                        height: 42,
                        borderRadius: 5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: colors.greyLight,
                      }}
                    >
                      <Svg style={{ width: 20, height: 25 }} src={DefaultUser} />
                    </View>
                  )
                }
              </View>
              {networks.map((n) => (
                <Pressable key={n.id} onPress={() => openNetworkManager(n.id)}>
                  <Image alt={`${n.name} logo`} resizeMethod="contain" size={42} style={{ borderRadius: 5, marginLeft: 10 }} source={{ uri: n?.logo_url }} />
                </Pressable>
              ))}
              {user?.administratedZone && (
                <Pressable onPress={() => openDashboard(user.administratedZone.id)} style={{ marginLeft: 10, height: 42, width: 42, alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Svg src={OthrBg} height={42} width={42} style={{ borderRadius: 5, position: 'absolute', top: 0, left: 0, }}/>
                  <Text style={{ color: colors.white, opacity: 0.5, fontSize: 12, fontFamily: fonts.lato.bold, marginBottom: 5 }}>{user?.administratedZone?.code}</Text>
                </Pressable>
              )}
              {user?.administratedCityGroup && (
                <Pressable onPress={() => openDashboard(user.administratedCityGroup.id)} style={{ marginLeft: 10, height: 42, width: 42, alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Svg src={OthrBg} height={42} width={42} style={{ borderRadius: 5, position: 'absolute', top: 0, left: 0, }}/>
                  <Text style={{ color: colors.white, opacity: 0.5, fontSize: 12, fontFamily: fonts.lato.bold, marginBottom: 5 }}>{user?.administratedCityGroup?.zone?.code}</Text>
                </Pressable>
              )}
              {worldAdmins.includes(user?.email) && (
                <Pressable onPress={() => openDashboard(0)} style={{ marginLeft: 10, height: 42, width: 42, alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Svg src={OthrBg} height={42} width={42} style={{ borderRadius: 5, position: 'absolute', top: 0, left: 0, }}/>
                  <Text style={{ color: colors.white, opacity: 0.5, fontSize: 8, fontFamily: fonts.lato.black, marginBottom: 8 }}>FRANCE</Text>
                </Pressable>
              )}
              {
                playerStatus?.isLoaded && (
                  <div onMouseEnter={() => setIsPlayerOpen(true)}>
                    <Bars
                      height="26"
                      width="26"
                      color={colors.white}
                      ariaLabel="audio-loading"
                      wrapperStyle={{ padding: 8, borderRadius: 5, backgroundColor: colors.burgundy, marginLeft: 10 }}
                      wrapperClass="wrapper-class"
                      visible={true}
                    />
                  </div>
                )
              }
              <View style={{ backgroundColor: colors.black, width: 1, height: 23, marginLeft: 20, borderRadius: 10 }} />
              <Pressable
                onPress={() => {
                  if (isAccountModalOpen) {
                    setIsAccountModalOpen(false);
                  }
                  if (burgerActive) {
                    setBurgerActive(false);
                  }
                  setIsNotificationsModalOpen(!isNotificationsModalOpen);
                }}
                style={{ height: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginLeft: 20 }}
              >
                <Image alt="Change me" resizeMethod="contain" style={{ borderRadius: 5, width: 22, height: 23 }} source={Notification} />
              </Pressable>
            </View>
          </ResponsiveView>
        </View>
      </div>
      {
        isAccountModalOpen && (
          <AccountModal user={user} push={push} setIsAccountModalOpen={setIsAccountModalOpen} />
        )
      }
      {
        isNotificationsModalOpen && (
          <NotificationsModal user={user} push={push} setIsNotificationsModalOpen={setIsNotificationsModalOpen} />
        )
      }
      {
        isPlayerOpen &&  (
          <PlayerModal setIsPlayerOpen={setIsPlayerOpen} />
        )
      }
    </View>
  );
};

export default WebHeaderWeb;
