import { Video, ResizeMode } from 'expo-av';
import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { VideoPlayerStyle } from './VideoPlayerStyle';

export default ({ navigation, width = '85%', style = {}, video_url }) => {
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      videoRef.current?.stopAsync();
    });

    return unsubscribe;
  }, [ navigation ]);

  return (
    <View
      style={[
        VideoPlayerStyle.container, style, { width, height: 'auto' },
      ]}
    >
      <Video
        ref={videoRef}
        source={{ uri: video_url }}
        rate={1.0}
        volume={1.0}
        isMuted={false}
        resizeMode={ResizeMode.CONTAIN}
        shouldPlay={true}
        videoStyle={{ width: '100%', height: '100%' }}
        style={VideoPlayerStyle.video}
        useNativeControls={true}
        onLoad={() => setLoaded(true)}
      >
        {!loaded && <View style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator size="large"/>
        </View>}
      </Video>
    </View>
  );
};
