import AppleLogin from 'react-apple-login';
import AppleSignin from 'react-apple-signin-auth';
import { APP_URL } from '../../../config';

export const AppleButton = () => {
  return (
    <AppleSignin
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: 'pro.othr.auth',
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: 'email name',
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI: APP_URL,
      }}
      uiType="dark"
      buttonExtraChildren={'Se connecter avec Apple'}
    />
    // <AppleLogin
    //   // designProp={{
    //   //   // height: 35,
    //   //   // width: 250,
    //   //   // locale: 'en_US',
    //   //   // type: 'sign-in',
    //   // }}
    //   clientId="pro.othr.auth"
    //   redirectURI="https://app.othr.pro"
    // />
  );
};

export default AppleButton;
