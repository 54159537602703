export default function Media(
  state = {
    media: [],
    myVideos: [],
    myPodcasts: [],
    myArticles: [],
  },
  action,
) {
  const newState = { ...state };
  switch (action.type) {
  case 'LOAD_MEDIA':
    newState.media = [ ...action.payload ];
    state.media.forEach(medium => {
      if (!newState.media.some(m => m.id === medium.id)) {
        newState.media.push(medium);
      }
    });
    return newState;
  case 'LOAD_MEDIUM':
    newState.media = state.media ? [ ...state.media.filter((a) => a.id !== action.payload.id), action.payload ] : [ action.payload ];
    return newState;
  case 'MY_VIDEOS':
    return {
      ...state,
      myVideos: action.videos,
    };
  case 'MY_PODCASTS':
    return {
      ...state,
      myPodcasts: action.podcasts,
    };
  case 'MY_ARTICLES':
    return {
      ...state,
      myArticles: action.articles,
    };
  default:
    return state;
  }
}
