import React, { useState } from 'react';
import { View, FlatList, Text } from 'native-base';
import { Image, useWindowDimensions } from 'react-native';

import { svgtopng } from 'styles/images';
import { fonts, TextStyles } from 'styles';
import { ConsultEvents } from 'styles/svg';

import WebItem from './Item';
import WebSoloList from './SoloList';

import Search from 'components/search/Search';
import { getWebListNumberOfItem, ResponsiveView } from 'components/device';
import HollowBackground from 'components/backgrounds/hollowBackground/HollowBackground';

const CustomFlatList = ({ resources, push, settings, onPress, type = undefined, numberOfItem = 5 }) => {
  const { height, width } = useWindowDimensions();
  const [sort, setSort] = useState(settings.filters[0]);

  let responsiveNumberOfItem;
  const maxNumberOfItem = getWebListNumberOfItem(width);
  if (numberOfItem > maxNumberOfItem) {
    responsiveNumberOfItem = maxNumberOfItem;
  } else {
    responsiveNumberOfItem = numberOfItem;
  }

  const formattedData = resources;
  const numberOfItemToAdd = responsiveNumberOfItem - (formattedData.length % responsiveNumberOfItem);
  if (numberOfItemToAdd !== responsiveNumberOfItem) {
    for (let i = 0; i < numberOfItemToAdd; ++i) {
      // formattedData.push({ id: uuid.v4(), empty: true });
    }
  }

  const PlainList = () => {
    const sortList = formattedData;
    if (sort === 'asc') {
      sortList.sort((a, b) => {
        if (a.empty) {
          return 1;
        }
        if (type === 'media') {
          return a.items?.find(
            i => i.type === settings.sortField
          )?.content?.localeCompare(b.items?.find(
            i => i.type === settings.sortField
          )?.content);
        }
        return a[settings.sortField]?.localeCompare(b[settings.sortField]);
      });
    } else if (sort === 'dsc') {
      sortList.sort((a, b) => {
        if (a.empty) {
          return 1;
        }
        if (type === 'media') {
          return b.items?.find(
            i => i.type === settings.sortField
          )?.content?.localeCompare(a.items?.find(
            i => i.type === settings.sortField
          )?.content);
        }
        return b[settings.sortField]?.localeCompare(a[settings.sortField]);
      });
    } else if (sort === 'createdAt') {
      sortList.sort((a, b) => {
        return new Date(a.createdAt) < new Date(b.createdAt);
      });
    } else if (sort === 'startDate') {
      sortList.sort((a, b) => {
        return new Date(a.start_date) < new Date(b.start_date);
      });
    }

    return (
      <View style={{ width: '100%' }}>
        <FlatList
          data={sortList}
          scrollEnabled={false}
          numColumns={responsiveNumberOfItem}
          keyExtractor={(item) => item.id}
          renderItem={({ item, index }) => (
            <View
              style={{
                marginRight: 10,
                marginBottom: 10,
                width: (width * 0.9 / responsiveNumberOfItem) - 10,
                height: (width * 0.9 / responsiveNumberOfItem) * 1.2
              }}
            >
              {
                !item.empty && (
                  <WebItem r={item} push={push} onPress={onPress ? () => onPress(item) : undefined} />
                )
              }
            </View>
          )}
        />
      </View>
    );
  };

  const DateListContainer = ({ month, year, literalMonth, disableMarginTop }) => (
    <View>
      <ResponsiveView style={{ marginTop: disableMarginTop ? 0 : 60 }}>
        <View style={{
          width: 120 + `${literalMonth} ${year}`.length * 6,
          borderRadius: 30,
          marginBottom: 20,
          overflow: 'hidden',
          flexDirection: 'row',
          alignItems: 'center',
          height: height * 0.05,
          justifyContent: 'center',
        }}>
          <HollowBackground />
          <Image alt="Change me" resizeMode="contain" style={{ width: 20, height: 20 }} source={ConsultEvents} />
          <Text style={TextStyles({ marginLeft: 10 }).bold18}>{`${literalMonth} ${year}`}</Text>
        </View>
      </ResponsiveView>
      <WebSoloList showSide push={push} resources={formattedData.filter(
        (d) => { return !d.empty && d.start_date.getMonth() === month && d.start_date.getFullYear() === year; }
      )} />
    </View>
  );

  const MonthList = () => {
    const dates = [];
    const current = new Date();
    formattedData.filter((d) => !d.empty).forEach((d) => {
      if (d.start_date < current) {
        return;
      }
      const isAlready = dates.filter((da) => da.month === d.start_date.getMonth() && da.year === d.start_date.getFullYear());
      if (isAlready.length > 0) {
        return;
      }
      const literal = d.start_date.toLocaleString('fr', { month: 'long' });
      dates.push({
        month: d.start_date.getMonth(),
        year: d.start_date.getFullYear(),
        literalMonth: literal.charAt(0).toUpperCase() + literal.slice(1)
      });
    });
    return (
      dates.map((d, index) => (
        <View key={index} style={{ zIndex: 0 }}>
          <DateListContainer {...d} disableMarginTop={index === 0} />
        </View>
      ))
    );
  };

  const TypeList = () => (
    settings.categories.map((c, index) => (
      c.length > 0 && (
        <View key={index} style={{ zIndex: 0, marginTop: '2%' }}>
          <ResponsiveView>
            <View style={{
              width: 120 + settings.categoryLabels[index].name.length * 6,
              borderRadius: 30,
              marginBottom: 20,
              overflow: 'hidden',
              flexDirection: 'row',
              alignItems: 'center',
              height: height * 0.05,
              justifyContent: 'center',
            }}>
              <HollowBackground />
              <Text style={TextStyles().bold18}>{settings.categoryLabels[index].name}</Text>
            </View>
          </ResponsiveView>
          <WebSoloList showSide push={push} resources={c} />
        </View>
      )))
  );

  const SphereList = () => (
    settings.spheres.map((s, index) => {
      const filteredData = formattedData.filter((m) => m.sphere_id === s.id);

      if (filteredData.length === 0) {
        return null;
      }
      return (
        <View key={index} style={{ zIndex: 0 }}>
          <ResponsiveView style={{ marginTop: '2%' }}>
            <View style={{
              width: 120 + s.name.length * 6,
              borderRadius: 30,
              marginBottom: 20,
              overflow: 'hidden',
              flexDirection: 'row',
              alignItems: 'center',
              height: height * 0.05,
              justifyContent: 'center',
            }}>
              <HollowBackground />
              <Image alt="Change me" resizeMode="contain" style={{ width: 20, height: 20, marginRight: 5, marginLeft: -5 }} source={svgtopng.other.consultOthrs} />
              <Text style={TextStyles().bold18}>{s.name}</Text>
            </View>
          </ResponsiveView>
          <WebSoloList showSide push={push} resources={filteredData} />
        </View>
      );
    })
  );

  const selectOption = [{
    key: 'startDate',
    value: <option key={'startDate'} value={'startDate'}>Date</option>
  }, {
    key: 'month',
    value: <option key={'month'} value={'month'}>Mois</option>
  }, {
    key: 'asc',
    value: <option key={'asc'} value={'asc'}>A à Z</option>
  }, {
    key: 'dsc',
    value: <option key={'dsc'} value={'dsc'}>Z à A</option>
  }, {
    key: 'types',
    value: <option key={'types'} value={'types'}>Type de Groupement</option>
  }, {
    key: 'spheres',
    value: <option key={'spheres'} value={'spheres'}>Sphère</option>
  }, {
    key: 'createdAt',
    value: <option key={'createdAt'} value={'createdAt'}>Date de publication</option>
  }];

  return (
    <View style={{ width: '100%' }}>
      <ResponsiveView style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 70 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={TextStyles({ color: '#707070' }).bold16}>
            Classé par:
          </Text>
          <select onChange={(v) => setSort(v.target.value)} style={{ marginRight: 5, fontFamily: fonts.lato.bold, fontSize: 16, backgroundColor: 'transparent', borderWidth: 0 }}>
            {settings.filters.map((f) => {
              const option = selectOption.find((o) => o.key === f);
              return option.value;
            })}
          </select>
        </View>
        <View style={{ marginLeft: 'auto', zIndex: 1, marginBottom: '1%', marginTop: 12.5 }}>
          <Search data={resources.filter((r) => !r.empty)} fields={settings.searchFields} type={type} push={push} />
        </View>
      </ResponsiveView>
      {
        sort === 'month' && (
          <View style={{ zIndex: -1, marginTop: '1%' }}>
            <MonthList />
          </View>
        )
      }
      {
        (sort === 'asc' || sort === 'dsc' || sort === 'createdAt' || sort === 'startDate') && (
          <ResponsiveView style={{ alignItems: 'center', zIndex: -1, marginTop: '1%' }}>
            <PlainList />
          </ResponsiveView>
        )
      }
      {
        sort === 'types' && (
          <View style={{ zIndex: -1, marginTop: '-1%' }}>
            <TypeList />
          </View>
        )
      }
      {
        sort === 'spheres' && (
          <View style={{ zIndex: -1, marginTop: '-1%' }}>
            <SphereList />
          </View>
        )
      }
    </View>
  );
};

export default CustomFlatList;
