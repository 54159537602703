import { getData } from '../Utils';

// ------------------------------------------------------------------//
// --------------------------- Selectors ----------------------------//
// ------------------------------------------------------------------//
export function getLogged(state, errorIfNotFound = false) {
  return getData(
    state,
    'Account.logged',
    errorIfNotFound && 'No logged data found',
  );
}
export function getLoading(state, errorIfNotFound = false) {
  return getData(
    state,
    'Account.loading',
    errorIfNotFound && 'No loading data found',
  );
}
export function getCurrentUser(state, errorIfNotFound = false) {
  return getData(
    state,
    'Account.currentUser',
    errorIfNotFound && 'No currentUser data found',
  );
}
export function getToken(state, errorIfNotFound = false) {
  return getData(
    state,
    'Account.token',
    errorIfNotFound && 'No token data found',
  );
}

export function getZones(state, errorIfNotFound = false) {
  return getData(
    state,
    'Account.zones',
    errorIfNotFound && 'No token data found',
  );
}
