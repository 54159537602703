import { StyleSheet, Dimensions } from 'react-native';

import { colors, fonts } from 'styles';

const { width } = Dimensions.get('window');

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  form: {
    marginTop: '20%',
    width: '90%',
  },
  header: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: 40,
    borderRadius: 0,
  },
  headerText: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    fontSize: 26,
    lineHeight: 26,
  },
  title: {
    fontSize: 22,
    fontFamily: fonts.lato.bold,
    color: colors.grey,
    lineHeight: 22,
    marginTop: 20,
  },
  field: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  explainer: {
    fontFamily: fonts.lato.regular,
    color: colors.greyDayColor,
    fontSize: 12,
    marginHorizontal: '5%',
    marginTop: 10,
  },
  button: {
    width: '80%',
    marginBottom: 120,
    marginTop: 20,
  },
  webContainer: {
    width: '80%',
    marginLeft: '7%',
    flexDirection: 'row',
  },
  webUserImageContainer: {
    width: width * 0.25
  },
  webRightPartContainer: {
    height: '100%',
    marginLeft: '5%',
    width: width * 0.317,
  },
  webInformationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  webCompanyImage: {
    borderRadius: 15,
    width: width * 0.05,
    height: width * 0.05,
  },
  webCompanyInformationContainer: {
    width: '100%',
    marginLeft: 15,
    alignItems: 'center',
    flexDirection: 'row',
  }
});
