import React from 'react';

const settings = {
  speed: 500,
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default settings;
