import { StyleSheet, Dimensions, Platform } from 'react-native';

import { colors, fonts } from 'styles';

const { width: widthScreen, height: heightScreen } = Dimensions.get('screen');

export const AddGroupStyles = StyleSheet.create({
  flexGrowOne: {
    flexGrow: 1,
    alignItems: 'center',
    paddingTop: 20,
  },
  container: {
    paddingTop: heightScreen * 0.05,
    alignItems: 'center',
  },
  imgClose: {
    resizeMode: 'contain',
    width: 150,
    height: 150,
  },
  containerImgClose: {
    left: 50,
    top: 50,
  },
  errorStyle: {
    position: 'absolute',
    right: -20,
    top: 20,
    width: 8,
    height: 30,
    borderRadius: 5,
    backgroundColor: colors.burgundy,
  },
  containerInput: {
    width: '85%',
    height: 76,
    borderTopColor: '#aaa',
    borderLeftColor: '#aaa',
    borderBottomColor: '#fff',
    borderRightColor: '#fff',
    borderWidth: 7,
    borderRadius: 30,
    justifyContent: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
    backgroundColor: colors.whiteShadow,
  },
  inputStyle: {
    marginLeft: -50,
    textAlign: 'center',
    alignSelf: 'center',
    shadowOpacity: 1,
    overflow: 'hidden',
    shadowColor: colors.whiteShadow,
    shadowRadius: 2,
    borderRadius: 30,
    shadowOffset: { width: 0, height: 1 },
    elevation: 70,
    fontWeight: 'bold',
    width: '100%',
  },
  containerForm: {
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'transparent',
  },
  textClose: {
    fontSize: 25,
    textAlign: 'center',
    color: colors.grey,
  },
  titre: {
    fontSize: 25,
    color: colors.grey,
    fontFamily: fonts.lato.bold,
    marginTop: 40,
    marginBottom: 20,
    textAlign: Platform.OS !== 'web' ? 'center' : undefined,
  },
  description: {
    fontSize: 16,
    color: colors.grey,
    fontFamily: fonts.lato.bold,
    margin: 20,
    textAlign: 'center',
  },
  littleDescription: {
    fontSize: 16,
    color: colors.greyDayColor,
    fontFamily: fonts.lato.regular,
    marginBottom: 20,
    textAlign: 'center',
  },
  containerVideoLink: {
    marginTop: 60,

  },
  imgInput: {
    marginLeft: 10,
    alignSelf: 'center',
    width: 50,
    height: 50,
    resizeMode: 'contain',
  },
  borderStyleComponent: {
    borderRadius: 30,
    marginVertical: '5%',
  },
  componentDescription: {
    marginVertical: '15%',
  },
  centerStyleComponent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  containerButton: {
    position: 'absolute',
    bottom: 15,
    left: (widthScreen * 0.5) - (169 / 2),
    width: 169,
    backgroundColor: colors.white,
    alignItems: 'center',
    padding: 20,
    borderRadius: (widthScreen * 0.25) / 2,
  },
  containerTextSvg: {
    position: 'absolute',
    top: 90,
    justifyContent: 'center',
    flexDirection: 'row',
    width: widthScreen * 0.5,
  },
  contentText: {
    alignItems: 'center',
    width: '100%',
  },
  logoTransformStyle: {
    transform: [{ rotate: '90deg' }],
  },
  textBtn: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    fontSize: 16,
  },
  errorStylePhotos: {
    position: 'absolute',
    top: 300,
    right: 0,
    width: 3,
    height: 13,
    borderRadius: 5,
    backgroundColor: colors.burgundy,
  },
  errorStyleLogo: {
    position: 'absolute',
    top: 635,
    right: 0,
    width: 3,
    height: 13,
    borderRadius: 5,
    backgroundColor: colors.burgundy,
  },
});
