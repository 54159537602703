import { StyleSheet, Dimensions } from 'react-native';
import { colors, fonts } from 'styles';

const widthScreen = Dimensions.get('screen').width;

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.whiteShadow,
  },
  containerButton: {
    position: 'absolute',
    bottom: 15,
    left: (widthScreen * 0.5) - (169 / 2),
    width: 169,
    backgroundColor: colors.white,
    alignItems: 'center',
    padding: 20,
    borderRadius: (widthScreen * 0.25) / 2,
  },
  slider: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: colors.whiteShadow,
    paddingBottom: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sliderTitle: {
    padding: 10,
    margin: 10,
    color: colors.grey,
    fontSize: 17,
    fontWeight: 'bold',
  },
  contentSlider: {
    textAlign: 'center',

    paddingBottom: 30,
  },
  verticalLine: {
    height: 11,
    width: 3,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
  contentItem: {
    paddingVertical: 40,
    borderWidth: 1,
    borderColor: colors.white,
  },

  filter: {
    width: '100%',
    height: '100%',
    backgroundColor: '#000022',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    overflow: 'hidden',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.6,
  },
  topTitle: {
    fontSize: 28,
    fontFamily: fonts.lato.bold,
    color: colors.white,
    textAlign: 'center',
  },
  title: {
    fontSize: 20,
    marginTop: '40%',
    fontFamily: fonts.lato.bold,
    color: colors.white,
    textAlign: 'center',
  },
  infosContainer: {
    position: 'absolute',
    top: '65%',
    alignItems: 'center',
  },
  presented: {
    color: colors.white,
    fontFamily: fonts.lato.light,
    fontSize: 16,
    marginTop: '7%',
    textAlign: 'center',
  },
  by: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    fontSize: 20,
    textAlign: 'center',
    marginVertical: 3,
  },
  byDescription: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    fontSize: 12,
    marginTop: '5%',
    width: '60%',
    textAlign: 'center',
  },
  lastArticles: {
    marginTop: '7%',
    fontSize: 23,
    fontFamily: fonts.lato.bold,
  },

  tvContainer: {
    // height: 400,
    width: '100%',
    paddingTop: 50,
  },
  tvBackground: {
    width: '100%',
    height: 150,
    justifyContent: 'flex-end',
  },
  tvTitle: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    fontSize: 20,
    marginHorizontal: '20%',
    marginBottom: 15,
  },
  tvInfosContainer: {
    width: '100%',
    paddingHorizontal: '20%',
    paddingTop: 25,
  },
  tvCategory: {
    textTransform: 'uppercase',
    fontFamily: fonts.lato.bold,
    color: colors.greyDark,
    marginBottom: 5,
    fontSize: 16,
  },
  tvBy: {
    color: colors.greyDayColor,
    fontSize: 12,
  },
  tvByWho: {
    color: colors.burgundy,
    fontFamily: fonts.lato.bold,
    textTransform: 'uppercase',
    fontSize: 12,
  },
  separator: {
    marginVertical: 10,
    backgroundColor: colors.bar,
    height: 2,
    width: '12%',
    borderRadius: 2,
  },
  tvDescription: {
    fontFamily: fonts.lato.regular,
    marginBottom: 10,
    fontSize: 12,
  },
  tvGradient: {
    height: 10,
    width: '100%',
  },
});
