import { StyleSheet } from 'react-native';

import { isMobile } from 'components/device';

export const ReportStyle = StyleSheet.create({
  form: {
    width: '90%',
    marginTop: isMobile ? '20%' : '5%',
  },
  input: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  mobileSubmitButton: {
    marginTop: 20,
    width: '70%',
  },
});
