import { Icon } from 'react-native-elements';
import { Image, Pressable } from 'native-base';
import React, { useState, useEffect } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { View, Text, useWindowDimensions } from 'react-native';

import Api from 'services/api';
import { colors, fonts, TextStyles } from 'styles';
import PlusIcon from '../../../../assets/images/plusButton.png';

import formValidation from './formValidation';
import { AddGroupStyles } from './AddGroupStyles';
import AddParentNetworkModal from './AddParentNetworkModal';

import Loader from 'components/loader';
import Input from 'components/form/input';
import PickerField from 'components/form/picker';
import ImagePicker from 'components/form/imagePicker';
import GrayButton from 'components/buttons/grayButton';
import PlaceAutocomplete from 'components/form/placeAutocomplete';

const ValueInput = ({ fields }) => (
  <>
    {fields.map((value, i) => (
      <View key={i} style={{ width: '100%' }}>
        <Field
          name={`${value}.name`}
          width="100%"
          label={'Valeur #' + (i + 1)}
          component={Input}
        />
        <Field
          name={`${value}.description`}
          label="Description"
          style={{ marginBottom: 20 }}
          width="100%"
          multiline
          height={140}
          component={Input}
        />
      </View>
    ))}
    <GrayButton
      onPress={() => fields.push({ name: '', description: '' })}
      style={{ marginBottom: 40, marginTop: 10 }}
      height={50}
      width="50%"
      titre="+ Ajouter une valeur"
    />
  </>
);

const ServiceInput = ({ fields }) => (
  <>
    {fields.map((value, i) => (
      <View key={i} style={{ width: '100%' }}>
        <Field
          name={`${value}.name`}
          width="100%"
          label={'Service #' + (i + 1)}
          component={Input}
        />
        <Field
          name={`${value}.description`}
          label="Description"
          style={{ marginBottom: 20 }}
          width="100%"
          multiline
          height={140}
          component={Input}
        />
      </View>
    ))}
    <GrayButton
      onPress={() => fields.push({ name: '', description: '' })}
      style={{ marginBottom: 40, marginTop: 10 }}
      height={50}
      width="50%"
      titre="+ Ajouter un service"
    />
  </>
);

function AddNetworkForm(props) {
  const { width, height } = useWindowDimensions();

  const [parentNetworks, setParentNetworks] = useState([]);
  const [parentNetworkFormOpen, setParentNetworkFormOpen] = useState(false);
  const [ networkTypes, setNetworkTypes ] = useState([]);

  const loadNetworkResources = async () => {
    try {
      const { data } = await Api.misc.getNetworkTypes();
      const { data: pn } = await Api.parent_networks.getAll(true);
      setNetworkTypes(data);
      setParentNetworks(pn);
    } catch(err) {
      console.error('Error loading network types', err);
    }
  };
  useEffect(() => {
    loadNetworkResources();
  }, []);

  if (!networkTypes || !parentNetworks) return <Loader />;

  return (
    <View style={AddGroupStyles.containerForm}>
      <AddParentNetworkModal networkTypes={networkTypes} visible={parentNetworkFormOpen} setVisible={setParentNetworkFormOpen} />
      <View style={{ width: '60%', alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View style={{ width: '42%' }}>
            <Field
              name='picture_url'
              images={null}
              svg={<Icon
                name="plus"
                type="simple-line-icon"
                size={70}
                color={colors.greyDark}
              />}
              style={{ ...AddGroupStyles.borderStyleComponent, minHeight: 360 }}
              height={width * 0.3}
              width="100%"
              titre="Photo de Profil"
              component={ImagePicker}
            />
          </View>
          <View style={{ width: '53%', marginLeft: '2.5%' }}>
            {
              !(props.type === 'edit') && (
                <>
                  <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20, marginTop: 0 }}>Réseau Mère</Text>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Field
                      name='parent_network_id'
                      style={AddGroupStyles.borderStyleComponent}
                      images={null}
                      width="85%"
                      items={parentNetworks.map((pn) => ({ id: pn.id.toString(), description: pn.description }))}
                      labelKey="description"
                      valueKey="id"
                      label="Groupement mère"
                      component={PickerField}
                    />
                    <Pressable style={{ marginLeft: '2%', height: 52, justifyContent: 'center' }} onPress={() => setParentNetworkFormOpen(true)}>
                      <Image alt="Change me" src={PlusIcon} resizeMethod="contain" style={{ width: 24, height: 24 }} />
                    </Pressable>
                  </View>
                </>
              )
            }
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Infos</Text>
            <View style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
              <View style={{ width: '100%' }}>
                <Field
                  name="name"
                  label="Nom du groupement"
                  width="100%"
                  component={Input}
                  required={true}
                />
              </View>
            </View>
            <View style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
              <View style={{ width: '48%' }}>
                <Field
                  name="website"
                  label="Site Web"
                  autoCapitalize="none"
                  autoCorrect={false}
                  width="100%"
                  component={Input}
                  required={true}
                />
              </View>
              <View style={{ width: '48%', marginLeft: '4%' }}>
                <Field
                  name="members"
                  label="Nombre d’adhérents"
                  autoCapitalize="none"
                  autoCorrect={false}
                  width="100%"
                  component={Input}
                  required={true}
                />
              </View>
            </View>
            <View style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
              <View style={{ width: '48%' }}>
                <Field
                  name="contact_email"
                  label="Email de contact"
                  autoCapitalize="none"
                  autoCorrect={false}
                  width="100%"
                  keyboardType="email-address"
                  textContentType="emailAddress"
                  component={Input}
                  required={true}
                />
              </View>
              <View style={{ width: '48%', marginLeft: '4%' }}>
                <Field
                  name="contact_phone"
                  label="Téléphone de contact"
                  autoCapitalize="none"
                  autoCorrect={false}
                  width="100%"
                  component={Input}
                  required={true}
                />
              </View>
            </View>
            <Text style={TextStyles({ margin: 20 }).bold20}>Iban (récupérer vos recettes d'event et d'adhésions)</Text>
            <Field
              name="iban"
              label="Iban"
              width="100%"
              component={Input}
              autoCorrect={false}
              autoCapitalize="none"
            />
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Vidéo de présentation</Text>
            <Field
              name='video_url'
              style={AddGroupStyles.borderStyleComponent}
              images={null}
              width="100%"
              autoCapitalize='none'
              autoCorrect={false}
              label="Lien vidéo de présentation"
              component={Input}
            />
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Lieu d'activité</Text>
            <View style={{ zIndex: 100, marginBottom: 10 }}>
              <Field
                width="100%"
                name="address"
                label="Address"
                component={PlaceAutocomplete}
              />
            </View>
            <Field
              name="addressPrecisions"
              label="Précisions"
              autoCapitalize='words'
              width="100%"
              component={Input}
            />
          </View>
        </View>
        <View style={{ flexDirection: 'row', width: '100%', marginTop: 30 }}>
          <View style={{ width: '26%' }}>
            <Field
              name='logo_url'
              images={null}
              svg={<Icon
                name="plus"
                type="simple-line-icon"
                size={50}
                color={colors.greyDark}
              />}
              style={AddGroupStyles.borderStyleComponent}
              height={width * 0.6 * 0.26}
              width="100%"
              titre="Logo du groupement"
              component={ImagePicker}
            />
          </View>
          <View style={{ width: '69%', marginLeft: '2.5%', height: height * 0.277 }}>
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20, height: height * 0.077 }}>Description</Text>
            <Field
              name="description"
              label="Descriptif de votre groupement"
              width="100%"
              height="100%"
              component={Input}
              required={true}
              multiline
            />
          </View>
        </View>
        <View style={{ marginTop: 20, flexDirection: 'row', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <View style={{ width: '48%' }}>
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Les valeurs</Text>
            <FieldArray
              name="worths"
              component={ValueInput}
            />
          </View>
          <View style={{ width: '48%', marginLeft: '4%' }}>
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Les services</Text>
            <FieldArray
              name="services"
              component={ServiceInput}
            />
            {/*
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 20, margin: 20 }}>Conditions d'entrée</Text>
            <FieldArray
              name="requirements"
              component={({ fields }) => (
                <>
                  {fields.map((requirement, i) => (
                    <View key={i} style={{ width: '100%' }}>
                      <Field
                        name={`${requirement}.name`}
                        label={'Condition #' + (i + 1)}
                        width="100%"
                        component={Input}
                      />
                      <Field
                        name={`${requirement}.description`}
                        label="Description"
                        style={{ marginBottom: 20 }}
                        multiline
                        height={140}
                        width="100%"
                        component={Input}
                      />
                    </View>
                  ))}
                  <GrayButton
                    onPress={() => fields.push({ name: '', description: '' })}
                    style={{ marginBottom: 40, marginTop: 10 }}
                    height={50}
                    width="50%"
                    titre="+ Ajouter une condition"
                  />
                </>
              )}
            />
             */}
          </View>
        </View>

        {
          /*
                  <Text style={AddNetworkStyles.titre}>Tarifs</Text>
        <FieldArray
          name="fees"
          component={({ fields }) => (
            <>
              {fields.map((price, i) => (
                <View key={i} style={{ width: Platform.OS !== 'web' ? MobileInputWidth : '100%' }}>
                  <View style={{ flexDirection: 'row', width: '100%' }}>
                    <View style={{ width: '60%', marginRight: '5%' }}>
                      <Field
                        name={`${price}.name`}
                        label={'Tarif #' + (i + 1)}
                        autoCapitalize="none"
                        style={{ paddingHorizontal: '0%' }}
                        width="100%"
                        component={Input}
                      />
                    </View>
                    <View style={{ width: '35%' }}>
                      <Field
                        name={`${price}.price`}
                        label="0€"
                        keyboardType="numeric"
                        width="100%"
                        style={{ paddingHorizontal: '0%' }}
                        component={Input}
                      />
                    </View>
                  </View>
                  <Field
                    name={`${price}.description`}
                    label="Description"
                    style={{ marginBottom: 20 }}
                    width="100%"
                    multiline
                    height={140}
                    component={Input}
                  />
                </View>
              ))}
              <GrayButton
                onPress={() => fields.push({ name: '', description: '' })}
                style={{ marginBottom: 40, marginTop: 10 }}
                height={50}
                width={Platform.OS !== 'web' ? '65%' : '50%'}
                titre="+ Ajouter un tarif"
              />
            </>
          )}
        />

        {!props.editMode && <>
          <Text style={AddNetworkStyles.titre}>Coordonnées personnelles</Text>
          <Text style={AddNetworkStyles.littleDescription}>Nous vous contacterons pour valider votre demande</Text>
          <Field
            name="contact.firstname"
            label="Prénom"
            component={Input}
            width="100%"
            required={true}
          />
          <Field
            name="contact.lastname"
            label="Nom"
            component={Input}
            width="100%"
            required={true}
          />
          <Field
            name="contact.phoneNumber"
            label="Téléphone"
            keyboardType="numeric"
            width="100%"
            component={Input}
            required={true}
          />
          <Field
            name="contact.email"
            label="Email"
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="email-address"
            width="100%"
            component={Input}
            required={true}
          />
        </>}
           */
        }
      </View>
    </View>
  );
}

const EditGroupForm = reduxForm({
  form: 'editNetwork', validate: formValidation, enableReinitialize: true
})((props) => <AddNetworkForm {...props} type="edit" />);
export {
  EditGroupForm,
};
export default reduxForm({ form: 'createNetwork', validate: formValidation, enableReinitialize: true })(AddNetworkForm);
