import React from 'react';
import { Image } from 'native-base';
import { View, TouchableOpacity, Text } from 'react-native';

import BombButtonStyle from './BombButtonStyle';

import Svg from 'components/svg';
import Loader from 'components/loader';
import { isMobile } from 'components/device';

export default function BombButton({
  svg,
  style,
  titre,
  width,
  height,
  images,
  onPress,
  svgWidth,
  textStyle,
  svgHeight,
  loading = false,
  borderRadius = 30,
  titrePosition = 'down',
}) {
  const ButtonContent = () => {
    if (loading) {
      return (<Loader />);
    } else {
      if (!images && !svg && !titre) return null;
      return (
        <View style={BombButtonStyle({ titre, titrePosition }).container}>
          {!!titre && titrePosition === 'middle' && (
            <Text
              style={[
                BombButtonStyle().textStyleButton,
                textStyle,
              ]}
            >{titre}</Text>
          )}
          {!!images && (
            <Image
              style={BombButtonStyle().imgLogoButton}
              source={{ uri: images }}
            />
          )}
          {!!svg ? (
            isMobile ? (
              <Svg src={svg} width={svgWidth || '100%'} height={svgHeight || '100%'} />
            ) : (
              <Svg src={svg} />
            )) : null}
          {!!titre && titrePosition === 'down' && (
            <Text
              style={[
                BombButtonStyle().textStyleButton,
                textStyle,
              ]}
            >
              {titre}
            </Text>
          )}
        </View>
      );
    }
  };

  return (
    <TouchableOpacity
      disabled={!onPress || loading}
      onPress={() => { if (onPress) onPress(); }}
      style={[BombButtonStyle().content, style, { width, height, borderRadius }]}
    >
      <ButtonContent />
    </TouchableOpacity>
  );
}
