import React from 'react';
import { View } from 'react-native';

import { reduxForm, Field } from 'redux-form';

import Input from 'components/form/input';
import styles from '../stepOne/StepOneStyle';

const validate = (values) => {
  const errors = {};

  if (!values.email || values.email.length === 0) {
    errors.email = 'Obligatoire';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email invalide';
  }

  if (!values.confirmEmail || values.confirmEmail.length === 0) {
    errors.confirmEmail = 'Obligatoire';
  } else if (values.email !== values.confirmEmail) {
    errors.confirmEmail = 'Les emails ne correspondent pas';
  }

  if (!values.password || values.password.length === 0) {
    errors.password = 'Obligatoire';
  } else if (values.password.length < 6) {
    errors.password = 'Miniumum 6 caractères';
  }

  if (!values.confirmPassword || values.confirmPassword.length === 0) {
    errors.confirmPassword = 'Obligatoire';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Les mots de passe ne correspondent pas';
  }

  return errors;
};

function StepTwoForm() {
  return (
    <View style={styles.form}>
      <Field
        centered
        name="email"
        label="Email"
        autoCorrect={false}
        autoCapitalize="none"
        autoCompleteType="email"
        component={Input}
        required={true}
        width="100%"
      />
      <Field
        centered
        name="confirmEmail"
        label="Confirmez votre email"
        autoCorrect={false}
        autoCapitalize="none"
        autoCompleteType="email"
        component={Input}
        required={true}
        width="100%"
      />
      <Field
        centered
        name="password"
        label="Mot de passe"
        autoCorrect={false}
        autoCapitalize="none"
        component={Input}
        required={true}
        width="100%"
      />
      <Field
        centered
        name="confirmPassword"
        label="Confirmez votre mot de passe"
        autoCorrect={false}
        autoCapitalize="none"
        component={Input}
        required={true}
        width="100%"
      />
    </View>
  );
}

export default reduxForm ({ form: 'StepTwo', validate })(StepTwoForm);
