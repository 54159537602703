import React from 'react';
import menus from '../../menu/config';
import { Pressable, Text, View } from 'native-base';
import { fonts, colors } from '../../../styles';
import Svg from 'components/svg';
import { Icon } from 'styles/svg';

const HeaderSection = ({ push, redirect, menuSelect }) => (
  <View
    style={{
      minWidth: 100,
      marginTop: -5,
      marginLeft: 15,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    }}
  >
    <Pressable style={{ width: 25, height: 25, cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }} onPress={() => push('Home')}>
      <Svg src={Icon} />
    </Pressable>
    <Text style={{ color: 'black', fontFamily: fonts.lato.bold, fontSize: 20, marginLeft: 15 }}>
      {menus[menuSelect].title}
    </Text>
  </View>
);

export default HeaderSection;
