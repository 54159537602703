import { StyleSheet, Platform } from 'react-native';
import { colors, fonts } from 'styles';

export default StyleSheet.create({
  hollowContainer: {
    height: 70,
    width: '90%',
    overflow: 'hidden',
    borderRadius: 40,
    marginVertical: '2.5%',
  },
  container: {
    width: '100%',
    height: '100%',
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infosContainer: {
    marginLeft: '11%',
    width: '50%',
    marginVertical: 10
  },
  title: {
    fontSize: 18,
    fontFamily: fonts.lato.bold,
  },
  description: {
    fontSize: 12,
    fontFamily: fonts.lato.regular,
    color: colors.greyDayColor,
  },
  price: {
    height: '100%',
    width: '25%',
    marginRight: 8,
    borderBottomRightRadius: 30,
    borderTopRightRadius: 30,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    shadowRadius: 5,
    shadowOpacity: Platform.OS === 'web' ? 0.1 : 1,
    shadowOffset: { height: 0, width: 0 },
    shadowColor: colors.black,
  },
  priceText: {
    fontFamily: fonts.lato.bold,
    fontSize: 18,
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});
