'use strict';
var __extends = (this && this.__extends) || (function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== 'function' && b !== null)
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.Address = void 0;
var index_1 = require('./index');
var Address = /** @class */ (function (_super) {
  __extends(Address, _super);
  function Address() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Address.prototype.verify = function (placeId) {
    return this.requester.get('/address/verify?placeId=' + placeId);
  };
  return Address;
}(index_1.Component));
exports.Address = Address;
