import { StyleSheet } from 'react-native';

import { isBrowser } from 'components/device';

export const InviteNetworkStyle = StyleSheet.create({
  form: {
    marginTop: isBrowser ? '5%' : '0%',
    width: '90%',
  },
  submit: {
    marginBottom: 50,
    marginTop: 40,
    width: '80%'
  },
});
