import axios from 'axios';
import store from '../store';
import { getToken } from './reducers/AccountReducerService';

class Http {
  axios = () => {
    const state = store.getState();
    const token = getToken(state) || null;
    const AuthStr = 'Bearer '.concat(token);
    return axios.create({
      baseURL: 'https://api.othr.pro',
      headers: { Authorization: AuthStr, 'Content-Type': 'application/ld+json; charset=utf-8' },
    });
  };

  handleError = (error) => {
    console.log('Error for request', error.request.responseURL);
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.warn('//----------------- ERROR RESPONSE ----------------//');
      console.log('data', error.response.data);
      console.log('status', error.response.status);
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log('//------------------ ERROR REQUEST ----------------//');
      console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('//---------------------- ERROR --------------------//');
      console.log('Error', error.message);
    }
    console.log(error);
    throw error;
  };

  get = async (params) => {
    try {
      return await this.axios().get(params.url, params.data);
    } catch (error) {
      this.handleError(error);
    }
  };

  post = async (params) => {
    try {
      return await this.axios().post(params.url, params.data);
    } catch (error) {
      this.handleError(error);
    }
  };

  put = async (params) => {
    try {
      return await this.axios().put(params.url, params.data);
    } catch (error) {
      this.handleError(error);
    }
  };

  patch = async (params) => {
    try {
      return await this.axios().patch(params.url, params.data, params.config);
    } catch (error) {
      this.handleError(error);
    }
  };

  delete = async (params) => {
    try {
      return await this.axios().delete(params.url, params.data);
    } catch (error) {
      this.handleError(error);
    }
  };
}

export default new Http();
