import React from 'react';
import { TouchableOpacity, Text, View, Image } from 'react-native';

import styles from './NetworkStyles';
import { pushToNetwork, pushToParentNetwork } from 'services/navigationPush';

import { isBrowser } from 'components/device';
import RoundedLogo from 'components/roundedLogo';
import DarkBackground, { DisableCardBackground } from 'components/backgrounds/darkBackground';
import HollowBackground from 'components/backgrounds/hollowBackground';

export default (props) => {
  const onPress = () => {
    if (props.onPress) {
      props.onPress();
    } else {
      if (props.networks) {
        pushToParentNetwork(props.push, props);
      } else {
        pushToNetwork(props.push, props);
      }
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={1}
      delayPressIn={50}
      onPress={onPress}
      disabled={props.disabled}
      style={[styles.networkContainer, { borderRadius: props.noRadius ? 0 : 15 }]}
    >
      <HollowBackground />
      <Image alt="Change me" source={{ uri: props.picture_url }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}/>
      {
        !props.disabled && (
          <DarkBackground />
        )
      }
      {
        typeof(props.number) === 'number' ? (
          <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <Image alt="Change me" source={{ uri: props.logo_url }} style={styles.networkImage} />
            <View style={{ height: '30%', width: '100%', alignItems: 'center', marginTop: '10%' }}>
              {typeof(props.number) === 'number' && (
                <View style={styles.networkNumberContainer}>
                  <Text style={styles.networkNumber}>{props.number}</Text>
                </View>
              )}
            </View>
            <Text style={[styles.networkDescription, { textAlign: 'center', width: '50%', marginLeft: 0, height: 100 }]} numberOfLines={3}>{props.description}</Text>
          </View>
        ) : (
          <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', paddingHorizontal: 20, marginBottom: !isBrowser ? 20 : '10%' }}>
            <RoundedLogo uri={props.logo_url} style={styles.networkImage} />
            <Text style={styles.networkDescription} numberOfLines={5}>{props.description}</Text>
          </View>
        )
      }
      {
        props.disabled && (
          <DisableCardBackground />
        )
      }
    </TouchableOpacity>
  );
};
