import React from 'react';
import { Image, Pressable, Text, View } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, useWindowDimensions } from 'react-native';

import { colors, fonts, TextStyles } from 'styles';
import { pushToMedia, pushToNetwork } from 'services/navigationPush';
import { MagazineWhite, VideoWhite, PodcastWhite } from 'styles/svg';

import Svg from 'components/svg';
import { ResponsiveView } from '../device';

const MediaHeader = ({ mediaType }) => {
  if (mediaType === 'article') {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Svg src={MagazineWhite} style={{ width: 29, height: 25 }} />
        <Text selectable style={{ marginLeft: 5, fontFamily: fonts.lato.bold, fontSize: 17, color: colors.white }}>Magazine</Text>
      </View>
    );
  }
  if (mediaType === 'video') {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Svg src={VideoWhite} style={{ width: 29, height: 29 }} />
        <Text selectable style={{ marginLeft: 5, fontFamily: fonts.lato.bold, fontSize: 17, color: colors.white }}>Video</Text>
      </View>
    );
  }
  if (mediaType === 'podcast') {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Svg src={PodcastWhite} style={{ width: 29, height: 25 }} />
        <Text selectable style={{ marginLeft: 5, fontFamily: fonts.lato.bold, fontSize: 17, color: colors.white }}>Podcast</Text>
      </View>
    );
  }
};

const MediaRedirection = ({ mediaType, media, push }) => {
  let text = '';
  if (mediaType === 'article') {
    text = 'Lire l\'article';
  }
  if (mediaType === 'podcast') {
    text = 'Ecouter le podcast';
  }
  if (mediaType === 'video') {
    text = 'Regarder la vidéo';
  }
  return (
    <Pressable
      onPress={() => pushToMedia(push, mediaType, media)}
      style={{ marginTop: '6%', backgroundColor: colors.white, borderRadius: 30, width: '45%', height: 35, alignItems: 'center', justifyContent: 'center' }}
    >
      <Text selectable style={{ fontFamily: fonts.lato.bold, fontSize: 18 }}>
        {text}
      </Text>
    </Pressable>
  );
};

const MediaCard = ({ push, media }) => {
  const { height } = useWindowDimensions();

  return (
    <ImageBackground
      source={{ uri: media.image }}
      style={{ width: '100%', height: height * 0.8 > 600 ? height * 0.8 : 600, paddingTop: 80 }}
    >
      <LinearGradient colors={['rgba(0,0,0,0.5)', 'rgba(0,0,0,0)']} start={[0.0, 0.5]} end={[1.0, 0.5]} locations={[0.0, 1.0]} style={{ width: '100%', height: '100%' }}>
        <ResponsiveView style={{ justifyContent: 'center', height: '100%' }}>
          <Text style={TextStyles({ color: colors.white, textUnderlineOffset: 6, textDecorationLine: 'underline' }).black27}>{('les médias du moment').toUpperCase()}</Text>
          <Image alt="Change me" source={{ uri: media.author.picture_url }} style={{ marginTop: '2.5%', width: 130, height: 130, borderRadius: 15, resizeMode: 'stretch' }}/>
          <Text style={{ marginTop: '0.5%', fontFamily: fonts.lato.black, fontSize: 19, color: colors.white, textUnderlineOffset: 6, textDecorationLine: 'underline' }}>{media.sphere}</Text>
          <Text style={{ marginTop: '2%', fontFamily: fonts.lato.black, fontSize: 42, color: colors.white }}>{media.title}</Text>
          <Text style={{ width: '80%', fontFamily: fonts.lato.regular, fontSize: 20, color: colors.white, lineHeight: 30 }} numberOfLines={3}>{media.description}</Text>
          <Pressable
            onPress={() => {}}
            // onPress={() => pushToNetwork(push, { id: media.id })}
            style={{ marginTop: '3%', backgroundColor: colors.white, borderRadius: 30, width: '12%', height: 45, alignItems: 'center', justifyContent: 'center' }}
          >
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 18 }}>
              Découvrir
            </Text>
          </Pressable>
        </ResponsiveView>
      </LinearGradient>
    </ImageBackground>
  );
};

export default MediaCard;
