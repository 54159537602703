import { View } from 'native-base';
import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';

import Svg from 'components/svg';
import MediaCard from './MediaCard';
import ContactCard from './ContactCard';
import NetworkCard from './NetworkCard';
import { PaginationOn, PaginationOff } from 'styles/svg';

SwiperCore.use([Pagination, Autoplay]);

const SlideContent = ({ item, push }) => {
  if (item.type === 'contact') {
    return <ContactCard contact={item} push={push} />;
  }
  if (item.type === 'media') {
    return <MediaCard media={item} push={push} />;
  }
  if (item.type === 'network') {
    return <NetworkCard network={item} push={push} />;
  }
};

const Promo = ({ push, data }) => {
  const { height } = useWindowDimensions();
  const [index, setIndex] = useState(0);

  return (
    <View style={{ width: '100%' }}>
      <Swiper
        speed={1000}
        autoplay={{
          delay: 5000,
          enabled: false,
        }}
        slidesPerView="auto"
        slideToClickedSlide={true}
        onSlideChange={(e) => setIndex(e.realIndex)}
        style={{ width: '100%', height: height * 0.8, minHeight: 600, zIndex: -1, marginBottom: 30 }}
      >
        {
          data.map((item, index) => (
            <SwiperSlide key={index} style={{ width: '100%', height: height * 0.8, minHeight: 600 }}>
              <SlideContent item={item} push={push} />
            </SwiperSlide>
          ))
        }
      </Swiper>
      {/*<View style={{ marginTop: 10, marginBottom: 25, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>*/}
      {/*  {*/}
      {/*    data.map((_, i) => (*/}
      {/*      <Svg key={i} src={index === i ? PaginationOn : PaginationOff} width={7} height={7} style={{ marginLeft: i === 0 ? 0 : 5 }} />*/}
      {/*    ))*/}
      {/*  }*/}
      {/*</View>*/}
    </View>
  );
};

export default Promo;
