import { View } from 'native-base';
import { submit, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import * as WebBrowser from 'expo-web-browser';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Text, Platform, Alert, TouchableOpacity } from 'react-native';

import Api from 'services/api';
import { APP_URL } from 'config';

import iapSubscribe from './iapSubscribe';
import StepThreeForm from './StepThreeForm';
import styles from '../stepOne/StepOneStyle';

import Logo from 'components/logo';
import { isBrowser } from 'components/device';
import AuthGoBack from 'components/authGoBack';
import TextSection from 'components/textSection';
import GoBack from 'components/buttons/goBackButton';
import BombButton from 'components/buttons/bombButton';
import LinearBackground from 'components/backgrounds/linearBackground';
import KeyboardAwareScrollView from 'components/keyboardAwareScrollView';

const StepThree = ({ route, navigation: { navigate }, submit, formValues }) => {
  console.log('formValues', formValues, route.params);
  const [ loading, setLoading ] = useState(false);
  const [ registerType, setRegisterType ] = useState(undefined);
  const [ percentOff, setPercentOff ] = useState(0);

  const handleCoupon = async () => {
    try {
      const data = await Api.payments.checkCoupon(formValues?.coupon);
      setPercentOff(data?.data?.percent_off || data?.data?.coupon?.percent_off || 0);
    } catch (err) {
      setPercentOff(0);
    }
  };

  useEffect(() => {
    const fetchRegisterType = async () => {
      const rt = await AsyncStorage.getItem('REGISTER_TYPE');
      setRegisterType(rt);
    };
    fetchRegisterType();
  }, []);

  useEffect(() => {
    handleCoupon();
  }, [ formValues?.coupon ]);

  const subscribe = async (values) => {
    const { userId, token } = route.params;
    if (Platform.OS === 'ios') {
      try {
        await iapSubscribe(setLoading, userId);
        Alert.alert('Succès', 'Vous êtes maintenant abonnés à Othr. Vous pouvez maintenant vous connecter', [{
          text: 'Ok',
          onPress: () => navigate('Login'),
        }, {
          text: 'Annuler'
        }]);
      } catch (err) {
        console.error('Error in subscribing with apple', err);
        if (err.message !== 'cancelled') {
          Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
        }
      }
    } else {
      const checkout = await Api.payments.subscribe(
        token,
        isMobile ? `${APP_URL}/subscription-success` : `${APP_URL}/subscription-success`,
        isMobile ? `${APP_URL}/subscription-cancel` : `${APP_URL}/subscription-cancel`,
        values.coupon,
        values.zone,
      );
      window.location.replace(checkout.data.url);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <LinearBackground />
      {!isBrowser && <GoBack/>}
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        {isBrowser && <View style={{ marginTop: '2%', marginLeft: '2%', width: '100%' }}>
          <AuthGoBack goBack={() => registerType === 'FORM' ? navigate('StepTwo') : navigate('StepOne')} />
        </View>}
        <View style={styles.container}>
          <Logo style={styles.logo} />
          <View style={{
            alignItems: 'center',
            ...isBrowser && {
              width: 450,
              paddingTop: 50,
              borderRadius: 30,
              paddingBottom: 50,
              backgroundColor: 'white',
            }
          }}>
            <View style={{ flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 40 }}>
              <TextSection titleSection="Département Souhaité" noBar />
              <View style={{ width: 30, height: 1, marginTop: 20, backgroundColor: 'black' }} />
            </View>
            <View style={styles.titleContent}>
              <Text style={styles.title}>Choisissez votre département</Text>
            </View>
            <StepThreeForm onSubmit={subscribe} initialValues={{ zone: route.params?.zoneId?.toString() }}/>
            <View style={styles.subscriptionDetails}>
              <View style={{ width: '60%' }}>
                <Text style={styles.subscriptionTitle}>Abonnement Othr</Text>
                {!!percentOff && <Text style={styles.subscriptionNetwork}>Groupement partenaire</Text>}
                <Text style={styles.subscriptionText}>Départemental (1 an)</Text>
              </View>
              <View style={styles.subscriptionSeparator}/>
              <View style={{ alignItems: 'center' }}>
                <Text style={styles.priceText} numberOfLines={1}>
                  {Platform.OS === 'ios' ? '499,99' : Math.round(3.6 * (100 - percentOff))}€ <Text style={styles.taxText}>HT</Text>
                </Text>
                <Text style={styles.subscriptionRenewal}>Renouvellement annuel</Text>
              </View>
            </View>
            <Text style={[styles.title, { fontSize: 12, paddingHorizontal: '10%', textAlign: 'center', marginTop: 20 } ]}>Grâce à votre abonnement Othr, vous pourrez rencontrez les entrepreneurs de votre secteur, participer à des évènements, consulter du contenu professionnel, rejoindre un réseau d'affaire, et recommander vos nouveaux contacts.</Text>
            <TouchableOpacity onPress={() => WebBrowser.openBrowserAsync('https://api.othr.pro/static/cgu.pdf')}>
              <Text style={styles.cguText}>Conditions générales de vente</Text>
            </TouchableOpacity>
            <BombButton
              loading={loading}
              style={{ marginTop: '7%' }}
              onPress={() => {
                submit('StepThree');
              }}
              height={52}
              width={255}
              titre="Souscription"
              titrePosition="middle"
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues('StepThree')(state),
});

const actionProps = {
  submit,
};

export default connect(mapStateToProps, actionProps)(StepThree);
