import { StyleSheet, Dimensions } from 'react-native';
import { colors, fonts } from 'styles';

const widthScreen = Dimensions.get('screen').width;

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  containerButton: {
    position: 'absolute',
    bottom: 15,
    left: (widthScreen * 0.5) - (169 / 2),
    width: 169,
    backgroundColor: colors.white,
    alignItems: 'center',
    padding: 20,
    borderRadius: (widthScreen * 0.25) / 2,
  },
  slider: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 120,
  },
  sliderTitle: {
    padding: 10,
    margin: 10,
    color: colors.grey,
    fontSize: 17,
    fontWeight: 'bold',
  },
  containtSlider: {
    marginBottom: 30,
    marginTop: 20,
  },
  containtContrib: {
    width: '85%',
    alignItems: 'center',
    marginTop: '10%',
  },

  imageContrib: {
    width: 56,
    height: 77,
    borderRadius: 15,
  },
  containeritemContributor: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    marginVertical: 10,
    justifyContent: 'center',
  },
  identifiant: {
    width: '10%',
  },

  imageContainer: {
    width: '20%',
  },
  textContribContainer: {
    paddingLeft: '5%',
    width: '60%',
  },
  identifiantId: {
    fontFamily: fonts.lato.black,
    fontSize: 18,
    color: colors.burgundy,
  },
  name: {
    fontFamily: fonts.lato.bold,
    fontSize: 14,
    color: colors.greyMedium,
  },
  description: {
    fontFamily: fonts.lato.light,
    fontSize: 10,
    color: colors.greyMedium,
  },
  adress: {
    fontFamily: fonts.lato.lightItalic,
    fontSize: 10,
    color: colors.greyMedium,
  },
  stat: {
    flexDirection: 'row',
    width: '75%',
    backgroundColor: colors.white,
    borderRadius: 50,
    justifyContent: 'space-between',
    marginTop: 10,
  },
  transmis: {
    backgroundColor: colors.burgundy,
    borderRadius: 50,
    color: colors.white,
    paddingVertical: 4,
    width: '50%',
    textAlign: 'right',
    paddingRight: 20,
    fontSize: 10,
    fontFamily: fonts.lato.boldItalic,
  },
  transmisText: {
    fontFamily: fonts.lato.bold,
    fontSize: 10,
    color: colors.greyMedium,
    textTransform: 'uppercase',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 4,
    paddingRight: 10,
  },
  containerContributors: {
    borderRadius: 30,
    overflow: 'hidden',
    width: '100%',
    marginBottom: '5%',
  },
  spheresListContainer: {
    height: 100,
    marginBottom: '5%',
  },
  sphereContainer: {
    height: '100%',
    width: 140,
    borderRadius: 35,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
  },
  sphereText: {
    color: colors.white,
    fontFamily: fonts.lato.bold,
    fontSize: 16,
  },
});
