import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Text, View } from 'native-base';
import { Alert, Image, SafeAreaView } from 'react-native';

import Api from 'services/api';
import { colors, TextStyles } from 'styles';
import { MeetWhite, Swap } from 'styles/svg';
import { swapRequest } from 'store/actions/user';
import { toastError, toastSuccess } from 'services/toasts';

import styles from './CreateSwapRequestStyle';
import SwapRequestForm from './CreateSwapRequestForm';

import WebUserImage from 'components/cards/WebUserImage';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import { isMobile, isBrowser, MobileView, BrowserView, ResponsiveView } from 'components/device';
import BackgroundLinearGradient from 'components/backgrounds/backgroundLinearGradient';

const SwapRequest = ({ route, navigation: { push, goBack }, submit, swapRequest }) => {
  const [user, setUser] = useState(undefined);
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadUser = async () => {
    try {
      const response = await Api.users.get(route.params.userId);
      setUser(response.data);
    } catch (err) {
      console.error('Error loading user', err);
    }
  };

  const sendRequest = async (values) => {
    setIsLoading(true);
    try {
      await swapRequest(values.description, route.params.userId);
      if (isMobile) {
        setIsSend(true);
        setTimeout(goBack, 2000);
      }
      if (isBrowser) {
        goBack();
        toastSuccess('Demande de rencontre envoyée!');
      }
      setIsLoading(false);
    } catch (err) {
      console.log('Error', err);
      if (err.response?.status === 409) {
        if (isMobile) {
          Alert.alert('Vous avez déjà envoyé une demande de swap', 'demande déjà envoyée');
        }
        if (isBrowser) {
          toastError('Vous avez déjà effectué une demande de swap');
        }
      } else {
        if (isMobile) {
          Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
        }
        if (isBrowser) {
          toastError('Une erreur est survenue, veuillez réessayer plus tard');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const SwapRequestFormContainer = () => (
    <ResponsiveView style={isBrowser ? {} : styles.container}>
      {/* MOBILE */}
      <MobileView>
        <BackgroundLinearGradient
          image={{ uri: user?.picture_url }}
          width="100%"
          height={450}
          styles={styles.header}
        >
          <Text style={styles.headerText}>Rencontrez {user?.firstname}</Text>
        </BackgroundLinearGradient>
        <Text style={styles.title}>Demande de swap</Text>
        <SwapRequestForm onSubmit={sendRequest} name={user?.firstname} />
        <Text style={styles.explainer}>Vos coordonnées seront transmises à {user?.firstname} lors de cette demande afin qu'il ou elle puisse prendre contact avec vous</Text>
        <MultiColorButton svg={MeetWhite} style={styles.button} loading={isLoading} text="Rencontrer" onPress={() => submit('swapRequest')} />
      </MobileView>
      {/* BROWSER */}
      <BrowserView>
        <View style={styles.webContainer}>
          <View style={styles.webUserImageContainer}>
            <WebUserImage user={user} />
          </View>
          <View style={styles.webRightPartContainer}>
            <View style={styles.webInformationContainer}>
              <Image
                alt="Change me"
                source={{ uri: !!(user?.logo_url || user?.picture_url) ? user?.logo_url || user?.picture_url : null }}
                style={styles.webCompanyImage}
              />
              <View style={styles.webCompanyInformationContainer}>
                <View style={{ width: '60%' }}>
                  <Text style={TextStyles({ lineHeight: 18, color: colors.grey }).regular17}>Entreprise</Text>
                  <Text style={TextStyles().black25}>{user?.firstname} {user?.lastname}</Text>
                </View>
              </View>
            </View>
            <Text style={TextStyles({ marginTop: 20 }).regular23}>Demande de Swap</Text>
            <SwapRequestForm name={user?.firstname} onSubmit={sendRequest} />
            <Text style={TextStyles({  marginTop: 20, marginBottom: 45, width: '57%', color: colors.greyDayColor }).regular12}>
              Vos cordonnées seront transmises à {user?.firstname} lors de cette demande afin de prendre contact avec vous
            </Text>
            <MultiColorButton loading={isLoading} style={{ width: '60%' }} text={`Rencontrer ${user?.firstname}`} onPress={() => submit('swapRequest')}  />
          </View>
        </View>
      </BrowserView>
    </ResponsiveView>
  );

  const SwapRequestConfirmation = () => (
    <SafeAreaView style={[styles.container, { justifyContent: 'center' }]}>
      <Swap width="20%" height="20%" />
      <Text style={TextStyles().regular16}>
        Demande de Swap envoyée !
      </Text>
    </SafeAreaView>
  );

  return (
    <LoadResourceLayout
      push={push}
      mobilePadding={false}
      loadResources={loadUser}
      breadCrumb={<Breadcrumb push={push} goBack={goBack} menuSelect="Othrs" />}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
      disableMenu={true}
    >
      {
        isSend && isMobile ? (
          <SwapRequestConfirmation />
        ) : (
          <SwapRequestFormContainer />
        )
      }
    </LoadResourceLayout>
  );
};

const actionProps = { swapRequest, submit };

export default connect(null, actionProps)(SwapRequest);
