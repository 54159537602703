import { View } from 'native-base';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { Image, Platform, Text, useWindowDimensions } from 'react-native';

import { colors, fonts } from 'styles';

import formValidation from './formValidation';

import Input from 'components/form/input';
import PickerField from 'components/form/picker';
import ContactPicker from 'components/form/contactPicker';
import WebUserImage from 'components/cards/WebUserImage';

const actions = [
  {
    label: 'Vous devez l\'appeler',
    value: 'Vous devez l\'appeler',
  },
  {
    label: 'Vous devez le ou la contacter par e-mail',
    value: 'Vous devez le ou la contacter par e-mail',
  },
  {
    label: 'Il ou Elle va vous appeler',
    value: 'Il ou Elle va vous appeler',
  },
  {
    label: 'Il ou Elle va vous envoyer un e-mail',
    value: 'Il ou Elle va vous envoyer un e-mail',
  },
];

const CreateAddRecommendationForm = ({ user, change }) => {
  const { width } = useWindowDimensions();
  const [selectedAction, setSelectedAction] = useState();

  const handleContactPicked = (selectedUser) => {
    change('createAddRecommendationForm', 'client_name', `${selectedUser.firstname} ${selectedUser.lastname}`);
    change('createAddRecommendationForm', 'client_phone', selectedUser.phone);
    change('createAddRecommendationForm', 'client_email', selectedUser.email_contact || selectedUser.email);
  };

  const handleContactRemove = () => {
    change('createAddRecommendationForm', 'client_name', '');
    change('createAddRecommendationForm', 'client_phone', '');
    change('createAddRecommendationForm', 'client_email', '');
  };

  return (
    <View style={{ width: '80%' }}>
      <View style={{ flexDirection: 'row', width: '100%', marginBottom: '2%' }}>
        <View style={{ width: width * 0.25, minWidth: 300 }}>
          <WebUserImage user={user} height={width * 0.3 > 345 ? width * 0.3 : 345} />
        </View>
        <View style={{ width: width * 0.51, marginLeft: '5%' }}>
          <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
            <Image
              alt="Change me"
              source={{ uri: !!(user?.logo_url || user?.picture_url) ? user?.logo_url || user?.picture_url : null }}
              style={{
                borderRadius: 15,
                width: width * 0.05,
                height: width * 0.05,
              }}
            />
            <View style={{ width: '100%', marginLeft: 15, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ width: '60%' }}>
                <Text style={{ fontFamily: fonts.lato.regular, fontSize: 17, lineHeight: 18, color: colors.grey }}>Entreprise</Text>
                <Text style={{ fontFamily: fonts.lato.black, fontSize: 25, color: colors.black }}>{user?.company || `${user?.firstname} ${user?.lastname}`}</Text>
              </View>
            </View>
          </View>
          <View style={{ width: width * 0.317 }}>
            <Text style={{ fontFamily: fonts.lato.regular, fontSize: 23, marginTop: 20, marginBottom: 20 }}>Recommandation</Text>
            <Field
              name="title"
              label="Titre de la Recommendation"
              autoCapitalize="none"
              width="100%"
              component={Input}
              required
            />
            <Field
              name="expected_action"
              label="Action attendue"
              autoCapitalize="none"
              items={actions}
              onChange={(e) => setSelectedAction(e)}
              labelKey='label'
              valueKey='value'
              width="100%"
              component={PickerField}
              required
            />
            <View style={{ zIndex: 1, width: '100%' }}>
              <Field
                name="client"
                label="Nom du client"
                autoCapitalize="none"
                width="100%"
                component={ContactPicker}
                style={{ marginBottom: 10, width: '100%' }}
                onChange={handleContactPicked}
                handleContactRemove={handleContactRemove}
                required
              />
            </View>
            <View style={{ zIndex: -1 }}>
              <Field
                name="client_name"
                label="Nom du client"
                autoCapitalize="none"
                component={Input}
                required
                style={{ flex: 1, marginRight: 20 }}
              />
              <Field
                name="client_phone"
                label="Téléphone"
                autoCapitalize="none"
                component={Input}
                width="100%"
                keyboardType="phone-pad"
                required={selectedAction === 'Vous devez l\'appeler'}
              />
              <Field
                name="client_email"
                label="Mail"
                autoCompleteType="email"
                textContentType="emailAddress"
                keyboardType="email-address"
                autoCapitalize="none"
                component={Input}
                required={selectedAction === 'Vous devez le ou la contacter par e-mail'}
                width="100%"
              />
            </View>
            <View style={{ width: '100%' }}>
              <Text style={{ fontFamily: fonts.lato.regular, fontSize: 23, marginTop: 20, marginBottom: 20 }}>Description</Text>
              <Field
                name="description"
                label={Platform.OS !== 'web' ? 'Décrivez votre Recommendation' : 'Expliquez pourquoi vous souhaitez le ou la Recommander'}
                height={150}
                width="100%"
                component={Input}
                multiline
                required
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const mapDispatchToProps = {
  change,
};

const CreateAddRecommendationReduxFrom = reduxForm({ form: 'createAddRecommendationForm', enableReinitialize: true, validate: formValidation })(
  connect(null, mapDispatchToProps)(CreateAddRecommendationForm),
);

export default CreateAddRecommendationReduxFrom;
