import React from 'react';
import { Text } from 'react-native';

import { IconCheck } from 'styles/svg';
import { ValidationEventStyle } from './ValidationEventStyle';

import Svg from 'components/svg';
import ShareButtons from 'components/shareButtons';
import GrayButton from 'components/buttons/grayButton';
import MenuScrollView from 'components/menuScrollView';

export default function ValidationEvent({ navigation: { push }, route: { params: { eventId, code } } }) {
  return (
    <MenuScrollView style={ValidationEventStyle.container} contentContainerStyle={ValidationEventStyle.content} push={push}>
      <Svg src={IconCheck} width={100} height={100} />
      <Text style={ValidationEventStyle.textOne}>Félicitations !</Text>
      <Text style={ValidationEventStyle.textTwo}>
        Votre Event a bien été créé. Code administrateur: {code}
      </Text>
      <GrayButton onPress={() => push('MyEvents')} titre="Mes Events" width="70%" style={{ marginVertical: '20%' }} />
      <ShareButtons title={'Partager'} url={`https://app.othr.pro/events/${eventId}`} />
    </MenuScrollView>
  );
}
