import { connect } from 'react-redux';
import * as Linking from 'expo-linking';
import React, { useEffect, useState } from 'react';
import { Text, View, CloseIcon, Pressable } from 'native-base';
import { FlatList, Image, TouchableOpacity } from 'react-native';

import Api from 'services/api';
import { Event, Reco, Swap } from 'styles/svg';
import { colors, fonts, TextStyles } from 'styles';
import { Logout as logout } from 'store/actions/account';

import Svg from 'components/svg';
import Loader from 'components/loader';
import { ResponsiveView } from 'components/device';
import HollowBackground from 'components/backgrounds/hollowBackground/HollowBackground';

import NotificationsStyles from 'screens/account/notifications/NotificationsStyles';

const NotificationsModal = ({ push, setIsNotificationsModalOpen }) => {
  const [ notifications, setNotifications ] = useState(null);

  const enrichNotification = (notification) => {
    let data = {};
    if (notification.recommendation_id) {
      data.source = `${notification.othr?.firstname} ${notification.othr?.lastname}`;
      data.image = notification.othr.picture_url;
      data.icon = Reco;
    } else if (notification.othr_id) {
      data.source = `${notification.othr?.firstname} ${notification.othr?.lastname}`;
      data.image = notification.othr.picture_url;
      data.icon = Swap;
    } else if (notification.event_id) {
      data.source = notification.event?.title;
      data.image = notification.event.network?.logo_url || notification.event.picture_url;
      data.icon = Event;
    } else if (notification.recommendation_id) {
      data.source = `${notification.othr?.firstname} ${notification.othr?.lastname}`;
      data.image = notification.othr.picture_url;
      data.icon = Reco;
    }
    data.link = Linking.createURL(notification.link?.split('/application/')?.[1]);
    return {
      ...notification,
      ...data,
    };
  };

  const loadNotifications = async () => {
    try {
      const { data } = await Api.profile.getNotifications(true);
      setNotifications(data.map(enrichNotification));
    } catch (err) {
      console.error('Error loading notifications', err);
    }
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  const renderNotification = ({ item }) => (
    <TouchableOpacity style={{ ...NotificationsStyles.notifContainer, backgroundColor: item.seen ? colors.liteGrey : colors.whiteShadow }} onPress={() => Linking.openURL(item.link)}>
      <View style={NotificationsStyles.imageContainer}>
        <Image alt="Change me" source={{ uri: item.image }} style={NotificationsStyles.image}/>
        <View style={NotificationsStyles.littleImageContainer}>
          <Svg src={item.icon} width={18} height={18} />
        </View>
      </View>
      <View style={NotificationsStyles.textContainer}>
        <Text numberOfLines={1} style={TextStyles({ color: colors.black }).bold14}>{item.title}</Text>
        <Text numberOfLines={1} style={TextStyles({ color: colors.black }).regular12}>{item.source}</Text>
      </View>
      {!item.seen && <View style={NotificationsStyles.seen}/>}
    </TouchableOpacity>
  );

  return (
    <ResponsiveView style={{ zIndex: 3 }}>
      <View
        style={{
          width: 375,
          height: 200,
          marginTop: -50,
          paddingTop: 55,
          marginLeft: 'auto',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            minHeight: 300,
            maxHeight: 600,
            width: '100%',
            borderRadius: 10,
            // paddingBottom: 10,
            alignItems: 'center',
            backgroundColor: colors.white,
          }}>
          <View style={{ marginTop: 20, flexDirection: 'row', alignItems: 'center', marginBottom: 30, width: '85%' }}>
            <Text style={TextStyles().bold17}>
              Notifications
            </Text>
            <View style={{ marginLeft: 'auto' }}>
              <Pressable onPress={() => setIsNotificationsModalOpen(false)}>
                <CloseIcon size={14} />
              </Pressable>
            </View>
          </View>
          {
            notifications === null ? (
              <Loader />
            ) : (
              <View style={{ flex: 1, width: '100%', height: '100%', overflow: 'scroll' }}>
                <FlatList
                  renderItem={renderNotification}
                  data={notifications.slice(0, 10)}
                  keyExtractor={(item) => (item.id)}
                  contentContainerStyle={NotificationsStyles.listContainer}
                  ItemSeparatorComponent={() => <View style={{ width: '100%', backgroundColor: colors.greyLight, height: 0.5, opacity: 0.5 }}/>}
                />
              </View>
            )
          }
          <View style={{ marginTop: 20, paddingBottom: 20 }}>
            <Pressable onPress={() => push('Notifications')}>
              <div className="hover-underline-animation" style={{ paddingBottom: 4 }}>
                <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15 }}>
                  Toutes les notifications
                </Text>
              </div>
            </Pressable>
          </View>
        </View>
      </View>
    </ResponsiveView>
  );
};

const mapDispatchToProps = {
  logout,
};

export default connect(null, mapDispatchToProps)(NotificationsModal);
