import React from 'react';
import { View } from 'react-native';
import { Field, reduxForm } from 'redux-form';

import Input from 'components/form/input/Input';
import PickerField from 'components/form/picker';
import { ContactUsStyle } from './ContactUsStyle';

const validate = (values) => {
  const errors = {};

  if (!values.object || values.object.length === 0) {
    errors.object = 'Obligatoire';
  }
  if (!values.request || values.request.length === 0) {
    errors.request = 'Obligatoire';
  }

  return errors;
};

function ContactUsForm() {
  return (
    <View style={ContactUsStyle.form}>
      <Field
        name="object"
        label="Objet"
        component={PickerField}
        items={[
          { id: 'Signaler un problème', name: 'Signaler un problème' },
          { id: 'Poser une question', name: 'Poser une question' },
          { id: 'Supprimer mon compte', name: 'Supprimer mon compte' },
          { id: 'Suppression des données personnelles', name: 'Suppression des données personnelles' },
          { id: 'Autre', name: 'Autre' },
        ]}
        labelKey='name'
        width="100%"
        valueKey='name'
        required={true}
        autoCapitalize="none"
        style={ContactUsStyle.input}
      />
      <Field
        height={250}
        width="100%"
        name="request"
        required={true}
        multiline={true}
        component={Input}
        style={ContactUsStyle.input}
        label="Décrivez votre demande"
      />
    </View>
  );
}

export default reduxForm({ form: 'contactUs', validate, enableReinitialize: true })(ContactUsForm);
