import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Text, View } from 'native-base';
import { SafeAreaView } from 'react-native';

import { IconCheck } from 'styles/svg';
import { contactNetwork } from 'store/actions/network';
import { toastError, toastSuccess } from 'services/toasts';
import { colors, TextStyles, ContainerStyles } from 'styles';

import styles from './ContactNetworkStyle';
import ContactNetworkForm from './ContactNetworkForm';

import BasicLayout from 'components/layouts/basicLayout';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import { isMobile, BrowserView, MobileView, isBrowser } from 'components/device';

const ContactNetwork = ({ navigation: { push, goBack, canGoBack }, submit, network, contactNetwork }) => {
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = async (values) => {
    try {
      setIsLoading(true);
      await contactNetwork(network.id, values);
      setIsSend(true);
      if (isBrowser) {
        toastSuccess('Demande envoyée au groupement!');
      }
      if (isMobile) {
        setTimeout(goBack, 2000);
      }
    } catch (err) {
      console.error('Error contacting network', err);
      if (isBrowser) {
        toastError('Une erreur est survenue');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BasicLayout
      push={push}
      headerSection={<HeaderSection push={push} menuSelect="Groupements" redirect="Network"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Groupements" />}
    >
      {
        isSend  && isMobile ? (
          <SafeAreaView style={[ContainerStyles.requestFormMobileSentContainer, { justifyContent: 'center' }]}>
            <IconCheck width="20%" height="20%" />
            <Text style={TextStyles({ marginTop: 20 }).regular16}>
              Votre message a bien été envoyé
            </Text>
            <Text style={TextStyles().regular16}>
              {network.name} vous contactera dans les plus brefs délais
            </Text>
          </SafeAreaView>
        ) : (
          <View>
            {/* MOBILE */}
            <MobileView>
              <View style={ContainerStyles.requestFormMobileContainer}>
                <Text style={TextStyles({ lineHeight: 23, color: colors.grey }).bold23}>Contacter {network.name}</Text>
                <ContactNetworkForm onSubmit={sendRequest} />
                <MultiColorButton loading={isLoading} text="Envoyer" onPress={() => submit('contactNetwork')} style={styles.submit} />
              </View>
            </MobileView>
            {/* BROWSER */}
            <BrowserView>
              <View style={ContainerStyles.requestFormWebContainer}>
                <Text style={TextStyles().black23}>Contactz un groupement à rejoindre Othr</Text>
                <ContactNetworkForm onSubmit={sendRequest} />
                <MultiColorButton loading={isLoading} text="Envoyer" onPress={() => submit('contactNetwork')} style={ContainerStyles.requestFormWebSubmitButton}/>
              </View>
            </BrowserView>
          </View>
        )
      }
    </BasicLayout>
  );
};

const mapStateToProps = (state, props) => ({
  network: state.Network.networks?.find((e) => (e.id.toString() === props.route.params.networkId.toString())),
});

const actionProps = { contactNetwork, submit };

export default connect(mapStateToProps, actionProps)(ContactNetwork);
