import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  icon: {
    // marginTop: -4,
    marginLeft: '-7%',
  },
  textContainer: {
    height: '100%',
    // paddingBottom: 4,
  }
});
