import { submit } from 'redux-form';
import { connect } from 'react-redux';
import React, { useMemo, useState } from 'react';
import { View, Alert, Platform } from 'react-native';

import { EditGroupForm } from './AddGroupForm';
import { editNetwork } from 'store/actions/network';
import { pushToNetwork } from 'services/navigationPush';
import { toastError, toastSuccess } from 'services/toasts';

import { isBrowser, isMobile } from 'components/device';
import HeaderSection from 'components/layouts/headerSection';
import BreadCrumb from 'components/layouts/breadCrumb/BreadCrumb';
import MultiColorButton from 'components/buttons/multiColorButton';
import BasicLayout from 'components/layouts/basicLayout/BasicLayout';

function EditGroup({ submit, navigation: { pop, push, goBack, canGoBack }, network, editNetwork }) {

  const [ loading, setLoading ] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await editNetwork(values);
      if (isMobile) {
        Alert.alert(
          'Succès',
          'Vos changements ont bien été sauvegardés',
          [{ text: 'Ok', onPress: pop }],
        );
      }
      if (isBrowser) {
        pushToNetwork(push, network);
        toastSuccess('Votre groupement a bien été modifié.');
      }
    } catch (err) {
      console.log('Error editing network', err);
      if (isMobile) {
        Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
      }
      if (isBrowser) {
        toastError('Une erreur est survenue.');
      }
    } finally {
      setLoading(false);
    }
  };

  const initialValues = useMemo(() => ({
    ...network,
    parent_network_id: network?.parent_network_id?.toString(),
    members: network?.members?.toString(),
    fees: network?.fees?.map(f => ({ ...f, price: f.price?.toString() })),
    type: network?.type?.id,
    addressPrecisions: network?.address?.precisions,
  }), [ network ]);

  return (
    <BasicLayout
      push={push}
      disableMenu={true}
      mobilePadding={false}
      headerSection={<HeaderSection push={push} menuSelect="Groupements" redirect="Groupements"/>}
      breadCrumb={<BreadCrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Groupements" />}
      title={'Modifier votre groupement'}
    >
      <View style={{ flex: 1, width: '100%', alignItems: Platform.OS === 'web' ? 'flex-start' : 'center' }}>
        <EditGroupForm onSubmit={handleSubmit} initialValues={initialValues} editMode/>
        <MultiColorButton
          onPress={() => submit('editNetwork')}
          text="Enregistrer"
          style={Platform.OS !== 'web' ? {
            width: '80%', height: 70, marginBottom: 150, marginTop: '20%',
          } : {
            width: '16%', marginLeft: '20%'
          }}
          loading={loading}
        />
      </View>
    </BasicLayout>
  );
}

const mapStateToProps = (state, props) => ({
  network: state.Network.networks?.find(n => n.id?.toString() === props.route.params.networkId?.split('-').slice(-1)[0]),
});

const actionProps = {
  submit,
  editNetwork,
};

export default connect(mapStateToProps, actionProps)(EditGroup);
