import 'expo-dev-client';
import * as Font from 'expo-font';
import { Provider } from 'react-redux';
import { Alert, Platform, StatusBar } from 'react-native';
import { LogBox, View } from 'react-native';
import { registerRootComponent } from 'expo';
import { ToastContainer } from 'react-toastify';
import * as SplashScreen from 'expo-splash-screen';
import { LoadScript } from '@react-google-maps/api';
import * as Notifications from 'expo-notifications';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PersistGate } from 'redux-persist/integration/react';
import React, { useCallback, useEffect, useState } from 'react';
import { NativeBaseProvider, Center, extendTheme } from 'native-base';
import { checkForUpdateAsync, fetchUpdateAsync, reloadAsync } from 'expo-updates';

import { GOOGLE_API_KEY } from 'config';
import { isBrowser } from 'components/device';
let sentry = null;
// if (Platform.OS !== 'web') {
//   console.log('import sentry');
//   import('@sentry/react-native').then(s => {
//     sentry = s;
//     console.log('sentry', sentry);
//   });
// }

// css style for web
import style from './style/style';

import { store, persistor } from 'store';
import Routes from 'routes';
import { loadFonts } from 'styles/fonts';
import StripeProvider from './StripeProvider';
import Loader from './src/components/loader/Loader';
import AudioPlayerContextProvider from './src/components/audioPlayer/AudioPlayerContext';

if (!isBrowser) {
  // LogBox.ignoreLogs(['new NativeEventEmitter']); // Ignore log notification by message
  // LogBox.ignoreAllLogs();
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    }),
  });
  // sentry.init({
  //   dsn: 'https://cc2164296d2a46ca8feacf7427373c4f@o1147974.ingest.sentry.io/6219356',
  //   tracesSampleRate: 1,
  //   // enableInExpoDevelopment: false,
  //   // debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  // });
}

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

const App = () => {
  const [appIsReady, setAppIsReady] = useState(false);

  const theme = extendTheme({
    components: {
      Input: {
        style: {
          color: 'red',
        }
      }
    }
  });

  const tryUpdate = async () => {
    const d1 = await checkForUpdateAsync();
    const { isAvailable } = d1;
    if (isAvailable) {
      let d = {};
      try {
        d = await fetchUpdateAsync();
      } catch (err) {
        console.error('Error fetching update', err);
      }
      const { isNew } = d;
      if (isNew) {
        await reloadAsync();
      }
    }
  };

  const prepare = async () => {
    try {
      await Font.loadAsync(loadFonts);
      if (!isBrowser) {
        try {
          await tryUpdate();
        } catch (e) {
          // Alert.alert('Error tryUpdate', e.toString());
          console.error('Error fetching update', e.toString());
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      // Tell the application to render
      setAppIsReady(true);
    }
  }

  useEffect(() => {
    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return isBrowser && (
      <NativeBaseProvider theme={theme}>
        <Center style={{ marginTop: '25%' }}>
          <Loader/>
        </Center>
      </NativeBaseProvider>
    );
  }

  return (
    <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
      <StripeProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StatusBar backgroundColor="transparent" barStyle="dark-content" />
            <NativeBaseProvider>
              <AudioPlayerContextProvider>
                {
                  !isBrowser ? (
                    <Routes />
                  ) : (
                    <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
                      <GoogleOAuthProvider clientId="362304675270-dliovn3b6l2a5s8otvafcormsgtnedsq.apps.googleusercontent.com">
                        <ToastContainer
                          draggable
                          rtl={false}
                          pauseOnHover
                          closeOnClick
                          autoClose={5000}
                          pauseOnFocusLoss
                          newestOnTop={false}
                          position="top-center"
                          hideProgressBar={false}
                        />
                        <Routes />
                      </GoogleOAuthProvider>
                    </LoadScript>
                  )
                }
              </AudioPlayerContextProvider>
            </NativeBaseProvider>
          </PersistGate>
        </Provider>
      </StripeProvider>
    </View>
  );
};

registerRootComponent(App);

export default App;
