import React from 'react';
import { Text } from 'native-base';
import { useWindowDimensions } from 'react-native';

import { colors, TextStyles } from '../../styles';
import { isBrowser } from '../../components/device';
import LoadResourceLayout from '../../components/layouts/loadResourceLayout';
import { JoinNetwork, Network } from '../../store/actions/network';
import { connect } from 'react-redux';

const ContributionPayment = ({ route, network, Network, navigation: { push }, publicPage = false, }) => {
  const { height } = useWindowDimensions();
  const { networkId, type } = route.params;

  const loadNetwork = async () => {
    try {
      await Network(networkId);
    } catch (err) {
      console.error('Error loading event', err);
    }
  };

  return (
    <LoadResourceLayout publicPage={publicPage} disableHeader={publicPage} disableMenu={publicPage} push={push} loadResources={loadNetwork}>
      {
        network ? (
          <div style={{ width: '100%', height: height * 0.6, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={TextStyles({ lineHeight: 25, width: !isBrowser ? '80%' : '30%', textAlign: 'center' }).bold20}>
              {
                type === 'success' ? 'Le paiement de votre cotisation a bien été effectué' : 'Le paiement de votre cotisation a été rejeté'
              }
            </Text>
            <Text style={TextStyles({ color: colors.greyPodcast, maxWidth: !isBrowser ? '80%' : '30%', textAlign: 'center', marginTop: 20 }).italic14}>
              {
                type === 'success' ? `${network.name} a bien reçu votre cotisation !` : 'Veuillez réessayer'
              }
            </Text>
          </div>
        ) : (
          <div style={{ width: '100%', height: height * 0.6, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={TextStyles({ lineHeight: 25, width: !isBrowser ? '80%' : '30%', textAlign: 'center' }).bold20}>
                Une erreur est surevenue
            </Text>
          </div>
        )
      }
    </LoadResourceLayout>
  );
};

const mapStateToProps = (state, props) => {
  const network = state.Network.networks?.find((e) => (e.id?.toString() === props.route.params.networkId?.toString().split('-').slice(-1)[0]));
  return ({
    userId: state.Account.userId,
    network,
  });
};
const mapDispatchToProps = {
  Network,
  JoinNetwork,
};
export default connect(mapStateToProps, mapDispatchToProps)(ContributionPayment);
