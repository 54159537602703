import React, { useEffect } from 'react';
import { useWindowDimensions, View } from 'react-native';
import { SocialIcon } from 'react-native-elements';
import { Text } from 'native-base';
import { colors, TextStyles } from '../../../styles';
import Svg from '../../../components/svg/Svg';
import { Swap } from '../../../styles/svg';

const appleStoreUrl= 'https://apps.apple.com/fr/app/othr/id1609669447';
const playStoreUrl = 'https://play.google.com/store/apps/details?id=pro.othr.app';

const ResetPasswordSuccess = (props) => {
  const { width, height } = useWindowDimensions();
  const isComputer = width > 800;
  console.log('isComputer', isComputer);
  useEffect(() => {
    if (isComputer) window.location.replace('/login');
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Svg src={Swap} style={{ height: height * 0.08 }} />
      <Text style={TextStyles({ lineHeight: 40, width: '80%', textAlign: 'center', marginTop: 40 }).black30}>
        Votre mot de passe a bien été modifié !
      </Text>
      <Text style={TextStyles({ color: colors.greyPodcast, maxWidth: '80%', textAlign: 'center', marginTop: 10 }).regular16}>
        Vous pouvez maintenant retourner sur votre application pour vous connecter
      </Text>
      <View style={{ position: 'absolute', bottom: 20, alignItems: 'center' }}>
        <Text style={TextStyles({ color: colors.grey, marginBottom: 20, textAlign: 'center', width: '90%' }).bold14}>
          Si vous n'avez pas encore l'application Othr, vous pouvez la télécharger ci-dessous
        </Text>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <SocialIcon
            type="apple"
            button={true}
            onPress={() => window.location.replace(appleStoreUrl)}
            style={{
              margin: 0,
              width: 150,
              height: 40,
              marginBottom: 20,
              borderRadius: 10,
              backgroundColor: '#000000',
              marginRight: 20,
            }}
          />
          <SocialIcon
            type="play"
            button={true}
            onPress={() => window.location.replace(playStoreUrl)}
            style={{
              margin: 0,
              width: 150,
              height: 40,
              marginBottom: 20,
              borderRadius: 10,
              backgroundColor: '#4285F4',
            }}
          />
        </View>
      </View>
    </div>
  );
};

export default ResetPasswordSuccess;
