import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

export default forwardRef((props, ref) => {
  const scrollRef = useRef();

  useImperativeHandle(ref, () => ({
    scrollToPosition: (x, y) => {
      scrollRef.current?.scrollToPosition(0, y);
    },
  }));

  return (<KeyboardAwareScrollView
    // keyboardOpeningTime={Number.MAX_SAFE_INTEGER}
    keyboardShouldPersistTaps={'handled'}
    enableResetScrollToCoords={false}
    enableAutomaticScroll={true}
    ref={scrollRef}
    extraScrollHeight={-180}
    showsVerticalScrollIndicator={false}
    {...props}
  />);
});
