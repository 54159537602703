import { StyleSheet, Dimensions, Platform } from 'react-native';
import { colors } from 'styles';

const widthScreen = Dimensions.get('screen').width;

export const CreateMediumStyle = StyleSheet.create({
  marginFive: {
    margin: 5,
  },
  flexGrowOne: {
    flexGrow: 1,
  },
  container: {
    alignItems: 'center',
    paddingBottom: 20,
  },
  containerForm: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    width: Platform.OS !== 'web' ? widthScreen * 0.9 : '100%',
  },
  textT1: {
    paddingTop: 75,
    alignItems: 'center',
    color: colors.white,
    fontSize: 20,
    fontWeight: 'bold',
  },
  textT2: {
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center',
    color: colors.white,
    fontSize: 20,
    fontWeight: 'bold',
  },
  organisation: {
    display: 'flex',
    flexDirection: 'row',
    height: 75,
    borderColor: colors.liteGrey,
    borderBottomWidth: 0.4,
  },
  organisationLeft: {
    display: 'flex',
    height: '100%',
    paddingRight: 10,
    width: '40%',
    marginLeft: 10,
  },
  organisationLeftText: {
    color: colors.black,
    fontSize: 15,
    marginRight: 5,
  },
  organisationRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
  },
  organisationRightText: {
    color: colors.black,
    fontSize: 20,
    fontWeight: 'bold',
    marginRight: 5,
  },
  organisationRightNumber: {
    color: colors.red,
    fontSize: 22,
    fontWeight: 'bold',
  },
  adress: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    // backgroundColor: colors.white,
    borderRadius: 10,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 5,
  },
  adressImage: {
    width: '20%',
    alignItems: 'flex-start',
    marginLeft: 10,
  },
  adreeInput: {
    width: '100%',
  },
  tarifContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 65,
    borderColor: colors.liteGrey,
    borderBottomWidth: 0.4,
  },
  tarif: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tariftext: {
    color: colors.black,
    fontSize: 16,
    marginRight: 5,
    marginLeft: 10,
  },
  note: {
    width: '30%',
    alignItems: 'flex-start',
    fontSize: 10,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.whiteShadow,
    borderRadius: 25,
    height: '100%',
    width: '100%',
  },
  title: {
    padding: 10,
    margin: 5,
    color: colors.grey,
    fontSize: 17,
    fontWeight: 'bold',
  },
  desciption: {
    paddingLeft: 15,
    paddingRight: 15,
    color: colors.black,
    margin: 15,
    borderRadius: 10,
    height: 150,
    textAlignVertical: 'top',
    paddingTop: 15,
  },
  gallery: {
    height: '100%',
  },
  gallerySlider: {},
  button: {
    width: '100%',
    height: 80,
    backgroundColor: colors.blue,
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
  },
  buttonText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  tickets: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 15,
    borderRadius: 10,
    // backgroundColor: colors.white,
  },
  viewContaint: {
    marginBottom: 30,
    alignItems: 'center',
    borderWidth: 10,
    borderColor: '#e0dede',
    alignSelf: 'center',
    borderRadius: 50,
    width: widthScreen * 0.7,
  },
  flexTwo: {
    flex: 2,
  },
  borderRadiusThirty: {
    borderRadius: 30,
  },
  textBombButton: {
    color: colors.white,
    fontWeight: 'bold',
    letterSpacing: 1.2,
  },
  containerEvent: {
    marginVertical: 10,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5%',
    width: widthScreen * 0.9,
  },
  widthFifty: {
    width: '50%',
  },
  spaceAvailbale: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
    width: widthScreen * 0.78,
  },
  containtTextAvailable: {
    width: '60%', alignSelf: 'center',
  },
  textSansLimite: {
    fontSize: 10, opacity: 0.3,
  },
  containtFieldPlace: {
    marginLeft: -15, width: '55%', alignSelf: 'center',
  },
  containtLieu: {
    marginVertical: 20,
    alignItems: 'center',
  },
  containerDescriptif: {
    marginVertical: 20,
    alignItems: 'center',
    width: widthScreen * 0.85,
  },
  containerBillet: {
    marginVertical: 10,
    alignItems: 'center',
    width: widthScreen * 0.85,
  },
  contentBillet: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  widthSixty: {
    width: '60%',
  },
  billetValue: {
    width: '40%',
    alignItems: 'flex-end',
  },
  marginTopThirty: {

  },
  placeAvailable: {
    fontWeight: '100',
    fontSize: 15,
  },
  borderRadiusFifty: {
    borderRadius: 50,
  },
  errorStylePhotos: {
    position: 'absolute',
    top: 300,
    right: 30,
    width: 3,
    height: 13,
    borderRadius: 5,
    backgroundColor: colors.burgundy,
  },
  contentModal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(242,242,242, 0.9)',
  },
});
