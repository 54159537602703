import React from 'react';
import { Image, View } from 'react-native';

import Svg from 'components/svg';
import { Logo, LogoBlack } from 'styles/svg';

export default ({ style, width = 159, height = 59, color = 'white' }) => (
  <View style={[style, { width, height }]}>
    {
      color === 'white' && (
        <Svg src={Logo} />
      )
    }
    {
      color === 'black' && (
        <Svg src={LogoBlack} />
      )
    }
  </View>
);
