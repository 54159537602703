export default function Recommendation (
  state = {
    recommendations: null,
    givenRecommendations: null,
    receivedRecommendations: null,
    createdRecommendation: {},
    userRecommendations: {},
  },
  action,
) {
  switch (action.type) {
  case 'LOAD_RECOMMENDATIONS':
    return {
      ...state,
      recommendations: action.recommendations,
    };
  case 'LOAD_GIVEN_RECOMMENDATIONS':
    return {
      ...state,
      givenRecommendations: action.givenRecommendations,
    };
  case 'LOAD_RECEIVED_RECOMMENDATIONS':
    return {
      ...state,
      receivedRecommendations: action.receivedRecommendations,
    };
  case 'CREATE_RECOMMENDATION':
    return {
      ...state,
      createdRecommendation: action.createdRecommendation,
    };
  case 'LOAD_RECOMMENDATION':
    return {
      ...state,
      recommendations: state.recommendations ? [ ...state.recommendations.filter((e) => e.id !== action.recommendation.id), action.recommendation ] : [ action.recommendation ],
    };
  case 'USER_RECOMMENDATIONS':
    return {
      ...state,
      userRecommendations: action.userRecommendations,
    };
  default:
    return state;
  }
}
