import { connect } from 'react-redux';
import React, { useState } from 'react';
import { FlatList, Image, Text, TouchableOpacity, View } from 'react-native';

import Api from 'services/api';
import { Swap } from 'styles/svg';
import { colors, TextStyles } from 'styles';
import { pushToUser } from 'services/navigationPush';

import styles from './SwapRequestsStyles';
import WebSwapRequestCard from './WebSwapRequestCard';

import Svg from 'components/svg';
import NoResources from 'components/noResources';
import { ResponsiveView } from 'components/device';
import GrayButton from 'components/buttons/grayButton';
import { MobileView, BrowserView } from 'components/device';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';
import HollowBackground from 'components/backgrounds/hollowBackground';

function SwapRequests({ navigation: { push, goBack, canGoBack }, currentUser }) {
  const [swapRequests, setSwapRequests] = useState(null);

  const loadSwapRequests = async () => {
    try {
      const { data } = await Api.users.getSwapRequests();
      setSwapRequests(data.filter((sr) => sr.user_a_id !== currentUser?.id));
    } catch (err) {
      console.error('Error loading swapRequests', err);
    }
  };

  const renderMobileSwapRequest = ({ item }) => (
    <TouchableOpacity style={styles.notifContainer} onPress={() => pushToUser(push, item.author)}>
      <HollowBackground/>
      <View style={styles.image}>
        <Svg src={Swap} width={37} height={28} />
      </View>
      <View style={styles.image}>
        <Image alt="Change me" source={{ uri: item.author?.picture_url }} style={{ width: '100%', height: '100%', borderRadius: 40 }}/>
      </View>
      <View style={styles.textContainer}>
        <Text numberOfLines={1} style={styles.title}>{item.author?.firstname} {item.author?.lastname}</Text>
        <Text numberOfLines={1} style={styles.description}>{item.author?.job}</Text>
      </View>
    </TouchableOpacity>
  );

  const MobileContent = () => (
    <View style={styles.container}>
      <FlatList
        keyExtractor={(item) => (item.id)}
        renderItem={renderMobileSwapRequest}
        contentContainerStyle={styles.listContainer}
        data={swapRequests.filter((sr) => sr.status === 'pending')}
      />
    </View>
  );

  const WebContent = () => {
    const formattedData = swapRequests.filter((sr) => sr.status === 'pending');

    return (
      <ResponsiveView>
        <View style={styles.webSwapRequestHeaderContainer}>
          <View style={styles.webSwapRequestHeader}>
            <Image alt="Change me" resizeMode="contain" style={styles.webSwapRequestHeaderImage} source={Swap} />
            <Text selectable style={TextStyles({ marginLeft: 15 }).bold30}>Demandes de swap reçues</Text>
            <Text selectable style={TextStyles({ color: colors.greyDayColor, marginLeft: 'auto' }).regular16}>
              Si vous acceptez une demande de swap, vos cordonnées seront transmises à la personne afin de prendre contact avec vous.
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 20 }}>
          {
            formattedData?.map((item) => (
              <WebSwapRequestCard key={item.id} push={push} item={item} />
            ))
          }
        </View>
      </ResponsiveView>
    );
  };

  return (
    <LoadResourceLayout
      push={push}
      goBack={goBack}
      disableGoBack={true}
      mobilePadding={false}
      title="Demandes de Swap"
      loadResources={loadSwapRequests}
      headerSection={<HeaderSection push={push} menuSelect="Othrs" redirect="Othrs"/>}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect="Othrs" />}
    >
      {swapRequests?.filter((sr) => sr.status === 'pending')?.length === 0 ? (
        /* NO RESOURCES COMPONENT */
        <NoResources
          noResourceText="Vous n'avez aucune demande de Swap à traiter"
          findResourceText="Lorsque des Othrs souhaiteront vous rencontrer, ils apparaîtront ici"
          buttons={[
            () => <GrayButton width={'100%'} onPress={() => push('Others')} titre={'Je peux les rencontrer'} />,
          ]}
        />
      ) : (
        <View>
          {/* MOBILE */}
          <MobileView>
            <MobileContent />
          </MobileView>
          {/* BROWSER */}
          <BrowserView>
            <WebContent />
          </BrowserView>
        </View>
      )}
    </LoadResourceLayout>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.Account.currentUser,
});

const ActionsProps = {};

export default connect(mapStateToProps, ActionsProps)(SwapRequests);
