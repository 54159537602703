import React from 'react';
import { View } from 'react-native';
import { Text } from 'native-base';

import { colors, fonts } from 'styles';
import styles from './SliderStyles';
import TextSection from '../textSection';
import SideScroller from './sideScroller';

export default (props) => (
  <View style={styles(props.enableMarginTop).sliderContainer}>
    {!!props.title && <Text style={{
      fontFamily: fonts.lato.bold,
      color: colors.grey,
      fontSize: 23,
      marginTop: 20,
      lineHeight: 23,
      marginBottom: props.subtitle ? 5 : 20,
    }}>{props.title}</Text>}
    {!!props.subtitle && <Text style={{ textAlign: 'center', width: '55%', marginBottom: 20, fontSize: 12, fontFamily: fonts.lato.regular, lineHeight: 15, color: colors.greyMedium }}>{props.subtitle}</Text>}
    <SideScroller height={props.height} contentWidth={props.contentWidth}>
      {props.children}
    </SideScroller>
  </View>
);
