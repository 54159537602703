import React from 'react';
import { View, Text } from 'native-base';
import { Dimensions, ImageBackground, useWindowDimensions } from 'react-native';

import { fonts, colors } from 'styles';

import HollowBackground from 'components/backgrounds/hollowBackground';
import DarkBackground from 'components/backgrounds/darkBackground/DarkBackground';

const RecommendationStatus = ({ status, amount }) => {
  let statusText = 'Erreur';
  let color = 'black';

  if (status === 'PENDING') {
    statusText = 'En attente';
  } else if (status === 'ACCEPTED') {
    statusText = 'Accepté';
    color = colors.blue;
  } else if (status === 'REFUSED') {
    statusText = 'Refusé';
    color = colors.red;
  } else if (amount) {
    statusText = 'Devis signé';
    color = colors.green;
  }
  return (
    <View style={{ width: '50%', marginBottom: '5%', borderRadius: 30, backgroundColor: colors.white }}>
      <Text
        selectable={true}
        style={{
          color,
          fontSize: 20,
          lineHeight: 45,
          textAlign: 'center',
          fontFamily: fonts.lato.bold,
        }}
      >
        {statusText}
      </Text>
    </View>
  );
};

const WebRecommendationCard = ({ recommendation, self = false, target = 'user' }) => {
  const { width } = useWindowDimensions();

  return (
    <View
      style={{
        minWidth: 280,
        minHeight: 338,
        borderRadius: 20,
        overflow: 'hidden',
        width: width * 0.18, // 320
        height: width * 0.285,// 520
      }}
    >
      <HollowBackground />
      <ImageBackground
        style={{
          width: '100%',
          height: width * 0.2,
        }}
        source={{ uri: target === 'user' ? recommendation.user.picture_url : recommendation.worker.picture_url }}
      >
        <DarkBackground />
        <View style={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
          <Text style={{ fontFamily: fonts.lato.bold, color: colors.white, fontSize: 25, marginBottom: '5%' }}>
            {target === 'user' ? recommendation.user.firstname : recommendation.worker.firstname} {target === 'user' ? recommendation.user.lastname : recommendation.worker.lastname}
          </Text>
          <RecommendationStatus status={recommendation.status} amount={recommendation.contract_amount} />
        </View>
      </ImageBackground>
      {
        recommendation.contract_amount ? (
          <View style={{ alignItems: 'center', justifyContent: 'center', height: width * 0.085, minHeight: 100 }}>
            <Text
              selectable={true}
              numberOfLines={1}
              style={{
                width: '90%',
                fontSize: 18,
                lineHeight: 24,
                textAlign: 'center',
                fontFamily: fonts.lato.bold,
              }}
            >
              {recommendation.title}
            </Text>
            <Text
              selectable={true}
              style={{
                fontSize: 15,
                textAlign: 'center',
                fontFamily: fonts.lato.regular,
              }}
            >
              {self ? 'Chiffre généré' : `Chiffre généré par ${recommendation.worker.firstname}`}
            </Text>
            <View style={{ width: '60%', marginTop: '5%', borderRadius: 30, backgroundColor: colors.white }}>
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Text
                  selectable={true}
                  style={{
                    color: colors.burgundy,
                    fontSize: 16,
                    lineHeight: 40,
                    textAlign: 'center',
                    fontFamily: fonts.lato.bold,
                  }}
                >
                  {recommendation.contract_amount}€
                </Text>
                <Text
                  selectable={true}
                  style={{
                    fontSize: 13,
                    marginLeft: 5,
                    textAlign: 'center',
                    fontFamily: fonts.lato.bold,
                  }}
                >
                  HT
                </Text>
              </View>
            </View>
          </View>
        ) : (
          <View style={{ alignItems: 'center', justifyContent: 'center', height: width * 0.085, minHeight: 140 }}>
            <Text
              selectable={true}
              numberOfLines={2}
              style={{
                width: '90%',
                fontSize: 18,
                lineHeight: 24,
                textAlign: 'center',
                fontFamily: fonts.lato.bold,
              }}
            >
              {recommendation.title}
            </Text>
            <Text
              numberOfLines={3}
              selectable={true}
              style={{
                width: '90%',
                fontSize: 15,
                textAlign: 'center',
                fontFamily: fonts.lato.regular,
                marginBottom: 10,
              }}
            >
              {recommendation.description}
            </Text>
          </View>
        )
      }
    </View>
  );
};

export default WebRecommendationCard;
