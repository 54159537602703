import moment from 'moment';
import { connect } from 'react-redux';
import * as Linking from 'expo-linking';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Image, useWindowDimensions } from 'react-native';
import { isTablet, isMobile } from 'react-device-detect';

import { View, Text, Pressable, AlertDialog, Button } from 'native-base';

import { API_URL } from 'config';
import { Location, OthrBg } from 'styles/svg';
import { toastError } from 'services/toasts';
import { colors, fonts, TextStyles } from 'styles';
import { deleteEvent, closeInscription } from 'store/actions/event';
import { Pencil, CloseTickets, BurgundyCancel, Link } from 'styles/svg';
import { formatString, pushToEvent, pushToNetwork, pushToUser } from 'services/navigationPush';

import styles from './EventDetailStyle';

import WebMap from 'components/map/WebMap';
import TicketPrice from 'components/ticketPrice';
import BombButton from 'components/buttons/bombButton';
import CopyToClipboard from 'components/copyToClipboard';
import MultiColorButton from 'components/buttons/multiColorButton';
import HollowBackground from 'components/backgrounds/hollowBackground';
import BackgroundLinearGradient from 'components/backgrounds/backgroundLinearGradient';
import Svg from '../../../components/svg/Svg';

const mobile = isMobile && !isTablet;

const BuyTickets = ({ event, publicPage, push, isInternal }) => {
  const [ticket, setTicket] = useState('');

  useEffect(() => {
    setTicket(event?.tickets[0]?.title);
  }, [event?.tickets]);

  const getShareLink = () => {
    if (event?.network) {
      return `${API_URL}/application/events/${formatString(event.network.name)}-${formatString(event?.title)}-${formatString(event?.id.toString())}`;
    }
    if (event?.user) {
      return `${API_URL}/application/events/${formatString(event.user.firstname)}_${formatString(event.user.lastname)}-${formatString(event?.title)}-${formatString(event?.id.toString())}`;
    }
    return `${API_URL}/application/events/${formatString(event?.title)}-${formatString(event?.id.toString())}`;
  };

  return (
    <View style={{
      paddingLeft: '5%',
      borderRadius: 50,
      width: mobile ? '100%' : 400,
      marginTop: mobile ? '10%' : 0,
    }}>
      <View>
        <Text selectable style={styles.descriptionTitle}>Choisissez votre billet</Text>
        {event?.tickets?.map((t) => (
          <TicketPrice key={t.id} name={t.title} description={t.description} price={(isInternal && (t.internal_price !== null && t.internal_price !== undefined)) ? t.internal_price : t.price} onPress={setTicket} ticketSelected={ticket === t.title}/>
        ))}
        {publicPage && event?.tickets?.some(t => (t.internal_price !== null && t.internal_price !== undefined)) && <View style={{ marginLeft: '8%', marginTop: 10 }}>
          <Text style={{ fontFamily: fonts.lato.boldItalic, color: colors.grey, fontSize: 10 }}>{event.network ? 'Vous appartenez à ce réseau ?' : 'Vous êtes membre Othr ?'}</Text>
          <Text style={{ fontFamily: fonts.lato.boldItalic, color: colors.secondary, fontSize: 10 }}>Prenez votre billet sur app.othr.pro ou via l’application mobile pour bénéficier du tarif membre !</Text>
        </View>}
        <View style={{ width: '70%' }}>
          <MultiColorButton style={[styles.multiColorButton, { width: '80%', marginBottom: 20 }]} text="Réserver" onPress={() => {
            if (ticket) {
              if (event?.user_tickets?.length >= event.total_places) {
                toastError('Il n\'y a plus assez de places disponibles pour cet Event');
              } else {
                push('EventBooking', { event, ticketName: ticket, publicPage, isInternal });
              }
            } else {
              toastError('Aucun ticket sélectionné, merci de choisir un ticket');
            }
          }} />
          <CopyToClipboard text={getShareLink()} copyText="Lien Copié !">
            <View style={{
              height: 65,
              width: '80%',
              borderRadius: 50,
              cursor: 'pointer',
              overflow: 'hidden',
              alignItems: 'center',
              marginBottom: '2.5%',
              justifyContent: 'center',
            }}>
              <HollowBackground />
              <View style={{ width: '90%', height: '75%', borderRadius: 30, backgroundColor: colors.white, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Image alt="Change me" source={Link} style={{ marginRight: 5, height: 13, width: 25 }} />
                <Text style={TextStyles().bold16}>
                  Partager
                </Text>
              </View>
            </View>
          </CopyToClipboard>
        </View>
      </View>
    </View>
  );
};

const MaxGuestTickets = ({ network, push }) => (
  <View
    style={{
      paddingLeft: '5%',
      width: mobile ? '100%' : 400,
      marginTop: mobile ? '10%' : 0,
    }}
  >
    <Text style={{ fontSize: 20, fontFamily: fonts.lato.bold, color: colors.grey, marginBottom: 20 }}>Vous avez atteint le nombre maximum de participations</Text>
    <Text style={{ fontSize: 15, fontFamily: fonts.lato.regular, color: colors.grey, marginBottom: 30 }}>Pour participer à plus d’évènements de {network?.name}, rejoignez-les !</Text>
    <MultiColorButton noHollow={true} text={'Rejoindre le groupement'} onPress={() => pushToNetwork(push, network)}/>
  </View>
);

const EventDetailsWeb = ({
  date,
  push,
  event,
  organizer,
  takenPlaces,
  deleteEvent,
  availablePlaces,
  closeInscription,
  isInternal,
  publicPage = false,
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [closeInscriptionOpen, setCloseInscriptionOpen] = useState(false);
  const [isCloseInscriptionLoading, setIsCloseInscriptionLoading] = useState(false);

  const { width, height } = useWindowDimensions();

  const currentDate = new Date();
  const end_date = new Date(event?.end_date);
  const start_date = new Date(event?.start_date);
  const OrganizerLogo = useCallback(() => {
    if (event.user_author_id || event.network_author_id) {
      return (
        <Image
          alt="Change me"
          source={{ uri: organizer?.logo_url || organizer?.picture_url }}
          style={{
            width: 90,
            height: 90,
            borderRadius: 15,
          }}
        />
      );
    } else {
      return (
        <View style={{ height: 90, width: 90, alignItems: 'center', justifyContent: 'flex-end' }}>
          <Svg src={OthrBg} height={90} width={90} style={{ borderRadius: 15, position: 'absolute', top: 0, left: 0, }}/>
          <Text style={{ color: colors.white, opacity: 0.5, fontSize: 28, fontFamily: fonts.lato.bold, marginBottom: 8 }}>{event.zone.code}</Text>
        </View>
      );
    }
  }, [ organizer ]);

  const EventImage = () => (
    <View style={{
      minWidth: mobile ? '100%' : 300,
      minHeight: 360,
      borderRadius: mobile ? 0 : 15,
      width: mobile ? '100%' : width * 0.25,
      height: width * 0.3,
      alignItems: 'center',
    }}>
      {event && !!event.picture_url && (
        <BackgroundLinearGradient
          width="100%"
          image={{ uri: event?.picture_url }}
          height={mobile ? '100%' : Math.max(width * 0.3, 360)}
          styles={{ borderRadius: mobile ? 0 : 15 }}
        >
          <View style={{
            width: 60,
            marginTop: '7.5%',
            marginLeft: '7.5%',
          }}>
            <View style={[styles.dayContainer]}>
              <Text selectable style={styles.day}>{moment(date).format('ddd').slice(0, 3).toUpperCase()}</Text>
              <View style={styles.separator} />
              <Text selectable style={styles.number}>{moment(date).format('DD')}</Text>
              <Text selectable adjustsFontSizeToFit style={styles.month}>{moment(date).format('MMM').slice(0, 3).toUpperCase()}</Text>
            </View>
            <Text selectable style={styles.hour}>{moment(date).format('HH[h]mm')}</Text>
          </View>
          <View style={[styles.infosContainer, { width: '80%', height: mobile ? 'auto' : width * 0.2, minHeight: 100, marginLeft: '7.5%' }]}>
            <Text selectable style={[styles.title, { fontSize: 40, lineHeight: 45, marginBottom: '12%' }]} numberOfLines={2}>{event?.title}</Text>
          </View>
        </BackgroundLinearGradient>
      )}
    </View>
  );

  const EventDescription = () => (
    <View style={{
      marginLeft: '5%',
      marginBottom: mobile ? '5%' : '20%',
      maxWidth: 914,
    }}>
      <View style={{ width: '40%', flexDirection: 'row', alignItems: 'center' }}>
        <OrganizerLogo />
        <View style={[styles.placesHollowContainer, { width: '60%', marginLeft: '2%', marginTop: 0  }]}>
          <View style={styles.placesContainer}>
            <View style={[styles.placesTextContainer, { width: '100%' }]}>
              {takenPlaces >= (availablePlaces * 0.8) && <Text selectable style={[styles.participants, { fontSize: 17 }]}><Text selectable style={styles.bold}>{takenPlaces || 'Aucun'}</Text> {`participant${takenPlaces > 1 ? 's' : ''}`}</Text>}
              <Text selectable={true} style={[styles.participants, { fontSize: 17, color: colors.orangeBrown }]}>
                <Text selectable={true} style={styles.bold}>{availablePlaces || ''}</Text> {`${availablePlaces === 0 ? 'Aucune' : ''} place${availablePlaces > 1 ? 's' : ''} restante${availablePlaces > 1 ? 's' : ''}`}</Text>
            </View>
          </View>
        </View>
        {
          /*
                 <View style={{ width: '30%' }}>
          <ShareButtons title={false} />
        </View>
           */
        }
      </View>
      <View style={{ width: '62.5%' }}>
        <Text selectable style={TextStyles({ marginTop: 20, color: colors.greyDark }).bold17}>
          Début:
          <Text selectable style={TextStyles({ marginLeft: 4 }).regular17}>{moment(event?.start_date).format('DD/MM/YYYY')} - {moment(event?.start_date).format('HH[h]mm')}</Text>
        </Text>
        {
          /*
                 <Text selectable style={TextStyles({ color: colors.greyDark }).bold17}>
          Fin:
          <Text selectable style={TextStyles({ marginLeft: 4 }).regular17}>{moment(event?.end_date).format('DD/MM/YYYY')} - {moment(event?.end_date).format('HH[h]mm')}</Text>
        </Text>
           */
        }
        <View style={styles.descriptionContainer}>
          <Text selectable={true} style={[styles.descriptionText, { width: '100%', fontSize: 18, lineHeight: 24, marginTop: '4%' }]}>{event?.description}</Text>
        </View>
      </View>
    </View>
  );

  const WebMapContainer = () => (
    <View style={{ width: width * 0.25, minWidth: 300, overflow: 'hidden', marginTop: mobile ? 0 : '10%', borderRadius: 15, height: height * 0.31, marginLeft: mobile ? '5%' : 0 }}>
      <HollowBackground />
      <View style={{ width: '96%', height: height * 0.213, margin: '2%' }}>
        <WebMap address={event?.address} color={event?.user?.color || '#ff0000'} />
      </View>
      <View style={{ marginTop: 5, marginBottom: 5, marginLeft: 20, alignItems: 'center', flexDirection: 'row' }}>
        <BombButton
          height={40}
          borderRadius={10}
          width={40}
          images={null}
          svg={Location}
          titre={null}
        />
        <View style={{ marginLeft: 20, width: '100%' }}>
          <Text style={{ width: '70%', marginVertical: '0.5%', fontFamily: fonts.lato.bold, fontSize: 14, lineHeight: 15 }}>{`${event?.address?.number} ${event?.address?.street}`}</Text>
          <Text style={{ width: '70%', marginVertical: '0.5%', fontFamily: fonts.lato.bold, fontSize: 14, lineHeight: 15 }}>{`${event?.address?.zip_code} ${event?.address?.city}`}</Text>
        </View>
      </View>
    </View>
  );

  const onOrganizerPress = () => {
    if (event?.user) {
      pushToUser(push, organizer);
    }
    if (event?.network) {
      pushToNetwork(push, organizer);
    }
  };

  const OrganizerDetails = () => (
    <View style={{ marginTop: '3%' }}>
      <Text style={{ margin: '3%', fontFamily: fonts.lato.bold, fontSize: 20 }}>Organisateur</Text>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', width: mobile ? '100%' : width * 0.25, minWidth: mobile ? '100%' : 300 }}>
          <View style={{ width: '100%', overflow: 'hidden', borderRadius: 20, flexDirection: 'row', alignItems: 'center' }}>
            <HollowBackground />
            <Pressable onPress={onOrganizerPress} style={{ margin: '5%' }}>
              <OrganizerLogo />
            </Pressable>
            <View style={{ width: '55%' }}>
              <Text style={{ fontFamily: fonts.lato.bold, fontSize: 16, width: '100%' }}>{event?.contact_name}</Text>
              <Pressable disabled={!event?.contact_number} onPress={() => Linking.openURL(`sms:${event?.contact_number}`)}>
                <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, width: '100%' }}>{event?.contact_number}</Text>
              </Pressable>
              <Pressable disabled={!event?.contact_email} onPress={() => Linking.openURL(`mailto:${event?.contact_email}`)}>
                <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, width: '100%' }}>{event?.contact_email}</Text>
              </Pressable>
            </View>
          </View>
        </View>
        {(event.user?.description || event.network?.description) && <Text selectable style={TextStyles({ width: 338, marginLeft: 20 }).bold15} numberOfLines={5}>
          {event?.user && `${event?.user?.firstname} ${event?.user?.lastname}`} {event?.network && event?.network?.name} :
          <Text selectable style={TextStyles().regular15}>
            {event?.user?.description || event?.network?.description}
          </Text>
        </Text>}
      </View>
    </View>
  );

  const DeleteDialogue = () => (
    <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(false)} leastDestructiveRef={undefined}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>Supprimer l'évènement</AlertDialog.Header>
        <AlertDialog.Body>
          Cette action supprimera définitivement votre Event. L'ensemble des participants seront remboursés.
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" colorScheme="coolGray" onPress={() => setDeleteOpen(false)}>
              Annuler
            </Button>
            <Button isLoading={isDeleteLoading} color={colors.burgundy} onPress={async () => {
              setIsDeleteLoading(true);
              await deleteEvent({ id: event.id });
              setIsDeleteLoading(false);
              setDeleteOpen(false);
              push('MyEvents');
            }}>
              Supprimer
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );

  const CloseInscriptionDialogue = () => (
    <AlertDialog isOpen={closeInscriptionOpen} onClose={() => setCloseInscriptionOpen(false)} leastDestructiveRef={undefined}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>Fermer les inscriptions</AlertDialog.Header>
        <AlertDialog.Body>
          Cette action fermera les inscriptions et aucun utilisateur ne pourra plus prendre de billet.
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" colorScheme="coolGray" onPress={() => setCloseInscriptionOpen(false)}>
              Annuler
            </Button>
            <Button isLoading={isCloseInscriptionLoading} color={colors.burgundy} onPress={async () => {
              setIsCloseInscriptionLoading(true);
              await closeInscription({ id: event.id });
              setIsCloseInscriptionLoading(false);
              setCloseInscriptionOpen(false);
              window.location.reload();
            }}>
              Fermer
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );

  return (
    <View style={{ alignItems: 'center', width, marginTop: (publicPage && !mobile) ? 70 : 0 }}>
      <DeleteDialogue />
      <CloseInscriptionDialogue />
      <View style={{ width: mobile ? '100%' : '70%' }}>
        {event?.canEdit &&
          <View style={{ width: '100%', flexDirection: 'row', marginBottom: 10 }}>
            {
              currentDate < start_date && (
                <Pressable
                  height={47}
                  onPress={() => {
                    window.scrollTo(0, 0);
                    setDeleteOpen(true);
                  }}
                  style={{ marginBottom: 20, justifyContent: 'center', flexDirection: 'row', alignItems: 'center', borderRadius: 30, overflow: 'hidden' }}
                >
                  <HollowBackground />
                  <Image alt="Change me" source={BurgundyCancel} style={{ marginLeft: 15, height: 13, width: 13 }} />
                  <Text style={{ fontFamily: fonts.lato.bold, fontSize: 17, color: colors.burgundy, marginLeft: 10, marginRight: 15 }}>Annuler l'évènement</Text>
                </Pressable>
              )
            }
            {
              availablePlaces > 0 && currentDate < start_date && (
                <Pressable
                  height={47}
                  onPress={() => {
                    window.scrollTo(0, 0);
                    setCloseInscriptionOpen(true);
                  }}
                  style={{ marginBottom: 20, marginLeft: 20, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 30, overflow: 'hidden' }}
                >
                  <HollowBackground />
                  <Image alt="Change me" source={CloseTickets} style={{ marginLeft: 15, height: 13, width: 13 }} />
                  <Text style={{ fontFamily: fonts.lato.bold, fontSize: 17, marginLeft: 10, marginRight: 15 }}>Fermer les inscriptions</Text>
                </Pressable>
              )
            }
            {
              currentDate < start_date && (
                <Pressable
                  height={47}
                  onPress={() => pushToEvent(push, event, 'edit')}
                  style={{ marginBottom: 20, marginLeft: 20, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 30, overflow: 'hidden' }}
                >
                  <HollowBackground />
                  <Image alt="Change me" source={Pencil} style={{ marginLeft: 15, height: 13, width: 13 }} />
                  <Text style={{ fontFamily: fonts.lato.bold, fontSize: 17, marginLeft: 10, marginRight: 15 }}>Modifier l'évènement</Text>
                </Pressable>
              )
            }
            <CopyToClipboard text={event?.code}>
              <View
                height={47}
                style={{ cursor: 'pointer', marginBottom: 20, marginLeft: 'auto', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
              >
                <Text style={{ fontFamily: fonts.lato.bold, fontSize: 17, marginLeft: 10 }}>Code administrateur:</Text>
                <Text style={{ fontFamily: fonts.lato.bold, fontSize: 17, marginLeft: 3, color: colors.burgundy }}>{event?.code}</Text>
                <Image alt="Change me" source={Link} style={{ marginLeft: 5, height: 13, width: 25 }} />
              </View>
            </CopyToClipboard>
          </View>
        }
      </View>
      <View style={{ width: mobile ? '100%' : '70%', flexDirection: mobile ? 'column' : 'row' }}>
        <View>
          <EventImage />
          {!mobile && <WebMapContainer />}
        </View>
        <View style={{ width: mobile ? '100%' : '60%', marginTop: mobile ? 20 : 0 }}>
          <EventDescription />
          {mobile && <WebMapContainer />}
          {(currentDate > end_date || availablePlaces < 1) ? null : ((event.canBuy) ? (
            <BuyTickets event={event} publicPage={publicPage} push={push} isInternal={isInternal} />
          ) : (
            <MaxGuestTickets network={event.network} push={push} />
          ))}
        </View>
      </View>
      <View style={{ width: mobile ? '90%' : '70%' }}>
        <OrganizerDetails />
      </View>
    </View>
  );
};

const mapStateToProps = () => ({
});

const actionProps = {
  deleteEvent,
  closeInscription,
};

export default connect(mapStateToProps, actionProps)(EventDetailsWeb);
