import { connect } from 'react-redux';
import { FlatList } from 'native-base';
import React, { useState } from 'react';
import { Image, Platform, Text, View } from 'react-native';

import { fonts } from 'styles';
import MediaStyle from '../MediaStyle';
import { svgtopng } from 'styles/images';
import { getMyArticles, getMyPodcasts, getMyVideos } from 'store/actions/media';

import NoResources from 'components/noResources';
import { ResponsiveView } from 'components/device';
import VideoCard from 'components/cards/medium/Video';
import GrayButton from 'components/buttons/grayButton';
import ArticleCard from 'components/cards/medium/Medium';
import PodcastCard from 'components/cards/medium/Podcast';
import HeaderSection from 'components/layouts/headerSection';
import CustomFlatList from 'components/webLists/CustomFlatList';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';

const selector = {
  'article': {
    title: 'Magazine',
    redirect: 'Articles',
    card: ArticleCard,
    add: 'AddArticle',
    update: 'ArticleDetails',
    listTitle: 'Mes Articles',
    promoLogo: svgtopng.magazine.magazine,
    noResource: 'Vous n\'avez pas encore d\'article',
    noResourceDesc: 'Une fois votre premier article publié, vous pourrez le retrouver ici',
    noResourceButton: 'Publier un article',
  },
  'podcast': {
    title: 'Podcasts',
    redirect: 'Podcasts',
    card: PodcastCard,
    add: 'AddPodcast',
    update: 'PodcastDetails',
    listTitle: 'Mes Podcasts',
    promoLogo: svgtopng.podcast.podcast,
    noResource: 'Vous n\'avez pas encore de podcast',
    noResourceDesc: 'Une fois votre premier podcast créé, vous pourrez le retrouver ici',
    noResourceButton: 'Créer un podcast',
  },
  'video': {
    title: 'Othr TV',
    redirect: 'Videos',
    card: VideoCard,
    add: 'AddVideo',
    update: 'VideoDetails',
    listTitle: 'Mes Videos',
    promoLogo: svgtopng.otherTV.otherTV,
    noResource: 'Vous n\'avez pas encore de vidéo',
    noResourceDesc: 'Une fois votre première vidéo créée, vous pourrez la retrouver ici',
    noResourceButton: 'Créer une vidéo',
  },
};

const MyMedia = ({
  navigation: { push, goBack, canGoBack },
  getMyArticles,
  getMyVideos,
  getMyPodcasts,
  type,
}) => {
  const [media, setMedia] = useState([]);

  const loadMedia = async () => {
    try {
      let media;
      if (type === 'article') {
        media = await getMyArticles();
      }
      if (type === 'video') {
        media = await getMyVideos();
      }
      if (type === 'podcast') {
        media = await getMyPodcasts();
      }
      console.log('medium of type', type, media);
      setMedia(media);
    } catch (err) {
      console.error('Error loading networks', err);
    }
  };

  const MediumCard = selector[type].card;
  const RenderMobileContent = () => (
    <View style={MediaStyle.slider}>
      <FlatList
        data={media}
        renderItem={({ item }) => (
          <MediumCard {...item} push={push} disableMarginTop height={450} />
        )}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id}
        style={{ flex: 1, width: '90%', height: '100%' }}
        contentContainerStyle={{ width: '100%' }}
      />
    </View>
  );

  const RenderWebContent = () => (
    <View>
      <ResponsiveView style={{ marginBottom: '1%', flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Image alt="Change me" resizeMode="contain" style={{ width: 30, height: 30 }} source={selector[type].promoLogo} />
          <Text selectable style={{ fontFamily: fonts.lato.bold, fontSize: 24, marginLeft: 10 }}>{selector[type].listTitle}</Text>
        </View>
      </ResponsiveView>
      <CustomFlatList
        push={push}
        type="media"
        resources={media}
        settings={{
          filters: ['createdAt', 'asc', 'dsc'],
          sortField: 'title',
          searchFields: ['title'],
        }}
      />
    </View>
  );

  return (
    <LoadResourceLayout
      push={push}
      disableGoBack={true}
      mobilePadding={false}
      loadResources={loadMedia}
      title={selector[type].listTitle}
      headerSection={<HeaderSection push={push} menuSelect={selector[type].title} redirect={selector[type].redirect} />}
      breadCrumb={<Breadcrumb canGoBack={canGoBack} push={push} goBack={goBack} menuSelect={selector[type].title} />}
    >
      {media.length === 0 ? (
        <NoResources
          noResourceText={selector[type].noResource}
          findResourceText={selector[type].noResourceDesc}
          buttons={[
            () => <GrayButton width="100%" onPress={() => push(selector[type].add)} titre={selector[type].noResourceButton} />,
          ]}
        />
      ) : (
        Platform.OS !== 'web' ? <RenderMobileContent /> : <RenderWebContent />
      )}
    </LoadResourceLayout>
  );
};

const mapStateToProps = (state) => ({
  myVideos: state.Media.myVideos,
  myPodcasts: state.Media.myPodcasts,
  myArticles: state.Media.myArticles,
});

const ActionProps = {
  getMyVideos,
  getMyPodcasts,
  getMyArticles,
};

export default connect(mapStateToProps, ActionProps)(MyMedia);
