import Api from 'services/api';

export function Users() {
  return async (dispatch) => {
    const response = await Api.users.getContacts();

    if (response?.status === 200) {
      const users = response.data;
      dispatch({
        type: 'LOAD_USERS',
        users,
      });
      return users;
    }
  };
}

export function TopRecosUsers() {
  return async (dispatch, getState) => {
    const response = await Api.users.getAll();

    if (response?.status === 200) {
      const users = response.data;
      dispatch({
        type: 'LOAD_USERS',
        users,
      });
      return users;
    }
  };
}
export function TopActifUsers() {
  return async (dispatch, getState) => {
    const response = await Api.users.getAll();

    if (response?.status === 200) {
      const users = response.data;
      dispatch({
        type: 'LOAD_USERS',
        users,
      });
      return users;
    }
  };
}

export function HeaderHighlightUser() {
  return async (dispatch, getState) => {
    const response = await Api.users.getAll();

    if (response?.status === 200) {
      const users = response.data;
      dispatch({
        type: 'LOAD_USERS',
        users,
      });
      return users;
    }
  };
}

export function UserById(id) {
  return async (dispatch) => {
    const response = await Api.users.get(id);

    if (response?.status === 200) {
      const user = response.data;

      dispatch({
        type: 'LOAD_USER',
        user,
      });
      return user;
    }

    return null;
  };
}

export function getSwapRequests() {
  return async (dispatch) => {
    const response = await Api.users.getSwapRequests();

    if (response?.status === 200) {
      dispatch({
        type: 'LOAD_SWAP_REQUESTS',
        payload: response.data,
      });
      return response.data;
    }

    return null;
  };
}

export function swapRequest(description, requestedUserId) {
  return async () => {
    const response = await Api.users.swapRequest(description, requestedUserId);

    if (response?.status === 200) {
      return response.data;
    }

    return null;
  };
}

export function networkAdhesion(message, networkId, type) {
  return async () => {
    const response = await Api.users.networkAdhesion(message, networkId, type);

    if (response?.status === 200) {
      return response.data;
    }

    return null;
  };
}

export function acceptSwapRequest(swapRequestId) {
  return async () => {
    const response = await Api.users.acceptSwapRequest(swapRequestId);

    if (response?.status === 200) {
      return response.data;
    }

    return null;
  };
}

export function rejectSwapRequest(swapRequestId) {
  return async () => {
    const response = await Api.users.rejectSwapRequest(swapRequestId);

    if (response?.status === 200) {
      return response.data;
    }

    return null;
  };
}

export function removeRelation(userId) {
  return async () => {
    const response = await Api.users.removeRelation(userId);

    if (response?.status === 200) {
      return response.data;
    }

    return null;
  };
}
