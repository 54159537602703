import { submit } from 'redux-form';
import { Alert } from 'react-native';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Text, View } from 'native-base';

import { IconCheck } from 'styles/svg';
import { editPassword } from 'store/actions/account';
import { TextStyles, ContainerStyles } from 'styles';
import { toastSuccess, toastError } from 'services/toasts';

import EditPasswordForm from './EditPasswordForm';

import BasicLayout from 'components/layouts/basicLayout';
import MultiColorButton from 'components/buttons/multiColorButton';
import { isMobile, MobileView, BrowserView, isBrowser } from 'components/device';

const EditPassword = ({ navigation: { push, goBack }, submit, editPassword }) => {
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = async (values) => {
    try {
      setIsLoading(true);
      await editPassword(values);
      setIsSend(true);
      if (isBrowser) {
        toastSuccess('Votre mot de passe a bien été effectué!');
      }
    } catch (err) {
      if (isBrowser) {
        toastError('Une erreur est survenue, veuillez réessayer plus tard');
      }
      if (isMobile) {
        Alert.alert('Une erreur est survenue', 'Veuillez réessayer plus tard');
      }
    } finally {
      setIsLoading(false);
      setTimeout(goBack, 3000);
    }
  };

  return (
    <BasicLayout push={push} title="Modifier mon mot de passe" disableMenu={true}>
      {
        /* MOBILE CONFIRMATION */
        isSend && isMobile ? (
          <View style={[ContainerStyles.requestFormMobileContainer, { justifyContent: 'center' }]}>
            <IconCheck width="20%" height="20%"/>
            <Text style={TextStyles({ marginTop: 20 }).regular16}>
              Votre mot de passe a bien été modifié
            </Text>
          </View>
        ) : (
          <View style={{ width: '100%' }}>
            {/* MOBILE */}
            <MobileView>
              <View style={ContainerStyles.requestFormMobileContainer}>
                <EditPasswordForm onSubmit={sendRequest} />
                <MultiColorButton style={{ marginTop: 40 }} loading={isLoading} text="Modifier" onPress={() => submit('editPassword')} />
              </View>
            </MobileView>
            {/* BROWSER */}
            <BrowserView>
              <View style={ContainerStyles.requestFormWebContainer}>
                <Text style={TextStyles().black23}>Modifier mon mot de passe</Text>
                <EditPasswordForm onSubmit={sendRequest} />
                <MultiColorButton
                  text="Envoyer"
                  loading={isLoading}
                  style={ContainerStyles.requestFormWebSubmitButton}
                  onPress={() => submit('editPassword')}
                />
              </View>
            </BrowserView>
          </View>
        )
      }
    </BasicLayout>
  );
};

const actionProps = { editPassword, submit };

export default connect(null, actionProps)(EditPassword);
