import Slider from 'react-slick';
import { View } from 'native-base';
import { useWindowDimensions } from 'react-native';
import React, { useRef, useState, useEffect } from 'react';

import WebItem from './Item';
import Stepper from './Stepper';
import settings from './settings';

import { ResponsiveView, getWebListNumberOfItem } from 'components/device';

const WebSoloList = ({ showSide = false, marginHorizontal = 0, resources, push, onPress, numberOfItem = 5, type = undefined }) => {
  const customSlider = useRef();
  const { width } = useWindowDimensions();
  const [mounted, setMounted] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [showSideItem, setShowSideItem] = useState([
    {
      right: false,
      left: false,
    },
    {
      right: false,
      left: false,
    },
    {
      right: false,
      left: false,
    },
  ]);

  const setSideItemFromIndex = (index) => {
    setShowSideItem([
      {
        right: index === 0,
        left: index === 0,
      },
      {
        right: index === 1,
        left: index === 1,
      },
      {
        right: index === 2,
        left: index === 2,
      }
    ]);
  };

  let responsiveNumberOfItem;
  const maxNumberOfItem = getWebListNumberOfItem(marginHorizontal === 0 ? width : width * (1 - marginHorizontal / 100));
  if (numberOfItem > maxNumberOfItem) {
    responsiveNumberOfItem = maxNumberOfItem;
  } else {
    responsiveNumberOfItem = numberOfItem;
  }

  const formattedData = type ? resources.filter((item) => item.type === type) : resources;

  for (let numberOfData = formattedData.length; numberOfData < responsiveNumberOfItem; ++numberOfData) {
    formattedData.push({ shadow: true });
  }

  const slices = [];
  for (let i = 0; i < formattedData.length; i += responsiveNumberOfItem){
    slices.push([i, (i + responsiveNumberOfItem)]);
  }

  const onClickStepperLeft = () => {
    const index = (slideIndex - 1) % 3;
    setSideItemFromIndex(index);
    setSlideIndex(slideIndex - 1);
    customSlider.current.slickPrev();
  };

  const onClickStepperRight = () => {
    const index = (slideIndex + 1) % 3;
    setSideItemFromIndex(index);
    setSlideIndex(slideIndex + 1);
    customSlider.current.slickNext();
  };

  useEffect(() => {
    if (!mounted && formattedData.length > responsiveNumberOfItem) {
      setSideItemFromIndex(0);
      setMounted(true);
    }
  });

  return (
    <Slider
      draggable={false}
      ref={slider => (customSlider.current = slider)}
      beforeChange={() => setDragging(true)}
      afterChange={() => setDragging(false)}
      {...settings}
    >
      {
        slices.map((slice, index) => (
          <View key={index}>
            {index !== 0 && <Stepper marginHorizontal={marginHorizontal} position="left" numberOfItem={responsiveNumberOfItem} onClick={onClickStepperLeft} />}
            {index !== slices.length - 1 && <Stepper marginHorizontal={marginHorizontal} position="right" numberOfItem={responsiveNumberOfItem} onClick={onClickStepperRight} />}
            <ResponsiveView style={[
              { flexDirection: 'row' },
              !showSide && { justifyContent: 'space-between' },
              marginHorizontal !== 0 && { width: (100 - marginHorizontal * 2).toString() + '%', marginHorizontal: marginHorizontal.toString() + '%' }
            ]}>
              {
                showSide && index > 0 && showSideItem[index % 3].left && (
                  <View style={{
                    maxWidth: 310,
                    maxHeight: 372,
                    width: (width * 0.9 / responsiveNumberOfItem) - 10,
                    height: (width * 0.9 / responsiveNumberOfItem) * 1.2,
                    marginLeft: (width * 0.9 / responsiveNumberOfItem) - 10 > 310 ? -(310 - (responsiveNumberOfItem * 10)) : -(width * 0.9 / responsiveNumberOfItem - (responsiveNumberOfItem * 10)),
                  }}>
                    <WebItem disabled={true} r={formattedData[slice[0] - 1]} push={push} onPress={dragging ? () => {} : onPress  ? () => onPress(formattedData[slice[0]]) : undefined} />
                  </View>
                )
              }
              {
                formattedData.slice(slice[0], slice[1]).map((item, sliceIndex) => (
                  <View key={sliceIndex}>
                    <View
                      key={sliceIndex}
                      style={{ flex: 1, width: (width * 0.9 / responsiveNumberOfItem) - 10, maxWidth: 310 }}
                    >
                      {
                        !item.empty && (
                          <View style={{
                            maxHeight: 372,
                            height: (width * 0.9 / responsiveNumberOfItem) * 1.2,
                            marginLeft: showSide ? (index === 0 && sliceIndex === 0 ? 0 : 10) : 0,
                          }}>
                            <WebItem r={item} push={push} onPress={dragging ? () => {} : onPress  ? () => onPress(item) : undefined} />
                          </View>
                        )
                      }
                    </View>
                  </View>
                ))
              }
              {
                showSide && index < slices.length - 1 && showSideItem[index % 3].right && (
                  <View style={{
                    maxWidth: 310,
                    maxHeight: 372,
                    marginLeft: 10,
                    width: (width * 0.9 / responsiveNumberOfItem) - 10,
                    height: (width * 0.9 / responsiveNumberOfItem) * 1.2,
                  }}>
                    <WebItem disabled={true} r={formattedData[slice[1]]} push={push} onPress={dragging ? () => {} : onPress  ? () => onPress(formattedData[slice[1] + 1]) : undefined} />
                  </View>
                )
              }
            </ResponsiveView>
          </View>
        ))
      }
    </Slider>
  );
};

export default WebSoloList;
