import React from 'react';
import { View, ImageBackground } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import BackgroundLinearGradientStyle from './BackgroundLinearGradientStyle';

export default function BackgroundLinearGradient({
  width,
  height,
  image,
  children,
  styles,
}) {
  return (
    <View style={BackgroundLinearGradientStyle({ width, height }).container}>
      <ImageBackground
        source={image}
        style={[
          BackgroundLinearGradientStyle({ width, height }).itemBillet2,
          { ...styles },
        ]}
        imageStyle={[BackgroundLinearGradientStyle().backgroundImageStyle, { ...styles }]}
      >
        <LinearGradient
          colors={['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.7)']}
          style={[
            BackgroundLinearGradientStyle({ height }).gradient,
            { ...styles },
          ]}
        >
          <View style={BackgroundLinearGradientStyle.content}>{children}</View>
        </LinearGradient>
      </ImageBackground>
    </View>
  );
}
