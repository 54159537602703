import { connect } from 'react-redux';
import React, { useState } from 'react';

import Api from 'services/api';
import Relations from './Relations';
import { Users } from 'store/actions/user';

import { BrowserView } from 'components/device';
import NoResources from 'components/noResources';
import GrayButton from 'components/buttons/grayButton';
import HeaderSection from 'components/layouts/headerSection';
import Breadcrumb from 'components/layouts/breadCrumb/BreadCrumb';
import LoadResourceLayout from 'components/layouts/loadResourceLayout';

const RelationsContainer = ({
  route,
  Users,
  users,
  navigation,
}) => {
  const [ spheres, setSpheres ] = useState([]);

  const loadSpheres = async () => {
    try {
      const { data } = await Api.misc.getSpheres();
      setSpheres(data);
    } catch (err) {
      console.error('Error getting spheres', err);
    }
  };

  const loadResources = async () => {
    await loadSpheres();
    await Users();
  };

  return (
    <LoadResourceLayout
      {...navigation}
      disableGoBack={true}
      mobilePadding={false}
      loadResources={loadResources}
      disableMenu={users && users.length > 0}
      headerSection={<HeaderSection push={navigation.push} menuSelect="Othrs" redirect="Othrs" />}
      breadCrumb={<Breadcrumb push={navigation.push} goBack={navigation.goBack} menuSelect="Othrs" />}
    >
      {users && users.length > 0 && <Relations route={route} users={users} spheres={spheres} navigation={navigation} />}
      {users?.length === 0 && (
        <NoResources
          noResourceText="Vous n'avez pas encore de contact"
          findResourceText="Une fois que vous aurez votre premier contact, vous pourrez le retrouver ici"
          buttons={[
            () => <GrayButton width="100%" onPress={() => navigation.push('Event')} titre="Participer à un Event" />,
            () => <GrayButton width="100%" onPress={() => navigation.push('Network')} titre="Rejoindre un groupement" />,
          ]}
        />
      )}
    </LoadResourceLayout>
  );
};

const mapStateToProps = (state) => ({
  users: state.User.users,
});

const mapDispatchToProps = {
  Users,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationsContainer);
