import { StyleSheet, Platform } from 'react-native';

import { colors, fonts } from 'styles';

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  form: {
    marginTop: Platform.OS !== 'web' ? '15%' : '5%',
    width: '90%',
  },
  submit: {
    position: 'absolute',
    bottom: 60,
  }
});
