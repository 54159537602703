'use strict';
exports.__esModule = true;
exports.Othr = void 0;
var axios_1 = require('axios');
var iap_1 = require('./components/iap');
var auth_1 = require('./components/auth');
var misc_1 = require('./components/misc');
var media_1 = require('./components/media');
var users_1 = require('./components/users');
var events_1 = require('./components/events');
var address_1 = require('./components/address');
var tickets_1 = require('./components/tickets');
var profile_1 = require('./components/profile');
var networks_1 = require('./components/networks');
var payments_1 = require('./components/payments');
var parent_networks_1 = require('./components/parent_networks');
var recommendations_1 = require('./components/recommendations');
var Othr = /** @class */ (function () {
  function Othr(config) {
    this.defaultConfig = {
      baseURL: 'http://192.168.1.238:8000'
    };
    this.requester = axios_1['default'].create(config || this.defaultConfig);
    this.iap = new iap_1.Iap(this.requester);
    this.auth = new auth_1.Auth(this.requester);
    this.misc = new misc_1.Misc(this.requester);
    this.users = new users_1.Users(this.requester);
    this.media = new media_1.Media(this.requester);
    this.events = new events_1.Events(this.requester);
    this.tickets = new tickets_1.Tickets(this.requester);
    this.address = new address_1.Address(this.requester);
    this.profile = new profile_1.Profile(this.requester);
    this.payments = new payments_1.Payments(this.requester);
    this.networks = new networks_1.Networks(this.requester);
    this.parent_networks = new parent_networks_1.ParentNetworks(this.requester);
    this.recommendations = new recommendations_1.Recommendations(this.requester);
  }
  return Othr;
}());
exports.Othr = Othr;
exports['default'] = Othr;
