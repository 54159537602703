import Constants from 'expo-constants';
import { Alert, Platform } from 'react-native';
import * as Notifications from 'expo-notifications';

async function registerForPushNotificationsAsync() {
  let token;
  if (Constants.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      Alert.alert('Notifications désactivées', 'Pour les activer, rendez-vous dans les paramètres de votre téléphone');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync({ experienceId: '@othr/othr' })).data;
  } else {
    alert('Must use physical device for Push Notifications');
  }

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#cccccc',
    });
  }

  return token;
}

export {
  registerForPushNotificationsAsync,
};
