import { StyleSheet, Dimensions } from 'react-native';
import { colors, fonts, images } from 'styles';

const widthScreen = Dimensions.get('window').width;

export const StatisticsStyle = StyleSheet.create({
  containerStyleAudience: {
    marginTop: 5,
    flexDirection: 'row',
    width: '100%',
    backgroundColor: colors.white,
    borderRadius: 150,
  },
  AudienceStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    borderRadius: 150,
    width: '40%',
    position: 'absolute',
    left: 0,
  },
  audiencePod: {
    color: colors.white,
    fontSize: 10,
    fontFamily: fonts.lato.bold,
  },
  containerTextAudience: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 10,
    paddingRight: 15,
  },
  textAudience: {
    fontSize: 10.5,
    color: colors.greyPodcast,
    textTransform: 'uppercase',
    textAlign: 'right',
    fontFamily: fonts.lato.bold,
  },
});
