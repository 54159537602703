import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { GOOGLE_API_KEY } from 'config';
import { geocodeAddress } from './geocodeAddress';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: 10,
  draggableCursor: 'crosshair',
  draggingCursor: 'pointer'
};

const WebMap = ({ address, color }) => {
  const [location, setLocation] = useState(null);

  const getLocation = async () => {
    try {
      const result = await geocodeAddress(address);
      setLocation(result);
    } catch (err) {
      console.error('Error getting location', err);
    }
  };

  useEffect(() => {
    getLocation();
  }, [address]);

  if (!location) return null;

  return (
    <div style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
      {/* <LoadScript googleMapsApiKey={GOOGLE_API_KEY}> */}
      <GoogleMap
        onClick={() => window.open('https://www.google.com/maps/place/?q=' + location.lat + ',' + location.lng)}
        zoom={12}
        center={location}
        mapContainerStyle={containerStyle}
        options={{
          disableDefaultUI: true,
          draggable: false,
        }}
      >
        <Marker position={location} />
      </GoogleMap>
      {/* </LoadScript> */}
    </div>
  );
};

export default WebMap;
