import React, { useState } from 'react';
import { AlertDialog, Button } from 'native-base';

import { toastError, toastSuccess } from 'services/toasts';

const ConfirmationModal = ({ isOpen, setIsOpen, name, id, setAcceptSwapResponseLoading, type = 'accepted', setRefuseSwapResponseLoading, acceptSwapRequest, rejectSwapRequest }) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <AlertDialog isOpen={isOpen} onClose={() => setIsOpen(false)} leastDestructiveRef={undefined}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{`${type === 'accepted' ? 'Accepter' : 'Refuser' } cette demande`}</AlertDialog.Header>
        <AlertDialog.Body>
          {
            type === 'accepted' ? (
              `En acceptant la demande ${name}, vos coordonnées lui seront envoyées afin qu\'il vous contacte pour convenir d\'un rendez-vous, et ainsi vous connecter`
            ) : (
              `Refuser la demande de ${name} est définitif`
            )
          }
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" colorScheme="coolGray" onPress={() => setIsOpen(false)}>
              Annuler
            </Button>
            <Button isLoading={isLoading} colorScheme="danger" onPress={async () => {
              try {
                setLoading(true);
                if (type === 'accepted') {
                  setAcceptSwapResponseLoading(true);
                  await acceptSwapRequest(id);
                  toastSuccess('Vous avez accepter l\'invitation de swap');
                  window.location.reload();
                } else {
                  setRefuseSwapResponseLoading(true);
                  await rejectSwapRequest(id);
                  toastSuccess('Vous avez refuser l\'invitation de swap');
                  window.location.reload();
                }
                toastSuccess('Votre réponse à bien été envoyée');
              } catch (err) {
                toastError('Une erreur est survenue');
              } finally {
                setLoading(false);
                setAcceptSwapResponseLoading(false);
                setRefuseSwapResponseLoading(false);
              }
            }}>
              {type === 'accepted' ? 'Accepter' : 'Refuser'}
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default ConfirmationModal;
