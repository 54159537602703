import React from 'react';
import { View } from 'react-native';
import { Field, reduxForm } from 'redux-form';

import Input from 'components/form/input/Input';
import PickerField from 'components/form/picker';
import { ReportStyle } from './ReportStyle';

const validate = (values) => {
  const errors = {};

  if (!values.object || values.object.length === 0) {
    errors.object = 'Obligatoire';
  }
  if (!values.request || values.request.length === 0) {
    errors.request = 'Obligatoire';
  }

  return errors;
};

function ContactUsForm() {
  return (
    <View style={ReportStyle.form}>
      <Field
        name="object"
        label="Motif"
        component={PickerField}
        items={[
          { id: 'Contenu inapproprié', name: 'Contenu inapproprié' },
          { id: 'Contenu offensant', name: 'Contenu offensant' },
          { id: 'Autre', name: 'Autre' },
        ]}
        labelKey='name'
        width="100%"
        valueKey='name'
        required={true}
        autoCapitalize="none"
        style={ReportStyle.input}
      />
      <Field
        height={250}
        width="100%"
        name="message"
        required={true}
        multiline={true}
        component={Input}
        style={ReportStyle.input}
        label="Expliquez-nous en quoi ce contenu n'a pas sa place sur Othr"
      />
    </View>
  );
}

export default reduxForm({ form: 'contactUs', validate, enableReinitialize: true })(ContactUsForm);
