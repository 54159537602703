import React from 'react';
import { View } from 'react-native';

import SliderTipStyle from './SliderTipStyle';

export default ({
  style, position, tipSpace, tipHeight = 49,
}) => {
  const posStyle = {
    left: undefined,
    right: undefined,
  };
  if (position === 'left') {
    posStyle.left = tipSpace || '2%';
  } else {
    posStyle.right = tipSpace || '2%';
  }
  return (
    <View style={{
      ...SliderTipStyle.sliderTip, ...style, ...posStyle, height: tipHeight,
    }}
    />
  );
};
