import Api from 'services/api';
import FileDownload from 'js-file-download';

export function download(id) {
  return async (dispatch) => {
    const response = await Api.tickets.download(id);

    if (response?.status === 200) {
      FileDownload(response.data, 'ticket.pdf');
      return Promise.resolve();
    }

    return Promise.reject();
  };
}

export function send(id, email) {
  return async (dispatch) => {
    const response = await Api.tickets.send(id, email);

    if (response?.status === 200) {
      return Promise.resolve();
    }

    return Promise.reject();
  };
}
