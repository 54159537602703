import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Users } from 'store/actions/user';

import Search from 'components/search/Search';
import FieldWrapper from 'components/form/fieldWrapper';

const ContactPicker = (props) => {
  const [contact, setContact] = useState(null);

  const loadResources = async () => {
    await props.Users();
  };

  useEffect(() => {
    loadResources();
  }, []);

  return (
    <FieldWrapper {...props}>
      <Search
        type="user"
        // width="100%"
        fields={['firstname', 'lastname']}
        borderRadius={25}
        data={props.contacts}
        placeholder="Rechercher un contact"
        onKeyDelete={() => {
          setContact(null);
          props.handleContactRemove();
        }}
        value={contact ? `${contact.firstname} ${contact.lastname}`: null}
        onPress={(value) => {
          setContact(value);
          props.input.onChange(value);
        }}
      />
    </FieldWrapper>
  );
};

const mapStateToProps = (state) => ({
  contacts: state.User.users,
});

const mapDispatchToProps = {
  Users,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPicker);
