import { StyleSheet, Platform } from 'react-native';
import { colors, fonts } from 'styles';

export default StyleSheet.create({
  container: {
    borderRadius: 30,
    elevation: 7,
    shadowColor: colors.greyDark,
    shadowOffset: {
    	width: 8,
    	height: 15,
    },
    shadowOpacity: 0.05,
    shadowRadius: 10,
  },
  button: {
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    backgroundColor: colors.white,
  },
  content: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  text: {
    fontSize: Platform.OS === 'android' ? 12 : 15,
    fontFamily: fonts.lato.regular,
    color: colors.black,
  },
  bar: {
    height: 13,
    width: 3,
    borderRadius: 3,
    backgroundColor: colors.grey,
    position: 'absolute',
    right: '8%',
    opacity: 0.1,
  },
  icon: {
    marginRight: 10,
    height: 17,
    width: 17,
  }
});
