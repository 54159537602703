import { Dimensions, StyleSheet } from 'react-native';

const { width, height } = Dimensions.get('window');

export default StyleSheet.create({
  webContainer: {
    marginBottom: 20,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    height: height * 0.05,
  },
  webImage: {
    width: 38,
    height: 34,
    marginRight: 10,
  },
  webPastEventContainer: {
    marginTop: 60,
  },
  webPastEventSubContainer: {
    width: '18%',
    borderRadius: 30,
    marginBottom: 20,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    height: height * 0.05,
    justifyContent: 'center',
  },
  webPastEventImage: {
    width: 22,
    height: 20,
    marginRight: 10,
  },
  mobileContainer: {
    width: '90%',
    alignItems: 'center',
  },
  mobileItemContainer: {
    width: '100%',
    marginBottom: 15,
  },
  mobileItemSubContainer: {
    flex: 1,
    width: '100%',
    marginBottom: 20,
    height: width * 1.15,
  },
});
