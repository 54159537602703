import Slider from 'react-slick';
import { View } from 'native-base';
import { useWindowDimensions } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';

import WebItem from './Item';
import Stepper from './Stepper';
import settings from './settings';
import { getWebListNumberOfItem, ResponsiveView } from '../device';

const WebDuoList = ({ resources, type, showSide = false, bigViewIndex, push, numberOfItem = 5 }) => {
  const customSlider = useRef();
  const { width } = useWindowDimensions();
  const [mounted, setMounted] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [showSideItem, setShowSideItem] = useState([
    {
      right: false,
      left: false,
    },
    {
      right: false,
      left: false,
    },
    {
      right: false,
      left: false,
    },
  ]);

  const setSideItemFromIndex = (index) => {
    setShowSideItem([
      {
        right: index === 0,
        left: index === 0,
      },
      {
        right: index === 1,
        left: index === 1,
      },
      {
        right: index === 2,
        left: index === 2,
      }
    ]);
  };

  let responsiveNumberOfItem;
  const maxNumberOfItem = getWebListNumberOfItem(width);
  if (numberOfItem > maxNumberOfItem) {
    responsiveNumberOfItem = maxNumberOfItem;
  } else {
    responsiveNumberOfItem = numberOfItem;
  }

  const resourcesFiltered = resources.filter((item) => item.type === type);

  const formattedData = [];
  let tmp = [];

  let viewIndex = bigViewIndex;
  let nextBigViewIndex = viewIndex;
  for (let i = 0; i < resourcesFiltered.length; ++i) {
    if (i === nextBigViewIndex) {
      formattedData.push([resourcesFiltered[i]]);
      nextBigViewIndex += responsiveNumberOfItem + viewIndex;
    } else {
      tmp.push(resourcesFiltered[i]);
      if (tmp.length === 2) {
        formattedData.push(tmp);
        tmp = [];
      }
    }
  }

  if (tmp.length > 0) {
    if (tmp.length === 1) {
      formattedData.push([tmp[0], { empty: true }]);
    } else {
      formattedData.push(tmp);
    }
  }

  const onClickStepperLeft = () => {
    const index = (slideIndex - 1) % 3;
    setSideItemFromIndex(index);
    setSlideIndex(slideIndex - 1);
    customSlider.current.slickPrev();
  };

  const onClickStepperRight = () => {
    const index = (slideIndex + 1) % 3;
    setSideItemFromIndex(index);
    setSlideIndex(slideIndex + 1);
    customSlider.current.slickNext();
  };

  const slices = [];
  for (let i = 0; i < formattedData.length; i += responsiveNumberOfItem){
    slices.push([i, (i + responsiveNumberOfItem)]);
  }

  useEffect(() => {
    if (!mounted && formattedData.length > responsiveNumberOfItem) {
      setSideItemFromIndex(0);
      setMounted(true);
    }
  });

  console.log(showSideItem);
  return (
    <Slider
      ref={slider => (customSlider.current = slider)}
      beforeChange={() => setDragging(true)}
      afterChange={() => setDragging(false)}
      draggable={false}
      {...settings}
    >
      {
        slices.map((slice, index) => (
          <View key={index}>
            {index !== 0 && <Stepper position="left" onClick={onClickStepperLeft} />}
            {index !== slices.length - 1 && <Stepper position="right" onClick={onClickStepperRight} />}
            <ResponsiveView style={[{ flexDirection: 'row' }, !showSide && { justifyContent: 'space-between' }]}>
              {
                showSide && index > 0 && showSideItem[index % 3].left && (
                  <View style={{
                    flex: 1,
                    borderRadius: 15,
                    flexDirection: 'column',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    marginLeft: formattedData[slice[0] - 1].length === 1 ? -(width * 0.9 / (responsiveNumberOfItem + 1) * 1.5 - ((responsiveNumberOfItem + 1) * 2)) : -(width * 0.9 / (responsiveNumberOfItem + 1) - ((responsiveNumberOfItem + 1) * 2)),
                    minWidth: formattedData[slice[0] - 1].length === 1 ? (width * 0.9 / (responsiveNumberOfItem + 1) * 2) - 20 : (width * 0.9 / (responsiveNumberOfItem + 1)) - 10,
                    maxWidth: formattedData[slice[0] - 1].length === 1 ? (width * 0.9 / (responsiveNumberOfItem + 1) * 2) - 20 : (width * 0.9 / (responsiveNumberOfItem + 1)) - 10,
                  }}>
                    {
                      formattedData[slice[0] - 1].length === 1 ? (
                        <View style={{ height: (width * 0.9 / (responsiveNumberOfItem + 1) * 2) * 1.2 }}>
                          {!formattedData[slice[0] - 1].empty && <WebItem disabled={true} r={formattedData[slice[0] - 1][0]} push={push} onPress={dragging ? () => {} : undefined} />}
                        </View>
                      ) : (
                        <View>
                          <View style={{ height: (width * 0.9 / (responsiveNumberOfItem + 1)) * 1.2 - 10 }}>
                            {!formattedData[slice[0] - 1].empty && <WebItem disabled={true} r={formattedData[slice[0] - 1][0]} push={push} onPress={dragging ? () => {} : undefined} />}
                          </View>
                          {
                            formattedData[slice[0] - 1].length === 2 && (
                              <View style={{ height: (width * 0.9 / (responsiveNumberOfItem + 1)) * 1.2, marginTop: 10 }}>
                                {!formattedData[slice[0] - 1].empty && <WebItem disabled={true} r={formattedData[slice[0] - 1][1]} push={push} onPress={dragging ? () => {} : undefined} />}
                              </View>
                            )
                          }
                        </View>
                      )
                    }
                  </View>
                )
              }
              {
                formattedData.slice(slice[0], slice[1]).map((item, sliceIndex) => (
                  <View
                    key={sliceIndex}
                    style={{
                      flex: 1,
                      flexDirection: 'column',
                      marginLeft: showSide ? (index === 0 && sliceIndex === 0 ? 0 : 10) : 0,
                      minWidth: item.length === 1 ? (width * 0.9 / (responsiveNumberOfItem + 1) * 2) - 20 : (width * 0.9 / (responsiveNumberOfItem + 1)) - 10,
                      maxWidth: item.length === 1 ? (width * 0.9 / (responsiveNumberOfItem + 1) * 2) - 20 : (width * 0.9 / (responsiveNumberOfItem + 1)) - 10,
                    }}>
                    {
                      item.length === 1 ? (
                        <View style={{ height: (width * 0.9 / (responsiveNumberOfItem + 1) * 2) * 1.2 }}>
                          {!item.empty && <WebItem r={item[0]} push={push} onPress={dragging ? () => {} : undefined} />}
                        </View>
                      ) : (
                        <View>
                          <View style={{ height: (width * 0.9 / (responsiveNumberOfItem + 1)) * 1.2 - 10 }}>
                            {!item.empty && <WebItem r={item[0]} push={push} onPress={dragging ? () => {} : undefined} />}
                          </View>
                          {
                            item.length === 2 && (
                              <View style={{ height: (width * 0.9 / (responsiveNumberOfItem + 1)) * 1.2, marginTop: 10 }}>
                                {!item.empty && <WebItem r={item[1]} push={push} onPress={dragging ? () => {} : undefined} />}
                              </View>
                            )
                          }
                        </View>
                      )
                    }
                  </View>
                ))
              }
              {
                showSide && index < slices.length - 1 && showSideItem[index % 3].right && (
                  <View style={{
                    flex: 1,
                    marginLeft: 10,
                    borderRadius: 15,
                    flexDirection: 'column',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    minWidth: formattedData[slice[1]].length === 1 ? (width * 0.9 / (responsiveNumberOfItem + 1) * 2) - 20 : (width * 0.9 / (responsiveNumberOfItem + 1)) - 10,
                    maxWidth: formattedData[slice[1]].length === 1 ? (width * 0.9 / (responsiveNumberOfItem + 1) * 2) - 20 : (width * 0.9 / (responsiveNumberOfItem + 1)) - 10,
                  }}>
                    {
                      formattedData[slice[1]].length === 1 ? (
                        <View style={{ height: (width * 0.9 / (responsiveNumberOfItem + 1) * 2) * 1.2 }}>
                          <WebItem disabled={true} r={formattedData[slice[1]][0]} push={push} onPress={dragging ? () => {} : undefined} />
                        </View>
                      ) : (
                        <View>
                          <View style={{ height: (width * 0.9 / (responsiveNumberOfItem + 1)) * 1.2 - 10 }}>
                            {!formattedData[slice[1] + 1].empty && <WebItem disabled={true} r={formattedData[slice[1]][0]} push={push} onPress={dragging ? () => {} : undefined} />}
                          </View>
                          {
                            formattedData[slice[1]].length === 2 && (
                              <View style={{ height: (width * 0.9 / (responsiveNumberOfItem + 1)) * 1.2, marginTop: 10 }}>
                                {!formattedData[slice[1]].empty && <WebItem disabled={true} r={formattedData[slice[1]][1]} push={push} onPress={dragging ? () => {} : undefined} />}
                              </View>
                            )
                          }
                        </View>
                      )
                    }
                  </View>
                )
              }
            </ResponsiveView>
          </View>
        ))
      }
    </Slider>
  );
};

export default WebDuoList;
