import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import { Dimensions } from 'react-native';
import { View, Pressable, Image, Text } from 'native-base';

import { fonts, TextStyles } from 'styles';
import { Events as EventSvg, User as OthrSvg, Networks as NetworkSvg, Magazine, Podcasts, Videos } from 'styles/svg';

import promoData from './promoData';

import TextSection from 'components/textSection';
import { ResponsiveView } from 'components/device';
import WebDuoList from 'components/webLists/DuoList';
import WebSoloList from 'components/webLists/SoloList';
import PromoCarousel from 'components/webCarousel/Promo';
import HollowBackground from 'components/backgrounds/hollowBackground';

const { width } = Dimensions.get('window');

function Home({ push, resources }) {
  const WebListContainer = ({
    List,
    title,
    titleSvg,
    redirection,
    redirectionText,
    disableMarginTop = false,
    hollowBackground = false,
  }) => (
    <View
      style={{
        overflow: 'hidden',
        marginTop: disableMarginTop ? 0 : 50,
        paddingTop: hollowBackground ? width * 0.02 : undefined,
        paddingBottom: hollowBackground ? width * 0.02 : undefined,
      }}
    >
      { hollowBackground && <HollowBackground /> }
      <ResponsiveView style={{ flexDirection: 'row', marginBottom: 20 }}>
        <Image
          alt="Change me"
          style={{
            width: 30,
            height: 30,
            marginRight: 5,
            resizeMode: 'contain',
          }}
          source={titleSvg}
        />
        <TextSection titleSection={title} noBar />
        <Pressable onPress={() => push(redirection)} style={{ cursor: 'pointer', marginLeft: 'auto' }}>
          <Text style={{ fontFamily: fonts.lato.bold, fontSize: 16, textDecorationLine: 'underline', textUnderlineOffset: 3 }}>{redirectionText}</Text>
        </Pressable>
      </ResponsiveView>
      <View>
        <List />
      </View>
    </View>
  );

  return (
    <View>
      <PromoCarousel push={push} data={promoData} />
      <WebListContainer
        disableMarginTop
        List={() => <WebSoloList showSide={true} push={push} resources={resources} type="event" />}
        title="Events"
        titleSvg={EventSvg}
        redirection="Event"
        redirectionText="Tous les évènements"
      />
      <WebListContainer
        List={() => <WebDuoList showSide={true} push={push} resources={resources} type="user" bigViewIndex={2} />}
        title="Othr"
        hollowBackground
        titleSvg={OthrSvg}
        redirection="Others"
        redirectionText="Tous les othr"
      />
      <WebListContainer
        List={() => <WebSoloList showSide={true} push={push} resources={resources} type="network" />}
        title="Groupements"
        titleSvg={NetworkSvg}
        redirection="Network"
        redirectionText="Tous les groupements"
      />
      {/* <ImageBackground style={{ width: '100%', height: height * 0.7, marginTop: 70 }} source={HomePng} /> */}
      <WebListContainer
        List={() => <WebSoloList showSide={true} push={push} resources={resources} type="article" />}
        title="Articles"
        titleSvg={Magazine}
        redirection="Articles"
        redirectionText="Tous les articles"
      />
      <WebListContainer
        List={() => <WebSoloList showSide={true} push={push} resources={resources} type="podcast" />}
        title="Podcasts"
        titleSvg={Podcasts}
        redirection="Podcasts"
        redirectionText="Tous les podcasts"
      />
      <WebListContainer
        List={() => <WebSoloList showSide={true} push={push} resources={resources} type="video" />}
        title="Vidéos"
        titleSvg={Videos}
        redirection="Videos"
        redirectionText="Toutes les vidéos"
      />
    </View>
  );
}

export default Home;
