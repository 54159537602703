import Api from 'services/api';

export function getGivenRecommendations() {
  return async (dispatch) => {
    const response = await Api.recommendations.getGiven();

    if (response?.status === 200) {
      const givenRecommendations = response.data;
      dispatch({
        type: 'LOAD_GIVEN_RECOMMENDATIONS',
        givenRecommendations,
      });
      return Promise.resolve(givenRecommendations);
    }

    return Promise.reject();
  };
}

export function getReceivedRecommendations() {
  return async (dispatch) => {
    const response = await Api.recommendations.getReceived();

    if (response?.status === 200) {
      const receivedRecommendations = response.data;
      dispatch({
        type: 'LOAD_RECEIVED_RECOMMENDATIONS',
        receivedRecommendations,
      });
      return Promise.resolve(receivedRecommendations);
    }

    return Promise.reject();
  };
}

export function createRecommendation(values) {
  return async (dispatch, getState) => {
    try {
      const response = await Api.recommendations.create(values);
      console.log('Recommendation created');
      return Promise.resolve(response.data);
    } catch (err) {
      console.log('Error in create recommendation', err);
      return Promise.reject(err);
    }
  };
}

export function getRecommendationDetails(id) {
  return async (dispatch) => {
    try {
      const response = await Api.recommendations.get(id);

      if (response?.status === 200) {
        const recommendation = response.data;
        dispatch({
          type: 'LOAD_RECOMMENDATION',
          recommendation,
        });
        return Promise.resolve(recommendation);
      }
    } catch (err) {
      console.log('Error in get recommendation', err);
      return Promise.reject(err);
    }
  };
}

export function acceptRecommendation(id, message) {
  return async (dispatch) => {
    try {
      const response = await Api.recommendations.accept(id, message);

      if (response?.status === 200) {
        const recommendation = response.data;
        return Promise.resolve(recommendation);
      }
    } catch (err) {
      console.log('Error in accept recommendation', err);
      return Promise.reject(err);
    }
  };
}

export function refuseRecommendation(id, message) {
  return async (dispatch) => {
    try {
      const response = await Api.recommendations.refuse(id, message);

      if (response?.status === 200) {
        const recommendation = response.data;
        return Promise.resolve(recommendation);
      }
    } catch (err) {
      console.log('Error in refuse recommendation', err);
      return Promise.reject(err);
    }
  };
}

export function recommendationContractSigned(id, amount) {
  return async (dispatch) => {
    try {
      const response = await Api.recommendations.contractSigned(id, amount);

      if (response?.status === 200) {
        const recommendation = response.data;
        return Promise.resolve(recommendation);
      }
    } catch (err) {
      console.log('Error in recommendation contract signed', err);
      return Promise.reject(err);
    }
  };
}
