import { StyleSheet, Platform, Dimensions } from 'react-native';
import { colors, fonts } from 'styles';
import { isIphoneX, getStatusBarHeight } from 'react-native-iphone-x-helper';
import { isBrowser } from '../../../../components/device';

const { height } = Dimensions.get('screen');

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: Platform.OS !== 'web' ? 'flex-start' : 'flex-start',
    width: '100%',
    minHeight: height,
    paddingTop: Platform.OS !== 'web' ? getStatusBarHeight(true) : 0,
    paddingBottom: 40,
  },
  logo: {
    marginTop: Platform.OS !== 'web' ? isIphoneX() ? '5%' : '10%' : '0%',
    marginBottom: Platform.OS !== 'web' ? isIphoneX() ? '30%' : '25%' : '2%',
  },
  titleContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: isBrowser ? 20 : 0,
  },
  title: {
    color: colors.greyDayColor,
    fontSize: 15,
  },
  form: {
    width: '70%',
    marginTop: Platform.OS !== 'web' ? '10%' : '0%',
  },
  forgotTxt: {
    color: colors.grey,
    fontSize: 14,
    opacity: 0.5,
    textAlign: 'center',
    marginTop: 10,
    width: '100%',
  },
  subscriptionDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 40,
    height: 60,
    width: '60%',
  },
  subscriptionTitle: {
    color: colors.burgundy,
    fontSize: 14,
    fontFamily: fonts.lato.black
  },
  subscriptionNetwork: {
    color: colors.grey,
    fontSize: 12,
    fontFamily: fonts.lato.bold,
  },
  subscriptionText: {
    color: colors.grey,
    fontSize: 14,
    fontFamily: fonts.lato.regular
  },
  cguText: {
    marginTop: 20,
    color: colors.grey,
    textDecorationLine: 'underline',
    fontSize: 14,
    fontFamily: fonts.lato.light,
    marginBottom: 10
  },
  subscriptionSeparator: {
    width: 1,
    height: '100%',
    backgroundColor: colors.grey,
    marginHorizontal: 15,
  },
  priceText: {
    color: colors.burgundy,
    fontFamily: fonts.lato.black,
    fontSize: 24,
  },
  taxText: {
    color: colors.grey,
    fontSize: 14,
  },
  subscriptionRenewal: {
    color: colors.grey,
    fontSize: 10,
    fontFamily: fonts.lato.regular,
  }
});
