import { StyleSheet, Platform } from 'react-native';
import { colors, fonts } from 'styles';

export const TextSectionStyle = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: Platform.OS !== 'web' ? '1%' : '0.5%',
    marginBottom: Platform.OS !== 'web' ? '5%' : '0%',
  },
  textTitle: {
    fontSize: Platform.select({
      web: 25,
      android: 17,
      ios: 20
    }),
    fontFamily: Platform.OS === 'android' ? fonts.lato.bold : fonts.lato.bold,
    color: colors.grey,
    paddingRight: 10,
  },
  vertLine: {
    width: 3,
    height: 14,
    backgroundColor: colors.greyLight,
    borderRadius: 3,
  },
});
