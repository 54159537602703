import { colors } from 'styles';
import { Dimensions, Platform, StyleSheet } from 'react-native';

const { width } = Dimensions.get('screen');
console.log('width', width);

const AdvertStyle = (enableMarginTop = true) => StyleSheet.create({
  containerPublicite: {
    padding: 20,
    backgroundColor: colors.white,
    marginTop: enableMarginTop ? '7%' : 0,
  },
  imagePub: {
    height: Platform.OS !== 'web' ? 400 : 200,
    width: Platform.OS !== 'web' ? (width - 40) : 300,
    resizeMode: 'cover',
  },
});

export default AdvertStyle;
