import React, { useState } from 'react';
import { View, ChevronLeftIcon, Pressable } from 'native-base';
import { Dimensions, FlatList, Text, TouchableOpacity } from 'react-native';

import { colors } from 'styles';

import Svg from '../svg/Svg';
import menus from './config';
import MenuItem from './MenuItem';
import SubMenu from './SubMenu';
import { MenuStyle } from './MenuStyle';
import { connect } from 'react-redux';

const WebMenu = ({ push, user }) => {
  const [selected, setSelected] = useState(undefined);

  const renderSubMenu = ({ item, index }) => (
    <SubMenu item={item} index={index} onPress={(target) => push(target)} logout={() => {}} />
  );

  return (
    <View style={{ width: 305, backgroundColor: colors.whiteShadow, padding: 20, borderRadius: 10 }}>
      {
        selected ? (
          <View style={MenuStyle.selectedContainer}>
            <View style={{ flexDirection: 'row' }}>
              <Pressable
                onPress={() => setSelected(undefined)}
                style={{ position: 'absolute', height: 49, marginLeft: -60, alignItems: 'center', justifyContent: 'center' }}
              >
                <ChevronLeftIcon />
              </Pressable>
              <Svg src={menus[selected].image} width={35} height={35} />
            </View>
            <Text style={MenuStyle.text}>{menus[selected].title}</Text>
            <FlatList
              numColumns={2}
              renderItem={renderSubMenu}
              data={menus[selected].children.filter(e => !e.isAvailable || e.isAvailable(user))}
              contentContainerStyle={{ marginTop: 25 }}
              columnWrapperStyle={{ justifyContent: 'center' }}
              keyExtractor={(item, index) => index.toString()}
            />
          </View>
        ) : (
          <>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ opacity: selected && selected !== 'Events' ? 0.5 : 1 }}>
                <MenuItem onPress={() => setSelected('Events')} data={menus['Events']} svgSize={35} containerSize={113} />
              </View>
              <View style={{ opacity: selected && selected !== 'Othrs' ? 0.5 : 1 }}>
                <MenuItem onPress={() => setSelected('Othrs')} data={menus['Othrs']} svgSize={35} containerSize={113} />
              </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ opacity: selected && selected !== 'Groupements' ? 0.5 : 1 }}>
                <MenuItem onPress={() => setSelected('Groupements')} data={menus['Groupements']} svgSize={35} containerSize={113} />
              </View>
              <View style={{ opacity: selected && selected !== 'Magazine' ? 0.5 : 1 }}>
                <MenuItem onPress={() => setSelected('Magazine')} data={menus['Magazine']} svgSize={35} containerSize={113} />
              </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ opacity: selected && selected !== 'Podcasts' ? 0.5 : 1 }}>
                <MenuItem onPress={() => setSelected('Podcasts')} data={menus['Podcasts']} svgSize={35} containerSize={113} />
              </View>
              <View style={{ opacity: selected && selected !== 'Othr TV' ? 0.5 : 1 }}>
                <MenuItem onPress={() => setSelected('Othr TV')} data={menus['Othr TV']} svgSize={35} containerSize={113} />
              </View>
            </View>
          </>
        )
      }
    </View>
  );
};

const mapStateToProps = (state) => ({
  user: state.Account.currentUser,
});

export default connect(mapStateToProps, null)(WebMenu);
