import Iframe from 'react-iframe';
import * as Linking from 'expo-linking';
import React, { useState } from 'react';
import { Icon } from 'react-native-elements';
import * as WebBrowser from 'expo-web-browser';
import { useWindowDimensions, Image, Text } from 'react-native';
import { Pressable, View, ChevronDownIcon, ChevronUpIcon, AlertDialog, Button } from 'native-base';

import { colors, fonts } from 'styles';
import { toastSuccess } from 'services/toasts';
import { BurgundyCancel, Location, Pencil, SwapRequestWeb } from 'styles/svg';
import { pushToUser } from 'services/navigationPush';

import styles from './UserDetailsStyle';

import Svg from 'components/svg/Svg';
import Loader from 'components/loader';
import WebMap from 'components/map/WebMap';
import BombButton from 'components/buttons/bombButton';
import GrayButton from 'components/buttons/grayButton';
import WebSoloList from 'components/webLists/SoloList';
import WebUserImage from 'components/cards/WebUserImage';
import MultiColorButton from 'components/buttons/multiColorButton/MultiColorButton';
import HollowBackground from 'components/backgrounds/hollowBackground/HollowBackground';

const mobileBreakpoint = 500;

const UserDescription = ({ user, publicPage, width, isPendingSwap, swapRequest, push, openWebsite, userId }) => {
  const urlParams = user?.video_url ? new URLSearchParams(user?.video_url.split('?')[1]) : null;
  const formattedVideoUrl = `https://youtube.com/embed/${urlParams?.get('v')}?autoplay=0`;
  const phoneAvailable = (user?.relationship === 'A' || user?.relationship === 'B' || user?.swapRequestAccepted) && user.phone;
  const emailAvailable = (user?.relationship === 'A' || user?.relationship === 'B' || user?.swapRequestAccepted) && user.email_contact;

  return (
    <View style={{
      height: '100%',
      marginLeft: '5%',
      width: '100%',
    }}>
      {user.logo_url && <View style={{ width: '100%', marginTop: width > mobileBreakpoint ? 0 : 20, flexDirection: 'row', alignItems: 'center' }}>
        <Image
          alt="Change me"
          source={{ uri: !!(user?.logo_url || user?.picture_url) ? user?.logo_url || user?.picture_url : null }}
          style={{
            borderRadius: 15,
            width: 90,
            height: 90,
          }}
        />
        <View style={{ marginLeft: 15 }}>
          <Text selectable style={{ fontFamily: fonts.lato.regular, fontSize: 17, lineHeight: 18, color: colors.grey }}>Entreprise</Text>
          <Text selectable style={{ fontFamily: fonts.lato.black, fontSize: 25, color: colors.black }}>{user?.company}</Text>
        </View>
      </View>}
      <View style={{ width: '100%', marginTop: width > mobileBreakpoint ? 0 : 20 }}>
        {
          user ? (
            <Text selectable style={{
              color: colors.black,
              fontSize: 23,
              fontFamily: fonts.lato.regular,
              marginBottom: '3%',
              marginTop: '3%',
            }}>Qui est {`${user.firstname} ${user.lastname}`} ?</Text>
          ) : null
        }
        {
          user.video_url && (
            <View style={{ width: '75%', height: 250, marginBottom: '3%', borderRadius: 10, overflow: 'hidden' }}>
              <Iframe width="100%" height="100%" url={formattedVideoUrl} />
            </View>
          )
        }
        <View style={{
          width: '100%',
          paddingBottom: '5%',
        }}>
          <Text selectable style={{
            lineHeight: 24,
            color: colors.greyDark,
            fontSize: 15,
            fontFamily: fonts.lato.regular,
            width: '100%'
          }}>{user?.description}</Text>
        </View>
      </View>
      <View style={{ flexDirection: width > mobileBreakpoint ? 'row' : 'column', width: '100%' }}>
        <View style={{ width: width > mobileBreakpoint ? '40%' : '90%' }}>
          {user?.website && (
            <View style={{ width: '100%', borderRadius: 50, height: 65, alignItems: 'center', marginBottom: '2.5%', justifyContent: 'center', overflow: 'hidden' }}>
              <HollowBackground />
              <GrayButton onPress={openWebsite} titre={user?.website} width="90%" />
            </View>
          )}
          {
            !publicPage && !swapRequest && user?.id !== userId && (
              user?.relationship === 'B' ? (
                <MultiColorButton style={{ width: '100%' }} onPress={() => push('AddRecommendation', { userId: user?.id })} text="Recommander" />
              ) : (
                !swapRequest ? (
                  isPendingSwap ? (
                    <MultiColorButton style={{ width: '100%', marginTop: '1%' }} color="#676767" textColor={'rgba(0,0,0,0.5)'} disabled text="Demande envoyée" />
                  ) : (
                    <MultiColorButton style={{ width: '100%', marginTop: '1%' }} onPress={() => push('CreateSwapRequest', { userId: user?.id })} text="Rencontrer" />
                  )
                ) : null
              )
            )
          }
        </View>
        {(phoneAvailable || emailAvailable) && <View style={{ justifyContent: 'center', width: width > mobileBreakpoint ? '50%' : '90%', marginLeft: width > mobileBreakpoint ? '10%' : 0, borderRadius: 20, overflow: 'hidden' }}>
          <HollowBackground />
          {phoneAvailable && <Pressable
            disabled={!user?.phone}
            onPress={() => Linking.openURL(`sms:${user?.phone}`)}
            style={{ marginLeft: 20, flexDirection: 'row', alignItems: 'center' }}
          >
            <Icon
              size={25}
              name="screen-smartphone"
              type="simple-line-icon"
              color={colors.grey}
            />
            <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15, marginLeft: 10, width: '80%' }}>{user?.phone}</Text>
          </Pressable>}
          {emailAvailable && <Pressable
            disabled={!user?.email_contact}
            onPress={() => Linking.openURL(`mailto:${user?.email_contact}`)}
            style={{ marginLeft: 20, marginTop: 5, flexDirection: 'row', alignItems: 'center' }}
          >
            <Icon
              size={25}
              name="envelope"
              type="simple-line-icon"
              color={colors.grey}
            />
            <Text numberOfLines={1} style={{ fontFamily: fonts.lato.bold, fontSize: 15, marginLeft: 10, width: '80%' }}>{user?.email_contact}</Text>
          </Pressable>}
        </View>}
      </View>
    </View>
  );
};

const UserWorths = ({ width, user }) => (
  <View style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', marginTop: 30, marginBottom: 30, paddingTop: 40, paddingBottom: 40 }}>
    <View style={{ width: width > mobileBreakpoint ? '75%' : '90%', flexDirection: width > mobileBreakpoint ? 'row': 'column', justifyContent: 'center' }}>
      <View style={{ width: width > mobileBreakpoint ? '48%' : '100%' }}>
        {user?.activities.length > 0 && <Text selectable style={{ fontFamily: fonts.lato.bold, color: colors.grey, fontSize: 20, textDecorationLine: 'underline', textUnderlineOffset: 6 }}>Activités</Text>}
        {
          user?.activities.map((s, index) => (
            <View key={index}>
              <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15, marginTop: 30, marginBottom: 20, color: colors.burgundy }}>{s.name.toUpperCase()}</Text>
              <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, color: colors.greyMedium2 }}>{s.description}</Text>
            </View>
          ))
        }
        {user?.services.length > 0 && <Text selectable style={{ marginTop: user?.activities.length > 0 ? 50 : 0, fontFamily: fonts.lato.bold, color: colors.grey, fontSize: 20, textDecorationLine: 'underline', textUnderlineOffset: 6 }}>Services</Text>}
        {
          user?.services.map((s, index) => (
            <View key={index}>
              <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15, marginTop: 30, marginBottom: 20, color: colors.burgundy }}>{s.name.toUpperCase()}</Text>
              <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, color: colors.greyMedium2 }}>{s.description}</Text>
            </View>
          ))
        }
      </View>
      <View style={{ width: width > mobileBreakpoint ? '48%' : '100%', marginLeft: width > mobileBreakpoint ? '4%' : 0, marginTop: width > mobileBreakpoint ? 0 : 30 }}>
        {user?.arguments.length > 0 && <Text style={{ fontFamily: fonts.lato.bold, color: colors.grey, fontSize: 20, textDecorationLine: 'underline', textUnderlineOffset: 6 }}>Pourquoi {user?.firstname}</Text>}
        {
          user?.arguments.map((a, index) => (
            <View key={index}>
              <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15, marginTop: 30, marginBottom: 20, color: colors.burgundy }}>{a.name.toUpperCase()}</Text>
              <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, color: colors.greyMedium2 }}>{a.description}</Text>
            </View>
          ))
        }
        {user?.anecdotes?.length > 0 && <Text style={{ marginTop: user?.arguments.length > 0 ? 50 : 0, fontFamily: fonts.lato.bold, color: colors.grey, fontSize: 20, textDecorationLine: 'underline', textUnderlineOffset: 6 }}>Anecdotes</Text>}
        {
          user?.anecdotes?.map((a, index) => (
            <View key={index}>
              <Text style={{ fontFamily: fonts.lato.bold, fontSize: 15, marginTop: 30, marginBottom: 20, color: colors.burgundy }}>{a.name.toUpperCase()}</Text>
              <Text style={{ fontFamily: fonts.lato.regular, fontSize: 15, color: colors.greyMedium2 }}>{a.description}</Text>
            </View>
          ))
        }
      </View>
    </View>
  </View>
);

function TextCount(props) {
  const { title, count, colorText } = props;
  return (
    <View style={styles.textCountContainer}>
      <Text style={[styles.textCount, { color: colorText }]}>{count}</Text>
      <Text style={styles.titleCount}>{title}</Text>
    </View>
  );
}

const SwapRequestResponse = ({ user, swapRequestMessage, onPressAccepted, onPressRejected, acceptSwapResponseLoading, refuseSwapResponseLoading }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <View style={{ width: '100%', position: 'fixed', zIndex: 100, marginTop: -80 }}>
      {
        isFormOpen && (
          <View style={{ width: '100%', paddingTop: 60, alignItems: 'center', backgroundColor: colors.white }}>
            <View style={{ width: '40%' }}>
              <Text style={{ fontSize: 18, fontFamily: fonts.lato.bold, marginLeft: 20 }}>
                Le message de {user?.firstname}
              </Text>
              <View style={{ width: '100%', marginTop: 20, borderRadius: 15, overflow: 'scroll', height: 200 }}>
                <HollowBackground />
                <Text style={{ fontSize: 16, marginTop: 10, fontFamily: fonts.lato.regular, marginLeft: 20 }}>
                  {swapRequestMessage}
                </Text>
              </View>
            </View>
            <Text style={{ marginTop: 40, color: colors.greyDayColor, fontSize: 14, fontFamily: fonts.lato.regular }}>
              Si vous acceptez, vos coordonnées seront transmises à {user?.firstname} afin de prendre contact avec vous
            </Text>
            <View style={{ marginTop: 20, width: '100%', paddingBottom: isFormOpen ? 40 : 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <GrayButton
                width="15%"
                onPress={onPressAccepted}
                loading={acceptSwapResponseLoading}
                titre="Accepter"
              />
              <GrayButton
                width="15%"
                onPress={onPressRejected}
                loading={refuseSwapResponseLoading}
                style={{ marginLeft: 30 }}
                titre="Refuser"
              />
            </View>
          </View>
        )
      }
      <View style={{ width: '100%', height: 90, marginTop: isFormOpen ? 0 : 40, justifyContent: 'center', alignItems: 'center', backgroundColor: colors.green }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Svg style={{ width: 30, height: 30 }} src={SwapRequestWeb} />
          <Text style={{ fontSize: 20, fontFamily: fonts.lato.regular, color: 'white', marginLeft: 20 }}>
            {user?.firstname} souhaite vous rencontrer
          </Text>
        </View>
        {!isFormOpen ? (
          <Pressable style={{ position: 'absolute', bottom: 5 }} onPress={() => setIsFormOpen(true)}>
            <ChevronDownIcon style={{ color: 'white' }} />
          </Pressable>
        ) : (
          <Pressable style={{ position: 'absolute', top: 5 }} onPress={() => setIsFormOpen(false)}>
            <ChevronUpIcon style={{ color: 'white' }} />
          </Pressable>
        )}
      </View>
    </View>
  );
};

const UserDetails = ({ publicPage, user, push, currentUser, goBack, route, acceptSwapRequest, rejectSwapRequest, removeRelation }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [acceptSwapResponseLoading, setAcceptSwapResponseLoading] = useState(false);
  const [refuseSwapResponseLoading, setRefuseSwapResponseLoading] = useState(false);

  const { width, height } = useWindowDimensions();

  if (!user) {
    return (
      <View style={{ height }}>
        <View style={{ marginTop: '25%' }}>
          <Loader />
        </View>
      </View>
    );
  }
  const openWebsite = () => {
    WebBrowser.openBrowserAsync(user?.website.includes('http') ? user?.website : `https://${user?.website}`);
  };

  // Have I already requested a swap with this user
  const isPendingSwap = currentUser?.id !== user.id && currentUser?.swap_requests?.find((sr) => sr.user_a_id === currentUser?.id && sr.status === 'pending');

  // Has this user requested me a swap
  let swapRequest = currentUser?.id !== user.id && user?.swapRequest;

  const MapContainer = () => (
    user?.address && (
      <View style={{ width: width > mobileBreakpoint ? 448 : '90%', overflow: 'hidden', marginTop: '1%', borderRadius: 15, height: height * 0.31 }}>
        <HollowBackground />
        <View style={{ width: '96%', height: height * 0.213, margin: '2%' }}>
          <WebMap address={user?.address} color={user?.user?.color || '#ff0000'} />
        </View>
        <View style={{ marginTop: 5, marginBottom: 5, marginLeft: 20, alignItems: 'center', flexDirection: 'row' }}>
          <BombButton
            height={40}
            borderRadius={10}
            width={40}
            images={null}
            svg={Location}
            titre={null}
          />
          <View style={{ marginLeft: 20, width: '100%' }}>
            <Text style={{ width: '70%', marginVertical: '0.5%', fontFamily: fonts.lato.bold, fontSize: 14, lineHeight: 15 }}>{`${user?.address.number} ${user?.address.street}`}</Text>
            <Text style={{ width: '70%', marginVertical: '0.5%', fontFamily: fonts.lato.bold, fontSize: 14, lineHeight: 15 }}>{`${user?.address.zip_code} ${user?.address.city}`}</Text>
          </View>
        </View>
      </View>
    )
  );

  const DeleteDialogue = () => {
    const [isDeleteLoading, setDeleteLoading] = useState(false);

    return (
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(false)} leastDestructiveRef={undefined}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Supprimer des contacts</AlertDialog.Header>
          <AlertDialog.Body>
            Cette action supprimera définitivement cet utilisateur de vos contacts.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button variant="unstyled" colorScheme="coolGray" onPress={() => setDeleteOpen(false)}>
                Annuler
              </Button>
              <Button isLoading={isDeleteLoading} color={colors.burgundy} onPress={async () => {
                setDeleteLoading(true);
                await removeRelation(user?.id);
                setDeleteLoading(false);
                setDeleteOpen(false);
                push('Relations');
              }}>
                Supprimer
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  return (
    <View
      style={[styles.container, { width: '100%', marginTop: publicPage ? 70 : 0 }]}
    >
      <DeleteDialogue />
      {
        currentUser?.id !== user.id && user?.relationship !== 'B' && swapRequest && (
          <SwapRequestResponse
            user={user}
            swapRequestMessage={swapRequest.description}
            refuseSwapResponseLoading={refuseSwapResponseLoading}
            acceptSwapResponseLoading={acceptSwapResponseLoading}
            onPressAccepted={async () => {
              setAcceptSwapResponseLoading(true);
              await acceptSwapRequest(swapRequest.id);
              toastSuccess('Vous avez accepté l\'invitation de swap');
              pushToUser(push, user);
            }}
            onPressRejected={async () => {
              setRefuseSwapResponseLoading(true);
              await rejectSwapRequest(swapRequest.id);
              toastSuccess('Vous avez refuser l\'invitation de swap');
              pushToUser(push, user);
            }}
          />
        )
      }
      <View style={{
        width: width > mobileBreakpoint ? '68%' : '90%',
        marginLeft: width > mobileBreakpoint ? '12.5%' : '5%',
        alignItems: width > mobileBreakpoint ? 'start' : 'center',
        marginTop: currentUser?.id !== user.id && user?.relationship !== 'B' && swapRequest ? 80 : 0
      }}>
        {/*{*/}
        {/*  user?.relationship === 'B' && (*/}
        {/*    <View style={{ width: '100%' }}>*/}
        {/*      <Pressable*/}
        {/*        width="22%"*/}
        {/*        height={47}*/}
        {/*        onPress={() => {*/}
        {/*          window.scrollTo(0, 0);*/}
        {/*          setDeleteOpen(true);*/}
        {/*        }}*/}
        {/*        style={{ marginBottom: 20, marginLeft: 'auto', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 30, overflow: 'hidden' }}*/}
        {/*      >*/}
        {/*        <HollowBackground />*/}
        {/*        <Image alt="Change me" source={BurgundyCancel} style={{ marginLeft: 15, height: 13, width: 13 }} />*/}
        {/*        <Text style={{ fontFamily: fonts.lato.bold, fontSize: 17, color: colors.burgundy, marginLeft: 10, marginRight: 15 }}>Supprimer des contacts</Text>*/}
        {/*      </Pressable>*/}
        {/*    </View>*/}
        {/*  )*/}
        {/*}*/}
        {
          user?.id === currentUser?.id && (
            <View style={{ width: '100%' }}>
              <Pressable
                width="22%"
                height={47}
                onPress={() => push('Account')}
                style={{ marginBottom: 20, marginLeft: 'auto', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 30, overflow: 'hidden' }}
              >
                <HollowBackground />
                <Image alt="Change me" source={Pencil} style={{ height: 13, width: 13 }} />
                <Text style={{ fontFamily: fonts.lato.bold, fontSize: 17, marginLeft: 10 }}>Modifier le profil</Text>
              </Pressable>
            </View>
          )
        }
        <View style={{ width: width > 1290 ? width * 0.25 : 320, flexDirection: width > mobileBreakpoint ? 'row' : 'column' }}>
          <View style={{ width: '100%' }}>
            <WebUserImage
              user={user}
              height={width > 1290 ? width * 0.3 : 380}
            />
            <View style={[styles.containerCount, { width: '100%', marginTop: 20 }]}>
              <HollowBackground />
              <TextCount title="Recos" count={user?.nbRecos} colorText={user?.color || colors.burgundy} />
              <TextCount title="Swaps" count={user?.nbSwap} colorText={user?.color || colors.burgundy} />
              <TextCount title="Mercis" count={user?.nbThanks} colorText={user?.color || colors.burgundy} />
            </View>
          </View>
          <View style={{ marginLeft: width > mobileBreakpoint ? width * 0.02 : 0, width: width > mobileBreakpoint ? width * 0.4 : '100%' }}>
            <UserDescription width={width} publicPage={publicPage} user={user} isPendingSwap={isPendingSwap} swapRequest={swapRequest} push={push} openWebsite={openWebsite} userId={currentUser?.id} />
          </View>
        </View>
      </View>
      <UserWorths width={width} user={user} />
      {
        user?.address && (
          <View style={{ marginLeft: '12.5%', marginTop: '2%' }}>
            <Text selectable style={{ fontFamily: fonts.lato.bold, color: colors.grey, fontSize: 20 }}>Siège social</Text>
            <MapContainer />
          </View>
        )
      }
      <View style={{ marginTop: 50 }}/>
      {
        !publicPage && user?.networks && user?.networks.length > 0 && (
          <View style={{ width: '100%' }}>
            <Text style={{ marginLeft: '12.5%', marginTop: '3%', marginBottom: '1%', fontFamily: fonts.lato.bold, fontSize: 20 }}>Ses Groupements</Text>
            <WebSoloList showSide push={push} resources={user?.networks.map((n) => { return { ...n, type: 'network' };})} type="network" />
          </View>
        )
      }
      {
        !publicPage && user?.events && user?.events.length > 0 && (
          <View style={{ width: '100%' }}>
            <Text style={{ marginLeft: '12.5%', marginTop: '3%', marginBottom: '1%', fontFamily: fonts.lato.bold, fontSize: 20 }}>{user?.firstname} y participe</Text>
            <WebSoloList showSide push={push} resources={user?.events.map((n) => { return { ...n, type: 'event' };})} type="event" />
          </View>
        )
      }

      {
        !publicPage && user?.media && user?.media.length > 0 && (
          <View style={{ width: '100%' }}>
            <Text style={{ marginLeft: '12.5%', marginTop: '3%', marginBottom: '1%', fontFamily: fonts.lato.bold, fontSize: 20 }}>Ses Médias</Text>
            <WebSoloList showSide push={push} resources={user?.media} />
          </View>
        )
      }
    </View>
  );
};

export default UserDetails;
