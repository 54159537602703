import React, { useState } from 'react';
import { Ionicons } from '@expo/vector-icons';
import { Input, Image, View } from 'native-base';

import { colors, fonts } from 'styles';
import InputStyles from './InputStyles';
import { isBrowser, isIOS } from 'components/device';
import FieldWrapper from 'components/form/fieldWrapper';
import HollowBackground from 'components/backgrounds/hollowBackground';
import { Text, TouchableOpacity } from 'react-native';

export default (props) => {
  const {
    input,
    label,
    header,
    centered = false,
    multiline = false,
    height = 52,
    img = null,
    style,
    maxLength = undefined,
    customOnChange,
    ...inputProps
  } = props;

  const [show, setShow] = useState(false);

  const onChange = (e) => {
    if (customOnChange) {
      const newValue = customOnChange(e);
      input.onChange(newValue);
    } else {
      input.onChange(e);
    }
  };
  return (
    <FieldWrapper {...props}>
      <View style={{ width: '100%', paddingLeft: 0, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: header ? 25 : 0 }}>
        {!!header && <Text style={{ position: 'absolute', top: -25, left: centered ? 'auto' : 21, fontFamily: fonts.lato.bold }}>{header}</Text>}
        <View style={[InputStyles.containerInput, {
          borderRadius: multiline ? 25 : 25,
          borderWidth: !isBrowser ? 0 : 1,
          marginBottom: !isBrowser ? 0 : 10,
          borderColor: !isBrowser ? 'transparent' : '#f6f6f6',
          height: !isBrowser ? (multiline ? 'auto' : height) : height,
        }]}>
          <HollowBackground />
          {/* <div className="hollow-background"> */}
          {!!img && <Image alt="Change me" style={InputStyles.imgInput} source={img} />}
          <Input
            textAlign={centered ? 'center' : 'left'}
            textAlignVertical={multiline ? 'top' : 'auto'}
            maxLength={maxLength}
            fontSize={14}
            {...inputProps}
            width={'100%'}
            variant="unstyled"
            placeholder={label}
            multiline={multiline}
            onBlur={input.onBlur}
            scrollEnabled={false}
            onFocus={input.onFocus}
            defaultValue={input.value}
            onChangeText={onChange}
            fontFamily={fonts.lato.regular}
            paddingTop={multiline ? 5 : 0}
            paddingLeft={centered ? 0 : 21}
            placeholderTextColor={colors.secondary}
            placeholderStyle={{ fontFamily: fonts.lato.italic }}
            color={colors.grey}
            returnKeyType={multiline ? (isIOS ? 'default' : 'next') : 'done'}
            minHeight={isBrowser ? ((!multiline || height > 150) ? height : 150) : (multiline ? height - 20 : 150)}
            type={input.name?.includes('assword') ? (show ? 'text' : 'password') : 'text'}
            InputRightElement={input.name?.includes('assword') && (
              <TouchableOpacity onPress={() => setShow(!show)} style={{ position: 'absolute', right: 13, width: 30, height: 30, alignItems: 'center',justifyContent: 'center' }}>
                <Ionicons name={show ? 'ios-eye-outline' : 'ios-eye-off-outline'} size={18} color={colors.grey} />
              </TouchableOpacity>
            )}
          />
          {props.required ? (
            <View
              style={{
                width: 3,
                right: 3,
                margin: 10,
                height: 12,
                borderRadius: 20,
                position: 'absolute',
                backgroundColor: colors.firebrick,
                borderColor: isBrowser ? colors.whiteShadow : 'transparent',
              }}
            />
          ) : null}
        </View>
      </View>
    </FieldWrapper>
  );
};
