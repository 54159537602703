import { Platform, StyleSheet } from 'react-native';

import { colors, fonts } from 'styles';

export default StyleSheet.create({
  networkContainer: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    borderRadius: 15,
    position: 'relative',
  },
  networkNumberContainer: {
    width: '40%',
    height: 91,
    backgroundColor: 'rgba(255,255,255,0.3)',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.white,
    borderRadius: 30,
    borderWidth: 3,
  },
  networkNumber: {
    color: colors.white,
    fontSize: 35,
    fontFamily: fonts.lato.bold,
  },
  networkImage: {
    width: Platform.OS !== 'web' ? 100 : 89,
    height: Platform.OS !== 'web' ? 100 : 87,
    borderRadius: 15,
  },
  networkDescription: {
    fontFamily: fonts.lato.regular,
    fontSize: 14,
    maxWidth: '60%',
    marginLeft: 20,
    color: colors.white,
  },
});
